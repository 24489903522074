import { all, call, put, takeEvery } from 'redux-saga/effects';
import { notifyError, notifySuccess } from '../components/common/Alert';
import { POPUP_EVENTS } from '../constants/events';
import {
  DELETE_FOLDER,
  DELETE_FOLDER_SUCCESS,
  RENAME_FOLDER,
  RENAME_FOLDER_SUCCESS,
  CHECK_FOLDER_FAVORITE,
  CHECK_FOLDER_FAVORITE_SUCCESS,
  CHECK_FOLDER_FAVORITE_FAILURE
} from '../redux/actions/folders';
import { renameDocs } from '../services/documents';
import { deleteFolder } from '../services/folders';
import { emitter } from '../utils/emitter';
import { checkFolderFavorite } from '../services/folder.favorite'

function* deleteFolderSaga(action) {
  try {
    const result = yield call(deleteFolder, action);
    if (result.success) {
      notifySuccess(result.msg);
      emitter.emit(POPUP_EVENTS.HIDE_CONFIRM_DELETE_FOLDER);

      yield put({
        type: DELETE_FOLDER_SUCCESS,
        payload: action.payload,
      });
    }
  } catch (err) {
    notifyError(err.response.data.msg);
    emitter.emit(POPUP_EVENTS.HIDE_CONFIRM_DELETE_FOLDER);
  }
}

function* renameFolderSaga(action) {
  try {
    const result = yield call(renameDocs, action);
    if (result.success) {
      notifySuccess(result.msg);
      yield put({
        type: RENAME_FOLDER_SUCCESS,
        payload: action.payload,
      });
    }
  } catch (err) {
    notifyError(err.response.data.msg);
  }
}

function *checkFolderFavoriteSaga(action) {
  try {
    const result = yield call(checkFolderFavorite, action);
    if (result.success) {
      notifySuccess(result.msg);
      yield put({
        type: CHECK_FOLDER_FAVORITE_SUCCESS,
        payload: action.payload,
      });
    }
  } catch (err) {
    yield put({
      type: CHECK_FOLDER_FAVORITE_FAILURE,
    });
    console.log(err);
    notifyError(err.response.data.msg);
  }
}

export default function* foldersWatcher() {
  yield all([
    takeEvery(RENAME_FOLDER, renameFolderSaga),
    takeEvery(CHECK_FOLDER_FAVORITE, checkFolderFavoriteSaga),
    takeEvery(DELETE_FOLDER, deleteFolderSaga),
  ]);
}

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch, useRouteMatch, useHistory } from 'react-router-dom';
import { getProjects } from '../services/project';
import moment from 'moment';
import { BsPlusLg, BsPlus } from 'react-icons/bs';
// import { MdMoreVert, MdOutlineMoreHoriz } from 'react-icons/md';
// import { Dropdown } from 'react-bootstrap';
import User from '../assets/images/dummyUser.svg';
// import OverView from '../components/task/OverView';
// import ProjectList from '../components/task/ProjectList';
import NewProject from '../components/task/admin/NewProject';
import Dashboard from '../components/task/admin/Dashboard';
import MemberInfo from '../components/task/admin/MemberInfo';
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import 'swiper/components/pagination/pagination.less';

SwiperCore.use([Pagination]);

const mapTabToStatus = {
  0: 'in_process',
  1: 'completed',
  2: 'pending',
};


function Workflow() {

  const role = useSelector((s) => s.userInfo.role);
  const match = useRouteMatch();
  const [projects, setProjects] = useState([]);
  const history = useHistory();

  const createNewProject = () => {
    history.push(`${match.url}/new`);
  };
  useEffect(() => {
    getProjects().then(({ projects: res }) => setProjects(res));
  }, []);

  if (role === 'admin') {
    return (
      <div className="body">
        <Switch>
          <Route exact path={`${match.url}/new`} component={NewProject} />
          <Route exact path={`${match.url}/member`} component={MemberInfo} />
          <Route exact path={`${match.url}/:id`} component={NewProject} />
          <Route component={Dashboard} />
        </Switch>
      </div>
    );
  }

  if (role === 'user') {
    // console.log('user')
    return (
      <Switch>
        <Route exact path={`${match.url}/:id`} component={NewProject} />
        <Route component={Dashboard} />
      </Switch>
    )
  }

  return null;
}

export default Workflow

import React, { useEffect, useRef, useState } from 'react';
import {
  getAllowedIPs,
  createAllowedIPs,
  deleteAllowedIPs,
} from '../../../services/security';
import SkyLight from 'react-skylight';
import { Button } from 'react-bootstrap';
import Confirm from '../../task/common/Confirm';
import { notifyErrorMsg } from '../../common/Alert';
import { MdClear } from 'react-icons/md'
import { Form } from 'react-bootstrap';
const sendEmailStyle = {
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  width: '40%',
  margin: 'auto',
  height: 'fit-content',
  minHeight: '0',
  borderRadius: '10px',
};
export default function AllowedIPs() {
  const popupRef = useRef(null);
  const [allowedIPs, setAllowedIPs] = useState([]);
  const [ip, setIp] = useState('');
  const handleSubmit = () => {
    createAllowedIPs({ ip })
      .then(() => {
        setAllowedIPs([...allowedIPs, { ip }]);
        setIp('');
        popupRef.current.hide();
      })
      .catch((err) => {
        notifyErrorMsg(err);
      });
  };
  const handleDelete = (ipToDelete) => {
    deleteAllowedIPs({ ip: ipToDelete })
      .then(() => {
        setAllowedIPs(allowedIPs.filter((x) => x.ip !== ipToDelete));
      })
      .catch((err) => {
        notifyErrorMsg(err);
      });
  };
  useEffect(() => {
    getAllowedIPs()
      .then(({ org_ips }) => {
        setAllowedIPs(org_ips);
      })
      .catch((err) => {
        notifyErrorMsg(err);
      });
  }, []);
  return (
    <div className="mt-4">
      <div className="w-100 d-flex justify-content-between">
        <div style={{ fontSize: '15px', color: '#016288', fontWeight: 'normal' }}>Allowed IPs</div>
        <button
          type="button"
          style={{ padding: '' }}
          className="setting-user-btn"
          onClick={() => popupRef.current.show()}
        >
          Add
        </button>
      </div>
      <table className="c-table mt-4">
        <thead>
          <tr>
            <th style={{ width: '98%' }}>IP</th>
            <th style={{ width: '2%' }} />
          </tr>
        </thead>
        <tbody>
          {allowedIPs.map((r) => (
            <tr key={r.ip}>
              <td>{r.ip}</td>
              <td>
                <Confirm
                  message="Are you sure you want to delete this IP?"
                  handleClick={() => handleDelete(r.ip)}
                  iconClass="fa fa-trash-o interactive"
                  iconStyle={{ fontSize: '2rem' }}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <SkyLight
        hideOnOverlayClicked
        ref={popupRef}
        dialogStyles={sendEmailStyle}
      >
        <div className="checkinout" style={{ paddingTop: '0.5em' }}>
          <div
            className="check-success--title add-ip-title"
            style={{ marginBottom: '1rem' }}
          >
            <span className='mb-0' style={{ marginBottom: '0px !important' }}>Add IP</span>

            <i
              className="icon-times-grey"
              style={{ fontSize: '22px' }}
              onClick={() => popupRef.current.hide()}
            ><MdClear /></i>
          </div>
          <div className="d-flex" style={{margin:'40px 0 24px'}}>
            <Form.Control
              id="ip"
              type="text"
              className="primary-input"
              value={ip}
              onChange={(e) => setIp(e.target.value)}
              placeholder='Enter IP Adress'
            />
          </div>
          <div className="check-button">
            <button type="button" onClick={() => popupRef.current.hide()}>
              Cancel
            </button>
            <Button onClick={handleSubmit}>OK</Button>
          </div>
        </div>
      </SkyLight>
    </div>
  );
}

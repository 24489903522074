import io from 'socket.io-client';
import axios from 'axios';
import CONFIG from './constants/config';
import { showNoti, showNotiChat } from './components/common/Alert';
import { emitter } from './utils/emitter';
import { HOME_EVENTS } from './constants/events';
import { ACTION_SHOW_NOTIFY_INFO } from './redux/actions/message';

axios({
    url: `${CONFIG.BASE_URL}/reminder_token`,
    method: 'GET',
    withCredentials: true,
})
    .then(({ data: { data: { token } } }) => {
        const conn = io("https://cms.docquik.com");
        // const manage = new Manager('/notifications')
        conn.on('connect', () => {
            console.log('connected')
            conn.on('notifications', payload=>{
                console.log(payload)
            })
        })
        // console.log(manage)
        const socket = io('https://cms.docquik.com/notifications', { query: { token } });
        // socket.on('connection', (so)=>{
        //     console.log(so);
        // })
        socket.on('error', payload => console.log(payload));
        socket.on('notifyReminder', payload => {
            emitter.emit(HOME_EVENTS.NEW_NOTIFICATION);
            showNoti(payload);
        });
        socket.on('notifyChat', payload => {
            emitter.emit(ACTION_SHOW_NOTIFY_INFO, payload);
            emitter.emit(HOME_EVENTS.NEW_NOTIFICATION);
            showNotiChat(payload);
        });
    });

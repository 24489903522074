import React, { useRef, useEffect, useState } from 'react';
import SkyLight from 'react-skylight';
import { emitter } from '../../utils/emitter';
import { HOME_EVENTS, POPUP_EVENTS } from '../../constants/events';
import { useDispatch, useSelector } from 'react-redux';
import { notifyError } from '../common/Alert';
import CONFIG from '../../constants/config';
import { checkFile } from '../../redux/actions/files';
import { MdClear, MdOutlineFileUpload } from 'react-icons/md';

const initialCheckinState = {
  file: '',
  parent_id: '',
  note: '',
  file_name: '',
};
const popupStyle = {
  left: '0px',
  marginLeft: 0,
  marginTop: 0,
  top: '0px',
  right: '0px',
  bottom: '0px',
  margin: 'auto',
  width: '40%',
  minHeight: '0rem',
  borderRadius: 10,
  height: 'fit-content',
};
const acceptMimeTypes = Object.values(CONFIG.ACCEPT_MIME_TYPES)
  .map((arr) => arr.join(','))
  .join(',');
export default function CheckinPopup() {
  const popupRef = useRef(null);
  const files = useSelector((s) => s.files);
  const dispatch = useDispatch();
  const { upload: recentlyUpload, accessed: recentlyAccessed } = useSelector(
    (s) => s.quickAccess
  );
  const [hasFile, setHasFile] = useState('');
  const [checkInState, setCheckInState] = useState(initialCheckinState);
  const [fileId, setFileId] = useState(null);
  function handleUploadFile(e) {
    const [file] = e.target.files;
    let fileCheckIn = files.find((f) => f.file_id === fileId);
    if (!fileCheckIn) { fileCheckIn = recentlyUpload.find((f) => f.file_id === fileId); }
    if (!fileCheckIn) { fileCheckIn = recentlyAccessed.find((f) => f.file_id === fileId); }
    if (fileCheckIn && file) {
      setHasFile(`${file.name}.${fileCheckIn.type}`);
      setCheckInState({
        ...checkInState,
        file,
        fileId,
        parent_id: fileCheckIn.parent_id,
      });
    } else {
      notifyError("This file isn't same name and type as previous one.");
    }
  }
  const handleChangeNote = (name) => (e) => {
    setCheckInState({ ...checkInState, [name]: e.target.value });
  };
  const hidePopup = () => {
    popupRef.current.hide();
  };
  function handleDiscardCheckout() {
    dispatch(checkFile(fileId, false));
    popupRef.current.hide();
  }
  function handleCheckIn() {
    emitter.emit(
      HOME_EVENTS.HANLDE_CHECKIN_FILE,
      checkInState.file,
      fileId,
      checkInState.parent_id,
      checkInState.note,
      hidePopup
    );
    setHasFile('');
    if (document.getElementById('uploadfile')) {
      document.getElementById('checkin').value = '';
    }
  }
  useEffect(() => {
    const showPopup = (id) => {
      if (popupRef.current) popupRef.current.show();
      setFileId(id);
    };
    function listenToTheEmitter() {
      emitter.on(POPUP_EVENTS.SHOW_CHECKIN, showPopup);
    }
    listenToTheEmitter();
    return () => {
      setCheckInState(initialCheckinState);
      emitter.off(POPUP_EVENTS.SHOW_CHECKIN, showPopup);
    };
  }, []);

  return (
    <SkyLight
      hideOnOverlayClicked
      overlayStyles={{ backgroundColor: 'rgba(49,49,49,.5)' }}
      ref={popupRef}
      dialogStyles={popupStyle}
      afterClose={() => setCheckInState(initialCheckinState)}
    >
      <div className="checkinout">
        <div className="check-success--title">
          <span>Check in</span>
          <i
            onClick={() => popupRef.current.hide()}
          ><MdClear /></i>
        </div>
        <div className="check-upload">
          <div className="round-upload">
            <i><MdOutlineFileUpload /></i>
            <input
              type="file"
              id="checkin"
              name="myfile"
              onChange={handleUploadFile}
              accept={acceptMimeTypes}
            />
          </div>
          <p>{hasFile !== '' ? hasFile : 'Upload the lastest file'}</p>
        </div>
        <div className="check-input">
          <input
            type="text"
            placeholder="Notes for file"
            className="form-control mb-3"
            onChange={handleChangeNote('note')}
            value={checkInState.comment}
          />
        </div>
        <div className="check-success--sub-title mb-4">
          <span>
            If you do not want to revise,{' '}
            <span onClick={handleDiscardCheckout}>Discard Check - Out</span>
          </span>
        </div>
        <div className="check-button">
          <button type="button" onClick={() => popupRef.current.hide()}>
            Cancel
          </button>
          <button type="button" onClick={handleCheckIn}>
            Check in
          </button>
        </div>
      </div>
    </SkyLight>
  );
}

import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { MdMoreVert, MdAdd, MdMoreHoriz } from 'react-icons/md';
import { BsCursorFill } from 'react-icons/bs';
import ChatUser from '../../assets/images/chat-user.png';
import imgChatIcon from '../../assets/images/image-chat-icon.svg';
import fileIcon from '../../assets/images/file-chat-icon.svg';
import filmIcon from '../../assets/images/film-chat-icon.svg';
import { Scrollbars } from 'react-custom-scrollbars';
import * as scrollbar from '../../helpers/scrollbar';

export default function ChatModule(props) {

    const [search, setSearch] = useState('');
    const [chatMessage, setChatMessage] = useState('');
    const [uploadMenu, setUploadMenu] = useState(false);

    const chatSearchSubmit = (e) => {
        e.preventDefault();
    }
    const chatInputSubmit = (e) => {
        e.preventDefault();
    }

    return (
        <div className="chat-home-container">
            <form method="POST" onSubmit={chatSearchSubmit}>
                <div className="search-chat-wraps">
                    <label className="label-search">Search to start a chat</label>
                    <div className="form-search-wrap w-100" style={{ minWidth: 'unset' }}>
                        <input
                            type="text"
                            name="search"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            className="search-input bg-white"
                            placeholder="Search"
                        />
                        <button type="submit" className="search-btn">
                            <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.98674 12.4735C7.31503 12.4732 8.60505 12.0285 9.65138 11.2103L12.9411 14.5L13.9993 13.4418L10.7095 10.1521C11.5282 9.10569 11.9732 7.81538 11.9735 6.48674C11.9735 3.1858 9.28768 0.5 5.98674 0.5C2.6858 0.5 0 3.1858 0 6.48674C0 9.78768 2.6858 12.4735 5.98674 12.4735ZM5.98674 1.99669C8.46301 1.99669 10.4768 4.01048 10.4768 6.48674C10.4768 8.96301 8.46301 10.9768 5.98674 10.9768C3.51048 10.9768 1.49669 8.96301 1.49669 6.48674C1.49669 4.01048 3.51048 1.99669 5.98674 1.99669Z" fill="#C2CFE0" />
                            </svg>
                        </button>
                    </div>
                </div>
            </form>
            <div className="chat-wraps">
                <div className="chat-user-profile">
                    <div className="user-info">
                        <div className="user-profile">
                            <div className="user-image">
                                <img src={ChatUser} alt="user" />
                            </div>
                            {/* <span className="login-notifi"></span> */}
                        </div>
                        <div className="info-text">
                            <p>Project Group</p>
                            <span>5 Member</span>
                        </div>
                    </div>
                    <div className="buttons-wrap">
                        <Dropdown className="files-dropdown chat-dropdown">
                            <Dropdown.Toggle id="dropdown-button-dark-example1">
                                <MdMoreVert />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1" active>
                                    Action
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item href="#/action-4">Separated link</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
                <div className="chats-div">
                    <Scrollbars
                        renderView={scrollbar.renderView}
                        renderTrackHorizontal={scrollbar.renderTrackHorizontal}
                        renderTrackVertical={scrollbar.renderTrackVertical}
                        renderThumbHorizontal={scrollbar.renderThumb}
                        renderThumbVertical={scrollbar.renderThumb}
                        universal={true}
                    >
                        <div className="chat-text-wrap">
                            <div className="chat-block-wrap">
                                <div className="chat-left-wrap">
                                    <div className="img-block">
                                        <img src={ChatUser} alt="user" />
                                    </div>
                                    <div className="text-chat-wra">
                                        <div className="message-chat-wrap">
                                            <div className="message-text">
                                                <p>Can you send the file?</p>
                                            </div>
                                            <Dropdown className="files-dropdown chat-drop">
                                                <Dropdown.Toggle id="dropdown-button-dark-example1">
                                                    <MdMoreHoriz />
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Dropdown.Item href="#/action-1" active>
                                                        Action
                                                    </Dropdown.Item>
                                                    <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                                    <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                                    <Dropdown.Divider />
                                                    <Dropdown.Item href="#/action-4">Separated link</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <p className="date-text">3 days ago</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </Scrollbars>
                </div>
                <div className="chat-inputs-wrap">
                    <form method="post" onSubmit={chatInputSubmit}>
                        <div className="chat-upload-wrap">
                            <div className="uplode-drodown">
                                <button type="button" className={`btn-dropdown ${uploadMenu ? 'active' : ''}`} onClick={() => setUploadMenu(!uploadMenu)}>
                                    <MdAdd />
                                </button>
                                <ul className={`upload-dropdown-menu ${uploadMenu ? 'show' : ''}`}>
                                    <li>
                                        <input type="file" name="film" accept="image/*" id="filmButton" />
                                        <label htmlFor="filmButton"><img src={filmIcon} alt="user" /></label>
                                    </li>
                                    <li>
                                        <input type="file" name="image" accept="image/*" id="imageButton" />
                                        <label htmlFor="imageButton"><img src={imgChatIcon} alt="user" /></label>
                                    </li>
                                    <li>
                                        <input type="file" name="file" accept="image/*" id="imageButton" />
                                        <label htmlFor="imageButton"><img src={fileIcon} alt="user" /></label>
                                    </li>
                                </ul>
                            </div>
                            <div className="input-chat-module">
                                <input
                                    type="text"
                                    className="chat-input-message"
                                    name="chat-message"
                                    value={chatMessage}
                                    onChange={e => setChatMessage(e.target.value)}
                                    placeholder="Type a message here"
                                />
                            </div>
                            <button type="button" className="emoji-btn">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="10" cy="10" r="8.75" stroke="#707C97" strokeOpacity="0.5" strokeWidth="2.5" />
                                    <path d="M6.36475 11.3647C7.04818 12.7153 8.42009 13.6375 10.0011 13.6375C11.5821 13.6375 12.954 12.7153 13.6375 11.3647" stroke="#707C97" strokeOpacity="0.5" strokeWidth="2.5" strokeLinecap="round" />
                                    <circle cx="7.27384" cy="7.27384" r="0.909091" fill="#707C97" fillOpacity="0.5" />
                                    <circle cx="12.7279" cy="7.27384" r="0.909091" fill="#707C97" fillOpacity="0.5" />
                                </svg>
                            </button>
                            <button type="submit" className="chat-submit-btn">
                                <BsCursorFill />
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

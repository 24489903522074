export const MINIMIZE_POPUP = 'MINIMIZE_POPUP';
export const CHECK_ID_LINKED = 'CHECK_ID_LINKED';
export const CHECK_FILE_TO_LINK = 'CHECK_FILE_TO_LINK';
export const LINK_FILE = 'LINKED_FILE';
export const LINK_FILE_SUCCESS = 'LINKED_FILE_SUCCESS';
export const LINK_FILE_FAILURE = 'LINKED_FILE_FAILURE';
export const DELETE_LINK = 'DELETE_LINK';
export const DELETE_LINK_SUCCESS = 'DELETE_LINK_SUCCESS';
export const DELETE_LINK_FAILURE = 'DELETE_LINK_FAILURE';
export const CHECK_LINK_FILES = 'CHECK_LINK_FILES';
export const GET_LINKED_FILES = 'GET_LINKED_FILES';
export const GET_LINKED_FILES_SUCCESS = 'GET_LINKED_FILES_SUCCESS';
export const RESET_LINK_POPUP = 'RESET_LINK_POPUP';
export const minimizePopup = (bool) => ({
  type: MINIMIZE_POPUP,
  payload: bool,
});
export const checkIdLinked = (fileId) => ({
  type: CHECK_ID_LINKED,
  payload: fileId,
});
export const checkFileToLink = (file) => ({
  type: CHECK_FILE_TO_LINK,
  payload: file,
});
export const linkFile = (file_id, file_to_link) => ({
  type: LINK_FILE,
  payload: {
    file_id,
    file_to_link,
  },
});
export const deleteLink = (file_id, file_id_to_delete) => ({
  type: DELETE_LINK,
  payload: {
    file_id,
    file_id_to_delete,
  },
});
export const getLinkedFiles = (file_id_linked) => ({
  type: GET_LINKED_FILES,
  payload: file_id_linked,
});
export const checkLinkedFiles = (links) => ({
  type: CHECK_LINK_FILES,
  payload: links,
});
export const resetLinkPopup = () => ({
  type: RESET_LINK_POPUP,
});

export function renderView({ style, ...props }) {

    return (
        <div
            style={{ ...style }}
            className="main-box"
            {...props} 
        />
    );
}

export function renderThumb({ style, ...props }) {

    return (
        <div
            style={{ ...style }}
            className="thumb-scroll"
            {...props} 
        />
    );
}

export function renderTrackVertical({ style, props }) {
    return (
        <div className="thumb-scroll-track-vertical" {...props} />
    )
}

export function renderTrackHorizontal({ style, props }) {
    return (
        <div className="thumb-scroll-track-horizontal" {...props} />
    )
}
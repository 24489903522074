import React, { useEffect, useState } from 'react';
import {
  createPasswordPolicy,
  deletePasswordPolicy,
  getPasswordPolicy,
  updatePasswordPolicy,
} from '../../../services/security';
import { notifyErrorMsg } from '../../common/Alert';
import { Form } from 'react-bootstrap';

// eslint-disable-next-line complexity
export default function PasswordPolicy() {
  const [passwordPolicy, setPasswordPolicy] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const handleChange = (name) => (e) => {
    if (name === 'c_mixed_pwd') {
      setPasswordPolicy({
        ...passwordPolicy,
        c_mixed_pwd: !passwordPolicy.c_mixed_pwd,
      });
    } else {
      setPasswordPolicy({ ...passwordPolicy, [name]: e.target.value });
    }
  };
  const handleDelete = () => {
    deletePasswordPolicy()
      .then(() => {
        setPasswordPolicy({
          expiry_period: null,
          pwd_length: null,
          mixed_pwd: null,
          no_special_char: null,
          no_numberic_char: null,
          no_pwd_history: null,
          isCreate: true,
        });
      })
      .catch((err) => notifyErrorMsg(err));
  };
  const handleChangeEdit = () => {
    setIsEdit(true);
    setPasswordPolicy({
      ...passwordPolicy,
      c_expiry_period: passwordPolicy.expiry_period,
      c_pwd_length: passwordPolicy.pwd_length,
      c_mixed_pwd: passwordPolicy.mixed_pwd,
      c_no_special_char: passwordPolicy.no_special_char,
      c_no_numberic_char: passwordPolicy.no_numberic_char,
      c_no_pwd_history: passwordPolicy.no_pwd_history,
    });
  };
  const handleSubmit = () => {
    if (passwordPolicy.isCreate) {
      createPasswordPolicy({
        expiry_period: passwordPolicy.c_expiry_period,
        pwd_length: passwordPolicy.c_pwd_length,
        mixed_pwd: passwordPolicy.c_mixed_pwd,
        no_special_char: passwordPolicy.c_no_special_char,
        no_numberic_char: passwordPolicy.c_no_numberic_char,
        no_pwd_history: passwordPolicy.c_no_pwd_history,
      })
        .then(() => {
          setPasswordPolicy({
            expiry_period: passwordPolicy.c_expiry_period,
            pwd_length: passwordPolicy.c_pwd_length,
            mixed_pwd: passwordPolicy.c_mixed_pwd,
            no_special_char: passwordPolicy.c_no_special_char,
            no_numberic_char: passwordPolicy.c_no_numberic_char,
            no_pwd_history: passwordPolicy.c_no_pwd_history,
          });
          setIsEdit(false);
        })
        .catch((err) => notifyErrorMsg(err));
    } else {
      updatePasswordPolicy({
        expiry_period: passwordPolicy.c_expiry_period,
        pwd_length: passwordPolicy.c_pwd_length,
        mixed_pwd: passwordPolicy.c_mixed_pwd,
        no_special_char: passwordPolicy.c_no_special_char,
        no_numberic_char: passwordPolicy.c_no_numberic_char,
        no_pwd_history: passwordPolicy.c_no_pwd_history,
      })
        .then(() => {
          setPasswordPolicy({
            expiry_period: passwordPolicy.c_expiry_period,
            pwd_length: passwordPolicy.c_pwd_length,
            mixed_pwd: passwordPolicy.c_mixed_pwd,
            no_special_char: passwordPolicy.c_no_special_char,
            no_numberic_char: passwordPolicy.c_no_numberic_char,
            no_pwd_history: passwordPolicy.c_no_pwd_history,
          });
          setIsEdit(false);
        })
        .catch((err) => notifyErrorMsg(err));
    }
  };
  const renderTable = (
    <>
      <div className='card-main-wrap'>
        <table className="f-table">
          <tbody>
            <tr>
              <td>Password Expiry Period (in days):</td>
              <td className='primary-color'>
                {passwordPolicy.expiry_period === null && 'unset'}
                {passwordPolicy.expiry_period}
              </td>
            </tr>
            <tr>
              <td>Password length (number of characters):</td>
              <td className='primary-color'>
                {passwordPolicy.pwd_length === null && 'unset'}
                {passwordPolicy.pwd_length}
              </td>
            </tr>
            <tr>
              <td>Enable mixed passwords:</td>
              <td className='primary-color'>
                {passwordPolicy.mixed_pwd === true && 'true'}
                {passwordPolicy.mixed_pwd === false && 'false'}
                {passwordPolicy.mixed_pwd === null && 'false'}
              </td>
            </tr>
            <tr>
              <td>Minimum no. of special character:</td>
              <td className='primary-color'>
                {passwordPolicy.no_special_char === null && 'unset'}
                {passwordPolicy.no_special_char}
              </td>
            </tr>
            <tr>
              <td>Minimum no. of numeric character:</td>
              <td className='primary-color'>
                {passwordPolicy.no_numberic_char === null && 'unset'}
                {passwordPolicy.no_numberic_char}
              </td>
            </tr>
            <tr>
              <td>Minimum no. of passwords in history:</td>
              <td className='primary-color'>
                {passwordPolicy.no_pwd_history === null && 'unset'}
                {passwordPolicy.no_pwd_history}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className='security-buttons-groups-ligth'>
        <button type="button" onClick={handleChangeEdit}>Change</button>
        <button type="button" onClick={handleDelete}>Reset</button>
      </div>
    </>
  );
  const renderForm = (
    <>
      <Form.Group className="form-group2">
        <Form.Label className="form-label2" htmlFor="expiry_period">Password Expiry Period (in days)</Form.Label>
        <Form.Control
          type="number"
          id="expiry_period"
          className="primary-input"
          value={passwordPolicy.c_expiry_period}
          onChange={handleChange('c_expiry_period')}
          placeholder='Enter a number'
        />
      </Form.Group>
      <Form.Group className="form-group2">
        <Form.Label className="form-label2" htmlFor="pwd_length">Password length (number of characters)</Form.Label>
        <Form.Control
          type="number"
          id="pwd_length"
          className="primary-input"
          value={passwordPolicy.c_pwd_length}
          onChange={handleChange('c_pwd_length')}
          placeholder='Enter a number'
        />
      </Form.Group>

      <Form.Group className="form-group2">
        <Form.Label className="form-label2" htmlFor="no_special_char">Minimum no. of special character</Form.Label>
        <Form.Control
          type="number"
          id="no_special_char"
          className="primary-input"
          value={passwordPolicy.c_no_special_char}
          onChange={handleChange('c_no_special_char')}
          placeholder='Enter a number'
        />
      </Form.Group>
      <Form.Group className="form-group2">
        <Form.Label className="form-label2" htmlFor="no_numberic_char">Minimum no. of numeric character</Form.Label>
        <Form.Control
          type="number"
          id="no_numberic_char"
          className="primary-input"
          value={passwordPolicy.c_no_numberic_char}
          onChange={handleChange('c_no_numberic_char')}
          placeholder='Enter a number'
        />

      </Form.Group>
      <Form.Group className="form-group2">
        <Form.Label className="form-label2" htmlFor="no_pwd_history">Minimum no. of passwords in history</Form.Label>
        <Form.Control
          type="number"
          id="no_pwd_history"
          className="primary-input"
          value={passwordPolicy.c_no_pwd_history}
          onChange={handleChange('c_no_pwd_history')}
          placeholder='Enter a number'
        />
      </Form.Group>
      <Form.Group className="form-group2 mb-4 row">
        {/* <Form.Label className="form-label2">Enable mixed passwords</Form.Label> */}

        <div className='col-5'>
          <label htmlFor='mixed_pwd' className='d-flex align-items-center justify-content-start' style={{cursor:'pointer'}}>
            <input
              type="checkbox"
              id="mixed_pwd"
              defaultChecked={passwordPolicy.mixed_pwd}
              checked={passwordPolicy.c_mixed_pwd}
              onChange={handleChange('c_mixed_pwd')}
            />
            <span className='ms-2'>Enable mixed passwords</span>
          </label>
        </div>
      </Form.Group>
      <div className='security-buttons-groups-ligth'>
        <button type="button" className='bg' onClick={handleSubmit}>Save</button>
        <button type="button" onClick={() => setIsEdit(false)}>Cancel</button>
      </div>
    </>
  );
  useEffect(() => {
    getPasswordPolicy()
      .then(({ pwd_policy }) => {
        if (!pwd_policy) {
          setPasswordPolicy({
            expiry_period: null,
            pwd_length: null,
            mixed_pwd: null,
            no_special_char: null,
            no_numberic_char: null,
            no_pwd_history: null,
            isCreate: true,
          });
        } else {
          setPasswordPolicy(pwd_policy);
        }
      })
      .catch((err) => notifyErrorMsg(err));
  }, []);
  return (
    <div className="bg-white ">
      {isEdit && renderForm}
      {!isEdit && renderTable}
    </div>
  );
}

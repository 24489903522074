import axios from 'axios';
import CONFIG from '../constants/config';

export const checkFavorite = async (action) => {
  const response = await axios({
    method: 'POST',
    url: `${CONFIG.BASE_URL}/favorite/${action.payload.fileId}`,
    withCredentials: true,
    data: {
      favorite: action.payload.isFavorite,
    },
  });
  return response.data;
};

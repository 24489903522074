import React from 'react';
import Select, { components } from 'react-select';
import PropTypes from 'prop-types';
import getDefaultThumbnail from '../../../utils/getDefaultThumbnail';
const Option = (props) => {
  let typeOfFile = '';
  if(props.data.type){
    // console.log(props.data);
    typeOfFile = props.data.type
  }else{
    const arr = props.data.file_name.split(".");
    typeOfFile = arr?.[1]
  }
  return <components.Option {...props}>

    <div className="d-flex ai-center">
      <img
        alt=""
        src={
          props.data.thumbnail_path
            ? props.data.thumbnail_path
            : getDefaultThumbnail(typeOfFile)
        }
        style={{
          width: '2rem',
          height: '2rem',
          marginRight: '1rem',
        }}
      />

      <div>{props.data.new_name}</div>
    </div>
  </components.Option>
};
Option.propTypes = {
  data: PropTypes.object.isRequired,
};

const Control = (props) => <components.Control {...props} />;

const ClearIndicator = (props) =>
  <components.ClearIndicator className="clear-button" {...props} />;
export default function SelectFile({ data, callback }) {
  const handleChange = (value) => {
    callback(value);
  };
  return (
    <Select
      closeMenuOnSelect
      components={{ Option, Control, ClearIndicator }}
      onChange={(value) => handleChange(value)}
      placeholder="Select file"
      isClearable
      styles={{
        control: (base, { isFocused, hasValue }) => ({
          ...base,
          border: 'none',
          borderRadius: 'none',
          borderBottom:
            isFocused || hasValue ? '2px solid #534cdd ' : '2px solid #cccccc',
          height: '100%',
          boxShadow: 'none',
          '&:hover': {
            borderColor: '#534cdd ',
            borderWidth: '2px',
          },
        }),
        container: (base) => ({
          ...base,
          width: '100%',
        }),
      }}
      options={data}
      getOptionValue={(opt) => opt.new_name}
      getOptionLabel={(opt) => opt.new_name}
    />
  );
}
SelectFile.propTypes = {
  data: PropTypes.array,
  callback: PropTypes.func.isRequired,
};
SelectFile.defaultProps = {
  data: [],
};

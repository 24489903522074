import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getTrashDocs, updateTrashDocs } from '../services/documents';
import { notifyErrorMsg } from '../components/common/Alert';
import ConfirmButton from '../components/task/common/ConfirmButton';
import { useHistory } from 'react-router-dom';
import Folder from '../assets/images/folder-icon.svg';
import MenuIcon from '../assets/images/grid-icon.svg';
import ListIcon from '../assets/images/list-icon.svg';
import FilesItem from '../components/trash/file.item';
import FolderItem from '../components/trash/folder.item';
import BootstrapTable from 'react-bootstrap-table-next';
import { MdMoreVert, MdKeyboardBackspace, MdFolder, MdOutlineSchedule, MdRestore, MdDeleteOutline } from 'react-icons/md';
import { BsCloudArrowUp } from 'react-icons/bs'
import { Dropdown, Row, Col } from 'react-bootstrap';
import 'swiper/swiper-bundle.min.css';
import 'swiper/components/navigation/navigation.less';
import getDefaultThumbnail from '../utils/getDefaultThumbnail';
import { bytesToSize, getFolderName } from '../utils/string';
import { formatDate } from '../utils/datetime';
import { nameSortfileOrFolderTable } from '../helpers/sort';

function getShortFilePath(filePath) {
  const filePathArr = filePath.split('/');
  if (filePathArr.length === 1) {
    return 'Root';
  }
  if (filePathArr.length === 2) {
    return `${filePathArr[filePathArr.length - 2]}`;
  }
  return `.../${filePathArr[filePathArr.length - 2]}`;
}

const dropdownText = [
  { id: 1, name: 'Name', value: 'name' },
  { id: 2, name: 'File Type', value: 'file-type' },
  { id: 3, name: 'Size', value: 'size' }
]

export default function Trash(props) {

  const history = useHistory();
  const allFolders = useSelector((s) => s.allFolders);

  const [isGridView, setIsGridView] = useState(true);
  const [docs, setDocs] = useState(false);
  const [checkedList, setCheckedList] = useState([]);
  const [folderCheckedList, setFolderCheckedList] = useState([]);
  const [sortArray, setSortArray] = useState('');
  const [filesArray, setFilesArray] = useState([]);
  const [searchVal, setSearchVal] = useState('');

  const searchFilter = (e) => {
    const { value } = e.target;
    setSearchVal(value);
    if (value !== '') {
      if (docs && docs.length > 0) {
        const filt = docs.filter((item) => {
          // const name = item.new_name || item.file_name || item.folder_name;
          if (item.new_name) {
            return item.new_name.toLowerCase().includes(value.toLowerCase());
          } else if (item.file_name) {
            return item.new_name.toLowerCase().includes(value.toLowerCase());
          } else if (item.folder_name) {
            return item.folder_name.toLowerCase().includes(value.toLowerCase());
          }
        });
        setFilesArray(filt);
      }
    } else {
      setFilesArray(docs)
    }
  }

  const handleRecycle = () => {
    updateTrashDocs({
      action: 'recycle',
      folder_ids: folderCheckedList,
      file_ids: checkedList,
    })
      .then(() => {
        setDocs(
          docs.filter(
            (f) =>
              !checkedList.includes(f.file_id) &&
              !folderCheckedList.includes(f.folder_id)
          )
        );
        setFolderCheckedList([]);
        setCheckedList([]);
      })
      .catch((err) => notifyErrorMsg(err));
  };
  const handleRemove = () => {
    updateTrashDocs({
      action: 'remove',
      folder_ids: folderCheckedList,
      file_ids: checkedList,
    })
      .then(() => {
        setDocs(
          docs.filter(
            (f) =>
              !checkedList.includes(f.file_id) &&
              !folderCheckedList.includes(f.folder_id)
          )
        );
        setFolderCheckedList([]);
        setCheckedList([]);
        getTrashDocs().then(({ trashDocs }) => {
          if (Array.isArray(trashDocs) && trashDocs.length > 0) {
            const newDocs = trashDocs.map((t, i) => ({ id: i + 1, ...t }))
            setDocs(newDocs);
            setFilesArray(newDocs);
          } else {
            setDocs([]);
            setFilesArray([]);
    
          }
        });
      })
      .catch((err) => notifyErrorMsg(err));
  };

  useEffect(() => {
    getTrashDocs().then(({ trashDocs }) => {
      if (Array.isArray(trashDocs) && trashDocs.length > 0) {
        const newDocs = trashDocs.map((t, i) => ({ id: i + 1, ...t }))
        setDocs(newDocs);
        setFilesArray(newDocs);
      } else {
        setDocs([]);
        setFilesArray([]);

      }
    });
  }, []);

  const sortHandler = (e, item) => {
    e.preventDefault();
    const sortVal = item.value;
    const data = searchVal !== '' ? filesArray : Array.isArray(docs) ? docs : [];
    if (sortArray === sortVal) {
      setSortArray('')
      setFilesArray(data)
    } else {
      setSortArray(sortVal);
      if (data && data.length > 0) {
        const fileSort = data.sort((a, b) => {
          switch (sortVal) {
            case 'name':
              if (a.new_name < b.new_name) { return -1; }
              if (a.new_name > b.new_name) { return 1; }
              return 0;
            case 'file-type':
              if (a.type < b.type) { return -1; }
              if (a.type > b.type) { return 1; }
              return 0;
            case 'size':
              if (a.size < b.size) { return -1; }
              if (a.size > b.size) { return 1; }
              return 0;
            default:
              return null;
          }

        });

        setFilesArray(fileSort);
      }
    }
  }


  function nameFormatter(cell, row) {
    return (
      <span className='d-flex align-items-center'>
        <img className="folder-icon" src={row.folder_id ? Folder : getDefaultThumbnail(row.type)} alt="folder" />
        {row.new_name || row.folder_name}
      </span>
    );
  }

  const fileTypeFormatter = (cell, row) => {
    return row.folder_id ? <span className='folder-name'>File Folder</span> : <span className='text-uppercase'>{row.type}</span>
  }

  const folderPathFormatter = (cell, row) => {
    return (
      <div className="folder-path-wraps">
        <span className='folder-icon'><MdFolder /></span>
        {!row.parent_id ? 'Root' : getFolderName(row.parent_id, allFolders)}
      </div>
    )
  }

  const sizeFormatter = (cell, row) => {
    return row.size ? bytesToSize(row.size) : '-'
  }

  const accessedFormatter = (cell, row) => {
    return (
      row.updated_at ?
        <div className='icon-with-text'>
          <span className='icon'><MdOutlineSchedule /></span>
          {formatDate(row.updated_at)}
        </div>
        :
        '-'
    )
  }
  const uploadFormatter = (cell, row) => {
    return (
      <div className='icon-with-text'>
        <span className='icon'><BsCloudArrowUp /></span>
        {formatDate(row.created_at)}
      </div>
    )
  }

  const columns = [

    {
      dataField: 'new_name',
      text: 'Name',
      formatter: nameFormatter,
      sort: true,
      sortFunc: nameSortfileOrFolderTable

    },
    {
      dataField: 'type',
      text: 'File Type',
      sort: true,
      formatter: fileTypeFormatter
    },
    {
      dataField: 'folder_name',
      text: 'Folder',
      sort: true,
      formatter: folderPathFormatter
    },
    // {
    //   dataField: 'member_icons',
    //   text: 'Member'
    // },
    {
      dataField: 'file_size',
      text: 'Size',
      sort: true,
      formatter: sizeFormatter
    },
    {
      dataField: 'updated_at',
      text: 'Last Accessed',
      sort: true,
      formatter: accessedFormatter
    },
    {
      dataField: 'created_at',
      text: 'Upload Date',
      sort: true,
      formatter: uploadFormatter
    }
  ];

  const handleOnSelect = (row, isSelect) => {
    const fileId = row.file_id;
    const folderId = row.folder_id;
    if (fileId) {
      if (checkedList.includes(fileId)) {
        setCheckedList(checkedList.filter((f) => f !== fileId));
      } else {
        setCheckedList([...checkedList, fileId]);
      }
    } else if (folderId) {
      if (folderCheckedList.includes(folderId)) {
        setFolderCheckedList(folderCheckedList.filter((f) => f !== folderId));
      } else {
        setFolderCheckedList([...folderCheckedList, folderId]);
      }
    }
  }
  const handleOnSelectAll = (isSelect, rows) => {
    if (checkedList.length + folderCheckedList.length === docs.length) {
      setCheckedList([]);
      setFolderCheckedList([]);
    } else {
      const arr = [];
      const arr2 = [];
      docs.forEach((d) => {
        if (d.file_id) {
          arr.push(d.file_id);
        } else if (d.folder_id) {
          arr2.push(d.folder_id);
        }
      });
      setCheckedList(arr);
      setFolderCheckedList(arr2);
    }
  }


  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    bgColor: '#f5f6f8',
    onSelect: handleOnSelect,
    onSelectAll: handleOnSelectAll
  }


  return (
    <section className="trash-section" onContextMenu={(e) => e.preventDefault()}>
      <div className="page-title-wrap trash-page">
        <h5 className="page-title ">Trash</h5>
        <input className='search-input' value={searchVal} onChange={searchFilter} type="text" placeholder="Search" />
      </div>
      <div className="link-page-title-wrap">
        <button type='button' onClick={() => history.goBack()}> <MdKeyboardBackspace /> </button>
        <img src={Folder} alt="folder" />
        <h4 className="trash-title">Home</h4>
      </div>

      <div className="page-btns-wrap d-flex align-items-center justify-content-between">
        <div className='d-flex w-50'>
          <ConfirmButton
            message="Are you sure you want to restore?"
            buttonStyle={{
              backgroundColor: '#016288',
              color: 'white',
              borderRadius: '6px',
              fontWeight: 500,
              fontSize: '12px',
              // padding: '0.45rem 1.4rem',
              border: 'none',
            }}
            buttonClass="restore"
            buttonText="Restore"
            handleClick={handleRecycle}
          >
            <span><MdRestore /></span> Restore
          </ConfirmButton>
          <ConfirmButton
            message="Are you sure you want to remove permanently?"
            buttonClass="removePermenently"
            buttonStyle={{
              backgroundColor: '#016288',
              color: 'white',
              borderRadius: '6px',
              fontWeight: 500,
              fontSize: '12px',
              // padding: '0.45rem 1.4rem',
              border: 'none',
              marginLeft: '8px',
            }}
            buttonText="Remove Permanently"
            handleClick={handleRemove}
          >
            <span><MdDeleteOutline /></span> Remove Permanently
          </ConfirmButton>
        </div>
        <div className='d-flex'>
          <button className=" setting-btn" onClick={() => setIsGridView(!isGridView)}><img src={isGridView ? MenuIcon : ListIcon} alt="setting-icon" /></button>
          {!isGridView && <Dropdown className="files-dropdown">
            <Dropdown.Toggle id="dropdown-button-dark-example1">
              <MdMoreVert />
            </Dropdown.Toggle>

            <Dropdown.Menu className="custom-dropdown custom-sort-dropmenu">
              {dropdownText && dropdownText.length > 0 ?
                dropdownText.map((item, i) =>
                  <Dropdown.Item
                    href="#/action-1"
                    key={i}
                    className={`${item.value === sortArray ? 'is_active' : ''}`}
                    onClick={(e) => sortHandler(e, item)}
                  >
                    {item.name}
                  </Dropdown.Item>
                )
                :
                null
              }
            </Dropdown.Menu>
          </Dropdown>
          }
        </div>

      </div>
      {isGridView ?
        <BootstrapTable keyField='id'
          data={filesArray}
          columns={columns}
          bordered={false}
          classes={'table-search'}
          selectRow={selectRow}
          noDataIndication="There is no data"
        />
        :
        <>
          {Array.isArray(filesArray) && filesArray.length > 0 && filesArray.filter(info => info.folder_id).length > 0 ?
            <>
              <div className="file-wrap mt-5">
                <h3 className="file-title">Folders</h3>
              </div>
              <div className='folders__items'>
                {filesArray.filter(info => info.folder_id).map((item, i) =>
                  <FolderItem
                    key={i}
                    folder={item}
                    onClick={handleOnSelect}
                    checkBoxValue={folderCheckedList.includes(item.folder_id)}
                  />
                )}
              </div>
            </>
            :
            null
          }
          {Array.isArray(filesArray) && filesArray.length > 0 && filesArray.filter(info => info.file_id).length > 0 ?
            <>
              <div className="file-wrap mt-5">
                <h3 className="file-title">Files</h3>
              </div>
              <Row>
                {filesArray.filter(info => info.file_id).map((item, index) =>
                  <Col xs={12} sm={4} key={index}>
                    <FilesItem
                      key={item.file_id}
                      file={item}
                      onClick={handleOnSelect}
                      checkBoxValue={checkedList.includes(item.file_id)}
                    />
                  </Col>
                )}
              </Row>
            </>
            :
            null
          }
        </>
      }

    </section >
  )
}

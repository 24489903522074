import axios from 'axios';
import CONFIG from '../constants/config';

export const getOrgProfile = async () => {
  const response = await axios({
    method: 'GET',
    url: `${CONFIG.BASE_URL}/org-profile`,
    withCredentials: true,
  });
  return response.data;
};

export const editOrgProfile = async ({
  org_name,
  org_logo,
  country,
  language,
  timezone,
}) => {
  if (org_logo && org_logo.search('base64') === -1) org_logo = undefined;
  const response = await axios({
    method: 'PATCH',
    url: `${CONFIG.BASE_URL}/org-profile`,
    withCredentials: true,
    data: { org_name, country, language, timezone, org_logo },
  });
  return response.data;
};

export const inviteeList = async ({ org_id }) => {
  const response = await axios({
    method: 'GET',
    url: `${CONFIG.BASE_URL}/organization/${org_id}/invite`,
    params: { org_id },
    withCredentials: true,
  });
  return response.data;
};

export const deleteUser = async ({ user_id }) => {
  const response = await axios({
    method: 'POST',
    url: `${CONFIG.BASE_URL}/delete_user`,
    data: { user_id },
    withCredentials: true,
  });
  return response.data;
};

export const disableUser = async ({ user_id }) => {
  const response = await axios({
    method: 'POST',
    url: `${CONFIG.BASE_URL}/disable_user`,
    data: { user_id },
    withCredentials: true,
  });
  return response.data;
};

export const enableUser = async ({ user_id }) => {
  const response = await axios({
    method: 'POST',
    url: `${CONFIG.BASE_URL}/enable_user`,
    data: { user_id },
    withCredentials: true,
  });
  return response.data;
};

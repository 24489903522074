export const GET_WINDOW_WIDTH = 'GET_WINDOW_WIDTH';
export const MAIN_NAVIGATION_TOGGLE = 'MAIN_NAVIGATION_TOGGLE';
export const ACTIVITES_SIDEBAR_TOGGLE = 'ACTIVITES_SIDEBAR_TOGGLE';

export const getWindowWidth = (width) => ({
    type: GET_WINDOW_WIDTH,
    payload: width
});

export const mainNavToggle = () => ({
    type: MAIN_NAVIGATION_TOGGLE
})
export const activitiesBarToggle = () => ({
    type: ACTIVITES_SIDEBAR_TOGGLE
})
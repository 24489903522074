export const UPLOAD_FILE = 'UPLOAD_FILE';
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_FAILURE = 'UPLOAD_FILE_FAILURE';
export const ADD_FILE = 'ADD_FILE';
export const RENAME_FILE = 'RENAME_FILE';
export const RENAME_FILE_SUCCESS = 'RENAME_FILE_SUCCESS';
export const RENAME_FILE_FAILURE = 'RENAME_FILE_FAILURE';
export const MOVE_FILE_SUCCESS = 'MOVE_FILE_SUCCESS';
export const MOVE_FILE_FAILURE = 'MOVE_FILE_FAILURE';
export const REMOVE_FILE = 'REMOVE_FILE';
export const REMOVE_FILE_SUCCESS = 'REMOVE_FILE_SUCCESS';
export const REMOVE_FILE_FAILURE = 'REMOVE_FILE_FAILURE';
export const CHECK_FAVORITE = 'CHECK_FAVORITE';
export const CHECK_FAVORITE_SUCCESS = 'CHECK_FAVORITE_SUCCESS';
export const CHECK_FAVORITE_FAILURE = 'CHECK_FAVORITE_FAILURE';
export const CHECK_FILE = 'CHECK_FILE';
export const CHECK_FILE_SUCCESS = 'CHECK_FILE_SUCCESS';
export const CHECK_FILE_FAILURE = 'CHECK_FILE_FAILURE';

export const SINGLE_FILE_SELECT = 'SINGLE_FILE_SELECT';
export const MULTIPLE_FILE_SELECT = 'MULTIPLE_FILE_SELECT';
export const REMOVE_SELECTED_FILES = 'REMOVE_SELECTED_FILES';

export const addFile = (file) => ({
  type: ADD_FILE,
  payload: file,
});

export const uploadFile = (file, fileId, parentId) => ({
  type: UPLOAD_FILE,
  payload: {
    file,
    fileId,
    parentId,
  },
});

export const renameFile = (fileId, newName) => ({
  type: RENAME_FILE,
  payload: {
    fileId,
    newName,
  },
});

export const removeFile = (fileId) => ({
  type: REMOVE_FILE,
  payload: fileId,
});

export const checkFavorite = (fileId, isFavorite, file) => ({
  type: CHECK_FAVORITE,
  payload: {
    fileId,
    isFavorite,
    file,
  },
});

export const checkFile = (fileId, isCheck, file) => ({
  type: CHECK_FILE,
  payload: {
    fileId,
    isCheck,
    file,
  },
});

export const checkInSuccess = (fileId) => ({
  type: CHECK_FILE_SUCCESS,
  payload: {
    fileId,
    isCheck: false,
  },
});


export const multipleSelectingFiles = (file) => ({
  type: MULTIPLE_FILE_SELECT,
  payload: file
})

export const singleSelectingFiles = (file) => ({
  type: SINGLE_FILE_SELECT,
  payload: file
})

export const removeSelectingFiles = () => ({
  type: REMOVE_SELECTED_FILES
})

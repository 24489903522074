import React, { useEffect, useState } from 'react';
import { getStorageForAdmin } from '../../../services/reports';
import { bytesToSizeNumber, bytesToSize } from '../../../utils/string';

export default function Storages() {
  const [storages, setStorages] = useState([]);

  useEffect(() => {
    getStorageForAdmin().then(({ users }) => {
      setStorages(users);
    });
  }, []);

  const usedGB = storages && storages.length ? bytesToSizeNumber(storages.reduce((prev, cur) => prev + Number(cur.storage_used), 0)) : 0;
  const totalGB = storages && storages.length ? bytesToSizeNumber(storages.reduce((prev, cur) => prev + Number(cur.storage), 0)) : 0;
  const percentage = (usedGB / totalGB) * 100;

  return (
    <div className="bg-white mt-4" style={{ borderRadius: 10 }}>
      <div className="card-wraps-chart d-flex mb-4">
        <div className="title-cards">Total Space Usage</div>
        {storages.length ? (
          <div className="chart-storage-area">
            <div className="gauge">
              <div className="gauge__fill__bg" style={{ transform: `rotate(calc(1deg * ( -45 + ${percentage} * 1.8)))` }}></div>
              <div className="gauge__cover">
                {bytesToSize(
                  storages.reduce((prev, cur) => prev + Number(cur.storage_used), 0)
                )}
                <small>of {bytesToSize(
                  storages.reduce((prev, cur) => prev + Number(cur.storage), 0)
                )}</small></div>
            </div>
          </div>
        )
          :
          null
        }
      </div>
      <div>
        <table className="ml-3 c-table" style={{ width: '100%' }} cellPadding="0" cellSpacing="0" border="0">
          <thead>
            <tr>
              <th style={{ textAlign: 'left' }}>Name</th>
              <th style={{ textAlign: 'left' }}>Used Space</th>
              <th style={{ textAlign: 'left' }}>Folders</th>
              <th style={{ textAlign: 'left' }}>
                Shared folders
              </th>
              <th style={{ textAlign: 'left' }}>Documents</th>
              <th style={{ textAlign: 'left' }}>
                Shared Documents
              </th>
            </tr>
          </thead>
          <tbody>
            {storages.map((row) => (
              <tr key={row.file_id}>
                <td style={{ textAlign: 'left' }}>
                  {' '}
                  {row.first_name}
                </td>

                <td style={{ textAlign: 'left', fontWeight: 'normal' }}>
                  {row.storage_used !== '0' ? bytesToSize(row.storage_used) : 0} of{' '}
                  {bytesToSize(row.storage)}
                </td>
                <td style={{ textAlign: 'left' }}>
                  {row.folders}
                </td>
                <td style={{ textAlign: 'left' }}>
                  {row.share_folders}
                </td>
                <td style={{ textAlign: 'left' }}>
                  {row.files}
                </td>
                <td style={{ textAlign: 'left' }}>
                  {row.share_files}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export const GET_REMINDERS = 'GET_REMINDERS';
export const GET_REMINDERS_SUCCESS = 'GET_REMINDERS_SUCCESS';
export const CREATE_REMINDER = 'CREATE_REMINDER';
export const CREATE_REMINDER_SUCCESS = 'CREATE_REMINDER_SUCCESS';
export const SELECT_REMINDER = 'SELECT_REMINDER';
export const EDIT_REMINDER = 'EDIT_REMINDER';
export const EDIT_REMINDER_SUCCESS = 'EDIT_REMINDER_SUCCESS';
export const DELETE_REMINDER = 'DELETE_REMINDER';
export const DELETE_REMINDER_SUCCESS = 'DELETE_REMINDER_SUCCESS';
export const RESET_REMINDER = 'RESET_REMINDER';

export const getReminders = () => ({
    type: GET_REMINDERS,
});

export const createReminder = (data) => ({
    type: CREATE_REMINDER,
    payload: data,
});

export const selectReminder = (reminder) => ({
    type: SELECT_REMINDER,
    payload: reminder,
});

export const editReminder = (data) => ({
    type: EDIT_REMINDER,
    payload: data,
});
export const deleteReminder = (reminderId) => ({
    type: DELETE_REMINDER,
    payload: reminderId,
});

export const resetReminder = () => ({
    type: RESET_REMINDER,
});

/* eslint-disable complexity */
/* eslint-disable max-statements */
import {
  CHECK_FAVORITE_SUCCESS,
  CHECK_FILE_SUCCESS,
  REMOVE_FILE_SUCCESS,
  RENAME_FILE_SUCCESS,
} from '../actions/files';
import { DELETE_FOLDER_SUCCESS, CHECK_FOLDER_FAVORITE_SUCCESS } from '../actions/folders';
import { LINK_FILE_SUCCESS } from '../actions/linkFilePopup';
import {
  CHECK_QUICK_ACCESS_SUCCESS,
  CHECK_QUICK_ACCESS_FAILURE,
  CHECK_QUICK_ACCESS,
  CHECK_QUICK_ACCESS_HIDDEN,
} from '../actions/quickAccess';

const initialState = {
  isLoading: false,
  upload: [],
  accessed: [],
  favorite: [],
  favoriteFolders: [],
  checkout: [],
};

export default function quickAccess (state, action) {
  switch (action.type) {
    case CHECK_QUICK_ACCESS: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case CHECK_QUICK_ACCESS_HIDDEN: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case CHECK_QUICK_ACCESS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        upload: [...action.payload.upload],
        accessed: [...action.payload.accessed],
        favorite: [...action.payload.favorite],
        favoriteFolders: [...action.payload.favoriteFolders],
        checkout: [...action.payload.checkout],
      };
    case CHECK_QUICK_ACCESS_FAILURE:
    case DELETE_FOLDER_SUCCESS: {
      const uploadIdsToDelete = [];
      const accessedIdsToDelete = [];
      const favoriteIdsToDelete = [];
      const checkoutIdsToDelete = [];
      (function getfileIdsToDelte(pid) {
        uploadIdsToDelete.push(pid);
        const hasDescendants = state.upload.filter((f) => f.parent_id === pid);
        if (hasDescendants.length) {
          hasDescendants.map((d) => getfileIdsToDelte(d.file_id));
        }
      }(action.payload));
      (function getfileIdsToDelte(pid) {
        accessedIdsToDelete.push(pid);
        const hasDescendants = state.accessed.filter(
          (f) => f.parent_id === pid
        );
        if (hasDescendants.length) {
          hasDescendants.map((d) => getfileIdsToDelte(d.file_id));
        }
      }(action.payload));
      (function getfileIdsToDelte(pid) {
        favoriteIdsToDelete.push(pid);
        const hasDescendants = state.favorite.filter(
          (f) => f.parent_id === pid
        );
        if (hasDescendants.length) {
          hasDescendants.map((d) => getfileIdsToDelte(d.file_id));
        }
      }(action.payload));
      (function getfileIdsToDelte(pid) {
        checkoutIdsToDelete.push(pid);
        const hasDescendants = state.checkout.filter(
          (f) => f.parent_id === pid
        );
        if (hasDescendants.length) {
          hasDescendants.map((d) => getfileIdsToDelte(d.file_id));
        }
      }(action.payload));
      return {
        ...state,
        upload: state.upload.filter(
          (f) => !uploadIdsToDelete.includes(f.file_id)
        ),
        accessed: state.accessed.filter(
          (f) => !accessedIdsToDelete.includes(f.file_id)
        ),
        favorite: state.favorite.filter(
          (f) => !favoriteIdsToDelete.includes(f.file_id)
        ),
        checkout: state.checkout.filter(
          (f) => !checkoutIdsToDelete.includes(f.file_id)
        ),
      };
    }
    case RENAME_FILE_SUCCESS: {
      if (state.upload.find((f) => f.file_id === action.payload.fileId)) {
        state.upload.find((f) => f.file_id === action.payload.fileId).new_name =
          action.payload.newName;
      }
      if (state.accessed.find((f) => f.file_id === action.payload.fileId)) {
        state.accessed.find(
          (f) => f.file_id === action.payload.fileId
        ).new_name = action.payload.newName;
      }
      if (state.favorite.find((f) => f.file_id === action.payload.fileId)) {
        state.favorite.find(
          (f) => f.file_id === action.payload.fileId
        ).new_name = action.payload.newName;
      }
      if (state.checkout.find((f) => f.file_id === action.payload.fileId)) {
        state.checkout.find(
          (f) => f.file_id === action.payload.fileId
        ).new_name = action.payload.newName;
      }
      return {
        ...state,
        upload: [...state.upload],
        accessed: [...state.accessed],
        favorite: [...state.favorite],
        checkout: [...state.checkout],
      };
    }
    case CHECK_FAVORITE_SUCCESS:
      if (state.upload.find((f) => f.file_id === action.payload.fileId)) {
        state.upload.find((f) => f.file_id === action.payload.fileId).favorite =
          action.payload.isFavorite;
      }
      if (state.accessed.find((f) => f.file_id === action.payload.fileId)) {
        state.accessed = state.accessed.filter(
          (f) => f.file_id !== action.payload.fileId
        );
        state.accessed.unshift(action.payload.file);
        state.accessed[0].favorite = action.payload.isFavorite;
      } else {
        state.accessed.unshift(action.payload.file);
        state.accessed[0].favorite = action.payload.isFavorite;
        state.accessed.pop();
      }
      if (state.favorite.find((f) => f.file_id === action.payload.fileId)) {
        state.favorite.find(
          (f) => f.file_id === action.payload.fileId
        ).favorite = action.payload.isFavorite;
      } else {
        action.payload.file.favorite = true;
        state.favorite.push(action.payload.file);
      }
      if (state.checkout.find((f) => f.file_id === action.payload.fileId)) {
        state.checkout.find(
          (f) => f.file_id === action.payload.fileId
        ).favorite = action.payload.isFavorite;
      }
      return {
        ...state,
        upload: [...state.upload],
        accessed: [...state.accessed],
        favorite: [...state.favorite.filter((s) => s.favorite)],
        checkout: [...state.checkout],
      };
    case CHECK_FOLDER_FAVORITE_SUCCESS:
      
      if (state.favoriteFolders.find((f) => f.folder_id === action.payload.folderId)) {
        state.favoriteFolders.find(
          (f) => f.folder_id === action.payload.folderId
        ).favorite = action.payload.isFavorite;
      } else {
        action.payload.folder.favorite = true;
        state.favoriteFolders.push(action.payload.folder);
      }
      return{
        ...state,
        favoriteFolders:[...state.favoriteFolders.filter((s) => s.favorite)]
      }
    case CHECK_FILE_SUCCESS:
      if (state.upload.find((f) => f.file_id === action.payload.fileId)) {
        state.upload.find((f) => f.file_id === action.payload.fileId).checkout =
          action.payload.isCheck;
      }
      if (state.accessed.find((f) => f.file_id === action.payload.fileId)) {
        state.accessed.find(
          (f) => f.file_id === action.payload.fileId
        ).checkout = action.payload.isCheck;
      }
      if (state.favorite.find((f) => f.file_id === action.payload.fileId)) {
        state.favorite.find(
          (f) => f.file_id === action.payload.fileId
        ).checkout = action.payload.isCheck;
      }
      if (state.checkout.find((f) => f.file_id === action.payload.fileId)) {
        state.checkout.find(
          (f) => f.file_id === action.payload.fileId
        ).checkout = action.payload.isCheck;
      } else {
        action.payload.file.checkout = true;
        state.checkout.push(action.payload.file);
      }
      return {
        ...state,
        upload: [...state.upload],
        accessed: [...state.accessed],
        favorite: [...state.favorite],
        checkout: [...state.checkout.filter((s) => s.checkout)],
      };
    case REMOVE_FILE_SUCCESS:
      return {
        ...state,
        upload: [...state.upload.filter((f) => f.file_id !== action.payload)],
        accessed: [
          ...state.accessed.filter((f) => f.file_id !== action.payload),
        ],
        favorite: [
          ...state.favorite.filter((f) => f.file_id !== action.payload),
        ],
        checkout: [
          ...state.checkout.filter((f) => f.file_id !== action.payload),
        ],
      };
    case LINK_FILE_SUCCESS: {
      const stateChange1 = state.upload.find(
        (f) => f.file_id === action.payload.file_id
      );
      const stateChange2 = state.upload.find(
        (f) => f.file_id === action.payload.file_to_link.file_id
      );
      const stateChange3 = state.accessed.find(
        (f) => f.file_id === action.payload.file_id
      );
      const stateChange4 = state.accessed.find(
        (f) => f.file_id === action.payload.file_to_link.file_id
      );
      const stateChange5 = state.favorite.find(
        (f) => f.file_id === action.payload.file_id
      );
      const stateChange6 = state.favorite.find(
        (f) => f.file_id === action.payload.file_to_link.file_id
      );
      const stateChange7 = state.checkout.find(
        (f) => f.file_id === action.payload.file_id
      );
      const stateChange8 = state.checkout.find(
        (f) => f.file_id === action.payload.file_to_link.file_id
      );
      if (stateChange1) stateChange1.link += 1;
      if (stateChange2) stateChange2.link += 1;
      if (stateChange3) {
        stateChange3.link += 1;
      } else {
        const fileChange3 = { ...action.payload.file_to_link };
        fileChange3.link += 1;
        state.accessed.push(fileChange3);
      }
      if (stateChange4) { stateChange4.link += 1; }
      else {
        const fileChange4 = { ...action.payload.file_to_link };
        fileChange4.link += 1;
        state.accessed.push(fileChange4);
      }
      if (stateChange5) stateChange5.link += 1;
      if (stateChange6) stateChange6.link += 1;
      if (stateChange7) stateChange7.link += 1;
      if (stateChange8) stateChange8.link += 1;
      return {
        ...state,
        upload: [...state.upload.filter((f) => f.file_id !== action.payload)],
        accessed: [
          ...state.accessed.filter((f) => f.file_id !== action.payload),
        ],
        favorite: [
          ...state.favorite.filter((f) => f.file_id !== action.payload),
        ],
        checkout: [
          ...state.checkout.filter((f) => f.file_id !== action.payload),
        ],
      };
    }
    default:
      return state || initialState;
  }
}

import axios from 'axios';
import CONFIG from '../constants/config';

export const getDocs = async () => {
  const response = await axios({
    method: 'GET',
    url: `${CONFIG.BASE_URL}/list-all-file-folder`,
    withCredentials: true,
  });
  return response.data;
};

export const getDocsRootFolder = async () => {
  const response = await axios({
    method: 'GET',
    url: `${CONFIG.BASE_URL}/mydocument`,
    withCredentials: true,
  });
  return response.data;
};

export const getDocsByFolderId = async (action) => {
  const response = await axios({
    method: 'GET',
    url: `${CONFIG.BASE_URL}/folder/${action.payload}`,
    withCredentials: true,
  });
  return response.data;
};

export const renameDocs = async (action) => {
  const response = await axios({
    method: 'POST',
    url: `${CONFIG.BASE_URL}/rename`,
    data: {
      file_id: action.payload.fileId,
      folder_id: action.payload.folderId,
      new_name: action.payload.newName,
    },
    withCredentials: true,
  });
  return response.data;
};

export const moveDocs = async (action) => {
  const response = await axios({
    method: 'POST',
    url: `${CONFIG.BASE_URL}/move`,
    data: {
      file_id: action.payload.fileId,
      folder_id: action.payload.folderId,
      new_parent_id:
        action.payload.newParentId === '0' ? 0 : action.payload.newParentId,
    },
    withCredentials: true,
  });
  return response.data;
};

export const getTrashDocs = async () => {
  const response = await axios({
    method: 'GET',
    url: `${CONFIG.BASE_URL}/trash-docs`,
    withCredentials: true,
  });
  return response.data;
};

export const updateTrashDocs = async ({ action, folder_ids, file_ids }) => {
  const response = await axios({
    method: 'PATCH',
    url: `${CONFIG.BASE_URL}/trash-docs`,
    withCredentials: true,
    data: {
      action,
      folder_ids,
      file_ids,
    },
  });
  return response.data;
};

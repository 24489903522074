import {
    PINBAR_OPEN,
    PINBAR_CLOSE,
    PINBAR_STICKY
} from '../actions/pinbar';

const initialState = {
    pinbarOpen: false,
    pinbarSticky: false
};

export default function pinbar(state, action) {
    switch (action.type) {
        case PINBAR_OPEN:
            return { ...state, pinbarOpen: true };
        case PINBAR_CLOSE:
            return { ...state, pinbarOpen: false };
        case PINBAR_STICKY:
            return { ...state, pinbarSticky: !state.pinbarSticky };

        default:
            return state || initialState;
    }
}
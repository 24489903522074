import React, { useState } from 'react';
import DummyUser from '../assets/images/dummy-small-user.svg';
import Delete from '../assets/images/delete-icon.svg';
import PDF from '../assets/images/pdf-icon.svg'
import { Scrollbars } from 'react-custom-scrollbars';
import * as scrollbar from '../helpers/scrollbar';
import { useDispatch, useSelector } from 'react-redux';
import {
    getNotifications,
    checkNotification,
    deleteNotification,
} from '../redux/actions/notifications';
import moment from 'moment';

function Notification() {

    const [activeNoti, setActiveNoti] = useState(0);
    const dispacth = useDispatch();
    const notifications = useSelector((s) => s.notifications.data);

    const handleDeleteNotification = (id) => {
        dispacth(deleteNotification(id));
    };

    const yesterday = moment().add(-1, 'days')
    const today = moment()

    const checkNotiHandler = (n) => {
        dispacth(checkNotification(n.notification_id))
        // dispacth(getNotifications())
    }


    return (
        <section className='notification-section'>
            <div className='page-top-header'>
                <h1 className="page-title">Notification</h1>
                {/* <button className='mark-read'>Mark All Read</button> */}
            </div>
            <div className="notifications-wrap">
                <Scrollbars
                    renderView={scrollbar.renderView}
                    renderTrackHorizontal={scrollbar.renderTrackHorizontal}
                    renderTrackVertical={scrollbar.renderTrackVertical}
                    renderThumbHorizontal={scrollbar.renderThumb}
                    renderThumbVertical={scrollbar.renderThumb}
                    universal={true}
                >
                    <div className='notification-container'>
                        {notifications && notifications.length > 0 ?
                            <ul className='notification-list-wrap'>

                                {/* <h3 className='notification-time'>TODAY</h3> */}
                                {notifications.filter((e) => {

                                    return today.format('YYYY-MM-DD') === moment(e.created_at).format('YYYY-MM-DD')
                                }).map((n, i) => (
                                    <li
                                        // new
                                        className={`notification-list ${!n.status ? 'new' : ''}`}
                                        key={i}
                                        onMouseOver={() => {
                                            setActiveNoti(i);
                                        }}
                                        onClick={() => checkNotiHandler(n)}
                                        onMouseLeave={() => setActiveNoti(null)}

                                    >
                                        <div className='notification-div-wrap '>
                                            <div className='n-img-wrap'>
                                                <img src={n.icon_link} alt="user-img" />
                                                {/* <img src={DummyUser} alt="user-img" /> */}
                                            </div>
                                            <div className='n-text-wrap'>
                                                <p className='n-title mb'>
                                                    {/* <span className='n-user-name'>Purnomo </span> */}
                                                    <span className='n-user-todo'>{n.title}
                                                        {/* <span className='n-file-name'> Presentation.pdf </span>
                                                        file */}
                                                    </span>
                                                </p>
                                                <p className='n-comment mb'>{n.detail}</p>
                                                <p className="n-user-activity-time mb">{moment(n.created_at).fromNow()}</p>
                                                {/* <ul className='n-uploadfile-wrap' >
                                                    <li className='upload-file-li'>
                                                        <div className='pdf-file-wrap'>
                                                            <img src={PDF} alt="pdf" />
                                                            <div>
                                                                <p className='n-pdf-name mb-0'>Presentation.Pdf</p>
                                                                <p className='n-pdf-size mb-0'>12Mb </p>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className='upload-file-li'>
                                                        <div className='pdf-file-wrap'>
                                                            <img src={PDF} alt="pdf" />
                                                            <div className='n-uploadfile-data-wrap'>
                                                                <p className='n-pdf-name '>Presentation.Pdf</p>
                                                                <p className='n-pdf-size'>12Mb </p>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul> */}
                                            </div>

                                        </div>
                                        <div className='noti-handle-btns'>
                                            <span className={`read_unred_icon ${!n.status ? 'is_active' : ''}`}></span>
                                            <button type="button" className='delete-btn' onClick={() => handleDeleteNotification(n.notification_id)}>
                                                <img src={Delete} alt="delete" />
                                            </button>
                                        </div>

                                    </li>
                                ))}
                                {/* <h3 className='notification-time'>YESTERDAY</h3> */}
                                {notifications.filter((e) => {

                                    return today.format('YYYY-MM-DD') !== moment(e.created_at).format('YYYY-MM-DD')
                                }).map((n, i) => (
                                    <li
                                        // new
                                        className={`notification-list ${!n.status ? 'new' : ''}`}
                                        key={i}
                                        onMouseOver={() => {
                                            setActiveNoti(i);
                                        }}
                                        onClick={() => checkNotiHandler(n)}
                                        onMouseLeave={() => setActiveNoti(null)}

                                    >
                                        <div className='notification-div-wrap '>
                                            <div className='n-img-wrap'>
                                                <img src={n.icon_link} alt="user-img" />
                                                {/* <img src={DummyUser} alt="user-img" /> */}
                                            </div>
                                            <div className='n-text-wrap'>
                                                <p className='n-title mb'>
                                                    {/* <span className='n-user-name'>Purnomo </span> */}
                                                    <span className='n-user-todo'>{n.title}
                                                        {/* <span className='n-file-name'> Presentation.pdf </span>
                                                        file */}
                                                    </span>
                                                </p>
                                                <p className='n-comment mb'>{n.detail}</p>
                                                <p className="n-user-activity-time mb">{moment(n.created_at).fromNow()}</p>
                                                {/* <ul className='n-uploadfile-wrap' >
                                                    <li className='upload-file-li'>
                                                        <div className='pdf-file-wrap'>
                                                            <img src={PDF} alt="pdf" />
                                                            <div>
                                                                <p className='n-pdf-name mb-0'>Presentation.Pdf</p>
                                                                <p className='n-pdf-size mb-0'>12Mb </p>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className='upload-file-li'>
                                                        <div className='pdf-file-wrap'>
                                                            <img src={PDF} alt="pdf" />
                                                            <div className='n-uploadfile-data-wrap'>
                                                                <p className='n-pdf-name '>Presentation.Pdf</p>
                                                                <p className='n-pdf-size'>12Mb </p>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul> */}
                                            </div>

                                        </div>
                                        <div className='noti-handle-btns'>
                                            <span className={`read_unred_icon ${!n.status ? 'is_active' : ''}`}></span>
                                            <button type="button" className='delete-btn' onClick={() => handleDeleteNotification(n.notification_id)}>
                                                <img src={Delete} alt="delete" />
                                            </button>
                                        </div>

                                    </li>
                                ))}

                            </ul>
                            :
                            null
                        }
                    </div>
                </Scrollbars>
            </div>
        </section>
    )
}

export default Notification

import {
    SINGLE_FILE_SELECT,
    MULTIPLE_FILE_SELECT,
    REMOVE_SELECTED_FILES
} from '../actions/files';

const initialState = {
    selectedFiles: [],
    selectedfolders: [],
    isMultipleFiles:false,
    isMultipleFolders:false,
};

export default function multiSelection(state = initialState, action) {
    switch (action.type) {
        case SINGLE_FILE_SELECT:
            return{
                ...state,
                isMultipleFiles:false,
                selectedFiles:[action.payload]
            }
        case MULTIPLE_FILE_SELECT:
            let newArrFiles = state.selectedFiles;
            const file = action.payload;
            if (newArrFiles.length > 0) {
                if (newArrFiles.find((item) => item.file_id === file.file_id)) {
                    newArrFiles = newArrFiles.filter((item) => item.file_id !== file.file_id)
                } else {
                    newArrFiles = [...newArrFiles, file]
                }
            }
            return {
                ...state,
                isMultipleFiles:true,
                selectedFiles: newArrFiles
            };
        case REMOVE_SELECTED_FILES:
            return {
                ...state,
                isMultipleFiles:false,
                selectedFiles: []
            };
        default:
            return state;
    }
}
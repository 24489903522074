import { all, call, put, takeEvery } from 'redux-saga/effects';
import { notifyError, notifySuccess } from '../components/common/Alert';
import { POPUP_EVENTS } from '../constants/events';
import {
  PASTE_COPIED_FILE,
  PASTE_COPIED_FILE_FAILURE,
  PASTE_COPIED_FILE_SUCCESS,
} from '../redux/actions/copiedFile';
import {
  CHECK_FAVORITE,
  CHECK_FILE,
  REMOVE_FILE,
  RENAME_FILE,
  UPLOAD_FILE,
  REMOVE_FILE_SUCCESS,
  RENAME_FILE_SUCCESS,
  CHECK_FAVORITE_SUCCESS,
  CHECK_FAVORITE_FAILURE,
  CHECK_FILE_FAILURE,
  CHECK_FILE_SUCCESS,
} from '../redux/actions/files';
import {
  DELETE_LINK,
  DELETE_LINK_SUCCESS,
  GET_LINKED_FILES,
  GET_LINKED_FILES_SUCCESS,
  LINK_FILE,
  LINK_FILE_SUCCESS,
} from '../redux/actions/linkFilePopup';
import { renameDocs } from '../services/documents';
import { checkFavorite } from '../services/file.favorite';
import {
  removeFile,
  checkFile,
  pasteFile,
  getUploadUrl,
  uploadFile,
  uploadCheckinDetail,
  linkFile,
  deleteLink,
  getLinkedFiles,
} from '../services/files';
import { emitter } from '../utils/emitter';

function *removeFileSaga(action) {
  try {
    const result = yield call(removeFile, action);
    if (result.success) {
      notifySuccess(result.msg);
      emitter.emit(POPUP_EVENTS.HIDE_CONFIRM_DELETE_FILE);
      yield put({
        type: REMOVE_FILE_SUCCESS,
        payload: action.payload,
      });
    }
  } catch (err) {
    notifyError(err.response.data.msg);
    emitter.emit(POPUP_EVENTS.HIDE_CONFIRM_DELETE_FILE);
  }
}

function *renameFileSaga(action) {
  try {
    const result = yield call(renameDocs, action);
    if (result.success) {
      notifySuccess(result.msg);
      yield put({
        type: RENAME_FILE_SUCCESS,
        payload: action.payload,
      });
    }
  } catch (err) {
    notifyError(err.response.data.msg);
  }
}

function *checkFavoriteSaga(action) {
  try {
    const result = yield call(checkFavorite, action);
    if (result.success) {
      notifySuccess(result.msg);
      yield put({
        type: CHECK_FAVORITE_SUCCESS,
        payload: action.payload,
      });
    }
  } catch (err) {
    yield put({
      type: CHECK_FAVORITE_FAILURE,
    });
    notifyError(err.response.data.msg);
  }
}

function *checkFileSaga(action) {
  try {
    const result = yield call(checkFile, action);
    if (result.success) {
      if (action.payload.isCheck) {
        emitter.emit(POPUP_EVENTS.SHOW_CHECKOUT_SUCCESS, action.payload.fileId);
      }
      yield put({
        type: CHECK_FILE_SUCCESS,
        payload: action.payload,
      });
    }
  } catch (err) {
    console.log(err);
    yield put({
      type: CHECK_FILE_FAILURE,
    });
    notifyError(err.response.data.msg);
  }
}

function *uploadFileSaga(action) {
  try {
    const result = yield call(getUploadUrl, action);
    const { upload_url, filename, parent_id } = result;
    emitter.emit(POPUP_EVENTS.SHOW_UPLOAD_POPUP);
    yield call(uploadFile, {
      payload: {
        file: action.payload.file,
        fileId: action.payload.fileId,
        upload_url,
      },
    });
    const result3 = yield call(uploadCheckinDetail, {
      payload: { filename, parent_id, fileId: action.payload.fileId },
    });
    console.log(result3);

    // then(() => {
    //   uploadFiles[index] = {
    //     ...uploadFiles[index],
    //     status: 'success',
    //   };
    //   setUploadFiles([...uploadFiles]);
    //   dispatch(checkDocs(parent_id));
    //   notifySuccess();
    // })
    // .catch(err => {
    //   uploadFiles[index] = {
    //     ...uploadFiles[index],
    //     status: 'failed',
    //   };
    //   setUploadFiles([...uploadFiles]);
    //   console.log(err);
    // })
    // );
    // }
  } catch (err) {
    console.log(err);
    yield put({
      type: CHECK_FILE_FAILURE,
    });
    notifyError(err.response.data.msg);
  }
}

function *pasteFileSaga(action) {
  try {
    const result = yield call(pasteFile, action);
    if (result.success) {
      notifySuccess(result.msg);
      yield put({
        type: PASTE_COPIED_FILE_SUCCESS,
        payload: result.files[0],
      });
    }
  } catch (err) {
    console.log(err);
    yield put({
      type: PASTE_COPIED_FILE_FAILURE,
    });
    notifyError(err.response.data.msg);
  }
}
function *linkFileSaga(action) {
  try {
    const result = yield call(linkFile, action);
    if (result.success) {
      notifySuccess(result.msg);
      yield put({
        type: LINK_FILE_SUCCESS,
        payload: {
          file_id: action.payload.file_id,
          file_to_link: action.payload.file_to_link,
        },
      });
    }
  } catch (err) {
    console.log(err);
    notifyError(err.response.data.msg);
  }
}
function *deleteLinkSaga(action) {
  try {
    const result = yield call(deleteLink, action);
    const { file_id, file_id_to_delete } = action.payload;
    if (result.success) {
      notifySuccess(result.msg);
      yield put({
        type: DELETE_LINK_SUCCESS,
        payload: {
          file_id,
          file_id_to_delete,
        },
      });
    }
  } catch (err) {
    console.log(err);
    notifyError(err.response.data.msg);
  }
}
function *getLinkedFilesSaga(action) {
  try {
    const result = yield call(getLinkedFiles, action);
    if (result.success) {
      yield put({
        type: GET_LINKED_FILES_SUCCESS,
        payload: result.files,
      });
    }
  } catch (err) {
    console.log(err);
    notifyError(err.response.data.msg);
  }
}

export default function *filesWatcher() {
  yield all([
    takeEvery(REMOVE_FILE, removeFileSaga),
    takeEvery(RENAME_FILE, renameFileSaga),
    takeEvery(CHECK_FAVORITE, checkFavoriteSaga),
    takeEvery(CHECK_FILE, checkFileSaga),
    takeEvery(PASTE_COPIED_FILE, pasteFileSaga),
    takeEvery(UPLOAD_FILE, uploadFileSaga),
    takeEvery(GET_LINKED_FILES, getLinkedFilesSaga),
    takeEvery(LINK_FILE, linkFileSaga),
    takeEvery(DELETE_LINK, deleteLinkSaga),
  ]);
}

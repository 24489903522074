export const GET_USER_INFO = 'GET_USER_INFO';
export const GET_USER_INFO_SUCCESS = 'GET_USER_INFO_SUCCESS';
export const GET_USER_INFO_FAIL = 'GET_USER_INFO_FAIL';
export const UPDATE_USER_INFO = 'UPDATE_USER_INFO';
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const UPDATE_USER_PLAN_MODAL = 'UPDATE_USER_PLAN_MODAL';

export const getUserInfo = () => ({
  type: GET_USER_INFO,
});

export const getUserInfoSuccess = payload => ({
  type: GET_USER_INFO_SUCCESS,
  payload,
});

export const getUserInfoFail = () => ({
  type: GET_USER_INFO_FAIL,
});

export const updateUserInfo = payload => ({
  type: UPDATE_USER_INFO,
  payload,
});

export const updatePassword = payload => ({
  type: UPDATE_PASSWORD,
  payload,
});

export const updateUserPlanModal = payload => ({
  type: UPDATE_USER_PLAN_MODAL,
  payload
})

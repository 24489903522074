export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const VERIFY_OTP = 'VERIFY_OTP';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const RESEND_OTP = 'RESEND_OTP';
export const SET_PHONE_NUMBER = 'SET_PHONE_NUMBER';

export const verifyOtp = credential => ({
  type: VERIFY_OTP,
  payload: credential,
});

export const login = credentials => ({
  type: LOGIN,
  payload: credentials,
});

export const logout = () => ({
  type: LOGOUT,
});

export const resendOtp = () => ({
    type: RESEND_OTP,
});

export const setPhoneNumber = (payload) => ({
    type: SET_PHONE_NUMBER,
    payload,
});

import React, { useRef } from 'react';
import SkyLight from 'react-skylight';
import PropTypes from 'prop-types';
const popupStyle = {
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  margin: 'auto',
  minHeight: '',
  height: 'fit-content',
  width: 'fit-content',
  borderRadius: '10px',
  padding: '24px'
};
export default function Confirm({
  message,
  handleClick,
  btnClassName,
  popupclassName,
  children
}) {
  const popupRef = useRef();
  const onConfirm = () => {
    handleClick();
    popupRef.current.hide();
  };
  return (
    <>
      <button type="button" className={btnClassName} onClick={() => popupRef.current.show()}>
        {children}
      </button>
      <div style={{ position: 'absolute' }}>
        <SkyLight className={popupclassName} dialogStyles={popupStyle} ref={popupRef} hideOnOverlayClicked>
          <div>
            <div className='text'>{message}</div>
            {/* confirm-buttons */}
            <div className="delete--button-group d-flex justify-content-between">
              <button
                type="button"
                onClick={() => popupRef.current.hide()}
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={onConfirm}
              >
                OK
              </button>
            </div>
          </div>
        </SkyLight>
      </div>
    </>
  );
}
Confirm.propTypes = {
  message: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired
};

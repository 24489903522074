import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
// import { formatDate } from '../../utils/datetime';
import { getFileName, bytesToSize } from '../../utils/string';
import getDefaultThumbnail from '../../utils/getDefaultThumbnail';
import { useDispatch, useSelector } from 'react-redux';
import { MdLock, MdLink, MdFolder, MdOutlineSchedule, MdMoreVert } from 'react-icons/md';
import { BsCloudArrowUp } from 'react-icons/bs';
import { checkQuickAccess } from '../../redux/actions/quickAccess';
import { checkFavorite } from '../../redux/actions/files';
import { getLinkedFiles, resetLinkPopup } from '../../redux/actions/linkFilePopup';
import moment from 'moment';
import FolderIcon from '../../assets/images/folder.svg'

export default function FileTable({
  rows,
  rowLimit,
  handleFileContextMenu,
  drop,
  allowDrop,
  dragStart,
}) {

  const history = useHistory();
  const dispatch = useDispatch();
  const [sortState, setSortState] = useState({
    name: null,
    size: null,
    time: null,
    date: null,
  });
  // const linkedFiles = useSelector(f => f.linkFilePopup.linked_files);
  const userInfo = useSelector(f => f.userInfo);
  const [rowSelectedIndex, setRowSelectedIndex] = useState(false);
  function compareBy(type) {
    if (type === 'name') {
      return function compare(file1, file2) {
        const name1 = file1.new_name || getFileName(file1.file_name);
        const name2 = file2.new_name || getFileName(file2.file_name);
        if (name1 > name2) {
          return 1;
        }
        if (name1 < name2) {
          return -1;
        }
        return 0;
      };
    }
    return function compare(file1, file2) {
      if (file1[type] > file2[type]) return 1;
      if (file1[type] < file2[type]) return -1;
      return 0;
    };
  }

  function handleSort(type) {
    switch (type) {
      case 'name':
        if (sortState.name === 'asc') {
          setSortState({
            name: 'desc',
            size: null,
            time: null,
          });
          rows.sort(compareBy('name'));
          rows.reverse();
        } else {
          setSortState({
            name: 'asc',
            size: null,
            time: null,
          });
          rows.sort(compareBy('name'));
        }
        break;
      case 'size':
        if (sortState.size === 'asc') {
          setSortState({
            name: null,
            size: 'desc',
            time: null,
          });
          rows.sort(compareBy('size'));
          rows.reverse();
        } else {
          setSortState({
            name: null,
            size: 'asc',
            time: null,
          });
          rows.sort(compareBy('size'));
        }
        break;
      case 'time':
        if (sortState.time === 'asc') {
          setSortState({
            name: null,
            size: null,
            time: 'desc',
          });
          rows.sort(compareBy('updated_at'));
          rows.reverse();
        } else {
          setSortState({
            name: null,
            size: null,
            time: 'asc',
          });
          rows.sort(compareBy('updated_at'));
        }
        break;
      case 'date':
        if (sortState.date === 'asc') {
          setSortState({
            name: null,
            size: null,
            date: 'desc',
          });
          rows.sort(compareBy('created_at'));
          rows.reverse();
        } else {
          setSortState({
            name: null,
            size: null,
            date: 'asc',
          });
          rows.sort(compareBy('created_at'));
        }
        break;
      default:
        break;
    }
    // if (sortedEsc) {
    //   sortDesc();
    // } else {
    //   sortEsc();
    // }
  }

  function showFile(id) {
    history.push(`/file-detail/${id}`);
    dispatch(checkQuickAccess());
  }
  function getShortFilePath(filePath) {
    const filePathArr = filePath.split('/');
    if (filePathArr.length === 1) {
      return 'root';
    }
    if (filePathArr.length === 2) {
      return `/${filePathArr[filePathArr.length - 2]}`;
    }
    return `.../${filePathArr[filePathArr.length - 2]}`;
  }
  function getFullFilePath(filePath) {
    const filePathArr = filePath.split('/');
    if (filePathArr.length === 1) {
      return 'root';
    }
    return `/${filePathArr.slice(0, filePathArr.length - 1).join('/')}`;
  }
  return (
    <div className="table-responsive">
      <table className='table-search table-hover'
      // cellPadding="0" cellSpacing="0" border="0"
      >
        <thead>
          <tr>
            {/* <th
          // style={{ width: '5%' }} 
          /> */}
            <th
              // style={{ width: '25%' }} 
              onClick={() => handleSort('name')}>
              Name&nbsp;
              {sortState.name === 'asc' &&
                <i className="fa fa-long-arrow-up" aria-hidden="true" />
              }
              {sortState.name === 'desc' &&
                <i className="fa fa-long-arrow-down" aria-hidden="true" />
              }
            </th>
            <th
            // style={{ width: '8%', minWidth: '6rem' }} 
            >Status</th>
            <th
            // style={{ width: '12%' }}
            >Folder</th>
            <th
            // style={{ width: '12%' }}
            >Member</th>
            <th
              // style={{ width: '12%' }}
              onClick={() => handleSort('size')}
            >
              Size&nbsp;
              {sortState.size === 'asc' &&
                <i className="fa fa-long-arrow-up" aria-hidden="true" />
              }
              {sortState.size === 'desc' &&
                <i className="fa fa-long-arrow-down" aria-hidden="true" />
              }
            </th>
            <th
              // style={{ width: '12%' }} 
              onClick={() => handleSort('time')}>
              Last Accessed&nbsp;
              {sortState.time === 'asc' &&
                <i className="fa fa-long-arrow-up" aria-hidden="true" />
              }
              {sortState.time === 'desc' &&
                <i className="fa fa-long-arrow-down" aria-hidden="true" />
              }
            </th>
            <th
              // style={{ width: '12%' }} 
              onClick={() => handleSort('date')}>
              Upload Date&nbsp;
              {sortState.date === 'asc' &&
                <i className="fa fa-long-arrow-up" aria-hidden="true" />
              }
              {sortState.date === 'desc' &&
                <i className="fa fa-long-arrow-down" aria-hidden="true" />
              }
            </th>
          </tr>
        </thead>
        <tbody>
          {rows.length
            ? rows.map(
              (row, index) =>
                index < rowLimit && (
                  <tr
                    key={row.file_id}
                    className={`${row.favorite ? 'is-favorite' : ''} ${row.checkout && 'is-checkout'
                      } : ''`}
                    style={{
                      color: index === rowSelectedIndex ? '#534CDD' : undefined,
                      cursor: 'pointer'
                    }}
                    // style={{ cursor: 'pointer' }}
                    onDoubleClick={() => showFile(row.file_id)}
                    onClick={() => setRowSelectedIndex(index)}
                    onContextMenu={(e) => handleFileContextMenu(e, row)}
                    onDrop={drop}
                    onDragOver={allowDrop}
                    onDragStart={dragStart}
                  >
                    <td className='icon-td'>
                      <span className='d-flex align-items-center' data-tip={row.new_name || row.folder_name} data-for="fileName">
                        <img className="folder-icon" src={row.folder_id ? FolderIcon : getDefaultThumbnail(row.type)} alt="folder" />
                        <span style={{ marginLeft: '10px', whiteSpace: 'nowrap', maxWidth: '100px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                          {row.folder_id ? row.folder_name : row.new_name ? row.new_name : getFileName(row.file_name)}
                        </span>
                        <ReactTooltip id="fileName" />
                      </span>
                    </td>
                    <td>
                      <div className='status-td-div'>
                        <button
                          type="button"
                          data-tip="favorite"
                          data-for="favorite"
                          className={`btns-likes ${row.favorite ? 'active' : ''}`}
                          onClick={() =>
                            dispatch(
                              checkFavorite(row.file_id, !row.favorite, row)
                            )
                          }
                        >
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 5.09C13.09 3.81 14.76 3 16.5 3C19.58 3 22 5.42 22 8.5C22 12.2769 18.6056 15.3549 13.4627 20.0185L13.45 20.03L12 21.35L10.55 20.04L10.5105 20.0041C5.38263 15.3442 2 12.2703 2 8.5C2 5.42 4.42 3 7.5 3C9.24 3 10.91 3.81 12 5.09Z" fill="#DF5656" />
                          </svg>
                          <span>Favorite</span>
                        </button>
                        {row.checkout ?
                          <button
                            type="button"
                            data-tip="checkout"
                            data-for="checkout"
                            className='btn-checkout-check'
                          >
                            <MdLock />
                            <span>Checkout</span>
                          </button>
                          :
                          null
                        }
                        {row.link ?
                          <button
                            type="button"
                            style={{ lineHeight: '0' }}
                            className='link-btns'
                            data-tip=""
                            data-for="linkedfile"
                            onMouseOver={() => dispatch(getLinkedFiles(row.file_id))}
                            onMouseLeave={() => dispatch(resetLinkPopup())}
                          >
                            <MdLink />
                            <span>Linked</span>
                          </button>
                          :
                          null
                        }
                      </div>
                      <ReactTooltip id="linkedfile" />
                      <ReactTooltip id="favorite" />
                      <ReactTooltip id="checkout" />
                    </td>
                    <td data-tip="" data-for={row.file_id}>
                      <div className="folder-path-wraps">
                        <span className='folder-icon'><MdFolder /></span>
                        {getShortFilePath(row.file_path ? row.file_path : '/')}
                      </div>
                      <ReactTooltip
                        id={row.file_id}
                        getContent={() =>
                          getFullFilePath(row.file_path ? row.file_path : '/')
                        }
                      />
                    </td>
                    <td>
                      <div className='tb-users'>
                        <div className="user-picture-wrap">
                          {userInfo?.avatar ?
                            <img src={userInfo.avatar} alt="user" />
                            :
                            <span className='user-icon'>
                              {userInfo.firstname[0] || ''}
                              {userInfo.lastname[0] || ''}
                            </span>
                          }
                        </div>
                        Only you
                      </div>
                    </td>
                    <td>
                      {bytesToSize(row.size)}
                    </td>
                    <td>
                      <div className='icon-with-text'>
                        <span className='icon'><MdOutlineSchedule /></span>
                        {moment(row.updated_at).format('MMM DD, YYYY')}
                      </div>
                    </td>
                    <td>
                      <div className='icon-with-text'>
                        <span className='icon'><BsCloudArrowUp /></span>
                        {moment(row.created_at).format('MMM DD, YYYY')}
                      </div>
                      <div className="files-dropdown table-td-drop">
                        <button type="button" data-for="menu" data-tip="Menu" style={{ lineHeight: '0' }} onClick={(e) => handleFileContextMenu(e, row)}><MdMoreVert /></button>
                        <ReactTooltip id="menu" />
                      </div>
                    </td>
                  </tr>
                )
            )
            : ''}
        </tbody>
      </table>
    </div>
  );
}
FileTable.propTypes = {
  rows: PropTypes.array.isRequired,
  // rowLimit: PropTypes.number.isRequired,
  // handleFileContextMenu: PropTypes.func.isRequired,
  // drop: PropTypes.func.isRequired,
  // allowDrop: PropTypes.func.isRequired,
  // dragStart: PropTypes.func.isRequired,
};

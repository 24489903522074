import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import FilesComponent from '../FilesComponent';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import FolderItem from './Folder.Item';
import { useDispatch, useSelector } from 'react-redux';
import { checkPath, checkPathBack } from '../../redux/actions/path';
import MenuIcon from '../../assets/images/grid-icon.svg'
import ListIcon from '../../assets/images/list-icon.svg'
import Folder from '../../assets/images/folder-icon.svg';
import { MdMoreVert, MdKeyboardBackspace, MdFolder, MdOutlineSchedule, MdLock, MdLink } from 'react-icons/md';
import { BsCloudArrowUp } from 'react-icons/bs'
import BootstrapTable from 'react-bootstrap-table-next';
import getDefaultThumbnail from '../../utils/getDefaultThumbnail';
import { bytesToSize, getFolderName } from '../../utils/string';
// import { formatDate } from '../../utils/datetime';
import moment from 'moment'
import { checkQuickAccess } from '../../redux/actions/quickAccess';
import ReactTooltip from 'react-tooltip';
import { checkFavorite } from '../../redux/actions/files';
import { getLinkedFiles, resetLinkPopup } from '../../redux/actions/linkFilePopup';

// function getShortFilePath(filePath) {
//   const filePathArr = filePath.split('/');
//   if (filePathArr.length === 1) {
//     return 'Root';
//   }
//   if (filePathArr.length === 2) {
//     return `${filePathArr[filePathArr.length - 2]}`;
//   }
//   return `${filePathArr[filePathArr.length - 2]}`;
// }

const dropdownText = [
  { id: 1, name: 'Name', value: 'name' },
  { id: 2, name: 'File Type', value: 'file-type' },
  { id: 3, name: 'Size', value: 'size' },
  { id: 4, name: 'Uploaded', value: 'uploaded' },
  { id: 5, name: 'Last Accessed', value: 'last-accessed' }
]

export default function FolderDetail({
  handleFileContextMenu,
  handleClick,
  handleClickPath,
  folders,
  files,
  handleDrop,
  handleDragOver,
  handleFolderContextMenu,
  drop,
  allowDrop,
  dragStart,
  handleCreateFolders,
  handlePasteCopiedFile,
  onSelect
}) {

  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const path = useSelector((s) => s.path);
  const allFolders = useSelector((s) => s.allFolders);
  const selectedFiles = useSelector((s) => s.multiSelection.selectedFiles);
  const isMultipleFiles = useSelector((s) => s.multiSelection.isMultipleFiles);

  useEffect(() => {
    if (path.length !== 2) {
      dispatch(checkPath({ id: params.id, name: getFolderName(params.id, allFolders) }))
    }
  }, [])

  const handleGoBack = () => {
    dispatch(checkPathBack());
    history.goBack();
  };

  const [gridView, setGridView] = useState(false);
  const [defaultFiles, setDefaultFiles] = useState([])
  const [filesArray, setFilesArray] = useState([]);
  const [foldersArray, setFoldersArray] = useState([]);
  const [sortArray, setSortArray] = useState('');

  useEffect(() => {
    setFilesArray(files);
    setDefaultFiles(files);
    setFoldersArray(folders);
  }, [folders, files])

  const gridviewHandler = () => {
    setGridView(!gridView);
  }

  function viewFileClick(e, row, rowIndex) {
    // console.log(row);
    dispatch(checkQuickAccess());
    history.push(`/file-detail/${row.file_id}`);
  }

  function nameFormatter(cell, row) {
    return (
      <span className='d-flex align-items-center' data-tip={row.new_name || row.folder_name} data-for="fileName">
        <img className="folder-icon" src={row.folder_id ? Folder : getDefaultThumbnail(row.type)} alt="folder" />
        <span style={{ whiteSpace: 'nowrap', maxWidth: '100px', overflow: 'hidden', textOverflow: 'ellipsis' }}>{row.new_name || row.folder_name}</span>
        <ReactTooltip id="fileName" />
      </span>
    );
  }

  const fileTypeFormatter = (cell, row) => {
    return row.folder_id ? <span className='folder-name'>File Folder</span> : <span className='text-uppercase'>{row.type}</span>
  }

  const folderPathFormatter = (cell, row) => {
    let folderName = '';
    folderName = getFolderName(row.parent_id, allFolders)

    return (
      <div className="folder-path-wraps" data-for="folderName" data-tip={folderName}>
        <span className='folder-icon'><MdFolder /></span>
        <span style={{ whiteSpace: 'nowrap', maxWidth: '55px', overflow: 'hidden', textOverflow: 'ellipsis' }}>{folderName}</span>
        <ReactTooltip id="folderName" />
      </div>
    )
  }

  const sizeFormatter = (cell, row) => {
    return row.size ? bytesToSize(row.size) : '-'
  }

  const accessedFormatter = (cell, row) => {
    const date = row.updated_at ? new Date(row.updated_at) : '';
    return (
      row.updated_at ?
        <div className='icon-with-text'>
          <span className='icon'><MdOutlineSchedule /></span>
          {moment(date).format('MMM DD, YYYY')}
        </div>
        :
        '-'
    )
  }
  const uploadFormatter = (cell, row) => {
    // console.log(row);
    const date = row.created_at ? new Date(row.created_at) : '';
    return (
      <>
        <div className='icon-with-text'>
          <span className='icon'><BsCloudArrowUp /></span>
          {moment(date).format('MMM DD, YYYY')}
        </div>
        <div className="files-dropdown table-td-drop">
          {row.folder_id ?
            <button type="button" data-for="menu" data-tip="Menu" style={{ lineHeight: '0' }} onClick={(e) => handleFolderContextMenu(row.folder_id, row.folder_name, e)}><MdMoreVert /></button>
            :
            <button type="button" data-for="menu" data-tip="Menu" style={{ lineHeight: '0' }} onClick={(e) => handleFileContextMenu(e, row)}><MdMoreVert /></button>
          }
          <ReactTooltip id="menu" />
        </div>
      </>
    )
  }

  const statusFormatter = (cell, row) => {
    return (
      <>
        <span className='status-td-div'>
          <button
            type="button"
            data-tip="favorite"
            data-for="favorite"
            className={`btns-likes ${row.favorite ? 'active' : ''}`}
            onClick={() =>
              dispatch(
                checkFavorite(row.file_id, !row.favorite, row)
              )
            }
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 5.09C13.09 3.81 14.76 3 16.5 3C19.58 3 22 5.42 22 8.5C22 12.2769 18.6056 15.3549 13.4627 20.0185L13.45 20.03L12 21.35L10.55 20.04L10.5105 20.0041C5.38263 15.3442 2 12.2703 2 8.5C2 5.42 4.42 3 7.5 3C9.24 3 10.91 3.81 12 5.09Z" fill="#DF5656" />
            </svg>

            <span>Favorite</span>
          </button>

          {row.checkout ?
            <button
              type="button"
              data-tip="checkout"
              data-for="checkout"
              className='btn-checkout-check'
            >
              <MdLock />
              <span>Checkout</span>
            </button>

            :
            null
          }
          {row.link ?

            <button
              type="button"
              style={{ lineHeight: '0' }}
              className='link-btns'
              data-tip=""
              data-for="linkedfile"
              onMouseOver={() => dispatch(getLinkedFiles(row.file_id))}
              onMouseLeave={() => dispatch(resetLinkPopup())}
            >
              <MdLink />
              <span>Linked</span>
            </button>
            :
            null
          }
        </span>
        <ReactTooltip id="linkedfile" />
        <ReactTooltip id="favorite" />
        <ReactTooltip id="checkout" />
      </>
    )
  }

  const sortHandler = (e, item) => {
    e.preventDefault();
    const sortVal = item.value;
    if (sortArray === sortVal) {
      console.log('if remove checkbox')
      setSortArray('')
      console.log(defaultFiles);
      setFilesArray(defaultFiles)
      return true;
    } else {
      setSortArray(sortVal);
      if (defaultFiles && defaultFiles.length > 0) {
        const fileSort = defaultFiles.sort((a, b) => {
          switch (sortVal) {
            case 'name':
              if (a.new_name < b.new_name) { return -1; }
              if (a.new_name > b.new_name) { return 1; }
              return 0;
            case 'file-type':
              if (a.type < b.type) { return -1; }
              if (a.type > b.type) { return 1; }
              return 0;
            case 'size':
              if (a.size < b.size) { return -1; }
              if (a.size > b.size) { return 1; }
              return 0;
            case 'uploaded':
              const a_cdate = new Date(a.created_at);
              const b_cdate = new Date(b.created_at);
              return b_cdate - a_cdate;
            case 'last-accessed':
              const a_date = new Date(a.updated_at);
              const b_date = new Date(b.updated_at);
              return b_date - a_date;
            default:
              return null;
          }

        });
        setFilesArray(fileSort);
      }
    }
  }

  const handleOnSelect = (row, isSelect) => {
    onSelect(row)
    
  }
  const handleOnSelectAll = (isSelect, rows) => {
    onSelect(rows)
   
  }

  const columns = [

    {
      dataField: 'new_name',
      text: 'Name',
      formatter: nameFormatter,
      sort: true,
      sortFunc: (a, b, order, dataField, row) => {
        if (order === 'asc') {
          // if (a < b) { return -1; }
          // if (a > b) { return 1; }
          return 1;
        } else {
          if (a > b) { return 1; }
          if (a < b) { return -1; }
          return 0;
        }
      }
    },
    {
      dataField: 'status',
      text: 'Status',
      sort: false,
      formatter: statusFormatter
    },
    {
      dataField: 'type',
      text: 'File Type',
      sort: true,
      formatter: fileTypeFormatter
    },
    {
      dataField: 'folder_name',
      text: 'Folder',
      sort: true,
      formatter: folderPathFormatter
    },
    // {
    //   dataField: 'member_icons',
    //   text: 'Member'
    // },
    {
      dataField: 'file_size',
      text: 'Size',
      sort: true,
      formatter: sizeFormatter
    },
    {
      dataField: 'updated_at',
      text: 'Last Accessed',
      sort: true,
      formatter: accessedFormatter
    },
    {
      dataField: 'created_at',
      text: 'Upload Date',
      sort: true,
      formatter: uploadFormatter
    }
  ];

  const arrayMarge = [...foldersArray, ...filesArray];

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    onSelect: handleOnSelect,
    onSelectAll: handleOnSelectAll,
    hideSelectColumn: true,
    bgColor: '#f5f6f8',
    selected: selectedFiles && selectedFiles.length > 0 ? selectedFiles.map((item) => item.id) : []
    // onDoubleClick: viewFileClick
  }

  const rowEvents = {
    onContextMenu: (e) => {
      if (isMultipleFiles) {
        handleFileContextMenu(e, selectedFiles?.[0])
      }
    }
  }

  return (
    <div
      className="main-body tab-panel-custom"
      tabIndex="-1"
      onKeyDown={(e) => {
        if (e.key === 'v' && e.ctrlKey) {
          handlePasteCopiedFile();
        }
      }}
      onContextMenu={(e) => {
        if (
          [
            'main-box',
            'main-body tab-panel-custom',
            'mainNav',
            'mainNav back-mainNav mb-5 justify-content-between',
            'recently-access',
            'folders',
            'folders__title',
            'folders__items',
            'files',
            'files__title',
            'files__items',
            'file-recent',
            'folders__title',
            'title-files d-flex align-items-start justify-content-between mt-4',
            'title-files d-flex align-items-start justify-content-between'
          ].includes(e.target.className)
        ) {
          handleCreateFolders(e);
        }
      }}
    >
      <div
        className="mainNav back-mainNav mb-5 justify-content-between"
        onDrop={(e) => e.preventDefault()}
        onDragOver={(e) => e.preventDefault()}
      >
        <div className='d-flex justify-content-start align-items-center'>
          <i className="icon-arrow-left" onClick={handleGoBack}><MdKeyboardBackspace /></i>
          <div className="path">
            <i className="icon-folder"><MdFolder /></i>
            &nbsp;
            {path.map((i) => (
              <span
                key={i.id}
                onClick={() => handleClickPath(i.id, i.name)}
                data-drop
                onDrop={drop}
                onDragOver={allowDrop}
                data-id={`fo-${i.id}`}
              >
                {i.id ? '/' : ''}&nbsp;
                {i.name}&nbsp;
              </span>
            ))}
          </div>
        </div>
        <div className='btns-groups-grids'>
          <button type="button" className='bg-transparent border-0' onClick={gridviewHandler}>
            <img src={gridView ? ListIcon : MenuIcon} alt="views" />
          </button>
          {gridView && <Dropdown className="files-dropdown">
            <Dropdown.Toggle id="dropdown-button-dark-example1">
              <MdMoreVert />
            </Dropdown.Toggle>

            <Dropdown.Menu className="custom-dropdown custom-sort-dropmenu">
              {dropdownText && dropdownText.length > 0 ?
                dropdownText.map((item, i) =>
                  <Dropdown.Item
                    href="#/action-1"
                    key={i}
                    className={`${item.value === sortArray ? 'is_active' : ''}`}
                    onClick={(e) => sortHandler(e, item)}
                  >
                    {item.name}
                  </Dropdown.Item>
                )
                :
                null
              }
            </Dropdown.Menu>
          </Dropdown>
          }
        </div>

      </div>
      {!gridView ?
        <ListViewBlock
          columns={columns}
          data={arrayMarge && arrayMarge.length > 0 ? arrayMarge.map((item, i) => ({ id: i, ...item })) : []}
          keyField={'id'}
          rowEvents={rowEvents}
          selectRow={selectRow}

        />
        :
        <GridViewBlock
          folders={foldersArray}
          handleDrop={handleDrop}
          handleDragOver={handleDragOver}
          handleClick={handleClick}
          handleFolderContextMenu={handleFolderContextMenu}
          handleFileContextMenu={handleFileContextMenu}
          dragStart={dragStart}
          drop={drop}
          allowDrop={allowDrop}
          filesArr={filesArray}
          onSelect={onSelect}
          selectedFiles={selectedFiles}
        />
      }

    </div>
  );
}

function GridViewBlock({
  folders,
  handleDrop,
  handleDragOver,
  handleClick,
  handleFolderContextMenu,
  handleFileContextMenu,
  dragStart,
  drop,
  allowDrop,
  filesArr,
  onSelect,
  selectedFiles
}) {

  return <>

    {folders && folders.length ? (
      <div
        className="folders"
        onDrop={handleDrop}
        onDragOver={handleDragOver}
      >
        <div className="title-files d-flex align-items-start justify-content-between mt-4">
          <h3 className="file-title">Folder</h3>
          {/* <button className="btn-outline-primary" onClick={showFolder
              Toggle}>{folderCount > 6 ? 'Less More' : 'Show all'}</button> */}
        </div>
        <Row className='mb-4'>
          {folders.map((item, i) => (
            <Col xs={12} sm={6} md={4} key={i}>
              <FolderItem
                key={item.folder_id}
                folder={item}
                handleClick={handleClick}
                handleFolderContextMenu={handleFolderContextMenu}
                dragStart={dragStart}
                onDrop={drop}
                onDragOver={allowDrop}
              />
            </Col>
          ))}
        </Row>
      </div>
    ) : null}

    {filesArr && filesArr.length ? (
      <div className="files" onDrop={handleDrop} onDragOver={handleDragOver}>
        <div className="title-files d-flex align-items-start justify-content-between">
          <h3 className="file-title">
            Files
            <span className='count-totals'>{filesArr && filesArr.length > 0 ? filesArr.length : 0}</span>
          </h3>
          {/* <button className="btn-outline-primary" onClick={showToggle}>{count > 4 ? 'Less More' : 'Show all'}</button> */}
        </div>
        <Row>
          {filesArr.map((item, index) => (
            <Col xs={12} sm={6} key={index}>
              <FilesComponent
                key={item.file_id}
                handleFileContextMenu={(e) => handleFileContextMenu(e, item)}
                id={index}
                file={item}
                drop={drop}
                allowDrop={allowDrop}
                dragStart={dragStart}
                onSelect={onSelect}
                fileSelected={selectedFiles && selectedFiles.find(file => file.file_id === item.file_id) ? true : false}
              />
            </Col>
          ))}
        </Row>
      </div>
    ) : null}

  </>
}

function ListViewBlock({
  data,
  columns,
  keyField,
  rowEvents,
  selectRow
}) {
  return (
    <div className='list-view-block'>
      <div className="d-flex align-items-center justify-content-between">
        {/* <button className="btn-primary">12 Files</button>
        <button className="btn-outline-primary">Show all</button> */}
      </div>
      <BootstrapTable keyField={keyField}
        data={data}
        columns={columns}
        bordered={false}
        classes={'table-search'}
        rowEvents={rowEvents}
        selectRow={selectRow}
        noDataIndication="There is no data"
      />
    </div>
  );
}

FolderDetail.propTypes = {
  handleFileContextMenu: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
  handleClickPath: PropTypes.func.isRequired,
  files: PropTypes.array.isRequired,
  folders: PropTypes.array.isRequired,
  handleDrop: PropTypes.func.isRequired,
  handleDragOver: PropTypes.func.isRequired,
  handleFolderContextMenu: PropTypes.func.isRequired,
  drop: PropTypes.func.isRequired,
  allowDrop: PropTypes.func.isRequired,
  dragStart: PropTypes.func.isRequired,
  handleCreateFolders: PropTypes.func.isRequired,
  handlePasteCopiedFile: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.object,
  }),
};

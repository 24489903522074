import { shallowMergeArrayByKey } from '../../utils/string';
import { CHECK_DOCS_SUCCESS } from '../actions/documents';
import {
  ADD_fOLDER_SUCCESS,
  ADD_fOLDER_FAILURE,
  RENAME_FOLDER_SUCCESS,
  RENAME_FOLDER_FAILURE,
  MOVE_FOLDER_SUCCESS,
  MOVE_FOLDER_FAILURE,
  DELETE_FOLDER_SUCCESS,
  DELETE_FOLDER_FAILURE,
  CHECK_FOLDER_FAVORITE_SUCCESS,
  CHECK_FOLDER_FAVORITE_FAILURE
} from '../actions/folders';

const initialState = [];

export default function folders(state, action) {
  switch (action.type) {
    case CHECK_DOCS_SUCCESS:
      return [
        ...shallowMergeArrayByKey(
          state,
          action.payload.folders,
          action.payload.docChecked,
          'folder_id'
        ),
      ];
    case ADD_fOLDER_SUCCESS:
    case ADD_fOLDER_FAILURE:
    case RENAME_FOLDER_SUCCESS:
      state.find((f) => f.folder_id === action.payload.folder_id).folder_name =
        action.payload.newName;
      return [...state];
    case RENAME_FOLDER_FAILURE:
    case MOVE_FOLDER_SUCCESS:
      state.find((f) => f.folder_id === action.payload.folderId).parent_id =
        action.payload.newParentId || null;
      return [...state];
    case MOVE_FOLDER_FAILURE:
    case DELETE_FOLDER_SUCCESS: {
      const folderIdsToDelete = [];
      (function getFolderIdsToDelte(pid) {
        folderIdsToDelete.push(pid);
        const hasDescendants = state.filter((f) => f.parent_id === pid);
        if (hasDescendants.length) {
          hasDescendants.map((d) => getFolderIdsToDelte(d.folder_id));
        }
      }(action.payload));
      return [...state.filter((f) => !folderIdsToDelete.includes(f.folder_id))];
    }
    case CHECK_FOLDER_FAVORITE_SUCCESS:
      if (state.find((f) => f.folder_id === action.payload.folderId)) {
        state.find((f) => f.folder_id === action.payload.folderId).favorite =
          action.payload.isFavorite;
      }
      return [...state];
    case DELETE_FOLDER_FAILURE:
    default:
      return state || initialState;
  }
}

import axios from 'axios';
import CONFIG from '../constants/config';

export const netBanking = async ({ member, plan_type, plan }) => {
  const response = await axios({
    method: 'POST',
    url: `${CONFIG.BASE_URL}/manage_subscription_payu_init`,
    data: {
      member,
      plan_type,
      plan,
    },
    withCredentials: true,
  });
  return response.data;
};

export const netBankingConfirm = async ({
  member,
  plan_type,
  plan,
  payu: {
    key,
    txnid,
    amount,
    productinfo,
    firstname,
    email,
    udf5,
    status,
    hash,
    addedon,
  },
}) => {
  const response = await axios({
    method: 'PATCH',
    url: `${CONFIG.BASE_URL}/manage_subscription_payu`,
    data: {
      member,
      plan_type,
      plan,
      payu: {
        key,
        txnid,
        amount,
        productinfo,
        firstname,
        email,
        udf5,
        status,
        hash,
        addedon,
      },
    },
    withCredentials: true,
  });
  return response.data;
};

import axios from "axios";
import CONFIG from '../../constants/config';
import hashFile from '../../utils/hashFile';
import { checkQuickAccess } from '../../redux/actions/quickAccess';
import { checkDocs } from '../../redux/actions/documents';

class PDFFILEReder {

    constructor(props) {
        this.dispatch = props?.dispatch;
        this.folderId = props?.folderId;
        this.orignalFileName = props?.fileName
        this.readyPromise = new Promise((resolve) => {
            if (window.AdobeDC) {
                resolve();
            } else {
                document.addEventListener("adobe_dc_view_sdk.ready", () => {
                    resolve();
                });
            }
        });
        this.adobeDCView = undefined;
    }
    ready() {
        return this.readyPromise;
    }
    previewFile(divId, viewerConfig, url, fileName) {
        // let clientId = "50b77b1ecf644babae5deac82a954e95";
        let clientId = "c2680166dea042e388e57380171e93dc";
        if (process.env.NODE_ENV === "development") {
            // clientId = "c2680166dea042e388e57380171e93dc"
            clientId = "3a208d8890c84c90bcc0b06b4c6afe82"
        } else {
            clientId = "c2680166dea042e388e57380171e93dc";
            // clientId = "50b77b1ecf644babae5deac82a954e95";
        }
        const config = {
            clientId: clientId, ///enter lient id here
        };
        if (divId) {
            config.divId = divId;
        }
        this.adobeDCView = new window.AdobeDC.View(config);
        const previewFilePromise = this.adobeDCView.previewFile(
            {
                content: {
                    location: {
                        url: url,
                    },
                },
                metaData: {
                    fileName: fileName,
                    id: "6d07d124-ac85-43b3-a867-36930f502ac6",
                },
            },
            viewerConfig
        );
        return previewFilePromise;
    }
    previewFileUsingFilePromise(divId, filePromise, fileName) {
        let clientId = "50b77b1ecf644babae5deac82a954e95";
        if (process.env.NODE_ENV === "development") {
            clientId = "3a208d8890c84c90bcc0b06b4c6afe82"
        } else {
            clientId = "50b77b1ecf644babae5deac82a954e95";
        }
        this.adobeDCView = new window.AdobeDC.View({
            clientId: clientId, //enter Client id here
            divId,
        });
        this.adobeDCView.previewFile(
            {
                content: {
                    promise: filePromise,
                },
                metaData: {
                    fileName: fileName,
                },
            },
            {}
        );
    }
    createNewFile = (blob) => {
        return new Promise(function (resolve) {
            var reader = new FileReader();

            reader.onloadend = function () {
                resolve(reader.result);
            };

            reader.readAsArrayBuffer(blob);
        });
    }
    registerSaveApiHandler() {
        const saveOptions = {
            autoSaveFrequency: 0,
            enableFocusPolling: false,
            showSaveButton: true
        }
        const saveApiHandler = async (metaData, content, options) => {
            // console.log(metaData, content, options);
            // console.log(typeof content.buffer);

            // const uni8array = new Uint8Array([content.buffer]);
            var blob = new Blob([content.buffer], { type: metaData.mimeType })
            blob.name = metaData.fileName
            // const urlFile = URL.createObjectURL(blob);
            // const file = new File([urlFile], `${metaData.fileName}.pdf`, { type: metaData.mimeType });
            // var fileReader = new FileReader()
            // var fileU;
            // fileReader.onload = function (event) {
            //     fileU = event.target.result;
            // }
            // fileReader.readAsDataURL(blob);
            // console.log(fileU);
            // console.log(blob);
            // console.log(urlFile);
            // axios.post()
            if (!this.folderId) {
                const data = await axios.get(
                    `${CONFIG.BASE_URL}/file_upload_link?originalname=${encodeURIComponent(
                        `${blob.name}.pdf`
                    )}&type=${blob.type}`,
                    { withCredentials: true }
                );
                const { upload_url, filename } = data.data;
                axios
                    .put(upload_url, blob, {
                        headers: {
                            'Content-Type': blob.type,
                        },
                        // onUploadProgress: ({ loaded, total }) => {
                        //     uploadFiles[0] = {
                        //         ...uploadFiles[0],
                        //         percent: fixPercent((loaded / total) * 100),
                        //         loaded,
                        //     };
                        //     setUploadFiles([...uploadFiles]);
                        // },
                    })
                    .then(() =>
                        hashFile(blob, (hash_code) => {
                            axios
                                .post(
                                    `${CONFIG.BASE_URL}/file/uploaded_file_detail`,
                                    {
                                        file_name: filename,
                                        hash_code,
                                    },
                                    { withCredentials: true }
                                )
                                .then(() => {
                                    // uploadFiles[0] = {
                                    //     ...uploadFiles[0],
                                    //     status: 'success',
                                    // };
                                    // setUploadFiles([...uploadFiles]);
                                    this.dispatch(checkDocs(null));
                                    this.dispatch(checkQuickAccess());
                                })
                                .catch((err) => {
                                    // uploadFiles[0] = {
                                    //     ...uploadFiles[0],
                                    //     status: 'failed',
                                    // };
                                    // setUploadFiles([...uploadFiles]);
                                    console.log(err);
                                });
                        })
                    );


            } else {
                const data = await axios.get(
                    `${CONFIG.BASE_URL
                    }/file_upload_link?originalname=${encodeURIComponent(
                        `${blob.name}.pdf`
                    )}&type=${blob.type}&parent_id=${this.folderId}`,
                    { withCredentials: true }
                );
                const { upload_url, filename, parent_id } = data.data;
                axios
                    .put(upload_url, blob, {
                        headers: {
                            'Content-Type': blob.type,
                        },
                        // onUploadProgress: ({ loaded, total }) => {
                        //     uploadFiles[0] = {
                        //         ...uploadFiles[0],
                        //         percent: fixPercent((loaded / total) * 100),
                        //         loaded,
                        //     };
                        //     setUploadFiles([...uploadFiles]);
                        // },
                    })
                    .then(() =>
                        hashFile(blob, (hash_code) => {
                            axios
                                .post(
                                    `${CONFIG.BASE_URL}/file/uploaded_file_detail`,
                                    {
                                        file_name: filename,
                                        parent_id:parent_id,
                                        hash_code,
                                    },
                                    { withCredentials: true }
                                )
                                .then(() => {
                                    // uploadFiles[0] = {
                                    //     ...uploadFiles[0],
                                    //     status: 'success',
                                    // };
                                    // setUploadFiles([...uploadFiles]);
                                    this.dispatch(checkDocs(parent_id));
                                })
                                .catch((err) => {
                                    // uploadFiles[0] = {
                                    //     ...uploadFiles[0],
                                    //     status: 'failed',
                                    // };
                                    // setUploadFiles([...uploadFiles]);
                                    console.log(err);
                                });
                        })
                    );
            }

            return new Promise((resolve) => {
                setTimeout(() => {
                    const response = {
                        code: window.AdobeDC.View.Enum.ApiResponseCode.SUCCESS,
                        data: {
                            metaData: Object.assign(metaData, {
                                updatedAt: new Date().getTime(),
                            }),
                            file: blob
                        },
                    };
                    // console.log(response);
                    resolve(response);
                }, 2000);
            });
        };
        this.adobeDCView.registerCallback(
            window.AdobeDC.View.Enum.CallbackType.SAVE_API,
            saveApiHandler,
            saveOptions
        );
    }
    registerEventsHandler() {
        this.adobeDCView.registerCallback(
            window.AdobeDC.View.Enum.CallbackType.EVENT_LISTENER,
            (event) => {
                console.log(event);
            },
            {
                enablePDFAnalytics: true,
            }
        );
    }
}
export default PDFFILEReder;
import axios from 'axios';
import CONFIG from '../constants/config';

export const checkFolderFavorite = async (action) => {
    const response = await axios({
        method: 'POST',
        url: `${CONFIG.BASE_URL}/folder/favorite/${action.payload.folderId}`,
        withCredentials: true,
        data: {
            favorite: action.payload.isFavorite,
        },
    });
    return response.data;
};

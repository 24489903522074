import FileSaver from 'file-saver';
import React, { useEffect, useRef, useState } from 'react';
import { getActivitiesForAdmin } from '../../../services/reports';
import SkyLight from 'react-skylight';
import { Button, Form } from 'react-bootstrap';
import { useLocation } from 'react-router-dom'
// import { MdOutlineFileDownload } from 'react-icons/md';
import moment from 'moment';
import SelectMember from '../../task/admin/SelectMember';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import { MdClear } from 'react-icons/md'
const sendEmailStyle = {
  left: '30%',
  marginLeft: 0,
  marginTop: 0,
  top: '15%',
  width: '40%',
  height: '25%',
  minHeight: '24rem',
  borderRadius: 10,
};
export default function Activities({ memberList }) {

  const location = useLocation();
  const [activities, setActivities] = useState([]);
  const [pageCount, setPageCount] = useState(null);
  const [search, setSearch] = useState({ from_date: 'yyyy-mm-dd', to_date: 'yyyy-mm-dd' });
  const [sort, setSort] = useState(0);
  const [sortObj, setSortObj] = useState({ orderBy: 'desc' });
  const popupRef = useRef(null);
  const handleShowSearch = () => popupRef.current.show();
  const handleCancelSearch = () => {
    getActivitiesForAdmin({}).then(({ recent_activities, pages }) => {
      setPageCount(pages);
      setActivities(recent_activities);
      setSearch({ from_date: 'yyyy-mm-dd', to_date: 'yyyy-mm-dd' });
    });
  };
  const handleCancelSearch2 = () => {
    setSearch({ from_date: 'yyyy-mm-dd', to_date: 'yyyy-mm-dd' });
    popupRef.current.hide();
  };
  const handleSearch = () => {
    setSortObj({ orderBy: 'desc' });
    setSort(0);
    getActivitiesForAdmin({ ...search, orderBy: 'desc' }).then(
      ({ recent_activities, pages }) => {
        popupRef.current.hide();
        setPageCount(pages);
        setActivities(recent_activities);
      }
    );
  };
  const handleExport = () => {
    getActivitiesForAdmin({ ...search, export_file: true }).then((data) => {
      const BOM = '\uFEFF';
      const csvData = BOM + data;
      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
      FileSaver.saveAs(
        blob,
        `Log_Activity_${moment().format('DD-MM-YYYY')}.csv`
      );
    });
  };
  const handleSort = (index) => {
    switch (index) {
      case 0:
        setSort(0);
        setSortObj({ orderBy: 'desc' });
        getActivitiesForAdmin({
          from_date: search.from_date,
          to_date: search.to_date,
          member_id: search.member_id,
          orderBy: 'desc',
        }).then(({ recent_activities, pages }) => {
          setActivities(recent_activities);
          setPageCount(pages);
        });
        break;
      case 1:
        setSort(1);
        setSortObj({ orderBy: 'asc' });
        getActivitiesForAdmin({
          from_date: search.from_date,
          to_date: search.to_date,
          member_id: search.member_id,
          orderBy: 'asc',
        }).then(({ recent_activities, pages }) => {
          setActivities(recent_activities);
          setPageCount(pages);
        });
        break;
      case 2:
        setSort(2);
        setSortObj({ sortBy: true });
        getActivitiesForAdmin({
          from_date: search.from_date,
          to_date: search.to_date,
          member_id: search.member_id,
          sortBy: true,
        }).then(({ recent_activities, pages }) => {
          setActivities(recent_activities);
          setPageCount(pages);
        });
        break;
      default:
        break;
    }
  };
  const handlePageClick = (data) => {
    const { selected } = data;
    getActivitiesForAdmin({
      page: selected + 1,
      from_date: search.from_date,
      to_date: search.to_date,
      member_id: search.member_id,
      ...sortObj,
    }).then(({ recent_activities, pages }) => {
      setActivities(recent_activities);
      setPageCount(pages);
    });
  };
  const handleSelectMember = (member) => {
    if (
      typeof member === 'object' &&
      member !== null &&
      Object.keys(member).length
    ) {
      setSearch({ ...search, member_id: member.user_id });
    }
  };
  useEffect(() => {
    getActivitiesForAdmin({ ...sortObj }).then(
      ({ recent_activities, pages }) => {
        setActivities(recent_activities);
        setPageCount(pages);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className={`activity-wraps ${location.pathname === '/activities' ? 'mt-0' : ''}`}>
      <div className="heading-activities-page">
        <div className="title-activi">RECENT ACTIVITIES</div>
        <div className="buton-activi-wraps">
          {!search.member_id && search.from_date === 'yyyy-mm-dd' ? (
            <button type='button'
              onClick={handleShowSearch}
            >
              Advanced Search
            </button>
          ) : null}
          {search.member_id || search.from_date !== 'yyyy-mm-dd' ? (
            <button type='button'
              onClick={handleCancelSearch}
            >
              Cancel Search
            </button>
          ) : null}
          <button type='button' onClick={handleExport}>
            {/* <span><MdOutlineFileDownload /></span>  */}
            Export Activities
          </button>
        </div>
      </div>
      <div className="table-responsive">
        <table
          className="c-table"
          cellPadding="0"
          cellSpacing="0"
          border="0"
          style={{ width: '100%', boxShadow: 'unset', border: '0' }}
        >
          <thead>
            <tr>
              <th
                style={{
                  width: '75%',
                  textAlign: 'left',
                  cursor: 'pointer',
                  fontWeight: sort === 2 ? 'bold' : undefined,
                }}
                onClick={() => handleSort(2)}
              >
                Events
              </th>
              <th
                style={{
                  width: '15%',
                  textAlign: 'center',
                  cursor: 'pointer',
                  fontWeight: sort !== 2 ? 'bold' : undefined,
                }}
                onClick={() => handleSort(sort === 0 ? 1 : 0)}
              >
                Log Time&nbsp;
                {sort === 1 &&
                  <i className="fa fa-long-arrow-up" aria-hidden="true" />
                }
                {sort === 0 &&
                  <i className="fa fa-long-arrow-down" aria-hidden="true" />
                }
              </th>
            </tr>
          </thead>
          <tbody>
            {activities.map((row, i) => (
              <tr key={i}>
                <td style={{ width: '75%', textAlign: 'left' }}>
                  {' '}
                  {row.events}
                </td>
                <td
                  style={{
                    width: '15%',
                    textAlign: 'center',
                    fontWeight: 'normal',
                  }}
                >
                  {moment(row.log_time).format('MM/DD/YYYY HH:MM')}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <ReactPaginate
          previousLabel="previous"
          nextLabel="next"
          breakLabel="..."
          breakClassName="break-me"
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName="c-pagination"
          subContainerClassName="pages pagination"
          activeClassName="active"
        />
      </div>
      <SkyLight ref={popupRef} dialogStyles={sendEmailStyle}>
        <div className="checkinout">
          <div className="check-success--title">
            <span style={{ marginBottom: '0px !important' }}>Advanced Search</span>
            <i onClick={handleCancelSearch2} ><MdClear /></i>
          </div>
          <Form.Group className="form-group2 row">
            <div className="col-6">
              <Form.Label htmlFor="from-date" className="form-label2">From</Form.Label>
              <Form.Control
                id="from-date"
                type="date"
                value={search.from_date}
                onChange={(e) =>
                  setSearch({ ...search, from_date: e.target.value })
                }
              />
            </div>
            <div className="col-6">
              <Form.Label htmlFor="to-date" className="form-label2">To</Form.Label>
              <Form.Control
                id="to-date"
                type="date"
                value={search.to_date}
                onChange={(e) =>
                  setSearch({ ...search, to_date: e.target.value })
                }
              />
            </div>
          </Form.Group>
          <Form.Group className='form-group2 mb-5'>
            <Form.Label className="form-label2">User</Form.Label>
            <SelectMember
              data={memberList}
              callback={handleSelectMember}
              placeholder=""
              clear={search.member_id}
            />
          </Form.Group>
          <div className="check-button">
            <button type="button" onClick={handleCancelSearch2}>
              Cancel
            </button>
            <Button onClick={handleSearch}>OK</Button>
          </div>
        </div>
      </SkyLight>
    </div>
  );
}
Activities.propTypes = {
  memberList: PropTypes.array.isRequired,
};

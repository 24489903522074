import React, { useState } from 'react';
import Activities from './reports/Activities';
import Storages from './reports/Storages';
import UsageStats from './reports/UsageStats';
import PropTypes from 'prop-types';
export default function Reports({ memberList }) {
  const [activeTab, setActiveTab] = useState(0);

  const handleChangeTab = (index) => () => {
    setActiveTab(index);
  };

  return (
    <div className='settings-settings-section'>
      <div className="d-flex">
        <div
          className={`setting-user-btn disable-back ${
            activeTab === 0 ? 'active' : ''
          }`}
          onClick={handleChangeTab(0)}
        >
          Usage Stats
        </div>
        <div
          className={`setting-user-btn disable-back ${
            activeTab === 1 ? 'active' : ''
          }`}
          onClick={handleChangeTab(1)}
        >
          Activities
        </div>
        <div
          className={`setting-user-btn disable-back ${
            activeTab === 2 ? 'active' : ''
          }`}
          onClick={handleChangeTab(2)}
        >
          Storage Stats
        </div>
      </div>
      <div>
        {activeTab === 0 && <UsageStats/>}
        {activeTab === 1 && <Activities memberList={memberList}/>}
        {activeTab === 2 && <Storages/>}
      </div>
    </div>
  );
}
Reports.propTypes = {
  memberList: PropTypes.array.isRequired,
};

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import React from 'react';

export function notifySuccess(msg) {
  if (msg) toast.success(msg);
  else toast.success('Success!');
}
export function notifyError(msg) {
  if (msg) toast.error(msg);
  else toast.error('Error!');
}
export function notifyErrorMsg(err) {
  if (err?.response?.data?.msg) toast.error(err?.response?.data?.msg);
  else toast.error('Error!');
}
export function notifyInfo(msg) {
  if (msg) toast.info(msg);
  else toast.info('This feature is not available!');
}

// eslint-disable-next-line react/prop-types
const Noti = ({ reminder: { icon_link, title } }) => (
  <div className="socket-noti">
    <img src={icon_link} alt=""/>
    <div className="socket-noti__title">{title}</div>
  </div>
);

export function showNoti(reminder) {
  toast(<Noti reminder={reminder}/>, {
    bodyClassName: 'toast-noti-body',
    closeButton: true,
    progress: false,
    autoClose: true,
    hideProgressBar: true,
  });
}

function renderContent(type, content) {
  if (type === 'jpg' || type === 'png') return 'You have sent an image.';
  if (
    type === 'doc' ||
    type === 'txt' ||
    type === 'pptx' ||
    type === 'pdf' ||
    type === 'xlsx' ||
    type === 'zip'
  ) { return 'You have sent a file.'; }
  return content;
}

// eslint-disable-next-line react/prop-types
const NotiChat = ({
  chat: { avatar_url, content, type, first_name, last_name },
}) => (
  <div className="socket-noti">
    <img src={avatar_url} alt=""/>
    <div className="socket-noti__title">
      <div>{`${first_name} ${last_name}`}</div>
      <div>{renderContent(type, content)}</div>
    </div>
  </div>
);

export function showNotiChat(chat) {
  toast(<NotiChat chat={chat}/>, {
    bodyClassName: 'toast-noti-body',
    closeButton: true,
    progress: false,
    autoClose: true,
    hideProgressBar: true,
  });
}

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { MdKeyboardBackspace, MdAccessTime, MdCheck } from 'react-icons/md';
import { BsPlusLg } from 'react-icons/bs';
import { AiFillEye } from 'react-icons/ai';
import moment from 'moment';
import { Scrollbars } from 'react-custom-scrollbars';
import * as scrollbar from '../helpers/scrollbar';
import PDF from '../assets/images/pdf.svg'
import dummtImag from '../assets/images/dummyUser.svg';
import { getProject } from '../services/project';
import Admin from '../components/task/Project.Admin';
import User from '../components/task/Project.User';

export default function ProjectDetails() {

    const history = useHistory();
    const userId = useSelector((s) => s.userInfo.user_id);
    const { ownerId } = useLocation();
    const { params: { id } } = useRouteMatch();
    const [detail, setDetail] = useState(null);
    const [isDeleted, setisDeleted] = useState(false);

    const updateDetail = () => {
        getProject({ projectId: id })
            .then(({ project }) => {
                setDetail(project);
                setisDeleted(project.removed)
            })
            .catch((err) => console.error(err));
    };

    useEffect(() => {
        getProject({ projectId: id })
            .then(({ project }) => {
                setDetail(project);
                setisDeleted(project.removed)
                
            })
            .catch((err) => console.error(err));
    }, [id]);

    if (detail?.projectTeam.find((m) => m.user_id === userId)?.designation === 'lead') {
        // console.log('admin')
        return <Admin detail={detail} isProjectDeleted={isDeleted} isOwner={userId === ownerId} updateDetail={updateDetail} />;
    } else if (detail?.projectTeam.find((m) => m.user_id === userId)?.active) {
        return <User detail={detail} isProjectDeleted={isDeleted} />;
    }

    return (
        <section className='project-details-section'>
            <div className='left-wrap-projectd'>
                <div className='heading-page-title-wrap'>
                    <h1>File Testing</h1>
                    <button type='button' onClick={() => history.goBack()} className='back-btn'><span><MdKeyboardBackspace /></span> Project details</button>
                </div>
                <div className='scroll-wrpas'>
                    <Scrollbars
                        renderView={scrollbar.renderView}
                        renderTrackHorizontal={scrollbar.renderTrackHorizontal}
                        renderTrackVertical={scrollbar.renderTrackVertical}
                        renderThumbHorizontal={scrollbar.renderThumb}
                        renderThumbVertical={scrollbar.renderThumb}
                        universal={true}
                    >
                        <div className='scroll-wraps-padding'>
                            <div className='details-card'>
                                <div className='header-details'>
                                    <div className='img-with-text-wrap'>
                                        <div className='img-wraps'>
                                            <img src={PDF} alt="" />
                                        </div>
                                        <h2>File Testing</h2>
                                    </div>
                                    <div className='button-wraps'>
                                        <button type="button" className='btn-deletes'>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M19.3249 9.4668C19.3249 9.4668 18.7819 16.2018 18.4669 19.0388C18.3169 20.3938 17.4799 21.1878 16.1089 21.2128C13.4999 21.2598 10.8879 21.2628 8.27991 21.2078C6.96091 21.1808 6.13791 20.3768 5.99091 19.0458C5.67391 16.1838 5.13391 9.4668 5.13391 9.4668" stroke="#9EA8B6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M20.7082 6.23828H3.75024" stroke="#9EA8B6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M17.4407 6.239C16.6557 6.239 15.9797 5.684 15.8257 4.915L15.5827 3.699C15.4327 3.138 14.9247 2.75 14.3457 2.75H10.1127C9.5337 2.75 9.0257 3.138 8.8757 3.699L8.6327 4.915C8.4787 5.684 7.8027 6.239 7.0177 6.239" stroke="#9EA8B6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </button>
                                        <button type="button" className='btn-edits'>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M11.4925 2.78906H7.75349C4.67849 2.78906 2.75049 4.96606 2.75049 8.04806V16.3621C2.75049 19.4441 4.66949 21.6211 7.75349 21.6211H16.5775C19.6625 21.6211 21.5815 19.4441 21.5815 16.3621V12.3341" stroke="#9EA8B6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                <path fillRule="evenodd" clipRule="evenodd" d="M8.82788 10.9205L16.3009 3.4475C17.2319 2.5175 18.7409 2.5175 19.6719 3.4475L20.8889 4.6645C21.8199 5.5955 21.8199 7.1055 20.8889 8.0355L13.3799 15.5445C12.9729 15.9515 12.4209 16.1805 11.8449 16.1805H8.09888L8.19288 12.4005C8.20688 11.8445 8.43388 11.3145 8.82788 10.9205Z" stroke="#9EA8B6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M15.1653 4.60156L19.7313 9.16756" stroke="#9EA8B6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                                <div className='deadline-wraps-block'>
                                    <p className='deadline-text'>Deadline <span>-150 days remaining</span></p>
                                    <p className='deadline-date'><span><MdAccessTime /></span> {`${moment().format('DD MMM, YYYY')} - ${moment().format('DD MMM, YYYY')}`}</p>
                                </div>
                                <div className='descriptin-wrap'>
                                    <p className='title-description'>Project Description</p>
                                    <div className='text-details'>
                                        <p className=''>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed porttitor amet risus interdum potenti magnis leo. Consectetur ultrices enim id vulputate bibendum lobortis. Dictum commodo enim nullam dictum. Amet, dis scelerisque cursus tortor purus lacus. Tempor amet posuere est hac tellus et, et. Nec scelerisque aliquam vitae malesuada adipiscing dui phasellus. Etiam quis risus diam amet ipsum quam egestas lacinia. Massa facilisi amet nulla ut lacinia augue porttitor. Venenatis commodo sed natoque praesent in porttitor et tristique enim. </p>
                                    </div>
                                </div>
                            </div>
                            <div className='card-both-wrap'>
                                <div className='card-same-wrap todo-wraps'>
                                    <div className='head-wraps-todo'>
                                        <div className='button-groups-todo'>
                                            <button type="button" className='is_active'><span className='icon time-icon'><MdAccessTime /></span> To do <span className='number'>0</span></button>
                                            <button type="button"><span className='icon done-icon'><MdCheck /></span>Done <span className='number'>0</span></button>
                                        </div>
                                        <button type='button' className='create-btn'><span className='icon'><BsPlusLg /></span> Create task</button>
                                    </div>
                                    <ul className='todo-list'>
                                        <li>
                                            <div className='todo-task-grid is_selected'>
                                                <h6>Edit Files</h6>
                                                <p>Editing file adsine</p>
                                                <div className='groups-of-btns-todo'>
                                                    <button type="button" className='edit'>
                                                        <svg className='big-icon-small' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M11.4925 2.78906H7.75349C4.67849 2.78906 2.75049 4.96606 2.75049 8.04806V16.3621C2.75049 19.4441 4.66949 21.6211 7.75349 21.6211H16.5775C19.6625 21.6211 21.5815 19.4441 21.5815 16.3621V12.3341" stroke="#9EA8B6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                            <path fillRule="evenodd" clipRule="evenodd" d="M8.82788 10.9205L16.3009 3.4475C17.2319 2.5175 18.7409 2.5175 19.6719 3.4475L20.8889 4.6645C21.8199 5.5955 21.8199 7.1055 20.8889 8.0355L13.3799 15.5445C12.9729 15.9515 12.4209 16.1805 11.8449 16.1805H8.09888L8.19288 12.4005C8.20688 11.8445 8.43388 11.3145 8.82788 10.9205Z" stroke="#9EA8B6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                            <path d="M15.1653 4.60156L19.7313 9.16756" stroke="#9EA8B6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                    </button>
                                                    <button type="button" className='delete'>
                                                        <svg className='big-icon-small' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M19.3249 9.4668C19.3249 9.4668 18.7819 16.2018 18.4669 19.0388C18.3169 20.3938 17.4799 21.1878 16.1089 21.2128C13.4999 21.2598 10.8879 21.2628 8.27991 21.2078C6.96091 21.1808 6.13791 20.3768 5.99091 19.0458C5.67391 16.1838 5.13391 9.4668 5.13391 9.4668" stroke="#9EA8B6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                            <path d="M20.7082 6.23828H3.75024" stroke="#9EA8B6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                            <path d="M17.4407 6.239C16.6557 6.239 15.9797 5.684 15.8257 4.915L15.5827 3.699C15.4327 3.138 14.9247 2.75 14.3457 2.75H10.1127C9.5337 2.75 9.0257 3.138 8.8757 3.699L8.6327 4.915C8.4787 5.684 7.8027 6.239 7.0177 6.239" stroke="#9EA8B6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                    </button>
                                                    <button type="button" className='done'>
                                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M7.66174 1.85938H5.16907C3.11907 1.85938 1.83374 3.31071 1.83374 5.36537V10.908C1.83374 12.9627 3.11307 14.414 5.16907 14.414H11.0517C13.1084 14.414 14.3877 12.9627 14.3877 10.908V8.22271" stroke="#9EA8B6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                            <path d="M5 6.375L8.375 9.75L14 3" stroke="#9EA8B6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className='todo-task-grid done'>
                                                <h6>Edit Files</h6>
                                                <p>Editing file adsine</p>
                                                <div className='groups-of-btns-todo'>
                                                    <button type="button" className='edit'>
                                                        <svg className='big-icon-small' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M11.4925 2.78906H7.75349C4.67849 2.78906 2.75049 4.96606 2.75049 8.04806V16.3621C2.75049 19.4441 4.66949 21.6211 7.75349 21.6211H16.5775C19.6625 21.6211 21.5815 19.4441 21.5815 16.3621V12.3341" stroke="#9EA8B6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                            <path fillRule="evenodd" clipRule="evenodd" d="M8.82788 10.9205L16.3009 3.4475C17.2319 2.5175 18.7409 2.5175 19.6719 3.4475L20.8889 4.6645C21.8199 5.5955 21.8199 7.1055 20.8889 8.0355L13.3799 15.5445C12.9729 15.9515 12.4209 16.1805 11.8449 16.1805H8.09888L8.19288 12.4005C8.20688 11.8445 8.43388 11.3145 8.82788 10.9205Z" stroke="#9EA8B6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                            <path d="M15.1653 4.60156L19.7313 9.16756" stroke="#9EA8B6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                    </button>
                                                    <button type="button" className='delete'>
                                                        <svg className='big-icon-small' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M19.3249 9.4668C19.3249 9.4668 18.7819 16.2018 18.4669 19.0388C18.3169 20.3938 17.4799 21.1878 16.1089 21.2128C13.4999 21.2598 10.8879 21.2628 8.27991 21.2078C6.96091 21.1808 6.13791 20.3768 5.99091 19.0458C5.67391 16.1838 5.13391 9.4668 5.13391 9.4668" stroke="#9EA8B6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                            <path d="M20.7082 6.23828H3.75024" stroke="#9EA8B6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                            <path d="M17.4407 6.239C16.6557 6.239 15.9797 5.684 15.8257 4.915L15.5827 3.699C15.4327 3.138 14.9247 2.75 14.3457 2.75H10.1127C9.5337 2.75 9.0257 3.138 8.8757 3.699L8.6327 4.915C8.4787 5.684 7.8027 6.239 7.0177 6.239" stroke="#9EA8B6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                    </button>
                                                    <button type="button" className='done'>
                                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M7.66174 1.85938H5.16907C3.11907 1.85938 1.83374 3.31071 1.83374 5.36537V10.908C1.83374 12.9627 3.11307 14.414 5.16907 14.414H11.0517C13.1084 14.414 14.3877 12.9627 14.3877 10.908V8.22271" stroke="#9EA8B6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                            <path d="M5 6.375L8.375 9.75L14 3" stroke="#9EA8B6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className='todo-task-grid'>
                                                <h6>Edit Files</h6>
                                                <p>Editing file adsine</p>
                                                <div className='groups-of-btns-todo'>
                                                    <button type="button" className='edit'>
                                                        <svg className='big-icon-small' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M11.4925 2.78906H7.75349C4.67849 2.78906 2.75049 4.96606 2.75049 8.04806V16.3621C2.75049 19.4441 4.66949 21.6211 7.75349 21.6211H16.5775C19.6625 21.6211 21.5815 19.4441 21.5815 16.3621V12.3341" stroke="#9EA8B6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                            <path fillRule="evenodd" clipRule="evenodd" d="M8.82788 10.9205L16.3009 3.4475C17.2319 2.5175 18.7409 2.5175 19.6719 3.4475L20.8889 4.6645C21.8199 5.5955 21.8199 7.1055 20.8889 8.0355L13.3799 15.5445C12.9729 15.9515 12.4209 16.1805 11.8449 16.1805H8.09888L8.19288 12.4005C8.20688 11.8445 8.43388 11.3145 8.82788 10.9205Z" stroke="#9EA8B6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                            <path d="M15.1653 4.60156L19.7313 9.16756" stroke="#9EA8B6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                    </button>
                                                    <button type="button" className='delete'>
                                                        <svg className='big-icon-small' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M19.3249 9.4668C19.3249 9.4668 18.7819 16.2018 18.4669 19.0388C18.3169 20.3938 17.4799 21.1878 16.1089 21.2128C13.4999 21.2598 10.8879 21.2628 8.27991 21.2078C6.96091 21.1808 6.13791 20.3768 5.99091 19.0458C5.67391 16.1838 5.13391 9.4668 5.13391 9.4668" stroke="#9EA8B6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                            <path d="M20.7082 6.23828H3.75024" stroke="#9EA8B6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                            <path d="M17.4407 6.239C16.6557 6.239 15.9797 5.684 15.8257 4.915L15.5827 3.699C15.4327 3.138 14.9247 2.75 14.3457 2.75H10.1127C9.5337 2.75 9.0257 3.138 8.8757 3.699L8.6327 4.915C8.4787 5.684 7.8027 6.239 7.0177 6.239" stroke="#9EA8B6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                    </button>
                                                    <button type="button" className='done'>
                                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M7.66174 1.85938H5.16907C3.11907 1.85938 1.83374 3.31071 1.83374 5.36537V10.908C1.83374 12.9627 3.11307 14.414 5.16907 14.414H11.0517C13.1084 14.414 14.3877 12.9627 14.3877 10.908V8.22271" stroke="#9EA8B6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                            <path d="M5 6.375L8.375 9.75L14 3" stroke="#9EA8B6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className='card-same-wrap recent-wraps'>
                                    <div className='head-wraps-recent'>
                                        <h3>Most recent repots <span className='number'>0</span></h3>
                                        <button type="button" className='create-btn view-icon-btns'><span className='icon'><AiFillEye /></span>View all reports</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Scrollbars>
                </div>
            </div>
            <div className='right-sidebar-details'>
                <Scrollbars
                    renderView={scrollbar.renderView}
                    renderTrackHorizontal={scrollbar.renderTrackHorizontal}
                    renderTrackVertical={scrollbar.renderTrackVertical}
                    renderThumbHorizontal={scrollbar.renderThumb}
                    renderThumbVertical={scrollbar.renderThumb}
                    universal={true}
                >
                    <div className='overview-wraps'>
                        <h3 className='title-over'>Overview</h3>
                        <div className="progress_overview">
                            <svg>
                                <circle cx="80" cy="-80" r="70"></circle>
                                <circle cx="80" cy="-80" r="70" style={{ strokeDashoffset: `calc(440 - (440 * 75) / 100)` }}></circle>
                            </svg>
                        </div>
                        <p className='text-overview'>75% Task completed</p>
                        <h4 className='hours-title'>16H 30M</h4>
                        <div className='dead-line-block'>
                            <p><span>Project time</span> 48 hours</p>
                            <p><span>Project Deadline</span> -150 days</p>
                        </div>
                    </div>
                    <div className='team-member-wrap'>
                        <div className='head-members-wrap'>
                            <p>Member<span>Now, task has 2 member</span></p>
                            <button type='button' className='create-btn'><span className='icon'><BsPlusLg /></span> Add member</button>
                        </div>
                        <ul className='member-list-ul'>
                            <li>
                                <div className='member-wraps'>
                                    <div className='member-image-wrap'>
                                        <img src={dummtImag} alt='' />
                                    </div>
                                    <p>Alexander Purwoto <span>Task Leader</span></p>
                                </div>
                            </li>
                            <li>
                                <div className='member-wraps'>
                                    <div className='member-image-wrap'>
                                        <img src={dummtImag} alt='' />
                                    </div>
                                    <p>Purnomo <span>Writer</span></p>
                                </div>
                            </li>
                            <li>
                                <div className='member-wraps'>
                                    <div className='member-image-wrap'>
                                        <img src={dummtImag} alt='' />
                                    </div>
                                    <p>Wakidi <span>Layouter</span></p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </Scrollbars>
            </div>
        </section>
    )
}

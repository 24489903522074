import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';
import * as scrollbar from '../../../helpers/scrollbar';
import { getProjects } from '../../../services/project';
import moment from 'moment';
import { BsPlusLg, BsPlus } from 'react-icons/bs';
// import { MdMoreVert, MdOutlineMoreHoriz } from 'react-icons/md';
// import { Dropdown } from 'react-bootstrap';
import User from '../../../assets/images/dummyUser.svg';
// import OverView from '../components/task/OverView';
import MemberList from '../MemberList';
import ProgressBar from './ProgressBar';
// import { getFileAnalysis } from '../../../redux/actions/analysis';
// import { PieChart, Pie, Tooltip, Cell } from 'recharts';
// import ProjectList from '../components/task/ProjectList';
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import 'swiper/components/pagination/pagination.less';

SwiperCore.use([Pagination]);

// const data02 = [];

// const mapTabToStatus = {
//   0: 'in_process',
//   1: 'completed',
//   2: 'pending',
// };


function Dashboard() {

  const role = useSelector((s) => s.userInfo.role);
  const userInfo = useSelector((s) => s.userInfo);
  const layout = useSelector((s) => s.layout);
  const match = useRouteMatch();
  const [projects, setProjects] = useState([]);
  const [inProcess, setInProcess] = useState([]);
  const [completed, setCompleted] = useState([]);
  const [pending, setPending] = useState([]);
  const [deletes, setDeletes] = useState([]);

  const history = useHistory();

  const createNewProject = () => {
    history.push(`${match.url}/new`);
  };
  useEffect(() => {
    getProjects().then(({ projects: res }) => {
      if (Array.isArray(res) && res.length > 0) {
        // console.log(res);
        setProjects(res)
        const pendingProject = res.filter((p) => p.status === 'pending' && !p.removed);
        setPending(pendingProject);
        const inProcessProject = res.filter((p) => p.status === 'in_process' && !p.removed);
        setInProcess(inProcessProject);
        const completedProject = res.filter((p) => p.status === 'completed' && !p.removed);
        setCompleted(completedProject);
        const deleteProject = res.filter((p) => p.removed);
        setDeletes(deleteProject)

      } else {
        setProjects([])
        setPending([]);
        setCompleted([]);
        setInProcess([]);
        setDeletes([]);
      }
    });
  }, []);

  return (
    <section className='main-section home-page-section'>
      <Scrollbars
        renderView={scrollbar.renderView}
        renderTrackHorizontal={scrollbar.renderTrackHorizontal}
        renderTrackVertical={scrollbar.renderTrackVertical}
        renderThumbHorizontal={scrollbar.renderThumb}
        renderThumbVertical={scrollbar.renderThumb}
        universal={true}
      >
        <div className='container-fluid'>
          <div className="row">
            <div className="col-md-8">
              <div className='page-top-header workflow-top-header'>
                <h1 className="page-title">Project</h1>
                {role === 'admin' && (
                  <button
                    type="button"
                    className="workflow-btn top"
                    onClick={createNewProject}
                    style={{ maxWidth: '185px', margin: '0', marginTop: '0' }}
                  >
                    <i><BsPlus /></i>
                    {layout.isMobileView ? 'Create' : 'Create new project'}
                  </button>
                )}
              </div>
              <div className="overview-wrap">

                <div className="overview-title">Overview</div>

                <ul className="overview-list">

                  <li className="overview-badge">
                    <h3 className="overview-list-title">Total Project</h3>
                    <div className="d-flex">
                      <span className="overview-list-bar"></span>
                      <div className="overview-number">{Array.isArray(projects) ? projects.length : 0}</div>
                    </div>
                  </li>
                  <li className="overview-badge">
                    <h3 className="overview-list-title">In process</h3>
                    <div className="d-flex">
                      <span className="overview-list-bar"></span>
                      <div className="overview-number">{Array.isArray(inProcess) ? inProcess.length : 0}</div>
                    </div>
                  </li>
                  <li className="overview-badge">
                    <h3 className="overview-list-title">Complete</h3>
                    <div className="d-flex">
                      <span className="overview-list-bar"></span>
                      <div className="overview-number">{Array.isArray(completed) ? completed.length : 0}</div>
                    </div>
                  </li>
                  <li className="overview-badge">
                    <h3 className="overview-list-title">Pending</h3>
                    <div className="d-flex">
                      <span className="overview-list-bar"></span>
                      <div className="overview-number">{Array.isArray(pending) ? pending.length : 0}</div>
                    </div>
                  </li>
                  <li className="overview-badge">
                    <h3 className="overview-list-title">Delete</h3>
                    <div className="d-flex">
                      <span className="overview-list-bar"></span>
                      <div className="overview-number">{Array.isArray(deletes) ? deletes.length : 0}</div>
                    </div>
                  </li>
                </ul>

              </div>

              {/* <div className="project-status-wraps"> */}
              {/* <Scrollbars
                  renderView={scrollbar.renderView}
                  renderTrackHorizontal={scrollbar.renderTrackHorizontal}
                  renderTrackVertical={scrollbar.renderTrackVertical}
                  renderThumbHorizontal={scrollbar.renderThumb}
                  renderThumbVertical={scrollbar.renderThumb}
                  universal={true}
                > */}
              <div className="project-status-div">
                <div className='grid-view-drag'>
                  <div className="project-status-badge">
                    <h3 className="project-status-title">In Process</h3>
                    <h4 className="status-number">{Array.isArray(inProcess) ? inProcess.length : 0}</h4>
                  </div>
                  <div className='list-project-drag'>
                    <Scrollbars
                      renderView={scrollbar.renderView}
                      renderTrackHorizontal={scrollbar.renderTrackHorizontal}
                      renderTrackVertical={scrollbar.renderTrackVertical}
                      renderThumbHorizontal={scrollbar.renderThumb}
                      renderThumbVertical={scrollbar.renderThumb}
                      universal={true}
                    >
                      {Array.isArray(inProcess) && inProcess.length > 0 ?
                        inProcess.map((p, index) => (
                          <div
                            className="file-testing-wrap"
                            key={p.project_id}
                            onClick={() =>
                              history.push(`/project/${p.project_id}`, {
                                ownerId: p.owner_id,
                              })
                            }
                          >
                            <div className="d-flex  justify-content-between">
                              <div className="test-icon">
                                {p.project_image ?
                                  <img alt="demo img" src={p.project_image} />
                                  :
                                  <span className='p-name'>{p.project_name[0]}</span>
                                }
                              </div>
                            </div>
                            <h3 className="testing-title">{p.project_name}</h3>
                            <div className="bottom-text mt-0">
                              <div className="users-wrap">
                                <ul className="user-ul mb-0">
                                  <li>
                                    <div className="user-picture-wrap">
                                      {userInfo && userInfo.avatar ?
                                        <img src={User} alt="user" />
                                        :
                                        <span className='user-icon'>{userInfo.firstname[0]}{userInfo.firstname[0]}</span>
                                      }
                                    </div>
                                  </li>
                                </ul>
                              </div>
                              <div className="dates-wrap">
                                <p className='mb-0'>
                                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.9998 3C8.02976 3 3.99976 7.03 3.99976 12H0.999756L4.88976 15.89L4.95976 16.03L8.99976 12H5.99976C5.99976 8.13 9.12976 5 12.9998 5C16.8698 5 19.9998 8.13 19.9998 12C19.9998 15.87 16.8698 19 12.9998 19C11.0698 19 9.31976 18.21 8.05976 16.94L6.63976 18.36C8.26976 19.99 10.5098 21 12.9998 21C17.9698 21 21.9998 16.97 21.9998 12C21.9998 7.03 17.9698 3 12.9998 3ZM11.9998 8V13L16.2498 15.52L17.0198 14.24L13.4998 12.15V8H11.9998Z" fill="#C2CFE0" stroke="#F5F6F8" strokeWidth="0.6" />
                                  </svg>
                                  <b>{moment(p.created_at).format('DD MMMM, YYYY')}</b>
                                </p>
                              </div>
                            </div>
                          </div>
                        ))
                        :
                        null
                      }
                      {/* < button className="drag-drop-btn"><BsPlus className="plus-icon" />   Drag or add new task</button> */}
                    </Scrollbars>
                  </div>
                </div>
                <div className='grid-view-drag'>
                  <div className="project-status-badge">
                    <h3 className="project-status-title">Complete</h3>
                    <h4 className="status-number">{Array.isArray(completed) ? completed.length : 0}</h4>
                  </div>
                  <div className='list-project-drag'>
                    <Scrollbars
                      renderView={scrollbar.renderView}
                      renderTrackHorizontal={scrollbar.renderTrackHorizontal}
                      renderTrackVertical={scrollbar.renderTrackVertical}
                      renderThumbHorizontal={scrollbar.renderThumb}
                      renderThumbVertical={scrollbar.renderThumb}
                      universal={true}
                    >
                      {Array.isArray(completed) && completed.length > 0 ?
                        completed.map((p, index) => (
                          <div
                            className="file-testing-wrap"
                            key={p.project_id}
                            onClick={() =>
                              history.push(`/project/${p.project_id}`, {
                                ownerId: p.owner_id,
                              })
                            }
                          >
                            <div className="d-flex  justify-content-between">
                              <div className="test-icon">
                                {p.project_image ?
                                  <img alt="demo img" src={p.project_image} />
                                  :
                                  <span className='p-name'>{p.project_name[0]}</span>
                                }
                              </div>
                            </div>
                            <h3 className="testing-title">{p.project_name}</h3>
                            <div className="bottom-text mt-0">
                              <div className="users-wrap">
                                <ul className="user-ul mb-0">
                                  <li>
                                    <div className="user-picture-wrap">
                                      {userInfo && userInfo.avatar ?
                                        <img src={User} alt="user" />
                                        :
                                        <span className='user-icon'>{userInfo.firstname[0]}{userInfo.firstname[0]}</span>
                                      }
                                    </div>
                                  </li>
                                </ul>
                              </div>
                              <div className="dates-wrap">
                                <p className='mb-0'>
                                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.9998 3C8.02976 3 3.99976 7.03 3.99976 12H0.999756L4.88976 15.89L4.95976 16.03L8.99976 12H5.99976C5.99976 8.13 9.12976 5 12.9998 5C16.8698 5 19.9998 8.13 19.9998 12C19.9998 15.87 16.8698 19 12.9998 19C11.0698 19 9.31976 18.21 8.05976 16.94L6.63976 18.36C8.26976 19.99 10.5098 21 12.9998 21C17.9698 21 21.9998 16.97 21.9998 12C21.9998 7.03 17.9698 3 12.9998 3ZM11.9998 8V13L16.2498 15.52L17.0198 14.24L13.4998 12.15V8H11.9998Z" fill="#C2CFE0" stroke="#F5F6F8" strokeWidth="0.6" />
                                  </svg>
                                  <b>{moment(p.created_at).format('DD MMMM, YYYY')}</b>
                                </p>
                              </div>
                            </div>
                          </div>
                        ))
                        :
                        null
                      }
                    </Scrollbars>
                  </div>
                </div>
                <div className='grid-view-drag'>
                  <div className="project-status-badge">
                    <h3 className="project-status-title">Pending</h3>
                    <h4 className="status-number">{Array.isArray(pending) ? pending.length : 0}</h4>
                  </div>
                  <div className='list-project-drag'>
                    <Scrollbars
                      renderView={scrollbar.renderView}
                      renderTrackHorizontal={scrollbar.renderTrackHorizontal}
                      renderTrackVertical={scrollbar.renderTrackVertical}
                      renderThumbHorizontal={scrollbar.renderThumb}
                      renderThumbVertical={scrollbar.renderThumb}
                      universal={true}
                    >
                      {Array.isArray(pending) && pending.length > 0 ?
                        pending.map((p, index) => (
                          <div
                            className="file-testing-wrap"
                            key={p.project_id}
                            onClick={() =>
                              history.push(`/project/${p.project_id}`, {
                                ownerId: p.owner_id,
                              })
                            }
                          >
                            <div className="d-flex  justify-content-between">
                              <div className="test-icon">
                                {p.project_image ?
                                  <img alt="demo img" src={p.project_image} />
                                  :
                                  <span className='p-name'>{p.project_name[0]}</span>
                                }
                              </div>
                            </div>
                            <h3 className="testing-title">{p.project_name}</h3>
                            <div className="bottom-text mt-0">
                              <div className="users-wrap">
                                <ul className="user-ul mb-0">
                                  <li>
                                    <div className="user-picture-wrap">
                                      {userInfo && userInfo.avatar ?
                                        <img src={User} alt="user" />
                                        :
                                        <span className='user-icon'>{userInfo.firstname[0]}{userInfo.firstname[0]}</span>
                                      }
                                    </div>
                                  </li>
                                </ul>
                              </div>
                              <div className="dates-wrap">
                                <p className='mb-0'>
                                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.9998 3C8.02976 3 3.99976 7.03 3.99976 12H0.999756L4.88976 15.89L4.95976 16.03L8.99976 12H5.99976C5.99976 8.13 9.12976 5 12.9998 5C16.8698 5 19.9998 8.13 19.9998 12C19.9998 15.87 16.8698 19 12.9998 19C11.0698 19 9.31976 18.21 8.05976 16.94L6.63976 18.36C8.26976 19.99 10.5098 21 12.9998 21C17.9698 21 21.9998 16.97 21.9998 12C21.9998 7.03 17.9698 3 12.9998 3ZM11.9998 8V13L16.2498 15.52L17.0198 14.24L13.4998 12.15V8H11.9998Z" fill="#C2CFE0" stroke="#F5F6F8" strokeWidth="0.6" />
                                  </svg>
                                  <b>{moment(p.created_at).format('DD MMMM, YYYY')}</b>
                                </p>
                              </div>
                            </div>
                          </div>
                        ))
                        :
                        null
                      }
                    </Scrollbars>
                  </div>
                </div>
                <div className='grid-view-drag'>
                  <div className="project-status-badge">
                    <h3 className="project-status-title">Delete</h3>
                    <h4 className="status-number">{Array.isArray(deletes) ? deletes.length : 0}</h4>
                  </div>
                  <div className='list-project-drag'>
                    <Scrollbars
                      renderView={scrollbar.renderView}
                      renderTrackHorizontal={scrollbar.renderTrackHorizontal}
                      renderTrackVertical={scrollbar.renderTrackVertical}
                      renderThumbHorizontal={scrollbar.renderThumb}
                      renderThumbVertical={scrollbar.renderThumb}
                      universal={true}
                    >
                      {Array.isArray(deletes) && deletes.length > 0 ?
                        deletes.map((p, index) => (
                          <div
                            className="file-testing-wrap"
                            key={p.project_id}
                            onClick={() =>
                              history.push(`/project/${p.project_id}`, {
                                ownerId: p.owner_id,
                              })
                            }
                          >
                            <div className="d-flex  justify-content-between">
                              <div className="test-icon">
                                {p.project_image ?
                                  <img alt="demo img" src={p.project_image} />
                                  :
                                  <span className='p-name'>{p.project_name[0]}</span>
                                }
                              </div>
                            </div>
                            <h3 className="testing-title">{p.project_name}</h3>
                            <div className="bottom-text mt-0">
                              <div className="users-wrap">
                                <ul className="user-ul mb-0">
                                  <li>
                                    <div className="user-picture-wrap">
                                      {userInfo && userInfo.avatar ?
                                        <img src={User} alt="user" />
                                        :
                                        <span className='user-icon'>{userInfo.firstname[0]}{userInfo.firstname[0]}</span>
                                      }
                                    </div>
                                  </li>
                                </ul>
                              </div>
                              <div className="dates-wrap">
                                <p className='mb-0'>
                                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.9998 3C8.02976 3 3.99976 7.03 3.99976 12H0.999756L4.88976 15.89L4.95976 16.03L8.99976 12H5.99976C5.99976 8.13 9.12976 5 12.9998 5C16.8698 5 19.9998 8.13 19.9998 12C19.9998 15.87 16.8698 19 12.9998 19C11.0698 19 9.31976 18.21 8.05976 16.94L6.63976 18.36C8.26976 19.99 10.5098 21 12.9998 21C17.9698 21 21.9998 16.97 21.9998 12C21.9998 7.03 17.9698 3 12.9998 3ZM11.9998 8V13L16.2498 15.52L17.0198 14.24L13.4998 12.15V8H11.9998Z" fill="#C2CFE0" stroke="#F5F6F8" strokeWidth="0.6" />
                                  </svg>
                                  <b>{moment(p.created_at).format('DD MMMM, YYYY')}</b>
                                </p>
                              </div>
                            </div>
                          </div>
                        ))
                        :
                        null
                      }
                    </Scrollbars>
                  </div>
                </div>
              </div>
              {/* </Scrollbars> */}
              {/* </div> */}
            </div>
            <div className="col-md-4 pe-0">

              <div className="progress-swiper">
                <Swiper pagination={true} className="mySwiper">
                  <SwiperSlide className='slider-wraps'>
                    <h3 className="progress-main-title">Progress</h3>
                    <div className='block-overview'>
                      <ProgressBar title="In process" project={inProcess} totalProject={projects} />
                      {role === 'admin' && (<button className='workflow-btn' onClick={createNewProject}><BsPlusLg />  Create New Project</button>)}
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className='slider-wraps'>
                    <h3 className="progress-main-title">Progress</h3>
                    <div className='block-overview'>
                      <ProgressBar title="Complete" project={completed} totalProject={projects} />
                      {role === 'admin' && (<button className='workflow-btn' onClick={createNewProject}><BsPlusLg />  Create New Project</button>)}
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className='slider-wraps'>
                    <h3 className="progress-main-title">Progress</h3>
                    <div className='block-overview'>
                      <ProgressBar title="Pending" project={pending} totalProject={projects} />
                      {role === 'admin' && (<button className='workflow-btn' onClick={createNewProject}><BsPlusLg />  Create New Project</button>)}
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>

              {role === 'admin' && (<MemberList />)}

            </div>

          </div>
        </div>

      </Scrollbars>
    </section >
  );
}

Dashboard.Content = () => <div>Content</div>;
export default Dashboard;

import React, { useEffect, useState } from 'react';
import { getMembers } from '../../services/authentication';
export default function MemberList() {
  const [memberList, setMemberList] = useState([]);
  useEffect(() => {
    getMembers()
      .then(({ members }) => {
        setMemberList(members);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <div className="team-member-table-wrap">

      <h3 className="member-table-title">Team Member</h3>
      <table className="member-table">

        <thead>
          <tr>

            <th>MEMBERS</th>

            <th>TASK</th>

            <th>HOURS</th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(memberList) && memberList.length > 0 ?
            memberList.map((mem, index) => (
              <tr key={mem.user_id}>

                <td>
                  <div className='d-flex align-items-center'>
                    <div className="member-user-wrap">
                      {mem.avatar_url ?
                        <img src={mem.avatar_url} alt="user" />
                        :
                        <span className='m-name'>
                          {mem.first_name[0]}{mem.last_name[0]}
                        </span>
                      }
                    </div>
                    <h3 className="member-name">{mem.first_name} {mem.last_name[0]}</h3>
                  </div>
                </td>
                <td > <h4 className="total-task">{mem.tasks}</h4></td>
                <td><h4 className="total-hours"> {mem.hours || '0h 0m'}</h4></td>
              </tr>
            ))
            :
            null
          }

        </tbody>

      </table>
    </div>

  );
}

import {
  CHECK_COPIED_FILE,
  PASTE_COPIED_FILE_FAILURE,
  PASTE_COPIED_FILE_SUCCESS,
} from '../actions/copiedFile';
import { REMOVE_FILE_SUCCESS } from '../actions/files';

const initialState = -1;

export default function copiedFile (state, action) {
  switch (action.type) {
    case CHECK_COPIED_FILE:
      return action.payload;
    case PASTE_COPIED_FILE_SUCCESS:
      return -1;
    case PASTE_COPIED_FILE_FAILURE:
      return state;
    case REMOVE_FILE_SUCCESS:
      if (action.payload === state) return -1;
      return state;
    default:
      return state || initialState;
  }
}

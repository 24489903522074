/* eslint-disable max-len */
// const BASE_URL = 'http://localhost:3000/api';
const BASE_URL = 'https://i9ekkljod4.execute-api.ap-south-1.amazonaws.com/prod/api';
// const BASE_URL = 'https://aroc0rhzs8.execute-api.ap-south-1.amazonaws.com/prod/api';

const CONFIG = {
  BASE_URL,
  STRAPI_URL: 'https://cms.docquik.io',
  HOME_PAGE_URL: 'https://docquik.com',
  CLOUDFRONT_URL: 'https://d1mdlfh1k000su.cloudfront.net',
  DROPBOX_CLIENT_ID:'y0zvyk618zex9kw',
  CALENDER_API_KEY: 'AIzaSyActYv_34DcFcaABT8Th0ISUgO9G7sDTp4',
  GOOGLE_CLIENT_ID:"707432438223-ahiv52flb006cdutflrcbbs8o0p7vi4l.apps.googleusercontent.com",
  // GOOGLE_CLIENT_ID:"868503150682-f3on0bv27vdep82aag2dohufu63q8vg0.apps.googleusercontent.com",
  // CALENDER_CLIENT_ID: '593591983264-89ct7m98rjh1318vf7pn3v35fgbirhv6.apps.googleusercontent.com',
  CALENDER_CLIENT_ID: '868503150682-fi6o3u0f3i13liko5luddf6mekp74kiu.apps.googleusercontent.com',
  CALENDER_ID: '3rgimefv1b63bc51lrckogmhm4@group.calendar.google.com',
  DISCOVERY_DOCS: ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"],
  SCOPES: "https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events",
  MIME_TYPES: {
    document: ['doc', 'docx'],
    text: ['txt'],
    powerpoint: ['ppt', 'pptx', 'potx'],
    pdf: ['pdf', 'pdf/a'],
    image: ['png', 'jpg', 'jpeg'],
    excel: ['xls', 'xlsx', 'xlsb', 'xlsm', 'xltx'],
    zip: ['zip'],
  },
  ACCEPT_MIME_TYPES: {
    document: ['.doc', '.docx', '.txt'],
    text: ['.txt'],
    powerpoint: ['.ppt', '.pptx', '.potx'],
    pdf: ['.pdf', '.pdf/a'],
    image: ['.png', '.jpg', '.jpeg'],
    excel: ['.xls', '.xlsb', '.xlsm', '.xltx', '.xlsx'],
    zip: ['.zip'],
  },
  // default storage 15GB
  DEFAULT_STORAGE: 16106127360,
  GOOGLE_OAUTH: `https://accounts.google.com/o/oauth2/v2/auth?client_id=730125837586-b0v1c0m833q5cq68s65qbdjltvv654ov.apps.googleusercontent.com&response_type=code&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile%20openid&redirect_uri=${BASE_URL}/login-google`,
  MICROSOFT_OAUTH: `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=53b6ce26-f0ab-49c3-860e-ca704ebb29e6&response_type=code&redirect_uri=${BASE_URL}/login-microsoft&response_mode=query&scope=user.read`,
  YAHOO_OAUTH: `https://api.login.yahoo.com/oauth2/request_auth?response_type=code&redirect_uri=${BASE_URL}/login-yahoo&scope=profile%20email&state=W4383K8MTWc9BhUZ-zwvfexJ9P6GHSDD&client_id=dj0yJmk9ZTRSa2RQenp6Z2xMJmQ9WVdrOWFHRm1hMWhWVEU0bWNHbzlNQT09JnM9Y29uc3VtZXJzZWNyZXQmc3Y9MCZ4PTI1&nonce=W4383K8MTWc9BhUZ-zwvfexJ9P6GHSDD`,
};
export default CONFIG;

import React, { useRef, useEffect, useState } from 'react';
import SkyLight from 'react-skylight';
import { emitter } from '../../utils/emitter';
import { POPUP_EVENTS } from '../../constants/events';
import { useDispatch, useSelector } from 'react-redux';
import { pasteFileCopied } from '../../redux/actions/copiedFile';
import { FaRegFolder, FaPaste } from 'react-icons/fa';

export default function FolderAreaContextMenu() {
  const [clientX, setClientX] = useState(0);
  const [clientY, setClientY] = useState(0);
  const copiedFile = useSelector((s) => s.copiedFile);
  const contextMenu = useRef(null);
  const dispatch = useDispatch();
  const path = useSelector(s => s.path);
  const contextMenuStyle = {
    height: '',
    left: clientX,
    marginLeft: 0,
    marginTop: 0,
    minHeight: '',
    top: clientY,
    width: '15rem',
  };
  const showFolderContextListener = (X, Y) => {
    setClientX(X);
    setClientY(Y);
    if (contextMenu.current) contextMenu.current.show();
  };

  function handleCreateNewFolder() {
    contextMenu.current.hide();
    emitter.emit(POPUP_EVENTS.SHOW_CREATE_FOLDER);
  }

  function handlePaste() {
    const folderId = path.slice(-1)[0].id ? path.slice(-1)[0].id : null;
    dispatch(pasteFileCopied(copiedFile, folderId));
    contextMenu.current.hide();
  }

  function listenToTheEmitter() {
    emitter.on(
      POPUP_EVENTS.SHOW_FOLDER_AREA_CONTEXT_MENU,
      showFolderContextListener
    );
  }
  const options = [
    {
      id: 0,
      name: 'Create new folder',
      iconClass: FaRegFolder,
      onClick: handleCreateNewFolder,
    },
    {
      id: 1,
      name: 'Paste',
      iconClass: FaPaste,
      onClick: handlePaste,
      style: { marginBottom: 0 },
    },
  ];

  useEffect(() => {
    listenToTheEmitter();
    return () => {
      emitter.off(
        POPUP_EVENTS.SHOW_FOLDER_AREA_CONTEXT_MENU,
        showFolderContextListener
      );
    };
  });

  return (
    <>
      <SkyLight
        hideOnOverlayClicked
        overlayStyles={{ backgroundColor: 'inherit' }}
        ref={contextMenu}
        dialogStyles={contextMenuStyle}
      >
        <div className="context-menu">
          {options.map((o) => (
            <div
              onClick={o.onClick}
              key={o.id}
              className="option"
              style={{ height: 'calc((100% / 2) - 1.6rem)', ...o.style }}
            >
              <span className="option-icon">
                <i><o.iconClass /></i>
              </span>
              <span
                className="text-icons"
                style={{
                  alignSelf: 'center',
                }}
              >
                {o.name}
              </span>
            </div>
          ))}
        </div>
      </SkyLight>
    </>
  );
}

import React, { useRef, useEffect, useState } from 'react';
import SkyLight from 'react-skylight';
import { emitter } from '../../../utils/emitter';
import { POPUP_EVENTS } from '../../../constants/events';
import TimeIcon from '../../../assets/images/times-grey.png';
import { useDispatch, useSelector } from 'react-redux';
import { createReport, editReport } from '../../../redux/actions/reports';
import MDEditor from '../../common/MDEditor';
import moment from 'moment';
import { notifyErrorMsg } from '../../common/Alert';
import { taskCheckoutManual } from '../../../services/project';
import { MdClear } from 'react-icons/md';
const newFolderPopupStyle = {
  minHeight: 0,
  width: '40%',
  height: '80%',
  marginLeft: '-15%',
  borderRadius: '10px',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  margin: 'auto',
  padding: 0,
};
export default function ReportPopup() {
  const popupRef = useRef(null);
  const dispatch = useDispatch();
  const reports = useSelector((s) => s.reports);
  const [manual, setManual] = useState(false);
  const [data, setData] = useState({
    taskId: '',
    jobWorked: '',
    nextTarget: '',
    percent: '',
  });
  const handleChangeJobWorked = (val) => {
    setData({
      ...data,
      jobWorked: val,
    });
  };
  const handleChangeNextTarget = (val) => {
    setData({
      ...data,
      nextTarget: val,
    });
  };
  const handleSubmit = () => {
    if (
      !manual &&
      reports[data.taskId]?.find(
        (r) =>
          moment(r.created_at).format('MM-DD-YYYY') ===
          moment().format('MM-DD-YYYY')
      )
    ) {
      const [report] = [...reports[data.taskId]].reverse();
      dispatch(
        editReport(
          data.taskId,
          report.report_id,
          data.jobWorked,
          data.nextTarget
        )
      );
    } else if (manual) {
      const [report] = [...reports[data.taskId]].reverse();
      taskCheckoutManual({
        taskId: data.taskId,
        checkOutTime: data.checkout_time,
      })
        .then(() => {
          dispatch(
            editReport(
              data.taskId,
              report.report_id,
              data.jobWorked,
              data.nextTarget
            )
          );
        })
        .catch((err) => notifyErrorMsg(err));
    } else {
      dispatch(createReport(data.taskId, data.jobWorked, data.nextTarget));
    }
  };
  const showPopup = ({ taskId, jobWorked, nextTarget, isManual }) => {
    setData({ ...data, taskId, jobWorked, nextTarget });
    if (isManual) setManual(true);
    if (popupRef.current) popupRef.current.show();
  };
  const hidePopup = () => {
    setData({
      jobWorked: '',
      nextTarget: '',
    });
    popupRef.current.hide();
  };
  useEffect(() => {
    function listenToTheEmitter() {
      emitter.on(POPUP_EVENTS.SHOW_REPORT, showPopup);
      emitter.on(POPUP_EVENTS.HIDE_REPORT, hidePopup);
    }
    listenToTheEmitter();
    const { job_worked, next_target } =
      reports[data.taskId]?.find(
        (r) =>
          moment(r.created_at).format('MM-DD-YYYY') ===
          moment().format('MM-DD-YYYY')
      ) || {};
    setData({
      ...data,
      nextTarget: next_target,
      jobWorked: job_worked,
    });
    return () => {
      setData({
        taskId: '',
        jobWorked: '',
        nextTarget: '',
        percent: '',
      });
      emitter.off(POPUP_EVENTS.SHOW_REPORT, showPopup);
      emitter.off(POPUP_EVENTS.HIDE_REPORT, hidePopup);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <SkyLight
      dialogStyles={newFolderPopupStyle}
      ref={popupRef}
      hideOnOverlayClicked
    >
      <div
        className="task-popup task_report_modal"
      >
        <div className="header_modal">
          <div className="heading--active">Report today</div>
          <button type='button' className='btn_modal_close_icon' onClick={() => popupRef.current.hide()}><MdClear /></button>

        </div>
        <div className='body_modal'>
          {manual && (
            <div className="time-containter d-flex mb-3">
              <label htmlFor="time">Checkout time</label>
              <input
                placeholder="Checkout time"
                type="time"
                id="time"
                value={data.checkout_time}
                style={{ flex: 1, marginLeft: '1.5rem' }}
                onChange={(e) =>
                  setData({ ...data, checkout_time: e.target.value })
                }
                step={60}
              />
            </div>
          )}
          <label className="label_task_modal">1. Job worked</label>
          <div className="texteditor-report-task">
            <MDEditor value={data.jobWorked} setValue={handleChangeJobWorked} />
          </div>
          <label className="label_task_modal">2. The Next target</label>
          <div className="texteditor-report-task">
            <MDEditor value={data.nextTarget} setValue={handleChangeNextTarget} />
          </div>
        </div>
        <div className="footer_modal">
          <button
            onClick={handleSubmit}
            type="button"
            className="button"
          >
            Save Report
          </button>
        </div>
      </div>
    </SkyLight>
  );
}

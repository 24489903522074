import React, { useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
// import { useSelector } from 'react-redux';
import SkyLight from 'react-skylight';
import PhoneInput from 'react-phone-input-2';
import { emitter } from '../../utils/emitter';
import { POPUP_EVENTS } from '../../constants/events';
import { MdClear } from 'react-icons/md';
import { addMember } from '../../services/authentication';
import { notifySuccess, notifyError } from '../../components/common/Alert';
export default function AddUser({ callback }) {
  // const reminders = useSelector((s) => s.reminders);
  const [displayPassword, setDisplayPassword] = useState([false, false]);
  const popupRef = useRef(null);
  const newFolderPopupStyle = {
    minHeight: 0,
    width: '40%',
    left: '45%',
    marginTop: '-23%',
    marginLeft: '-15%',
    borderRadius: '10px',
    padding: 0,
  };
  const [signUpInput, setSignUpInput] = useState({
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    password: '',
    password2: '',
    address: '',
  });

  const handleSubmit = () => {
    addMember({
      payload: {
        first_name: signUpInput.firstname,
        last_name: signUpInput.lastname,
        email: signUpInput.email,
        phone_number: signUpInput.phone,
        address: signUpInput.address,
        password: signUpInput.password,
      },
    })
      .then(({ success, msg }) => {
        if (success) notifySuccess(msg);
        callback();
        popupRef.current.hide();
      })
      .catch((err) => notifyError(err?.response?.data?.msg));
  };

  const handleChangeSignUpInput = (e) => {
    setSignUpInput({
      ...signUpInput,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangePhone = (e) => {
    setSignUpInput({
      ...signUpInput,
      phone: e,
    });
  };

  function handleDisplayPassword(index) {
    displayPassword[index] = !displayPassword[index];
    setDisplayPassword([...displayPassword]);
  }

  const showPopup = () => {
    if (popupRef.current) popupRef.current.show();
  };

  useEffect(() => {
    function listenToTheEmitter() {
      emitter.on(POPUP_EVENTS.ADD_USER, showPopup);
    }
    listenToTheEmitter();
    return () => {
      emitter.off(POPUP_EVENTS.ADD_USER, showPopup);
    };
  }, []);
  return (
    <SkyLight
      dialogStyles={newFolderPopupStyle}
      ref={popupRef}
      hideOnOverlayClicked
      onClick={() => popupRef.current.show()}
      className="add-user-modal"
    >
      <div className="task-popup">

        <div
          className="heading--active"
        // style={{ fontSize: '2.8rem', fontWeight: 'bold' }}
        >
          Add member
        </div>


        <div id="SignUpScreen">
          <Form.Group className='form-group2 row'>
            <Form.Label className='form-label2 col-sm-12'>Name</Form.Label>
            <div className='col-sm-6 col-xs-12'>
              <Form.Control
                type="text"
                className="primary-input"
                placeholder="First Name"
                name="firstname"
                onChange={handleChangeSignUpInput}
                value={signUpInput.firstname}
              />
            </div>
            <div className='col-sm-6 col-xs-12'>
              <Form.Control
                type="text"
                className="primary-input"
                placeholder="Last Name"
                name="lastname"
                onChange={handleChangeSignUpInput}
                value={signUpInput.lastname}
              />
            </div>
          </Form.Group>
          <Form.Group className='form-group2'>
            <Form.Label className='form-label2 '>Email</Form.Label>
            <Form.Control
              type="email"
              className=" primary-input"
              placeholder="Email"
              name="email"
              onChange={handleChangeSignUpInput}
              value={signUpInput.email}
            />
          </Form.Group>

          <Form.Group className='form-group2'>

            <Form.Label className='form-label2 '>Number Phone</Form.Label>


            <PhoneInput
              inputClass="primary-input"
              // className="primary-input"
              country="in"
              inputClass={`phone-input ${signUpInput.phone ? 'is-active' : ''}`}
              inputProps={{
                required: true,
                autoFocus: true,
              }}
              value={signUpInput.phone}
              countryCodeEditable={false}
              onChange={handleChangePhone}
            />

          </Form.Group>
          {/* <PhoneInput
            country="in"
            inputClass={`phone-input ${signUpInput.phone ? 'is-active' : ''}`}
            inputProps={{

              required: true,
              autoFocus: true,
            }}
            value={signUpInput.phone}
            countryCodeEditable={false}
            onChange={handleChangePhone}
          /> */}

          <Form.Group className='form-group2'>
            <Form.Label className='form-label2 '>Password</Form.Label>


            <input
              className="primary-input  "
              placeholder="Password (At least 8 characters)"
              type={displayPassword[0] ? 'text' : 'password'}
              name="password"
              onChange={handleChangeSignUpInput}
              value={signUpInput.password}
            />
            <i
              className={!displayPassword[0] ? 'fa fa-eye-slash' : 'fa fa-eye'}
              onClick={() => handleDisplayPassword(0)}
              style={{
                position: 'absolute',
                top: '1rem',
                right: '1rem',
                cursor: 'pointer',
              }}
            />


          </Form.Group>

          <Form.Group className='form-group2'>
            <Form.Label className='form-label2 '>Confirm Password</Form.Label>
            <input
              className="primary-input"
              placeholder="Confirm Password"
              type={displayPassword[1] ? 'text' : 'password'}
              name="password2"
              onChange={handleChangeSignUpInput}
              value={signUpInput.password2}
            />
            <i
              className={!displayPassword[1] ? 'fa fa-eye-slash' : 'fa fa-eye'}
              onClick={() => handleDisplayPassword(1)}
              style={{
                position: 'absolute',
                top: '1rem',
                right: '1rem',
                cursor: 'pointer',
              }}
            />

          </Form.Group>

          <Form.Group className='form-group2'>
            <Form.Label className='form-label2 '>Address</Form.Label>
            <input
              className="primary-input"
              placeholder="Address"
              type="text"
              name="address"
              onChange={handleChangeSignUpInput}
              value={signUpInput.address}
            />
          </Form.Group>

          {/* <div className="oneLine">
            <div className="signUpButton" style={{ textAlign: 'center' }}>
              <button
                className="signUpButtonColor"
                type="button"
                onClick={handleSubmit}
              >
                Add member
              </button>
            </div>
          </div> */}
          <div className='d-flex align-items-center justify-content-center'>
            <button type="button" className=' cancel-btn ' onClick={() => popupRef.current.hide()}>Cancel </button>
            <button type="button"
              className='change-btn ' onClick={handleSubmit}
            // customStyle={{ marginLeft: '2rem' }}
            >
              Add Member
            </button>
          </div>
        </div>
      </div>
    </SkyLight >
  );
}

import React, { useState, useEffect } from 'react';
import { Dropbox } from 'dropbox';
import { notifyError } from './Alert';
import { uploadAuth } from '../../redux/actions/uploadAuth';
import { useDispatch, useSelector } from 'react-redux'
import CONFIG from '../../constants/config'

export default function DropboxAuth(props) {

    const { dropboxLogin, dropboxLoginError } = uploadAuth;
    const dispatch = useDispatch();
    const dropbox = useSelector((s) => s.uploadAuth.dropbox);

    const [isDropboxLoader, setIsDropboxLoader] = useState(false);
    const [isDropboxLogin, setIsDropboxLogin] = useState(false);
    const [dropboxUser, setDropboxUser] = useState(null);

    useEffect(() => {
        const tokenDropbox = localStorage.getItem('authDropbox')
        if (tokenDropbox) {
            setIsDropboxLogin(true)
            const dropbox_user = JSON.parse(tokenDropbox);
            setDropboxUser(dropbox_user);
            dispatch(dropboxLogin(dropbox_user))
        } else {
            setIsDropboxLogin(false)
            setDropboxUser(null);
            dispatch(dropboxLoginError())
        }
    }, [dropbox.isDropboxLogin])

    const authDropbox = () => {
        const dbx = new Dropbox({ clientId: CONFIG.DROPBOX_CLIENT_ID, clientSecret: 'xbqc4yw2tqe5hhp' })
        dbx.auth.getAuthenticationUrl('http://localhost:3001/callback')
            .then((authUrl) => {

                window.location.href = authUrl;
            })
            .catch(err => {
                if (err) {
                    setIsDropboxLogin(false)
                    setDropboxUser(null);
                    dispatch(dropboxLoginError())
                }
            })
    }

    const renderDropboxButton = (type, className) => {
        let buttonText = "";
        switch (type) {
            default:
                buttonText = "Connect to Dropbox";
                break;
        }
        if (isDropboxLogin || dropbox.isDropboxLogin) {
            return <button type="button" className={className}>Dropbox Connected</button>
        }
        return (

            <button
                type="button"
                className={className}
                // onClick={saveWithGoogle}
                onClick={authDropbox}
            >
                {props.children ?
                    props.children
                    :
                    isDropboxLoader ?
                        'Loading...'
                        :
                        buttonText
                }
            </button>
        )
    }

    return (
        <>{renderDropboxButton('', props.className)}</>
    );
}

import React, { useState, useEffect } from 'react';
import { GoogleLogin } from 'react-google-login';
import { notifyError } from './Alert';
import { uploadAuth } from '../../redux/actions/uploadAuth';
import { useDispatch, useSelector } from 'react-redux'
import CONFIG from '../../constants/config'

export default function GoogleAuth(props) {

    const { googleLogin, googleLoginError } = uploadAuth;
    const dispatch = useDispatch();
    const googleDrive = useSelector((s)=>s.uploadAuth.googleDrive);

    const [isGoogleLoader, setIsGoogleLoader] = useState(false);
    const [isGoogleLogin, setIsGoogleLogin] = useState(false);
    const [googleUser, setGoogleUser] = useState(null);

    useEffect(() => {
        const tokenGoogle = localStorage.getItem('authGDrive')
        if (tokenGoogle) {
            setIsGoogleLogin(true)
            const google_user = JSON.parse(tokenGoogle);
            setGoogleUser(google_user);
            dispatch(googleLogin(google_user))
        } else {
            setIsGoogleLogin(false)
            setGoogleUser(null);
            dispatch(googleLoginError())
            setIsGoogleLoader(false);
        }
    }, [googleDrive.isGoogleLogin])

    const responseGoogle = (response) => {
        // console.log(response);
        setIsGoogleLoader(true);
        const google_user = response.getAuthResponse()
        localStorage.setItem('authGDrive', JSON.stringify(google_user))
        setIsGoogleLogin(true);
        setGoogleUser(google_user);
        dispatch(googleLogin(google_user))
        setIsGoogleLoader(false);

    }

    const responseGoogleError = (error) => {
        console.log(error)
        setIsGoogleLoader(false);
        notifyError(error.message);
        localStorage.removeItem('authGDrive');
        setGoogleUser(null);
        setIsGoogleLogin(false)
        dispatch(googleLoginError())
    }

    const renderGoogleButton = (type, className) => {
        let buttonText = "";
        switch (type) {
            default:
                buttonText = "Connect to Google Drive";
                break;
        }
        if (isGoogleLogin || googleDrive.isGoogleLogin) {
            return <button type="button" className={className}>Google Drive Connected</button>
        }
        return <GoogleLogin
            clientId={CONFIG.GOOGLE_CLIENT_ID}
            // clientId="868503150682-f3on0bv27vdep82aag2dohufu63q8vg0.apps.googleusercontent.com"
            // clientId="868503150682-3te1sn6qietfdc2rca0kbacqol6lpfm1.apps.googleusercontent.com"
            render={renderProps => (

                <button
                    type="button"
                    className={className}
                    // onClick={saveWithGoogle}
                    onClick={renderProps.onClick} disabled={renderProps.disabled || isGoogleLoader}
                >
                    {props.children ?
                        props.children
                        :
                        isGoogleLoader ?
                            'Loading...'
                            :
                            buttonText
                    }
                </button>
            )}
            buttonText={buttonText}
            onSuccess={responseGoogle}
            onFailure={responseGoogleError}
            cookiePolicy={'single_host_origin'}
            scope='openid email profile https://www.googleapis.com/auth/drive'
        // https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/drive
        // responseType='code'
        />
    }

    return (
        <>{renderGoogleButton('', props.className)}</>
    );
}

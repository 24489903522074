import axios from 'axios';
import CONFIG from '../constants/config';

export const deleteFolder = async (action) => {
  const response = await axios({
    method: 'DELETE',
    url: `${CONFIG.BASE_URL}/folder/${action.payload}`,
    withCredentials: true,
    data: {
      del_permanently: false,
    },
  });
  return response.data;
};

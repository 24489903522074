import React from 'react';
import LiquidFillGauge from 'react-liquid-gauge';
import PropTypes from 'prop-types';

export default function CustomLiquidFillGauge({ radius, value }) {
  return (
    <LiquidFillGauge
      style={{
        margin: '0 auto',
        zIndex: 2,
        transform: 'rotate(50deg)',
        left: '20%',
      }}
      width={radius * 2}
      height={radius * 2}
      value={value}
      waveAnimationTime={1000}
      percent="%"
      textSize={1}
      textOffsetX={0}
      textOffsetY={0}
      textRenderer={(props) => {
        const propsValue = Math.round(props.value);
        const propsRadius = Math.min(props.height / 2, props.width / 2);
        const textPixels = (props.textSize * propsRadius) / 2;
        const valueStyle = {
          fontSize: textPixels,
        };
        const percentStyle = {
          fontSize: textPixels * 0.6,
        };

        return (
          <tspan>
            <tspan className="value" style={valueStyle}>
              {propsValue}
            </tspan>
            <tspan style={percentStyle}>{props.percent}</tspan>
          </tspan>
        );
      }}
      riseAnimation
      waveAnimation
      waveFrequency={1}
      waveAmplitude={5}
      gradient
      circleStyle={{ fill: '#5C4EBD' }}
      waveStyle={{
        fill: '#B2A8DD',
        backgroundcolor: '#B2A8DD',
      }}
      textStyle={{
        fill: '#444',
        fontFamily: 'Arial',
        transform: 'translate(0, 1rem)',
      }}
      waveTextStyle={{
        fill: '#fff',
        fontFamily: 'Arial',
        transform: 'translate(0, 1rem)',
      }}
    />
  );
}

CustomLiquidFillGauge.propTypes = {
  radius: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  height: PropTypes.number,
  width: PropTypes.number,
  textSize: PropTypes.number,
  percent: PropTypes.number,
};

export const GET_TASKS_BY_PROJECT_ID = 'GET_TASKS_BY_PROJECT_ID';
export const GET_TASKS_BY_PROJECT_ID_SUCCESS =
  'GET_TASKS_BY_PROJECT_ID_SUCCESS';
export const GET_TASKS_BY_PROJECT_ID_FAILURE =
  'GET_TASKS_BY_PROJECT_ID_FAILURE';

export const CHECK_IN_SELECTED_TASK = 'CHECK_IN_SELECTED_TASK';
export const CHECK_IN_SELECTED_TASK_SUCCESS = 'CHECK_IN_SELECTED_TASK_SUCCESS';
export const CHECK_IN_SELECTED_TASK_FAILURE = 'CHECK_IN_SELECTED_TASK_FAILURE';

export const CHECK_OUT_SELECTED_TASK = 'CHECK_OUT_SELECTED_TASK';
export const CHECK_OUT_SELECTED_TASK_SUCCESS =
  'CHECK_OUT_SELECTED_TASK_SUCCESS';
export const CHECK_OUT_SELECTED_TASK_FAILURE =
  'CHECK_OUT_SELECTED_TASK_FAILURE';

export const VERIFY_CHECKOUT_TIME = 'VERIFY_CHECKOUT_TIME';

export const getTasksByProjectId = (projectId) => ({
  type: GET_TASKS_BY_PROJECT_ID,
  payload: { projectId },
});

export const checkInSelectedTask = (taskId) => ({
  type: CHECK_IN_SELECTED_TASK,
  payload: { taskId },
});

export const verifyCheckOutTime = ({
  taskId,
  jobWorked,
  nextTarget,
}) => ({
  type: VERIFY_CHECKOUT_TIME,
  payload: { taskId, jobWorked, nextTarget },
});

export const checkOutSelectedTask = (taskId) => ({
  type: CHECK_OUT_SELECTED_TASK,
  payload: { taskId },
});

import React, { useRef, useEffect, useState } from 'react';
import SkyLight from 'react-skylight';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { emitter } from '../../../utils/emitter';
import { POPUP_EVENTS } from '../../../constants/events';
import { MdClear } from 'react-icons/md';
// import DeadLineIcon from '../../../assets/images/deadlineicon.png';
// import SelectDeadline from './SelectDeadline';
import SelectMember from '../admin/SelectMember';
import { triggerMouseEvent } from '../../../utils/domInteract';
import {
  createTask,
  editTaskByProjectId,
  getTaskByProjectId,
} from '../../../services/project';
import { notifyError, notifySuccess } from '../../common/Alert';
import SelectFile from '../admin/SelectFile';
import getDefaultThumbnail from '../../../utils/getDefaultThumbnail';
import { getDocs } from '../../../services/documents';
import ConfirmButton from './ConfirmButton';
// const months = [
//   'January',
//   'February',
//   'March',
//   'April',
//   'May',
//   'June',
//   'July',
//   'August',
//   'September',
//   'October',
//   'November',
//   'December',
// ];
const newFolderPopupStyle = {
  minHeight: 0,
  width: '41%',
  marginLeft: '-15%',
  borderRadius: '10px',
  padding: 0,
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  height: 'fit-content',
  margin: 'auto',
};
// const initialDeadline = {
//   month: '',
//   day: '',
//   year: '',
// };
export default function CreateTask({
  memberList,
  projectId,
  taskId,
  callback,
  afterClose,
  projectFiles
}) {
  const popupRef = useRef(null);
  const myId = useSelector((s) => s.userInfo.user_id);
  const [deadLine, setDeadLine] = useState('');
  // const [deadLine, setDeadLine] = useState({ ...initialDeadline });
  const [taskName, setTaskName] = useState('');
  const [description, setDescription] = useState('');
  const [selectedMember, setSelectedMember] = useState({});
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [selectedFile, setSelectedFile] = useState({});
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [oldMembers, setOldMembers] = useState([]);
  const [oldFiles, setOldFiles] = useState([]);
  const [files, setFiles] = useState([]);
  const handleChangeDeaLine = (e) => {
    const date = e?.target?.value;
    // const handleChangeDeaLine = (name) => (e) => {
    setDeadLine(date);
    // setDeadLine({ ...deadLine, [name]: e.target.value });
  };

  const handleSelectMember = (member) => {
    if (
      typeof member === 'object' &&
      member !== null &&
      Object.keys(member).length
    ) {
      setSelectedMember({ ...selectedMember, ...member });
    }
  };
  const addMember = () => {
    if (selectedMember?.user_id) {
      setSelectedMembers([...selectedMembers, selectedMember]);
      setSelectedMember({});
      setTimeout(() => {
        const clearButton = document.querySelector('.clear-button');
        triggerMouseEvent(clearButton, 'mousedown');
      }, 100);
    }
  };
  const removeMemberFromList = (user_id) => {
    setSelectedMembers([
      ...selectedMembers.filter((mem) => mem.user_id !== user_id),
    ]);
  };
  const addFile = () => {
    if (Object.keys(selectedFile).length !== 0) {
      setSelectedFiles([...selectedFiles, selectedFile]);
      setSelectedFile({});
    } else {
      notifyError('please select file')
    }
  };
  const removeFileFromList = (file_id) => {
    setSelectedFiles([...selectedFiles.filter((f) => f.file_id !== file_id)]);
  };
  const handleSelectFiles = (file) => {
    setSelectedFile(file);
  };
  function formatDayOrMonth(x) {
    return x.toString().padStart(2, '0');
  }
  const handleSubmit = () => {
    if (taskId) {
      editTaskByProjectId({
        projectId,
        taskId,
        task_name: taskName,
        description,
        member_ids: selectedMembers
          .filter((mem) => !oldMembers.map((o) => o).includes(mem.user_id))
          .map((mem) => mem.user_id),
        file_ids: selectedFiles.map((s) => s.file_id),
        deleted_members: oldMembers
          .filter(
            (mem) =>
              !selectedMembers.map((o) => o.user_id).includes(mem.user_id)
          )
          .map((mem) => mem.user_id),
        deleted_files: oldFiles
          .filter(
            (file) =>
              !selectedFiles.map((o) => o.file_id).includes(file.file_id)
          )
          .map((file) => file.file_id),
        deadline: deadLine,
        // deadline: `${deadLine.year}-${formatDayOrMonth(
        //   months.indexOf(deadLine.month) + 1
        // )}-${formatDayOrMonth(deadLine.day)}`,
      })
        .then(({ msg }) => {
          notifySuccess(msg);
          popupRef.current.hide();
          callback();
          setTaskName('');
          setDescription('');
          setSelectedMembers([]);
          setSelectedFiles([]);
          setDeadLine('')
          // SelectDeadline({ ...initialDeadline });
        })
        .catch((err) => {
          console.log(err);
          notifyError(err?.response?.data?.msg);
        });
    } else {
      createTask({
        payload: {
          projectId,
          task_name: taskName,
          description,
          member_ids: selectedMembers.map((m) => m.user_id),
          file_ids: selectedFiles.map((s) => s.file_id),
          deadline: deadLine,
          // deadline: `${deadLine.year}-${formatDayOrMonth(
          //   months.indexOf(deadLine.month) + 1
          // )}-${formatDayOrMonth(deadLine.day)}`,
        },
      })
        .then(({ msg }) => {
          notifySuccess(msg);
          popupRef.current.hide();
          callback();
          setTaskName('');
          setDescription('');
          setSelectedMembers([]);
          setSelectedFiles([]);
          setDeadLine('')
          // SelectDeadline({ ...initialDeadline });
        })
        .catch((err) => notifyError(err?.response?.data?.msg));
    }
  };
  const handleAfterClose = () => {
    afterClose();
    setTaskName('');
    setDescription('');
    setSelectedMembers([]);
    setSelectedFiles([]);
    setDeadLine('')
  };
  const showPopup = () => {
    if (popupRef.current) popupRef.current.show();
  };
  const renderSubmitButton = taskId ? (
    <ConfirmButton
      handleClick={handleSubmit}
      buttonClass="btn-submit"
      buttonText="Edit"
      message="Are you sure you want to edit this task?"
      popupClass="modal-deletes"
    >Edit</ConfirmButton>
  ) : (
    <button
      type="button"
      className="btn-submit"
      onClick={handleSubmit}
    >
      Save
    </button>
  );

  useEffect(() => {
    setFiles(projectFiles)
  }, [projectFiles])

  useEffect(() => {
    function listenToTheEmitter() {
      emitter.on(POPUP_EVENTS.CREATE_TASK, showPopup);
    }
    listenToTheEmitter();
    // getDocs()
    //   .then(({ files: res }) => {
    //     setFiles(res);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
    if (taskId) {
      getTaskByProjectId({ payload: { projectId, taskId } }).then(
        ({ task }) => {
          console.log(task)
          setTaskName(task.task_name);
          setDeadLine(moment(task.deadline).format('YYYY-MM-DD'));
          // setDeadLine({
          //   month: months[moment(task.deadline).month()],
          //   day: moment(task.deadline).day(),
          //   year: moment(task.deadline).year(),
          // });
          setDescription(task.description);
          setSelectedMembers(task.members);
          setOldMembers(task.members);
          setSelectedFiles(task.files);
          setOldFiles(task.files);
        }
      );
    }
    return () => {
      emitter.off(POPUP_EVENTS.CREATE_TASK, showPopup);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskId]);

  const renderSelectedFiles = (f, i) => {
    let fileType = ''
    if (f.type) {
      fileType = f.type
    } else {
      const fileName = f.file_name?.split(".");
      fileType = fileName?.[1]
    }
    return <div
      className="add-member-wrap"
      key={i}
    >
      <div className='memeber-image-wrap'>
        <img
          alt=""
          src={
            f.thumbnail_path
              ? f.thumbnail_path
              : getDefaultThumbnail(fileType)
          }
        />
      </div>
      <div className="member-info file-name">
        <div className="text--small">{f.new_name}</div>
      </div>
      <button type='button' className='btn-remove-member' onClick={() => removeFileFromList(f.file_id)}>
        <MdClear />
      </button>

    </div>
  }

  const closeModal = () => {
    popupRef.current.hide();
    setTaskName('');
    setDeadLine('');
    setDescription('');
    setSelectedMembers([]);
    setSelectedFiles([]);

  }

  return (
    <SkyLight
      dialogStyles={newFolderPopupStyle}
      ref={popupRef}
      hideOnOverlayClicked
      afterClose={handleAfterClose}
      className="add-member-modal"
    >
      <div className="task-popup">
        <div className="modal-header-task">
          <div className="heading--active">
            {taskId ? 'Edit' : 'Create'} Task
          </div>
          <button type='button' className='btn-modal-close' onClick={closeModal}>
            <MdClear />
          </button>
        </div>
        <div className='modal-body-task'>
          <Form.Group className="form-group2">
            {/* <div style={{ display: 'flex' }} className="heading--active mb-3">
              <div>Task Name </div>
              <div style={{ color: 'red', marginLeft: 5 }}>*</div>
            </div> */}
            <Form.Label className='form-label2 required'>Task Name</Form.Label>
            <Form.Control
              type="text"
              className="primary-input"
              value={taskName}
              placeholder="Task Name"
              onChange={(e) => setTaskName(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="form-group2">
            <Form.Label className='form-label2 required'>Deadline</Form.Label>
            <Form.Control type="date" className="primary-input" value={deadLine} onChange={handleChangeDeaLine} />
          </Form.Group>
          <Form.Group className="form-group2">
            <Form.Label className='form-label2 required'>Select member</Form.Label>
            <div className="both-flex-group">
              <div className="input-wraps">
                <SelectMember
                  data={memberList.filter(
                    (m) =>
                      // m.user_id !== myId &&
                      // m.designation !== 'lead' &&
                      m.active &&
                      !selectedMembers.map((x) => x.user_id).includes(m.user_id)
                  )}
                  callback={handleSelectMember}
                  placeholder="Select member"
                />
              </div>
              <div className="button-wraps" style={{ alignSelf: 'flex-end' }}>
                <button type="button" className="button" onClick={addMember}>
                  Add
                </button>
              </div>
            </div>
            {Array.isArray(selectedMembers) && selectedMembers.length > 0 ?
              <div className="list-of-selected mt-3">
                {selectedMembers.map((mem) => (
                  <div key={mem.user_id} className="add-member-wrap">
                    <div className='memeber-image-wrap'>
                      {mem.avatar_url ? (
                        <img
                          alt=""
                          src={mem.avatar_url}
                          style={{
                            width: '4.5rem',
                            height: '4.5rem',
                            marginRight: '1rem',
                          }}
                        />
                      ) : (
                        <span className='m-name'>{mem.first_name[0]}{mem.last_name[0]}</span>
                      )}
                    </div>
                    <div className="member-info">
                      <div className="title--bold">{mem.first_name}</div>
                      <div className="text">{mem.designation}</div>
                    </div>
                    <button type='button' className='btn-remove-member' onClick={() => removeMemberFromList(mem.user_id)}>
                      <MdClear />
                    </button>

                  </div>
                ))}
              </div>
              :
              null
            }
          </Form.Group>

          <Form.Group className="form-group2">
            <Form.Label className='form-label2 required'>Select files</Form.Label>
            <div className="both-flex-group">
              <div className="input-wraps">
                <SelectFile
                  data={files.filter(
                    (f) =>
                      !selectedFiles.map((fi) => fi.file_id).includes(f.file_id)
                  )}
                  callback={handleSelectFiles}
                />
              </div>
              <div className="button-wraps" style={{ alignSelf: 'flex-end' }}>
                <button type="button" className="button" onClick={addFile}>
                  Add
                </button>
              </div>
            </div>
            {Array.isArray(selectedFiles) && selectedFiles.length > 0 ?
              <div className="list-of-selected list-of-fileSelected mt-3">
                {selectedFiles.map((f, i) => renderSelectedFiles(f, i))}
              </div>
              :
              null
            }
          </Form.Group>
          <Form.Group className="form-group2">
            {/* <label>
              <div>Project Description</div>
              <div style={{ color: 'red', marginLeft: 5 }}>*</div>
            </label> */}
            <Form.Label className='form-label2 required'>Project Description</Form.Label>
            <Form.Control
              as="textarea"
              className='primary-input'
              rows={5}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Form.Group>
        </div>
        <div className="modal-footer-task mt-1">
          <button type='button' className='btn-cancel' onClick={() => popupRef.current.hide()}>
            Cancel
          </button>
          {renderSubmitButton}
        </div>
      </div>
    </SkyLight>
  );
}
CreateTask.propTypes = {
  projectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  taskId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  callback: PropTypes.func.isRequired,
  afterClose: PropTypes.func.isRequired,
  memberList: PropTypes.array.isRequired,
};
CreateTask.defaultProps = {
  taskId: null,
};

import React, { useRef, useEffect, useState } from 'react';
import SkyLight from 'react-skylight';
import { emitter } from '../../utils/emitter';
import { POPUP_EVENTS } from '../../constants/events';
import CONFIG from '../../constants/config';
import Axios from 'axios';
import moment from 'moment';
import { bytesToSize } from '../../utils/string';
import FileSaver from 'file-saver';
import { MdClear, MdOutlineFileDownload } from 'react-icons/md';

const popupStyle = {
  left: '0px',
  marginLeft: 0,
  marginTop: 0,
  top: '0px',
  right: '0px',
  bottom: '0px',
  margin: 'auto',
  width: '60%',
  minHeight: '29rem',
  borderRadius: 10,
  height: 'fit-content',
};
export default function VersionPopup() {
  const popupRef = useRef(null);
  const [versions, setVersion] = useState([]);
  useEffect(() => {
    const showPopup = (id) => {
      if (popupRef.current) popupRef.current.show();
      Axios({
        method: 'get',
        url: `${CONFIG.BASE_URL}/list_version/${id}`,
        withCredentials: true,
      }).then(({ data: { versions: res } }) => {
        setVersion(res);
      });
    };
    function listenToTheEmitter() {
      emitter.on(POPUP_EVENTS.VERSION, showPopup);
    }
    listenToTheEmitter();
    return () => {
      emitter.off(POPUP_EVENTS.VERSION, showPopup);
    };
  }, []);
  return (
    <SkyLight
      hideOnOverlayClicked
      overlayStyles={{ backgroundColor: 'rgba(49,49,49,.5)' }}
      ref={popupRef}
      dialogStyles={popupStyle}

    >
      <div className='vision-modal-wrap'>
        <h3 className="vision-popup-title">Document Revisions <i className='close-modal' onClick={() => popupRef.current.hide()}><MdClear /></i></h3>
        <div className='table-responsive'>
          <table
            className="table table-hover table-vision"
          >
            <thead>
              <tr>
                <th>Version</th>
                <th>Size</th>
                <th>Owner</th>
                <th>Last modified</th>
                <th>Notes</th>
              </tr>
            </thead>
            <tbody>
              {versions.map((v) => (
                <tr key={v.version}>
                  <td>
                    {v.version}&nbsp;&nbsp;
                    <i
                      className="interactive"
                      onClick={() => FileSaver.saveAs(v.path, v.file_name)}
                    >
                      <MdOutlineFileDownload />
                    </i>
                  </td>
                  <td>
                    {bytesToSize(v.size)}
                  </td>
                  <td>
                    {v.modified_by}
                  </td>
                  <td>
                    {moment(v.created_at).format('MMMM DD, YYYY')}
                  </td>
                  <td>
                    {v.comment ? v.comment : '-'}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </SkyLight>
  );
}

import { GET_ALL_FOLDERS_DOCS_SUCCESS } from '../actions/documents';

const initialState = [];

export default function allFolders(state = initialState, action) {
    switch (action.type) {
        case GET_ALL_FOLDERS_DOCS_SUCCESS:
            
                return [
                    ...action.payload.allFolders
                ];
            
        default:
            return state;
    }
}
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATIONS_FAILURE = 'GET_NOTIFICATIONS_FAILURE';
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const CHECK_NOTIFICATION = 'CHECK_NOTIFICATION';
export const CHECK_NOTIFICATION_SUCCESS = 'CHECK_NOTIFICATION_SUCCESS';
export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION';
export const DELETE_NOTIFICATION_SUCCESS = 'DELETE_NOTIFICATION_SUCCESS';
export const DELETE_NOTIFICATIONS = 'DELETE_NOTIFICATIONS';
export const DELETE_NOTIFICATIONS_SUCCESS = 'DELETE_NOTIFICATIONS_SUCCESS';

export const getNotifications = () => ({
    type: GET_NOTIFICATIONS,
});

export const checkNotification = (id) => ({
    type: CHECK_NOTIFICATION,
    payload: id,
});

export const addNotification = () => ({
    type: ADD_NOTIFICATION,
});

export const deleteNotification = (id) => ({
    type: DELETE_NOTIFICATION,
    payload: id,
});

export const deleteNotifications = () => ({
    type: DELETE_NOTIFICATIONS,
});

import CryptoJS from 'crypto-js';
export default function hashFile(file, callback) {
  const reader = new FileReader();
  reader.onload = (e) => {
    const data = e.target.result;
    const hashed = CryptoJS.MD5(CryptoJS.lib.WordArray.create(data));
    callback(hashed.toString());
  };
  reader.readAsArrayBuffer(file);
}

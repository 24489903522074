import React, { useEffect, useState } from 'react';
import { getManageStorage } from '../../../services/settings';
import { bytesToSize } from '../../../utils/string';
import { notifyError } from '../../common/Alert';

export default function ManageStorage() {
  const [storages, setStorages] = useState([]);
  const [search, setSearch] = useState('');
  useEffect(() => {
    getManageStorage().then(({ data }) => {
      setStorages(data);
    }).catch((err) => notifyError(err?.response?.data?.data));
  }, []);
  return (
    <div className="bg-white mt-4" style={{ borderRadius: 10 }}>
      <div className="search-manage-file">
        <input className="search-input2" placeholder="Search User" value={search} onChange={(e) => setSearch(e.target.value)} />
      </div>
      <div>
        <table className="c-table" cellPadding="0" cellSpacing="0" border="0">
          <thead >
            <tr>
              <th>User</th>
              <th>Basic Storage</th>
              <th>Additional Storage</th>
              <th>Total Storage</th>
              <th>Usage Storage</th>
            </tr>
          </thead>
          <tbody>
            {storages.filter(r => r.email.search(search) !== -1).map((row) => (
              <tr key={row.user_id}>
                <td>{row.email}</td>
                <td>{bytesToSize(row.storage)}</td>
                <td>0 bytes</td>
                <td>{bytesToSize(row.storage)}</td>
                <td>{row.storage_used !== '0' ? bytesToSize(row.storage_used) : '0 bytes'}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

import { all, call, put, takeEvery, select } from 'redux-saga/effects';
import { notifyError, notifySuccess } from '../components/common/Alert';
// import { useSelector } from 'react-redux';
import {
  CHECK_DOCS_SUCCESS,
  CHECK_DOCS,
  MOVE_DOCS,
  CHECK_DOCS_HIDDEN,
} from '../redux/actions/documents';

import { GET_USAGE_SUCCESS } from '../redux/actions/usage';

import { MOVE_FILE_FAILURE, MOVE_FILE_SUCCESS } from '../redux/actions/files';
import {
  MOVE_FOLDER_FAILURE,
  MOVE_FOLDER_SUCCESS,
} from '../redux/actions/folders';
import {
  getDocsRootFolder,
  getDocsByFolderId,
  moveDocs,
} from '../services/documents';


function* checkDocsSaga(action) {
  // const usage = useSelector(s => s.usage)

  try {
    if (action.payload) {
      const result = yield call(getDocsByFolderId, action);
      if (result.success) {
        yield put({
          type: CHECK_DOCS_SUCCESS,
          payload: {
            docChecked: action.payload,
            folders: result.folders,
            files: result.files
          },
        });
      }
    } else {
      const result = yield call(getDocsRootFolder);
      const usage = yield select(s => s.usage);
      if (result.success) {
        const files = result.files;
        yield put({
          type: CHECK_DOCS_SUCCESS,
          payload: {
            docChecked: action.payload,
            folders: result.folders,
            files: result.files,
            allFolders:result.folders
          },
        });
        // console.log(usage);
        let totalSize = 0;
        for (let f = 0; f < files.length; f++) {
          totalSize = totalSize + files[f].size
        }
        yield put({
          type: GET_USAGE_SUCCESS,
          payload: {
            ...usage,
            storage: usage.storage,
            files: files.length,
            storage_used: totalSize
          }
        })
      }
    }
  } catch (err) {
    console.log(err);
  }
}

function* moveDocsSaga(action) {
  try {
    const result = yield call(moveDocs, action);
    if (result.success) {
      notifySuccess(result.msg);
      if (action.payload.fileId) {
        yield put({
          type: MOVE_FILE_SUCCESS,
          payload: action.payload,
        });
      } else if (action.payload.folderId) {
        yield put({
          type: MOVE_FOLDER_SUCCESS,
          payload: action.payload,
        });
      }
    }
  } catch (err) {
    if (action.payload.fileId) {
      yield put({
        type: MOVE_FILE_FAILURE,
        payload: action.payload,
      });
    } else if (action.payload.folderId) {
      yield put({
        type: MOVE_FOLDER_FAILURE,
        payload: action.payload,
      });
    }
    notifyError(err.response.data.msg);
  }
}

export default function* documentsWatcher() {
  yield all([
    takeEvery(CHECK_DOCS, checkDocsSaga),
    takeEvery(CHECK_DOCS_HIDDEN, checkDocsSaga),
    takeEvery(MOVE_DOCS, moveDocsSaga),
  ]);
}

import React, { useRef, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import SkyLight from 'react-skylight';
import { emitter } from '../../utils/emitter';
import { POPUP_EVENTS } from '../../constants/events';
import { useDispatch, useSelector } from 'react-redux';
import { deleteFolder } from '../../redux/actions/folders';
import { pasteFileCopied } from '../../redux/actions/copiedFile';
import { checkPath } from '../../redux/actions/path';
import { BsPlay } from 'react-icons/bs'
import { FiTrash, FiEye } from 'react-icons/fi'
import { FaRegCopy } from 'react-icons/fa'
import { MdDriveFileMoveOutline, MdEdit } from 'react-icons/md'


export default function FolderContextMenu() {
  const [clientX, setClientX] = useState(0);
  const [clientY, setClientY] = useState(0);
  const [folderId, setFileId] = useState(null);
  const [folderName, setFileName] = useState(null);
  const copiedFile = useSelector((s) => s.copiedFile);
  const confirmDetele = useRef(null);
  const contextMenu = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const contextMenuStyle = {
    height: '15rem',
    left: clientX,
    marginLeft: 0,
    marginTop: 0,
    minHeight: 200,
    top: clientY,
    width: '20rem',
  };
  const confirmDeteleStyle = {
    left: '40%',
    marginLeft: 0,
    marginTop: 0,
    top: '35%',
    width: '25%',
    // height: '15%',
    minHeight: '0',
    padding: '25px',
    borderRadius: 10
  };
  const showFolderContextListener = (id, name, X, Y) => {
    setClientX(X);
    setClientY(Y);
    setFileId(id);
    setFileName(name);
    if (contextMenu.current) contextMenu.current.show();
  };

  const hideFolderContextListener = () => contextMenu.current.hide();

  const hideConfirmDeleteFoler = () => confirmDetele.current.hide();

  function handleExplore() {
    contextMenu.current.hide();
    dispatch(checkPath({ id: folderId, name: folderName }));
    const pathN = history.location.pathname.split('/')
    history.push(`/${pathN[1]}/folder-detail/${folderId}`);
  }

  function handleRemoveFolder() {
    contextMenu.current.hide();
    dispatch(deleteFolder(folderId));
  }

  function handleMoveFolder() {
    emitter.emit(POPUP_EVENTS.SHOW_MOVE_FILE_OR_FOLDER, null, folderId);
    hideFolderContextListener();
  }

  function showConfirmDelete() {
    confirmDetele.current.show();
    contextMenu.current.hide();
  }

  function handleDetail() {
    emitter.emit(POPUP_EVENTS.SHOW_DETAIL_FILE_OR_FOLDER, null, folderId);
    hideFolderContextListener();
  }

  function handlePaste() {
    dispatch(pasteFileCopied(copiedFile, folderId));
    contextMenu.current.hide();
  }

  function listenToTheEmitter() {
    emitter.on(
      POPUP_EVENTS.SHOW_FOLDER_CONTEXT_MENU,
      showFolderContextListener
    );
    emitter.on(
      POPUP_EVENTS.HIDE_FOLDER_CONTEXT_MENU,
      hideFolderContextListener
    );
    emitter.on(POPUP_EVENTS.HIDE_CONFIRM_DELETE_FOLDER, hideConfirmDeleteFoler);
  }

  const showRenamePopup = () =>
    emitter.emit(POPUP_EVENTS.SHOW_RENAME_POPUP, null, folderId, folderName);
  function handleRename() {
    showRenamePopup();
    hideFolderContextListener();
  }

  const options = [
    {
      id: 0,
      name: 'Explore',
      iconClass: BsPlay,
      onClick: handleExplore,
    },
    {
      id: 1,
      name: 'Rename',
      iconClass: MdEdit,
      onClick: handleRename,
    },
    {
      id: 2,
      name: 'Delete',
      iconClass: FiTrash,
      onClick: showConfirmDelete,
    },
    {
      id: 3,
      name: 'Move',
      iconClass: MdDriveFileMoveOutline,
      onClick: handleMoveFolder,
    },
    {
      id: 4,
      name: 'Properties',
      iconClass: FiEye,
      onClick: handleDetail,
    },
    {
      id: 5,
      name: 'Paste',
      iconClass: FaRegCopy,
      onClick: handlePaste,
    },
  ];

  useEffect(() => {
    listenToTheEmitter();
    return () => {
      emitter.off(
        POPUP_EVENTS.SHOW_FOLDER_CONTEXT_MENU,
        showFolderContextListener
      );
      emitter.off(
        POPUP_EVENTS.HIDE_FOLDER_CONTEXT_MENU,
        hideFolderContextListener
      );
      emitter.off(
        POPUP_EVENTS.HIDE_CONFIRM_DELETE_FOLDER,
        hideConfirmDeleteFoler
      );
    };
  });

  return (
    <>
      <SkyLight
        hideOnOverlayClicked
        overlayStyles={{ backgroundColor: 'inherit' }}
        ref={contextMenu}
        dialogStyles={contextMenuStyle}
      >
        <div className="context-menu">
          {options.map((o) => (
            <div
              onClick={o.onClick}
              key={o.id}
              className="option"
              style={{ height: 'calc((100% / 6) - 1.6rem)' }}
            >
              <span className="option-icon">
                <i className='icon'><o.iconClass /></i>
              </span>
              <span
                style={{
                  alignSelf: 'center',
                }}
              >
                {o.name}
              </span>
            </div>
          ))}
        </div>
      </SkyLight>
      <SkyLight
        hideOnOverlayClicked
        overlayStyles={{ backgroundColor: 'inherit' }}
        ref={confirmDetele}
        dialogStyles={confirmDeteleStyle}
      >
        <div style={{ paddingTop: '0.8em', textAlign: 'center', fontWeight: '500', fontSize: '18px' }}>
          Do you want to delete this folder?
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              margin: '3.5em auto 0',
              maxWidth: '240px',
              fontSize: '15px'
            }}
          >
            <button
              onClick={() => confirmDetele.current.hide()}
              type="button"
              style={{ border: 'none', backgroundColor: 'inherit' }}
            >
              Cancel
            </button>
            <button
              onClick={handleRemoveFolder}
              type="button"
              style={{
                border: 'none',
                backgroundColor: '#016288',
                borderRadius: '5px',
                width: '5em',
                marginLeft: '.5em',
                color: 'white',
                fontWeight: 500,
                height: '2.2em',
              }}
            >
              OK
            </button>
          </div>
        </div>
      </SkyLight>
    </>
  );
}

export const CHECK_PATH = 'CHECK_PATH';
export const CHECK_PATH_BACK = 'CHECK_PATH_BACK';

export const checkPath = item => ({
    type: CHECK_PATH,
    payload: item,
});

export const checkPathBack = () => ({
    type: CHECK_PATH_BACK,
});

import React from 'react';
import { useHistory } from 'react-router-dom';
import ava1 from '../../../assets/images/dummyUser.svg';
import ava2 from '../../../assets/images/dummyUser.svg';
import message from '../../../assets/images/message.png';
import taskImg from '../../../assets/images/taskImg.png';
export default function MemberInfo() {
  const history = useHistory();
  return (
    <div className="">
      <div>
        <div className="content-header__title">Project</div>
      </div>
      <div className="mt-4 d-flex ai-center mb-3">
        <i className="ic-arrow-left2 mr-3 interactive" onClick={() => history.goBack()}/>
        <div className="heading--active">Member Infomation</div>
      </div>
      <div className="d-flex">
        <div className="col-3 pl-0">
          <div
            className="d-flex b-white ai-center p-4 br-10"
            style={{ flexDirection: 'column' }}
          >
            <img src={ava1} alt=""/>
            <div className="heading--active">Abc</div>
            <div className="title">UX Research</div>
            <div className="d-flex">
              <div
                className="col d-flex ai-center mt-3 pl-1 pr-1"
                style={{ flexDirection: 'column' }}
              >
                <i className="ic-assign-black"/>
                <div className="title mt-2" style={{ textAlign: 'center' }}>View report</div>
              </div>
              <div
                className="col d-flex ai-center mt-3 pl-1 pr-1"
                style={{ flexDirection: 'column' }}
              >
                <i className="ic-mail-black"/>
                <div className="title mt-2">Email</div>
              </div>
              <div
                className="col d-flex ai-center mt-3 pl-1 pr-1"
                style={{ flexDirection: 'column' }}
              >
                <i className="ic-message-black"/>
                <div className="title mt-2">Message</div>
              </div>
              <div
                className="col d-flex ai-center mt-3 pl-1 pr-1"
                style={{ flexDirection: 'column' }}
              >
                <i className="ic-remove"/>
                <div className="title mt-2">Delete</div>
              </div>
            </div>
            <div className="hr w-100 mt-3 mb-5"/>
            <div className="text--small">Role</div>
            <div className="title mb-4">Member</div>
            <div className="text--small">Email</div>
            <div className="title mb-4">huongtran1197hd@gmail.com</div>
            <div className="text--small">Phone</div>
            <div className="title mb-4">0925 151 236</div>
            <div className="text--small">Member form</div>
            <div className="title mb-4">8/10/2020</div>
          </div>
        </div>
        <div className="col-9">
          <div className="project-list">
            <div className="heading--active">Job now</div>
            <div className="d-flex">
              {[0, 1, 2].map((i) => (
                <div key={i} className="card">
                  <div className="d-flex">
                    <div className="text">7 October</div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {i === 2 && (
                        <img
                          alt="message"
                          className="interactive"
                          src={message}
                          style={{ width: '2.5rem', marginRight: '1rem' }}
                        />
                      )}
                      <i className="ic-3dot interactive"/>
                    </div>
                  </div>
                  <div
                    className="d-flex interactive"
                    onClick={() => history.push('/project/1')}
                  >
                    <img alt="demo img" src={taskImg}/>
                    <div className="">
                      <div className="title--bold">Web Design</div>
                      <div className="text">Design some files</div>
                    </div>
                  </div>
                  <div>
                    <div className="text">60% completed</div>
                    <div className="progressbar">
                      <div className="progress-bar-fill"/>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="members">
                      <img alt="some avatars" src={ava1}/>
                      <img alt="some avatars" src={ava2}/>
                      <div className="more-members">+4</div>
                    </div>
                    <div className="chip">1 day left</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div/>
    </div>
  );
}

import React, { useRef, useEffect, useState } from 'react';
import SkyLight from 'react-skylight';
import PropTypes from 'prop-types';
import { emitter } from '../../../utils/emitter';
import { POPUP_EVENTS } from '../../../constants/events';
import SelectMember from '../admin/SelectMember';
import { getMembers } from '../../../services/authentication';
import { notifyError, notifySuccess } from '../../common/Alert';
import { editProject } from '../../../services/project';
import { MdClear } from 'react-icons/md';

const initialSelectedMember = { designation: '' };
const newFolderPopupStyle = {
  minHeight: 0,
  width: '40%',
  left: '45%',
  marginLeft: '-15%',
  borderRadius: '10px',
  padding: 0,
};
export default function AddMember({ projectTeam, projectId, callback }) {
  const popupRef = useRef(null);
  const [memberList, setMemberList] = useState([]);
  const [isClear, setIsClear] = useState(false);
  const [selectedMember, setSelectedMember] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);

  const showPopup = () => {
    if (popupRef.current) popupRef.current.show();
  };
  const handleSelectMember = (member) => {
    if (typeof member === 'object' && member !== null && Object.keys(member).length) {
      setSelectedMember({ ...selectedMember, ...member });
      setIsClear(false)
    }
  };
  const removeMemberFromList = (user_id) => {
    setSelectedMembers([
      ...selectedMembers.filter((mem) => mem.user_id !== user_id),
    ]);
  };
  const addMember = () => {
    if (selectedMember.designation && selectedMember.designation !== "") {
      setSelectedMembers([...selectedMembers, selectedMember]);
      setSelectedMember({ ...initialSelectedMember });
      setIsClear(true)
    }else{
      notifyError('Designation is required!')
    }
    // setTimeout(() => {
    //   const [, clearButton] = document.getElementsByClassName('clear-button');
    //   triggerMouseEvent(clearButton, 'mousedown');
    // }, 100);
  };
  const handleSubmit = () => {
    editProject({
      projectId,
      new_members: selectedMembers.map((mem) => ({
        member_id: mem.user_id,
        designation: mem.designation,
      })),
    })
      .then(({ msg }) => {
        notifySuccess(msg);
        callback(selectedMembers);
        popupRef.current.hide();
      })
      .catch((err) => notifyError(err?.response?.data?.msg));
  };
  useEffect(() => {
    getMembers()
      .then(({ members }) => {
        setMemberList(members);
      })
      .catch((err) => {
        console.log(err);
      });
    function listenToTheEmitter() {
      emitter.on(POPUP_EVENTS.CREATE_ADD_MEMBER, showPopup);
    }
    listenToTheEmitter();
    return () => {
      emitter.off(POPUP_EVENTS.CREATE_ADD_MEMBER, showPopup);
    };
  }, []);

  return (
    <SkyLight
      dialogStyles={newFolderPopupStyle}
      ref={popupRef}
      hideOnOverlayClicked
      className="add-member-modal"
    >
      <div className="task-popup">
        <div className="modal-header-task">
          <div className="heading--active">Add member</div>
          <button type='button' className='btn-modal-close' onClick={() => popupRef.current.hide()}>
            <MdClear />
          </button>
        </div>
        <div className="modal-body-task row">
          <div className="col-sm-5 col-xs-12">
            <SelectMember
              data={memberList.filter(
                (m) =>
                  !selectedMembers.map((t) => t.user_id).includes(m.user_id) &&
                  !projectTeam.map((t) => t.user_id).includes(m.user_id)
              )}
              callback={handleSelectMember}
              placeholder="Select member"
              clear={isClear}
            />
          </div>
          <div className="col-sm-5 col-xs-12">
            <input
              type="text"
              className="primary-input"
              value={selectedMember.designation}
              onChange={(e) => {
                setSelectedMember({
                  ...selectedMember,
                  designation: e.target.value,
                });
              }}
              placeholder="Designation"
            />
          </div>
          <div className="col-sm-2 col-xs-4">
            <button type="button" className="button" onClick={addMember}>
              Add
            </button>
          </div>
        </div>
        <br />
        <div className="list-of-selected">
          {selectedMembers.map((mem) => (
            <div key={mem.user_id} className="add-member-wrap">
              <div className='memeber-image-wrap'>
                {mem.avatar_url ? (
                  <img
                    alt=""
                    src={mem.avatar_url}
                    style={{
                      width: '4.5rem',
                      height: '4.5rem',
                      marginRight: '1rem',
                    }}
                  />
                ) : (
                  <span className='m-name'>{mem.first_name[0]}{mem.last_name[0]}</span>
                )}
              </div>
              <div className="member-info">
                <div className="title--bold">{mem.first_name}</div>
                <div className="text">{mem.designation}</div>
              </div>
              <button type='button' className='btn-remove-member' onClick={() => removeMemberFromList(mem.user_id)}>
                <MdClear />
              </button>

            </div>
          ))}
        </div>
        <div className="modal-footer-task">
          <button type='button' className='btn-cancel' onClick={() => popupRef.current.hide()}>
            Cancel
          </button>
          <button
            type="button"
            className="button b-primary ml-auto"
            onClick={handleSubmit}
          >
            Create
          </button>
        </div>
      </div>
    </SkyLight>
  );
}
AddMember.propTypes = {
  projectTeam: PropTypes.array,
  projectId: PropTypes.oneOfType(PropTypes.string, PropTypes.number),
  callback: PropTypes.func.isRequired,
};
AddMember.defaultProps = {
  projectTeam: [],
  projectId: 0,
};

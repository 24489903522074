import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { MdMoreVert, MdLock, MdLink } from 'react-icons/md';
// import { Dropdown } from 'react-bootstrap';
import PropTypes from 'prop-types';
// import dummySmallImg from '../assets/images/dummy-small-user.svg';
// import PDFIcon from '../assets/images/pdf-icon.svg';
// import { formatDate } from '../utils/datetime';
import { bytesToSize, getFileName } from '../utils/string';
import { renderIf } from '../utils/components';
import { checkQuickAccess } from '../redux/actions/quickAccess';
import { checkFavorite, renameFile, removeSelectingFiles } from '../redux/actions/files';
import { checkFileCopied } from '../redux/actions/copiedFile';
import getDefaultThumbnail from '../utils/getDefaultThumbnail';
import {
    checkFileToLink,
    // checkIdLinked,
} from '../redux/actions/linkFilePopup';
import CONFIG from '../constants/config';
import moment from 'moment'
import ReactTooltip from 'react-tooltip';
// import { POPUP_EVENTS } from '../constants/events';
// import { emitter } from '../utils/emitter';

function FilesComponent({
    handleFileContextMenu,
    file,
    drop,
    allowDrop,
    dragStart,
    onSelect,
    fileSelected
}) {
    // console.log(fileSelected);

    // const [fileSelected, setFileSelected] = useState(false);
    const [newName, setNewName] = useState(null);
    const [quickRename, setQuickRename] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const {
        file_id,
        new_name,
        file_name,
        created_at,
        favorite,
        checkout,
        link,
    } = file;
    // console.log(file);

    const history = useHistory();
    const inputRef = useRef(null);
    const dispatch = useDispatch();
    const copiedFile = useSelector((s) => s.copiedFile);
    const linkFilePopup = useSelector((s) => s.linkFilePopup);
    const userInfo = useSelector((s) => s.userInfo);
    const isMultipleFiles = useSelector(s => s.multiSelection.isMultipleFiles)
    const showUpName = new_name ? new_name : getFileName(file_name);
    // console.log(userInfo);
    function handleClick() {
        dispatch(checkQuickAccess());
        history.push(`/file-detail/${file_id}`);
    }

    const handleQuickRename = (e) => {
        if (e.keyCode === 13) {
            if (file_id) dispatch(renameFile(file_id, newName));
            setQuickRename(false);
        }
    };

    useEffect(() => {
        if (quickRename) {
            inputRef.current.focus();
        }
    }, [quickRename]);

    const thumbnail = file.thumbnail_path
        ? `${CONFIG.CLOUDFRONT_URL}/${file.thumbnail_path}`
        : getDefaultThumbnail(file.type);

    const classes = {
        input: {
            width: '100%',
            display: quickRename ? 'block' : 'none',
            border: 'none',
            boxShadow: '1px 1px 2px grey',
            textAlign: 'center',
        },
    };


    return (
        <div
            className={`files-wraps files__items ${isActive ? 'is-active' : ''} ${file_id === copiedFile ? 'is-copied' : ''} ${fileSelected ? 'file-selected' : ''}`}
            onContextMenu={isMultipleFiles ? handleFileContextMenu : null}
            onClick={() => {
                if (linkFilePopup.minimize) {
                    dispatch(checkFileToLink(file));
                }
                onSelect(file)
                // setFileSelected(true);
            }}
            // onBlur={() => dispatch(removeSelectingFiles())}
            // onBlur={() => setFileSelected(false)}
            onDoubleClick={quickRename ? null : handleClick}
            onKeyDown={(e) => {
                if (e.keyCode === 113) {
                    setQuickRename(true);
                } else if (e.key === 'c' && e.ctrlKey) {
                    dispatch(checkFileCopied(file_id));
                }
            }}
            tabIndex={file_id}
            onDragStart={dragStart}
            onDrop={drop}
            onDragOver={allowDrop}
            onMouseOver={() => setIsActive(true)}
            onMouseOut={() => setIsActive(false)}
            draggable="true"
            data-id={`fi-${file_id}`}
        >
            <div className="top-files">
                <div className="files-text">
                    <div className="icon">
                        <img src={thumbnail} alt="" />
                    </div>
                    {renderIf(
                        <p>{showUpName}</p>,
                        !quickRename
                    )}
                    <input
                        ref={inputRef}
                        onKeyDown={handleQuickRename}
                        onBlur={() => {
                            setQuickRename(false);
                            setNewName(null);
                        }}
                        type="text"
                        style={classes.input}
                        value={newName !== null ? newName : showUpName}
                        onChange={(e) => setNewName(e.target.value)}
                    />

                </div>
                <div className={`groups-btns ${!checkout ? 'justify-content-end' : ''}`}>
                    {/* file is favorite please add active class near btn-likes */}
                    {link > 0 ? <button data-for="link" data-tip="Linked" type="button" className='btn-checkout-check'><MdLink /></button> : null}
                    {checkout ? <button data-for="lock" data-tip="Locked" type="button" className='btn-checkout-check'><MdLock /></button> : null}
                    <button
                        data-for="favorites" data-tip="Favorites"
                        type="button"
                        className={`btn-likes ${favorite ? 'active' : ''}`}
                        onClick={() => dispatch(checkFavorite(file_id, favorite ? false : true, file))}
                    >
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 5.09C13.09 3.81 14.76 3 16.5 3C19.58 3 22 5.42 22 8.5C22 12.2769 18.6056 15.3549 13.4627 20.0185L13.45 20.03L12 21.35L10.55 20.04L10.5105 20.0041C5.38263 15.3442 2 12.2703 2 8.5C2 5.42 4.42 3 7.5 3C9.24 3 10.91 3.81 12 5.09Z" fill="#DF5656" />
                        </svg>
                    </button>
                    <div className="files-dropdown">
                        <button type="button" data-for="menu" data-tip="Menu" style={{ lineHeight: '0' }} onClick={handleFileContextMenu}><MdMoreVert /></button>
                    </div>
                    {/* <Dropdown className="files-dropdown">
                        <Dropdown.Toggle id="dropdown-button-dark-example1" onClick={handleFileContextMenu}>
                            <MdMoreVert />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item href="#/action-1">
                                Action
                            </Dropdown.Item>
                            <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                            <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item href="#/action-4">Separated link</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown> */}
                </div>
                <ReactTooltip id="link" />
                <ReactTooltip id="lock" />
                <ReactTooltip id="favorites" />
                <ReactTooltip id="menu" />
            </div>
            <div className="bottom-text">
                <div className="users-wrap">
                    <ul className="user-ul">
                        <li>
                            <div className="user-picture-wrap">
                                {userInfo?.avatar ?
                                    <img src={userInfo.avatar} alt="user" />
                                    :
                                    <span className='user-icon'>
                                        {userInfo.firstname[0] || ''}
                                        {userInfo.lastname[0] || ''}
                                    </span>
                                }
                                {/* <img src={dummySmallImg} alt="user" /> */}
                            </div>
                        </li>
                        {/* <li>
                            <div className="user-picture-wrap">
                                <img src={dummySmallImg} alt="user" />
                            </div>
                        </li>
                        <li>
                            <div className="user-picture-wrap">
                                <img src={dummySmallImg} alt="user" />
                            </div>
                        </li> */}
                    </ul>
                    <p>Modified {moment(new Date(file.updated_at)).format('MMM DD, YYYY')}</p>
                </div>
                <div className="dates-wrap">
                    <p>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.9998 3C8.02976 3 3.99976 7.03 3.99976 12H0.999756L4.88976 15.89L4.95976 16.03L8.99976 12H5.99976C5.99976 8.13 9.12976 5 12.9998 5C16.8698 5 19.9998 8.13 19.9998 12C19.9998 15.87 16.8698 19 12.9998 19C11.0698 19 9.31976 18.21 8.05976 16.94L6.63976 18.36C8.26976 19.99 10.5098 21 12.9998 21C17.9698 21 21.9998 16.97 21.9998 12C21.9998 7.03 17.9698 3 12.9998 3ZM11.9998 8V13L16.2498 15.52L17.0198 14.24L13.4998 12.15V8H11.9998Z" fill="#C2CFE0" stroke="#F5F6F8" strokeWidth="0.6" />
                        </svg>
                        <b>
                            {/* Nov, 30 */}
                            {moment(new Date(created_at)).format('MMM DD, YYYY')}
                        </b>
                    </p>
                    <span>{bytesToSize(file?.size)}</span>
                </div>
            </div>
        </div >
    )
}

FilesComponent.propTypes = {
    handleFileContextMenu: PropTypes.func.isRequired,
    file: PropTypes.object.isRequired,
    drop: PropTypes.func,
    allowDrop: PropTypes.func,
    dragStart: PropTypes.func.isRequired,
};

FilesComponent.defaultProps = {
    drop: undefined,
    allowDrop: undefined,
};

export default FilesComponent;
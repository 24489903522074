import axios from 'axios';
import CONFIG from '../constants/config';

export const getUsage = async () => {
  const response = await axios({
    method: 'get',
    url: `${CONFIG.BASE_URL}/total-file`,
    withCredentials: true,
  });
  return response.data;
};

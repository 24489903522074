import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import Button from '../common/Button';
// import linkImg from '../../assets/images/linkfile.svg';
import { MdLink } from 'react-icons/md'
import { BsBoxArrowUpRight } from 'react-icons/bs'
import { emitter } from '../../utils/emitter';
import { POPUP_EVENTS } from '../../constants/events';
import {
  linkFile,
  minimizePopup,
  resetLinkPopup,
} from '../../redux/actions/linkFilePopup';
import { removeSelectingFiles } from '../../redux/actions/files';

export default function LinkFilesMinimize() {
  const linkFilePopup = useSelector((s) => s.linkFilePopup);
  const selectedFiles = useSelector((s) => s.multiSelection.selectedFiles);
  const dispatch = useDispatch();
  // console.log(linkFilePopup);
  const handleLinkFile = () => {
    if (selectedFiles && selectedFiles.length > 0) {
      for (let i = 0; i < selectedFiles.length; i++) {
        dispatch(linkFile(linkFilePopup.file_id, selectedFiles[i]));
      }
      // dispatch(linkFile(linkFilePopup.file_id, linkFilePopup.file_to_link));
      dispatch(resetLinkPopup());
      dispatch(removeSelectingFiles())
    }
  };
  let fileNames = [];
  if (selectedFiles && selectedFiles.length > 0) {
    for (let f = 0; f < selectedFiles.length; f++) {
      fileNames.push(selectedFiles[f].new_name);
    }
  }
  if (linkFilePopup.minimize) {
    return (
      <div
        className="link-file-minimize"
      >
        <div className="main-link-minimize" style={{ display: 'flex' }}>
          <i className='icon-link'><MdLink /></i>
          <div>
            <p>
              Link file
            </p>
            <p>
              Link with file: {fileNames.length > 0 ? fileNames.join(',') : null}
              {/* Link with file: {linkFilePopup.file_to_link?.new_name} */}
            </p>
          </div>
        </div>
        <div className="btns-files-minimize">
          <button type='button' onClick={handleLinkFile}>Link</button>
          <i
            className="extend-ic"
            onClick={() => {
              emitter.emit(POPUP_EVENTS.SHOW_LINK_FILES);
              dispatch(minimizePopup(false));
            }}
          >
            <BsBoxArrowUpRight />
          </i>
        </div>
      </div>
    );
  }
  return null;
}

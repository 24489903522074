import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import PropTypes from 'prop-types';

export default function TableView({
    data,
    columns,
    keyField,
    rowEvents,
    selectRow
}) {
    return (
        <div className='list-view-block'>
            <div className="d-flex align-items-center justify-content-between">
                {/* <button className="btn-primary">12 Files</button>
        <button className="btn-outline-primary">Show all</button> */}
            </div>
            <BootstrapTable keyField={keyField}
                data={data}
                columns={columns}
                bordered={false}
                classes={'table-search'}
                rowEvents={rowEvents}
                selectRow={selectRow}
                noDataIndication="There is no data"
                wrapperClasses="table-responsive"
            />
        </div>
    )
}

TableView.propTypes = {
    data: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
    keyField: PropTypes.string.isRequired,
    rowEvents: PropTypes.object
}

TableView.defaultProps = {
    data: [],
    columns: [],
    keyField: '1',
    rowEvents: {}
}

import React, { useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import { emitter } from '../utils/emitter';
import { POPUP_EVENTS } from '../constants/events';
import ShowReminder from '../components/reminder/ShowReminder';
import CreateReminder from '../components/reminder/CreateReminder';
import CalendarComponent from '../components/reminder/CalendarComponent';
// import DummyUser from '../assets/images/dummy-small-user.svg';
import axios from 'axios';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
    deleteReminder,
    getReminders,
    selectReminder,
    resetReminder,
} from '../redux/actions/reminders';
import {
    ISOStringToLocalDate,
    ISOstringToLocalTime,
} from '../utils/datetime';
import { Scrollbars } from 'react-custom-scrollbars';
import * as scrollbar from '../helpers/scrollbar';
import { Dropdown } from 'react-bootstrap';
import Edit from '../assets/images/Edit.svg';
import Delete from '../assets/images/Delete.svg';
import DropdownIcon from '../assets/images/Dropdown.svg';
import PlusIcon from '../assets/images/plus-icon.svg';

export default function Reminder() {

    const [info, setInfo] = useState({});
    const [icons, setIcons] = useState([]);
    const reminders = useSelector((s) => s.reminders);
    const userInfo = useSelector(s => s.userInfo);
    const dispatch = useDispatch();

    const selectReminderToEdit = (e, reminder) => {
        dispatch(selectReminder(reminder));
        emitter.emit(POPUP_EVENTS.CREATE_REMINDER);
        // e.preventDefault();
    };
    const handleDelete = (e, id) => {
        e.preventDefault();
        dispatch(deleteReminder(id));
    };
    const quickCreateReminder = () => {
        setInfo({
            date: Date.now(),
            dateStr: moment(Date.now()).format('YYYY-MM-DD'),
        });
        emitter.emit(POPUP_EVENTS.CREATE_REMINDER);
    };
    useEffect(() => {
        if (
            !reminders.todo.length &&
            !reminders.inprocess.length &&
            !reminders.done.length
        ) {
            dispatch(getReminders());
        }
        axios({
            url: 'https://cms.docquik.com/icons',
            method: 'GET',
        })
            .then(({ data }) => {
                setIcons(data);
            })
            .catch(() => { });
        // window.onload = function () {
        //     const classs = document.querySelector('.fc-toolbar.fc-header-toolbar')
        //     classs.addClass('row col-lg-12');
        // };
        return () => {
            dispatch(resetReminder());
            // window.onload = function () {
            //     const classs = document.querySelector('.fc-toolbar.fc-header-toolbar')
            //     classs.removeClass('row col-lg-12');
            // };
        };



        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>

            <div className='page-top-header reminder'>
                <h1 className="page-title">Reminder</h1>
            </div>

            <div className='reminder-page-wrap'>
                <div className='row'>
                    <div className='col-lg-9 col-sm-12 col-xs-12 col-md-12'>
                        {reminders.todo.find((f) => f) ?
                            <div className='due-soon-wrap'>
                                <h3 className='due-title'>Due Soon</h3>
                                <Scrollbars
                                    renderView={scrollbar.renderView}
                                    renderTrackHorizontal={scrollbar.renderTrackHorizontal}
                                    renderTrackVertical={scrollbar.renderTrackVertical}
                                    renderThumbHorizontal={scrollbar.renderThumb}
                                    renderThumbVertical={scrollbar.renderThumb}
                                    universal={true}
                                >


                                    <div className='due-badge-list'>

                                        <div className='due-badge-wrap'>
                                            <div className='due-time'>
                                                {reminders.todo.find((f) => f)?.all_day
                                                    ? 'All day'
                                                    :
                                                    `${ISOstringToLocalTime(reminders.todo.find((f) => f)?.start_time)} - ${ISOstringToLocalTime(reminders.todo.find((f) => f)?.end_time)}`}
                                            </div>
                                            <p className="due-status"> {reminders.todo.find((f) => f)?.title}</p>
                                            <ul className='due-user-li'>
                                                <li>
                                                    <div className='due-user-img-wrap'>
                                                        {userInfo?.avatar ?
                                                            <img src={userInfo.avatar} alt="user" />
                                                            :
                                                            <span className='user-icon'>
                                                                {userInfo.firstname[0] || ''}
                                                                {userInfo.lastname[0] || ''}
                                                            </span>
                                                        }
                                                    </div>
                                                </li>

                                            </ul>
                                        </div>

                                    </div>


                                </Scrollbars>
                            </div>
                            :
                            null
                        }
                        <div className='calender-wrap'>
                            {/* <CalendarComponent /> */}
                            <FullCalendar
                                defaultView="dayGridMonth"
                                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                                headerToolbar={{
                                    // end: 'prev,next, dayGridMonth,timeGridWeek,today ',
                                    start: 'prev,next,title', // will normally be on the left. if RTL, will be on the right
                                    // center: '',
                                    end: 'dayGridMonth,timeGridWeek,dayGridDay',
                                    today: 'today',
                                }}
                                allDay={false}
                                weekends
                                selectable
                                dateClick={(i) => {
                                    if (
                                        moment(i.dateStr) >= moment() ||
                                        i.dateStr === moment(Date.now()).format('YYYY-MM-DD')
                                    ) {
                                        setInfo(i);
                                        if (
                                            reminders.todo.filter(

                                                (r) => ISOStringToLocalDate(r.start_time) === i.dateStr
                                            ).length
                                        ) {
                                            emitter.emit(POPUP_EVENTS.SHOW_REMINDER);
                                        } else {
                                            emitter.emit(POPUP_EVENTS.CREATE_REMINDER);
                                        }
                                    }
                                }}
                                events={reminders.todo.map((r) => ({
                                    title: r.title,
                                    allDay: r.all_day,
                                    start: `${ISOStringToLocalDate(
                                        r.start_time
                                    )}T${ISOstringToLocalTime(r.start_time)}:00`,
                                    end: `${ISOStringToLocalDate(
                                        r.end_time
                                    )}T${ISOstringToLocalTime(r.end_time)}:00`,
                                }))}
                            />
                        </div>
                    </div>

                    <div className='reminder-right-bar-wrap'>
                        <div>
                            {[...reminders.inprocess, ...reminders.todo]
                                .filter(
                                    (r) =>
                                        ISOStringToLocalDate(r.start_time) ===
                                        moment(Date.now()).format('YYYY-MM-DD')
                                ).length > 0 ?
                                <ul className='reminder-list'>
                                    <Scrollbars
                                        renderView={scrollbar.renderView}
                                        renderTrackHorizontal={scrollbar.renderTrackHorizontal}
                                        renderTrackVertical={scrollbar.renderTrackVertical}
                                        renderThumbHorizontal={scrollbar.renderThumb}
                                        renderThumbVertical={scrollbar.renderThumb}
                                        // autoHeight
                                        universal={true}
                                    >
                                        <h3 className='reminder-list-title'>Today</h3>
                                        {[...reminders.inprocess, ...reminders.todo]
                                            .filter(
                                                (r) =>
                                                    ISOStringToLocalDate(r.start_time) ===
                                                    moment(Date.now()).format('YYYY-MM-DD')
                                            )
                                            .map((r) => (
                                                <li key={r.reminder_id}>
                                                    <div className='reminder-list-wrap'>
                                                        <div className=' test d-flex  justify-content-between mb-12'>
                                                            <p className="reminder-time">
                                                                {r.all_day ? 'All day:' : ISOstringToLocalTime(r.start_time)}
                                                            </p>
                                                            <div>
                                                                <Dropdown className="d-inline reminder-drop" drop="start" >
                                                                    <Dropdown.Toggle id="dropdown-autoclose-inside" className='reminder-dropdown'>
                                                                        <img src={DropdownIcon} alt="dropdown" />
                                                                    </Dropdown.Toggle>
                                                                    <Dropdown.Menu className='reminder-dropdown-menu'>
                                                                        <Dropdown.Item as="button" onClick={(e) => selectReminderToEdit(e, reminders.todo.find((f) => f))}><img src={Edit} at="edit" /><p className='reminder-dropdown-title'>Edit</p></Dropdown.Item>
                                                                        <Dropdown.Item as="button" onClick={(e) => handleDelete(e, reminders.todo.find((f) => f)?.reminder_id)}><img src={Delete} at="delete" /><p className='reminder-dropdown-title'>Delete</p></Dropdown.Item>
                                                                    </Dropdown.Menu>
                                                                </Dropdown>

                                                            </div>

                                                        </div>

                                                        <h3 className='reminder-list-status'>{r.title}</h3>

                                                        <ul className='due-user-li'>
                                                            <li>
                                                                <div className='reminder-user-img-wrap'>
                                                                    {userInfo?.avatar ?
                                                                        <img src={userInfo.avatar} alt="user" />
                                                                        :
                                                                        <span className='user-icon'>
                                                                            {userInfo.firstname[0] || ''}
                                                                            {userInfo.lastname[0] || ''}
                                                                        </span>
                                                                    }
                                                                </div>
                                                            </li>

                                                        </ul>
                                                    </div>
                                                </li>
                                            ))}
                                        {[...reminders.done]
                                            .filter(
                                                (r) =>
                                                    ISOStringToLocalDate(r.start_time) ===
                                                    moment(Date.now()).format('YYYY-MM-DD')
                                            ).map((r) => (
                                                <li key={r.reminder_id}>
                                                    <div className='reminder-list-wrap'>
                                                        <div className=' test d-flex  justify-content-between mb-12'>
                                                            <p className="reminder-time">
                                                                {r.all_day ? 'All day:' : ISOstringToLocalTime(r.start_time)}
                                                            </p>
                                                            <div>
                                                                <Dropdown className="d-inline reminder-drop" drop="start" >
                                                                    <Dropdown.Toggle id="dropdown-autoclose-inside" className='reminder-dropdown'>
                                                                        <img src={DropdownIcon} alt="dropdown" />
                                                                    </Dropdown.Toggle>
                                                                    <Dropdown.Menu className='reminder-dropdown-menu'>
                                                                        <Dropdown.Item href="#" onClick={(e) => selectReminderToEdit(e, reminders.todo.find((f) => f))}><img src={Edit} at="edit" /><p className='reminder-dropdown-title'>Edit</p></Dropdown.Item>
                                                                        <Dropdown.Item href="#" onClick={(e) => handleDelete(e, reminders.todo.find((f) => f)?.reminder_id)}><img src={Delete} at="delete" /><p className='reminder-dropdown-title'>Delete</p></Dropdown.Item>
                                                                    </Dropdown.Menu>
                                                                </Dropdown>

                                                            </div>

                                                        </div>

                                                        <h3 className='reminder-list-status'>{r.title}</h3>

                                                        <ul className='due-user-li'>
                                                            <li>
                                                                <div className='reminder-user-img-wrap'>
                                                                    {userInfo?.avatar ?
                                                                        <img src={userInfo.avatar} alt="user" />
                                                                        :
                                                                        <span className='user-icon'>
                                                                            {userInfo.firstname[0] || ''}
                                                                            {userInfo.lastname[0] || ''}
                                                                        </span>
                                                                    }
                                                                </div>
                                                            </li>

                                                        </ul>
                                                    </div>
                                                </li>
                                            ))}
                                        {/* <li>
                                        <div className='reminder-list-wrap'>
                                            <div className='d-flex align-self-center justify-content-between mb-12'>
                                                <div className="reminder-time">9:00 - 9:30</div>
                                                <div>...</div>
                                            </div>

                                            <h3 className='reminder-list-status'>Sending File</h3>

                                            <ul className='due-user-li'>
                                                <li>
                                                    <div className='reminder-user-img-wrap'>
                                                        <img src={DummyUser} alt="user-img" />
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className='user-img-wrap'>
                                                        <img src={DummyUser} alt="user-img" />
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className='user-img-wrap'>
                                                        <img src={DummyUser} alt="user-img" />
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className='user-img-wrap'>
                                                        <img src={DummyUser} alt="user-img" />
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </li> */}

                                        {/* <h3 className='reminder-list-title'>Tommorrow</h3>
                                    <li>
                                        <div className='reminder-list-wrap'>
                                            <div className='d-flex align-self-center justify-content-between mb-12'>
                                                <div className="reminder-time">9:00 - 9:30</div>
                                                <div>...</div>

                                            </div>

                                            <h3 className='reminder-list-status'>Sending File</h3>

                                            <ul className='due-user-li'>
                                                <li>
                                                    <div className='reminder-user-img-wrap'>
                                                        <img src={DummyUser} alt="user-img" />
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className='user-img-wrap'>
                                                        <img src={DummyUser} alt="user-img" />
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className='user-img-wrap'>
                                                        <img src={DummyUser} alt="user-img" />
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className='user-img-wrap'>
                                                        <img src={DummyUser} alt="user-img" />
                                                    </div>
                                                </li>
                                            </ul>

                                        </div>
                                    </li> */}
                                    </Scrollbars>
                                </ul>
                                :
                                null
                            }
                        </div>
                        <button type="button" className="reminder-btn" onClick={quickCreateReminder}>
                            <img src={PlusIcon} alt="plusicon" />
                            <h5 className='reminder-btn-title'> Create Reminder</h5>
                        </button>
                    </div>

                </div>

                <CreateReminder info={info} icons={icons} />
                <ShowReminder
                    time={moment(info.date).format('dddd, D MMMM, YYYY')}
                    items={[...reminders.inprocess, ...reminders.todo].filter(
                        (r) => ISOStringToLocalDate(r.start_time) === info.dateStr
                    )}
                    selectReminderToEdit={selectReminderToEdit}
                />

            </div>

        </>
    )
}

import React, { useState, useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import TheLayout from './components/TheLayout';
import CONFIG from './constants/config';
import CustomRedirect from './components/common/CustomRedirect';
import Signin from './view/Signin';
import Otp from './view/Otp';
import ResetPassword from './view/ResetPassword';
import Home from "./view/Home"
import Trash from "./view/Trash"
import Notification from './view/Notification'
import FileDetail from './view/FileDetail'
import PreviewImage from './view/PreviewImage';
import Reminder from './view/Reminder';
import Chat from './view/Chat';
import ProjectDetails from './view/ProjectDetails';
import Workflow from './view/Workflow';
import SettingsPage from './view/Settings';
import Files from './view/Files';
import Profile from './view/Profile';
import ActivitiesPage from './view/Activities';
import CallBack from './view/CallBack';
import { useHistory, useLocation } from 'react-router-dom'
import Meeting from './components/meeting/Meeting';

// routes

// export const routes = [
//     { path: '/login', component: Signin, exact: true, isPrivate: false },
//     { path: '/otp', component: Otp, exact: true, isPrivate: false },
//     { path: '/reset-password', component: ResetPassword, exact: true, isPrivate: false },
//     { path: '/home', component: Home, exact: true, isPrivate: true },
//     { path: '/trash', component: Trash, exact: true, isPrivate: true },
//     { path: '/about', component: About, exact: true, isPrivate: true }
// ]

function CustomRedirect2({ include }) {
    if (include.includes(window.location.pathname)) return <Redirect to="/login" />;
    return null;
}
CustomRedirect2.propTypes = {
    include: PropTypes.array,
};
CustomRedirect2.defaultProps = {
    include: [],
};

function AppRouter() {

    const [loggedIn, setLoggedIn] = useState(null);
    const userInfo = useSelector((s) => s.userInfo);
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        axios({
            url: `${CONFIG.BASE_URL}/me`,
            method: 'GET',
            withCredentials: true,
        })
            .then(() => {
                setLoggedIn(true)
                if (location.pathname === '/') {
                    history.push('/home');
                }

            })
            .catch(() => setLoggedIn(false));

        // if (!userInfo.hasValue) {
        //   dispatch(getUserInfo());
        // }
    }, []);
    // console.log(loggedIn);
    if (loggedIn === null) return null;

    if (!loggedIn) {
        return (
            <Switch>
                <Route exact path="/login" component={Signin} />
                {/* <Route exact path="/loginFromApp" component={LoginFromApp} /> */}
                {/* <Route exact path="/signup" component={SignUp}/> */}
                <Route exact path="/otp" component={Otp} />
                <Route path="/reset-password" component={ResetPassword} />
                {/* <Route exact path="/set-phonenumber" component={SignUpThirdParty} /> */}
                {/* <Route exact path="/organization/:org_id/invite/:invite_uid" component={AcceptInvitation} /> */}
                {/* <Route exact path="/file-detail/:id" component={FileDetail} /> */}
                <CustomRedirect exclude={[]} to="/login" />
            </Switch>
        );
    }

    return (
        <Switch>
            <Route exact path="/login" component={Signin} />
            {/* <Route exact path="/loginFromApp" component={LoginFromApp} /> */}
            <Route exact path="/home" render={(props) => <TheLayout {...props}><Home /></TheLayout>} />
            <Route exact path="/files" render={(props) => <TheLayout {...props}><Files /></TheLayout>} />
            <Route exact path="/file-detail/:id" component={FileDetail} />
            <Route exact path="/trash" render={(props) => <TheLayout {...props}><Trash /></TheLayout>} />
            <Route exact path="/home/:type/:id" render={(props) => <TheLayout {...props}><Home /></TheLayout>} />
            <Route exact path="/files/:type/:id" render={(props) => <TheLayout {...props}><Files /></TheLayout>} />
            <Route exact path="/reminder" render={(props) => <TheLayout {...props}><Reminder /></TheLayout>} />
            <Route exact path="/previewImage" component={PreviewImage} />
            <Route path="/projects" render={(props) => <TheLayout {...props}><Workflow /></TheLayout>} />
            <Route path="/project/:id" render={(props) => <TheLayout {...props}><ProjectDetails /></TheLayout>} />
            <Route path="/setting" render={(props) => <TheLayout {...props}><SettingsPage /></TheLayout>} />
            <Route path="/user" render={(props) => <TheLayout {...props}><Profile /></TheLayout>} />
            <Route path="/activities" render={(props) => <TheLayout {...props}><ActivitiesPage /></TheLayout>} />
            <Route path="/callback" render={(props) => <TheLayout {...props}><CallBack /></TheLayout>} />
            {userInfo !== 'BASIC' ?
                <>
                    <Route exact path="/notification" render={(props) => <TheLayout {...props}><Notification /></TheLayout>} />
                    <Route exact path="/chat" render={(props) => <TheLayout {...props}><Chat /></TheLayout>} />
                    <Route exact path="/meeting" render={(props) => <TheLayout {...props}><Meeting /></TheLayout>} />
                </>
                :
                null
            }
            <Route exact path="/previewImage" component={PreviewImage} />
            <CustomRedirect exclude={['/previewImage']} to="/home" />
            {/* <Route exact path="/user" component={UserInfo} /> */}
            {/* <Route exact path="/share-file/:id" component={ShareFile} /> */}


            {/* 
          <Route path="/logs" component={Logs} />
          <Route exact path="/analysis" component={Analysis} />
          <Route exact path="/organization/:org_id/invite/:invite_uid" component={AcceptInvitation} />*/}

            {/* <CustomRedirect exclude={['/previewImage']} to="/home" /> */}
        </Switch>
    );
}

export default AppRouter;
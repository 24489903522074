import { all, call, put, takeEvery } from 'redux-saga/effects';
import {
  CHECK_QUICK_ACCESS,
  CHECK_QUICK_ACCESS_HIDDEN,
  CHECK_QUICK_ACCESS_SUCCESS,
} from '../redux/actions/quickAccess';
import {
  getRecentlyAccessed,
  getRecentlyUpload,
  getCheckoutFiles,
  getFavoriteFiles,
  getFavoriteFolders
} from '../services/quickAccess';

function *checkQuickAccessSaga() {
  try {
    const [uploadRes, accessedRes, CheckoutRes, favoriteRes, favoriteFolder] = yield all([
      call(getRecentlyUpload),
      call(getRecentlyAccessed),
      call(getCheckoutFiles),
      call(getFavoriteFiles),
      call(getFavoriteFolders),
    ]);
    if (
      uploadRes.success ||
      accessedRes.success ||
      CheckoutRes.success ||
      favoriteRes.success || 
      favoriteFolder.success
    ) {
      yield put({
        type: CHECK_QUICK_ACCESS_SUCCESS,
        payload: {
          upload: uploadRes.files || [],
          accessed: accessedRes.files || [],
          favorite: favoriteRes.msg || [],
          favoriteFolders:favoriteFolder.msg || [],
          checkout: CheckoutRes.msg || [],
        },
      });
    }
  } catch (err) {
    console.log(err);
  }
}

export default function *quickAccessWatcher() {
  yield takeEvery(CHECK_QUICK_ACCESS, checkQuickAccessSaga);
  yield takeEvery(CHECK_QUICK_ACCESS_HIDDEN, checkQuickAccessSaga);
}

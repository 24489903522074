import { call, putResolve, put, takeEvery, all } from 'redux-saga/effects';
import {
  login,
  verifyOtp,
  logOut,
  userInfo,
  updateInfo,
  resendOtp,
  setPhoneNumber,
  sendEmail,
  updatePassword,
} from '../services/authentication';
import {
  LOGIN,
  LOGOUT,
  RESEND_OTP,
  SET_PHONE_NUMBER,
  VERIFY_OTP,
} from '../redux/actions/authentication';
import {
  GET_USER_INFO,
  UPDATE_PASSWORD,
  UPDATE_USER_INFO,
} from '../redux/actions/userInfo';
import { emitter } from '../utils/emitter';
import { EVENTS } from '../constants/events';
import { notifyError, notifySuccess } from '../components/common/Alert';
function *loginSaga(action) {
  try {
    const result = yield call(login, action);
    if (result.success) {
      action.payload.history.push('/otp', {
        msg: result.msg,
      });
    } else {
      notifyError(result.data.msg);
    }
  } catch (err) {
    notifyError(err.response.msg);
  }
}

function *verifyOtpSaga(action) {
  try {
    const result = yield call(verifyOtp, action);
    if (result.success) {
      yield putResolve({
        type: 'LOGIN_SUCCESS',
      });
      if (action.payload.resetPassword) {
        try {
          const result2 = yield call(sendEmail, action);
          notifySuccess(result2.msg);
        } catch (err) {
          notifyError(err.response.data.msg);
        }
        setTimeout(() => window.location.assign('/login'), 3000);
      } else {
        window.location.assign('/home');
      }
    }
    // console.log(result);
  } catch (err) {
    if (err.response) {
      emitter.emit(EVENTS.VERIFY_OTP_FAIL, err.response.data.msg);
    } else {
      emitter.emit(EVENTS.VERIFY_OTP_FAIL, 'The OTP is not valid');
    }
  }
}

function *resendOtpSaga() {
  try {
    const result = yield call(resendOtp);
    if (result.success) {
      notifySuccess();
    }
    // console.log(result);
  } catch (err) {
    if (err.response) {
      notifyError(err.response.data.msg);
    }
  }
}

function *logOutSaga(action) {
  try {
    const result = yield call(logOut, action);
    if (result.success) {
      window.location.assign('/login');
    }
    // console.log(result);
  } catch (err) {
    notifyError('Log out failed');
  }
}

function *userInfoSaga(action) {
  try {
    const result = yield call(userInfo, action);
    if (result.success) {
      const {
        user_id,
        first_name: firstname,
        last_name: lastname,
        email,
        phone_number: phone,
        avatar_url: avatar,
        role,
        org_id,
        plan,
        pwd_validity_period,
        org_pay_with,
      } = result.userInfo;
      yield put({
        type: 'GET_USER_INFO_SUCCESS',
        payload: {
          user_id,
          firstname,
          lastname,
          email,
          phone,
          avatar,
          role,
          org_id,
          plan,
          pwd_validity_period,
          org_pay_with,
        },
      });
    }
    // console.log(result);
  } catch (err) {
    notifyError('user info fail');
  }
}

function *updateUserInfoSaga(action) {
  try {
    const result = yield call(updateInfo, action);
    if (result.success) {
      yield put({
        type: 'GET_USER_INFO',
      });
      notifySuccess();
    }
  } catch (err) {
    notifyError(err.response.data.msg);
  }
}

function *updatePasswordSaga(action) {
  try {
    const result = yield call(updatePassword, action);
    if (result.success) {
      notifySuccess();
      setTimeout(() => window.location.assign('/home'), 3000);
    }
  } catch (err) {
    console.log(err);
    notifyError(err.response.data.msg);
  }
}

function *setPhoneNumberSaga(action) {
  try {
    const result = yield call(setPhoneNumber, action);
    if (result.success) {
      notifySuccess();
      window.location.assign('/otp');
    }
  } catch (err) {
    notifyError(err.response.data.msg);
  }
}

export default function *authenticationWatcher() {
  yield all([
    yield takeEvery(LOGIN, loginSaga),
    yield takeEvery(VERIFY_OTP, verifyOtpSaga),
    yield takeEvery(RESEND_OTP, resendOtpSaga),
    yield takeEvery(LOGOUT, logOutSaga),
    yield takeEvery(SET_PHONE_NUMBER, setPhoneNumberSaga),
    yield takeEvery(GET_USER_INFO, userInfoSaga),
    yield takeEvery(UPDATE_USER_INFO, updateUserInfoSaga),
    yield takeEvery(UPDATE_PASSWORD, updatePasswordSaga),
  ]);
}

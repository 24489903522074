import React, { useRef, useEffect, useState } from 'react';
import axios from 'axios';
import SkyLight from 'react-skylight';
import PropTypes from 'prop-types';
import { emitter } from '../../utils/emitter';
import { POPUP_EVENTS } from '../../constants/events';
import { renderIf } from '../../utils/components';
import CONFIG from '../../constants/config';
import { MdKeyboardBackspace, MdFolder, MdKeyboardArrowRight } from 'react-icons/md';

const FolderToFilter = ({ folderList, parentId, setParentId, setFilterFolder }) =>
{
  const parentFolder = parentId !== null ? folderList.find(f => f.folder_id === parentId) : 0;
  return (
    <>
      {renderIf(
        <>
          <div className="list-head" onClick={() => setParentId(parentFolder.parent_id)}>
            <i className="icon-arrow-left"><MdKeyboardBackspace /></i>&nbsp;&nbsp;
            <i className="icon-folder"><MdFolder /></i>&nbsp;&nbsp;
            {parentFolder.folder_name}
          </div>
        </>,
        parentFolder)}
      {renderIf(
        <>
          <div className="list-head">
            DocQuick App
          </div>
        </>,
        !parentFolder)}
      <div className="list">
        {renderIf(
          <div
            className="list-item"
            onDoubleClick={() => {
              setParentId(null);
              setFilterFolder(null);
            }}
          >
            <i className="icon-folder"><MdFolder /></i>
            &nbsp;&nbsp;&nbsp;Anywhere
          </div>,
        !parentFolder
       )}
        {folderList.length
      ? folderList
          .filter((f) => f.parent_id === parentId)
          .map((f) =>
            <div
              className="list-item"
              key={f.folder_id}
              onDoubleClick={() => {
                setParentId(f.folder_id);
                setFilterFolder(f.folder_name);
              }}
            >
              <i className="icon-folder"><MdFolder /></i>
              &nbsp;&nbsp;&nbsp;{f.folder_name}
              <span>
                <i
                  onClick={() => {
                    setParentId(f.folder_id);
                    setFilterFolder(f.folder_name);
                  }}
                  className="icon-arrow-right"
                  style={{ float: 'right' }}
                >
                  <MdKeyboardArrowRight />
                </i>
              </span>
            </div>
          )
      : null}
      </div>
      {/* <button type="button" className="list-select">Select</button> */}
    </>
); };

FolderToFilter.propTypes = {
  folderList: PropTypes.array.isRequired,
  parentId: PropTypes.number,
  setParentId: PropTypes.func.isRequired,
  setFilterFolder: PropTypes.func.isRequired,
};

export default function SearchFilterPopup({
  filter,
  setFilter,
  hanldeFilterSearchResults,
}) {
  const popupRef = useRef(null);
  const [isVisible, setIsVisible] = useState(true);
  const [showFolderList, setShowFolderList] = useState(false);
  const [folderList, setFolderList] = useState([]);
  const [parentId, setParentId] = useState(null);
  const [filterFolder, setFilterFolder] = useState(null);
  const newFolderPopupStyle = {
    width: '50%',
    // height: '50%',
    // minHeight: 400,
    top: '50%',
    left: '40%',
    marginLeft: '-15%',
    borderRadius: '15px',
  };
  function getSetFolderList() {
    axios
      .get(`${CONFIG.BASE_URL}/list-folders-for-move`, {
        withCredentials: true,
      })
      .then(({ data: { data: res } }) => {
        setFolderList(res);
      });
  }

  function hidePopup() {
    popupRef.current.hide();
    setIsVisible(false);
  }

  function handleChangeFilter(e) {
    setFilter({
      ...filter,
      [e.target.name]: e.target.value ? e.target.value : null,
    });
  }

  function applyFilterChange() {
    hanldeFilterSearchResults();
    hidePopup();
  }

  const handleBlur = () => {
    setShowFolderList(false);
    setFilter({
      ...filter,
      folder_id: parentId,
    });
  };

  const resetFilter = () => {
    setFilter({
      from_date_access: null,
      to_date_access: null,
      type: null,
      folder_id: null,
      from_date_create: null,
      to_date_create: null,
      size: null,
    });
    setFilterFolder(null);
  };

  useEffect(() => {
    const showPopup = () => {
      if (popupRef.current) popupRef.current.show();
      getSetFolderList();
    };
    function listenToTheEmitter() {
      emitter.on(POPUP_EVENTS.SHOW_SEARCH_FILTER_POPUP, showPopup);
    }
    listenToTheEmitter();

    listenToTheEmitter();
    getSetFolderList();

    return () => {
      emitter.off(POPUP_EVENTS.SHOW_SEARCH_FILTER_POPUP, showPopup);
    };
  }, []);
  return (
    <SkyLight
      dialogStyles={newFolderPopupStyle}
      ref={popupRef}
      isVisible={isVisible}
      hideOnOverlayClicked
    >
      <div className="searchFilterPopup">
        <div className='filter-title'>Filter</div>
        <div className="filter-select">
          <div className="filter--form">
            <div className="filter--form-control">
              <label htmlFor="type">Type</label>
              <select
                name="type"
                value={filter.type ? filter.type : ''}
                onChange={handleChangeFilter}
              >
                <option value="">Any</option>
                <option value="document">Document</option>
                <option value="excel">Excel</option>
                <option value="pdf">PDF</option>
                <option value="image">Image</option>
                <option value="powerpoint">Powerpoint</option>
                <option value="text">Text</option>
              </select>
            </div>
            <div className="filter--form-control">
              <label>Location</label>
              <button
                type="button"
                onBlur={handleBlur}
              >
                <span onClick={() => setShowFolderList(!showFolderList)}>
                  {filterFolder ? filterFolder : 'anywhere'}
                </span>
                <div className='menu-location' style={{ display: showFolderList ? 'flex' : 'none' }}>
                  <FolderToFilter
                    folderList={folderList}
                    parentId={parentId}
                    setParentId={setParentId}
                    setFilterFolder={setFilterFolder}
                  />
                </div>
              </button>
            </div>
            <div className="filter--form-control">
              <label htmlFor="date_access">Last modified</label>
              <div className="date-range">
                <input type="date" name="from_date_access" value={filter.from_date_access ? filter.from_date_access : 'dd/mm/yyyy'} onChange={handleChangeFilter}/>
                <input type="date" name="to_date_access" value={filter.to_date_access ? filter.to_date_access : 'dd/mm/yyyy'} onChange={handleChangeFilter}/>
              </div>
              {/* <select
                name="date_access"
                value={filter.date_access ? filter.date_access : ''}
                onChange={handleChangeFilter}
              >
                <option value="">Any time</option>
                <option value="1">Choose Date</option>
              </select> */}
            </div>
            <div className="filter--form-control">
              <label htmlFor="date_create">Created Date</label>
              <div className="date-range">
                <input type="date" name="from_date_create" value={filter.from_date_create ? filter.from_date_create : 'dd/mm/yyyy'} onChange={handleChangeFilter}/>
                <input type="date" name="to_date_create" value={filter.to_date_create ? filter.to_date_create : 'dd/mm/yyyy'} onChange={handleChangeFilter}/>
              </div>
            </div>
            <div className="filter--form-control">
              <label htmlFor="size">Size</label>
              <select
                name="size"
                value={filter.size ? filter.size : ''}
                onChange={handleChangeFilter}
              >
                <option value="0">Any Size</option>
                <option value="1">0 - 1MB</option>
                <option value="2">1MB - 10MB</option>
                <option value="3">10MB - 100MB</option>
                <option value="4">&ge; 100MB</option>
              </select>
            </div>
          </div>
          <div className="filter--button-group">
            <button type="button" onClick={resetFilter}>
              Reset
            </button>
            <button type="button" onClick={applyFilterChange}>
              Apply
            </button>
          </div>
        </div>
      </div>
    </SkyLight>
  );
}
SearchFilterPopup.propTypes = {
  filter: PropTypes.object.isRequired,
  setFilter: PropTypes.func.isRequired,
  hanldeFilterSearchResults: PropTypes.func.isRequired,
};

import React, { useRef, useEffect, useState } from 'react';
import SkyLight from 'react-skylight';
import { emitter } from '../../utils/emitter';
import { POPUP_EVENTS } from '../../constants/events';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { MdClear } from 'react-icons/md'
import axios from 'axios';
import { GoogleLogin } from 'react-google-login';
import {
  createReminder,
  deleteReminder,
  editReminder,
  selectReminder,
} from '../../redux/actions/reminders';
import {
  timeLocalToISOString,
  ISOstringToLocalTime,
  ISOStringToLocalDate,
} from '../../utils/datetime';
import { notifyError } from '../common/Alert';
import { createEventGoogleReminder, deleteEventGoogleReminder, editEventGoogleReminder } from '../../services/reminders';
import CONFIG from '../../constants/config';
// import CONFIG from '../../constants/config';

let IconsDefault = []

// const api = 'http://localhost:8000/api';

export default function CreateReminder({ info, icons }) {
  const popupRef = useRef(null);
  const newFolderPopupStyle = {
    // minHeight: 0,
    // width: '40%',
    // left: '45%',
    // marginLeft: '-15%',
    // borderRadius: '10px',
    // padding: 0,
  };
  const [defaultIcons, setDefaultIcons] = useState([]);
  const [allDay, setAllDay] = useState(false);
  const [showIcon, setShowIcon] = useState(false);
  const [showpopup, setShowpopup] = useState(false);
  const [googleUser, setGoogleUser] = useState(null);
  const [isGoogleLogin, setIsGoogleLogin] = useState(false);
  const [isGoogleLoader, setIsGoogleLoader] = useState(false);
  const [data, setData] = useState({
    icon_link: icons?.[0]?.ImageIcon?.[0]?.url ? icons?.[0]?.ImageIcon?.[0]?.url : '',
    title: '',
    start_time: '',
    end_time: '',
    detail: '',
  });
  const hiddenRadio = useRef(null);
  const reminders = useSelector((s) => s.reminders);
  const dispatch = useDispatch();

  var auth2;

  const createApi = (google_eventId) => {
    const date = info?.dateStr || moment(Date.now(), 'YYYY-MM-DD', true);
    const startDate = allDay
      ? timeLocalToISOString(moment(date).format('YYYY-MM-DD'), '23:58')
      : timeLocalToISOString(date, data.start_time);
    const endDate = allDay
      ? timeLocalToISOString(moment(date).format('YYYY-MM-DD'), '23:59')
      : timeLocalToISOString(date, data.end_time);
    dispatch(
      createReminder({
        all_day: allDay,
        icon_link: data.icon_link || icons[0]?.ImageIcon[0]?.url,
        title: data.title,
        start_time: startDate,
        end_time: endDate,
        detail: data.detail,
        google_calendar_id: google_eventId
      })
    );
  }

  const updateApi = () => {
    dispatch(
      editReminder({
        all_day: allDay,
        icon_link: data.icon_link,
        title: data.title,
        start_time: allDay
          ? timeLocalToISOString(moment(data?.start_date ? data.start_date : Date.now()).format('YYYY-MM-DD'), '23:58')
          : timeLocalToISOString(
            ISOStringToLocalDate(reminders.selected.start_time),
            data.start_time
          ),
        end_time: allDay
          ? timeLocalToISOString(moment(data?.end_date ? data.end_date : Date.now()).format('YYYY-MM-DD'), '23:59')
          : timeLocalToISOString(
            ISOStringToLocalDate(reminders.selected.start_time),
            data.end_time
          ),
        detail: data.detail,
        status: data.status,
        reminder_id: data.reminder_id,
      })
    );
  }

  useEffect(() => {
    const tokenGoogle = localStorage.getItem('authGoogle')
    if (tokenGoogle) {
      setIsGoogleLogin(true)
      const google_user = JSON.parse(tokenGoogle);
      setGoogleUser(google_user);
    } else {
      setIsGoogleLogin(false)
      setGoogleUser(null);
    }
  }, [])


  useEffect(() => {
    if (icons && icons.length > 0) {
      setData({ ...data, icon_link: icons?.[0]?.ImageIcon?.[0]?.url ? icons?.[0]?.ImageIcon?.[0]?.url : '' })
    }
  }, [icons])

  useEffect(() => {
    axios({
      url: 'https://cms.docquik.com/icons',
      method: 'GET',
    })
      .then(({ data }) => {
        setDefaultIcons(data);
        IconsDefault = data;
      })
      .catch(() => { });
  }, [])

  const showPopup = () => {
    if (popupRef.current) {
      popupRef.current.show();
      setShowpopup(true)
    }
  };
  const handleChange = (name) => (e) => {
    setData({ ...data, [name]: e.target.value });
  };
  const handleDelete = (id) => {
    if (data?.google_calendar_id) {
      const formData = {
        access_token: googleUser.access_token,
        google_calendar_id: data?.google_calendar_id
      }
      deleteEventGoogleReminder(formData)
        .then((res) => {
          console.log(res);
          // const data = res.data;
          if (res.status_code === 200) {
            dispatch(deleteReminder(id));
          }
        })
        .catch((err) => {
          notifyError(err?.message);
          localStorage.removeItem('authGoogle');
          setGoogleUser(null);
          setIsGoogleLogin(false)
        })
    } else {
      dispatch(deleteReminder(id));
    }
  };
  const handleSubmit = () => {
    if (Object.keys(reminders.selected).length > 0) {
      if (data.google_calendar_id) {
        saveWithGoogle();
      } else {
        dispatch(
          editReminder({
            all_day: allDay,
            icon_link: data.icon_link,
            title: data.title,
            start_time: allDay
              ? timeLocalToISOString(moment(data?.start_date ? data.start_date : Date.now()).format('YYYY-MM-DD'), '23:58')
              : timeLocalToISOString(
                ISOStringToLocalDate(reminders.selected.start_time),
                data.start_time
              ),
            end_time: allDay
              ? timeLocalToISOString(moment(data?.end_date ? data.end_date : Date.now()).format('YYYY-MM-DD'), '23:59')
              : timeLocalToISOString(
                ISOStringToLocalDate(reminders.selected.start_time),
                data.end_time
              ),
            detail: data.detail,
            status: data.status,
            reminder_id: data.reminder_id,
          })
        );
      }
    } else {
      const date = info?.dateStr || moment(Date.now(), 'YYYY-MM-DD', true);
      const startDate = allDay
        ? timeLocalToISOString(moment(date).format('YYYY-MM-DD'), '23:58')
        : timeLocalToISOString(date, data.start_time);
      const endDate = allDay
        ? timeLocalToISOString(moment(date).format('YYYY-MM-DD'), '23:59')
        : timeLocalToISOString(date, data.end_time);
      dispatch(
        createReminder({
          all_day: allDay,
          icon_link: data.icon_link || icons[0]?.ImageIcon[0]?.url,
          title: data.title,
          start_time: startDate,
          end_time: endDate,
          detail: data.detail
        })
      );
    }
    setData({
      icon_link: '',
      title: '',
      start_time: '',
      end_time: '',
      detail: '',
      reminder_id: '',
      status: '',
    });
    setAllDay(false);
    setShowIcon(false);
  };
  function listenToTheEmitter() {
    emitter.on(POPUP_EVENTS.CREATE_REMINDER, showPopup);
  }

  const saveWithGoogle = () => {
    setIsGoogleLoader(true);

    const tokenGoogle = localStorage.getItem('authGoogle');
    if (tokenGoogle || isGoogleLogin) {
      const google_user = JSON.parse(tokenGoogle);
      setGoogleUser(google_user);

      var date = info?.dateStr || new Date();
      const startDate = allDay
        ? moment(`${date} 0:00`) // timeLocalToISOString(date, '0:00')
        : moment(`${date} ${data.start_time}`) // timeLocalToISOString(date, data.start_time);
      const endDate = allDay
        ? moment(`${date} 23:59`) // timeLocalToISOString(date, '23:59')
        : moment(`${date} ${data.end_time}`) // timeLocalToISOString(date, data.end_time);
      // console.log(new Date(`${date} 23:58`))
      // console.log(new Date(`${date} ${data.start_time}`))
      if (Object.keys(reminders.selected).length) {
        const formData = {
          access_token: google_user.access_token,
          summary: data.title,
          startDate: startDate,
          endDate: endDate,
          description: data.detail,
          google_calendar_id: data?.google_calendar_id
        }
        // console.log(formDa)
        editEventGoogleReminder(formData).then(res => {
          console.log(res);
          const data = res.data;
          if (res.status_code === 200) {
            updateApi(data.id);
            setData({
              icon_link: '',
              title: '',
              start_time: '',
              end_time: '',
              detail: '',
              reminder_id: '',
              status: '',
            });
            setAllDay(false);
            setShowIcon(false);
            setIsGoogleLoader(false)
            popupRef.current.hide();
            setShowpopup(false)
          }
        }).catch(err => {
          console.log(err.message)
          notifyError(err.message);
          setIsGoogleLoader(false);
          localStorage.removeItem('authGoogle');
          setGoogleUser(null);
          setIsGoogleLogin(false)
        });
      } else {
        const formData = {
          access_token: google_user.access_token,
          summary: data.title,
          startDate: startDate,
          endDate: endDate,
          description: data.detail
        }
        // console.log(formDa)
        createEventGoogleReminder(formData).then(res => {
          // console.log(res);
          const data = res.data;
          if (res.status_code === 200) {
            createApi(data.id);
            setData({
              icon_link: '',
              title: '',
              start_time: '',
              end_time: '',
              detail: '',
              reminder_id: '',
              status: '',
            });
            setAllDay(false);
            setShowIcon(false);
            setIsGoogleLoader(false)
            popupRef.current.hide();
            setShowpopup(false)
          }
        }).catch(err => {
          console.log(err.message)
          notifyError(err.message);
          setIsGoogleLoader(false);
          localStorage.removeItem('authGoogle');
          setGoogleUser(null);
          setIsGoogleLogin(false)
        });
      }

    } else {
      setIsGoogleLoader(false);
      setIsGoogleLogin(false);
      localStorage.removeItem('authGoogle');
      setGoogleUser(null);
      setIsGoogleLogin(false)
    }

  }



  // const initSigninV2 = () => {
  //   const auth = window.gapi.auth2.init({
  //     clientId: "868503150682-3te1sn6qietfdc2rca0kbacqol6lpfm1.apps.googleusercontent.com",
  //     // apiKey: "AIzaSyD-awk30fDM1YGsNFRlKOV_SjDNN5iqcmA",
  //     scope: "openid email profile https://www.googleapis.com/auth/calendar",

  //     // scope: "https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events",
  //     // discoveryDocs: ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"]
  //     // client_id: CONFIG.CALENDER_CLIENT_ID,
  //     // scope: CONFIG.SCOPES
  //   });

  //   // Listen for sign-in state changes.
  //   auth.isSignedIn.listen((val) => {
  //     setIsGoogleLogin(val);
  //   });

  //   // Listen for changes to current user.
  //   auth.currentUser.listen((user) => {
  //     // console.log(user);
  //     const google_user = user.getAuthResponse();
  //     console.log(user.getRefreshToken());

  //     setIsGoogleLoader(false)
  //     setIsGoogleLogin(true);
  //     localStorage.setItem('authGoogle', JSON.stringify(google_user))
  //     setGoogleUser(google_user);
  //     const date = info?.dateStr || moment(Date.now(), 'YYYY-MM-DD', true);
  //     const startDate = allDay
  //       ? timeLocalToISOString(moment(date).format('YYYY-MM-DD'), '23:58')
  //       : timeLocalToISOString(date, data.start_time);
  //     const endDate = allDay
  //       ? timeLocalToISOString(moment(date).format('YYYY-MM-DD'), '23:59')
  //       : timeLocalToISOString(date, data.end_time);
  //     const formData = {
  //       access_token: google_user.access_token,
  //       summary: data.title,
  //       startDate: moment(startDate).format('YYYY-MM-DDThh:mm:ss+00:00'),
  //       endDate: moment(endDate).format('YYYY-MM-DDThh:mm:ss+00:00'),
  //       description: data.detail
  //     }
  //     axios({
  //       url: `${api}/insert`,
  //       method: 'post',
  //       data: formData
  //     }).then(res => {
  //       console.log(res);
  //     })
  //     // createApi();
  //   });

  //   // Sign in the user if they are currently signed in.
  //   if (auth.isSignedIn.get() == true) {
  //     auth.signIn();
  //   }

  //   auth2 = auth;
  //   // Start with the current live values.
  //   // refreshValues();
  // };

  useEffect(() => {
    setData({
      icon_link: '',
      title: '',
      start_time: '',
      end_time: '',
      detail: '',
      reminder_id: '',
      status: '',
    });
    setAllDay(false);
    setShowIcon(false);
  }, [reminders.todo]);
  useEffect(() => {
    // console.log(reminders.selected);
    setData({
      icon_link: reminders.selected.icon_link,
      title: reminders.selected.title,
      start_time: ISOstringToLocalTime(reminders.selected.start_time),
      end_time: ISOstringToLocalTime(reminders.selected.end_time),
      detail: reminders.selected.detail,
      reminder_id: reminders.selected.reminder_id,
      status: reminders.selected.status,
      google_calendar_id: reminders.selected.google_calendar_id,
      start_date: ISOStringToLocalDate(reminders.selected.start_time),
      end_date: ISOStringToLocalDate(reminders.selected.end_time)
    });
    setAllDay(reminders.selected.all_day || false);
  }, [reminders.selected]);

  // useEffect(() => {
  //   if (popupRef && popupRef.current && popupRef.current.state) {
  //     if (!popupRef.current.state.isVisible) {
  //       setShowpopup(false)
  //     }
  //   }
  // })

  const responseGoogle = (response) => {
    // console.log(response);
    const google_user = response.getAuthResponse()
    localStorage.setItem('authGoogle', JSON.stringify(google_user))
    setIsGoogleLogin(true);
    setGoogleUser(google_user);
    var date = info?.dateStr || new Date();
    const startDate = allDay
      ? moment(`${date} 0:00`) // timeLocalToISOString(date, '0:00')
      : moment(`${date} ${data.start_time}`) // timeLocalToISOString(date, data.start_time);
    const endDate = allDay
      ? moment(`${date} 23:59`) // timeLocalToISOString(date, '23:59')
      : moment(`${date} ${data.end_time}`) // timeLocalToISOString(date, data.end_time);

    const formData = {
      access_token: google_user.access_token,
      summary: data.title,
      startDate: startDate,
      endDate: endDate,
      description: data.detail
    }
    // console.log(formDa)
    createEventGoogleReminder(formData).then(res => {
      // console.log(res);
      const data = res.data;
      if (res.status_code === 200) {
        createApi(data.id);
        setData({
          icon_link: '',
          title: '',
          start_time: '',
          end_time: '',
          detail: '',
          reminder_id: '',
          status: '',
        });
        setAllDay(false);
        setShowIcon(false);
        popupRef.current.hide();
        setShowpopup(false)
      } else {
        notifyError(response.data);
      }
      // setIsGoogleLogin(false);
      // console.log(res);
    }).catch(err => {
      console.log(err.message)
      notifyError(err.message);
      localStorage.removeItem('authGoogle');
      setGoogleUser(null);
      setIsGoogleLogin(false)
    });

    // const { code } = response;
    // axios.post(`${api}/create-token`, { code })
    //   .then(response => {
    //     console.log(response.data);
    //   })
    //   .catch(error => console.log(error.message))
  }

  const editResponseGoogle = (response) => {

    const google_user = response.getAuthResponse();
    localStorage.setItem('authGoogle', JSON.stringify(google_user))
    setIsGoogleLogin(true);
    setGoogleUser(google_user);
    var date = info?.dateStr || new Date();
    const startDate = allDay
      ? moment(`${date} 0:00`) // timeLocalToISOString(date, '0:00')
      : moment(`${date} ${data.start_time}`) // timeLocalToISOString(date, data.start_time);
    const endDate = allDay
      ? moment(`${date} 23:59`) // timeLocalToISOString(date, '23:59')
      : moment(`${date} ${data.end_time}`) // timeLocalToISOString(date, data.end_time);

    const formData = {
      access_token: google_user.access_token,
      summary: data.title,
      startDate: startDate,
      endDate: endDate,
      description: data.detail,
      google_calendar_id: data?.google_calendar_id
    }
    // console.log(formDa)
    editEventGoogleReminder(formData).then(res => {
      console.log(res);
      const data = res.data;
      if (res.status_code === 200) {
        updateApi(data.id);
        setData({
          icon_link: '',
          title: '',
          start_time: '',
          end_time: '',
          detail: '',
          reminder_id: '',
          status: '',
        });
        setAllDay(false);
        setShowIcon(false);
        setIsGoogleLoader(false)
        popupRef.current.hide();
        setShowpopup(false)
      }
    }).catch(err => {
      console.log(err.message)
      notifyError(err.message);
      setIsGoogleLoader(false);
      localStorage.removeItem('authGoogle');
      setGoogleUser(null);
      setIsGoogleLogin(false)
    });
  }

  const deleteResponseGoogle = (response) => {
    const google_user = response.getAuthResponse();
    localStorage.setItem('authGoogle', JSON.stringify(google_user))
    setIsGoogleLogin(true);
    setGoogleUser(google_user);
    var date = info?.dateStr || new Date();
    const startDate = allDay
      ? moment(`${date} 0:00`) // timeLocalToISOString(date, '0:00')
      : moment(`${date} ${data.start_time}`) // timeLocalToISOString(date, data.start_time);
    const endDate = allDay
      ? moment(`${date} 23:59`) // timeLocalToISOString(date, '23:59')
      : moment(`${date} ${data.end_time}`) // timeLocalToISOString(date, data.end_time);

    const formData = {
      access_token: google_user.access_token,
      google_calendar_id: data?.google_calendar_id
    }
    // console.log(formDa)
    deleteEventGoogleReminder(formData)
      .then((res) => {
        if (res.status_code === 200) {
          dispatch(deleteReminder(reminders && reminders.selected.reminder_id));
        }
      })
      .catch((err) => {
        console.log(err.message)
        notifyError(err.message);
        setIsGoogleLoader(false);
        localStorage.removeItem('authGoogle');
        setGoogleUser(null);
        setIsGoogleLogin(false)
      })

  }

  const responseGoogleError = (error) => {
    console.log(error)
    setIsGoogleLoader(false);
    notifyError(error.message);
    localStorage.removeItem('authGoogle');
    setGoogleUser(null);
    setIsGoogleLogin(false)
  }

  useEffect(() => {
    listenToTheEmitter();
    return () => {
      emitter.off(POPUP_EVENTS.CREATE_REMINDER, showPopup);
      // setShowpopup(false)
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderGoogleButton = (type, className) => {
    let buttonText = "";
    switch (type) {
      case 'save':
        buttonText = "Save with Google";
        break;
      case 'edit':
        buttonText = "Edit with Google";
        break;
      case 'delete':
        buttonText = "Delete with Google";
        break;
      default:
        buttonText = "Save with Google";
        break;
    }
    return <GoogleLogin
      clientId={CONFIG.GOOGLE_CLIENT_ID}
      // clientId="868503150682-3te1sn6qietfdc2rca0kbacqol6lpfm1.apps.googleusercontent.com"
      // clientId="868503150682-3te1sn6qietfdc2rca0kbacqol6lpfm1.apps.googleusercontent.com"
      render={renderProps => (
        <button
          type="button"
          className={className}
          // onClick={saveWithGoogle}
          onClick={renderProps.onClick} disabled={renderProps.disabled || isGoogleLoader}
        >
          {isGoogleLoader ? 'Loading...' : buttonText}
        </button>
      )}
      buttonText={buttonText}
      onSuccess={type === 'save' ? responseGoogle : type === 'edit' ? editResponseGoogle : type === 'delete' ? deleteResponseGoogle : null}
      onFailure={responseGoogleError}
      cookiePolicy={'single_host_origin'}
      scope='openid email profile https://www.googleapis.com/auth/calendar'
    // responseType='code'
    />
  }

  return (
    <SkyLight
      dialogStyles={newFolderPopupStyle}
      ref={popupRef}
      // hideOnOverlayClicked
      afterClose={() => dispatch(selectReminder({}))}
      className={`reminder-modal-popup ${showpopup ? 'show' : ''}`}
    >
      <div className="create-reminder">
        <div className="create-reminder__title">
          <h3>{reminders && Object.keys(reminders?.selected)?.length ? 'Edit' : 'Create'} Reminder</h3>
          <button type='button' className='btn-clear-modal' onClick={() => { popupRef.current.hide(); setShowpopup(false) }}><MdClear /></button>
        </div>
        <div className="reminder-form">
          <div className="create-reminder__input">
            <div className='top-form-title'>
              <div className="icon-select">
                <div
                  className="icon-select__icon"
                  onClick={() => setShowIcon(!showIcon)}

                >
                  <img alt="" src={data.icon_link || icons[0]?.ImageIcon[0]?.url} />
                </div>
                <div
                  className="icons"
                  style={{ display: showIcon ? '' : 'none' }}
                >
                  {icons.map((i, index) => (
                    <div key={i.id} className={`icon-select__icon_sub ${data.icon_link === i.ImageIcon[0]?.url ? 'active' : ''}`}>
                      <img
                        alt=""
                        src={i.ImageIcon[0]?.url}
                        onClick={() => {
                          setData({ ...data, icon_link: i.ImageIcon[0]?.url });
                          setShowIcon(false);
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>
              <div className='title-reminder-create'>
                <h4>{data.title || 'Your Title'}</h4>
              </div>

            </div>
            <div className="time-containter">
              <input
                type={"text"}
                placeholder="Your Title"
                className='title-control'
                value={data.title}
                onChange={handleChange('title')}
              />
            </div>

          </div>
          <div className="date-time-container">
            <div className="time-containter-time">
              <label>Start Time</label>
              <input
                placeholder="Time Start"
                type="time"
                className='title-control'
                disabled={allDay}
                value={allDay ? '' : data.start_time}
                onChange={handleChange('start_time')}
                step={60}
              />
            </div>
            <div className="time-containter-time">
              <label>End Time</label>
              <input
                placeholder="Time End"
                type="time"
                className='title-control'
                disabled={allDay}
                value={allDay ? '' : data.end_time}
                onChange={handleChange('end_time')}
                step={60}
              />
            </div>
          </div>
          <div className='radio-wraps-reminder'>
            <input
              type="radio"
              name="allday"
              checked={allDay}
              id="allday"
              onClick={() => {
                setAllDay(!allDay);
                // if (allDay) hiddenRadio.current.click();
              }}
            />
            {/* <input
              type="radio"
              name="allday"
              ref={hiddenRadio}
              style={{ position: 'absolute', top: '-10000px' }}
              id="allday"
            /> */}
            <label htmlFor="allday">All day</label>
          </div>
          <div className="create-reminder__textarea">
            <label>Detail</label>
            <textarea
              rows={5}
              value={data.detail}
              className='title-control'
              onChange={handleChange('detail')}
            />
          </div>
        </div>
        <div className="create-reminder__btns">
          <button
            type='button'
            className='btn-cancel'
            onClick={() => {
              popupRef.current.hide();
              setShowpopup(false)
            }}>Cancel</button>
          {reminders && Object.keys(reminders.selected).length ? (
            isGoogleLogin ?
              <button
                type="button"
                className="delete"
                onClick={() => {
                  handleDelete(reminders && reminders.selected.reminder_id);
                  popupRef.current.hide();
                  setShowpopup(false)
                }}
              >
                Delete
              </button>
              :
              renderGoogleButton('delete', 'delete')
          ) : null}
          {Object.keys(reminders?.selected)?.length ?
            isGoogleLogin ?
              <button
                type="button"
                className='create'
                onClick={() => {
                  handleSubmit();
                  popupRef.current.hide();
                  setShowpopup(false)
                }}
              >
                Edit
              </button>
              :
              renderGoogleButton('edit', 'create')
            :
            <button
              type="button"
              className='create'
              onClick={() => {
                handleSubmit();
                popupRef.current.hide();
                setShowpopup(false)
              }}
            >
              Create
            </button>
          }
          {Object.keys(reminders?.selected)?.length === 0 || !isGoogleLogin ?
            isGoogleLogin ?
              <button type="button"
                className='btn-secondary'
                // onClick={saveWithGoogle}
                onClick={saveWithGoogle} disabled={isGoogleLoader}
              >
                {isGoogleLoader ? 'Loading...' : 'Save with Google'}
              </button>
              :
              renderGoogleButton('save', 'btn-secondary')

            :
            null
          }
        </div>
      </div>
    </SkyLight>
  );
}
CreateReminder.propTypes = {
  info: PropTypes.object,
  icons: PropTypes.array,
};

CreateReminder.defaultProps = {
  info: { date: Date.now(), dateStr: moment(Date.now()).format('YYYY-MM-DD') },
  icons: IconsDefault
}

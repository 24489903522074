import React from 'react';
import FileTable from './File.Table';
import noFile from '../../assets/images/nofile.png'


export default function SearchResult({
    searchResults,
    isSearching,
    handleFileContextMenu
}) {

    return (
        <div className="search-result-section main-body no-file">
            {isSearching ? (
                <div className="main-body no-file">
                    <img src={noFile} alt="" />
                    <p>Your files are searching...</p>
                </div>
            ) : (
                <div className='main-body file-recent search-result'>

                    <div className="Search-result-title-wrap">
                        <h3 className="Search-title">Search Result</h3>
                        <h3 className="searched-result"> {searchResults.length} Search Result</h3>
                    </div>
                    {isSearching ?
                        'loading'
                        : (
                            <FileTable
                                rows={searchResults}
                                rowLimit={searchResults.length}
                                handleFileContextMenu={handleFileContextMenu}
                            />
                        )
                    }
                </div>
            )}
        </div>

    )
}

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getFileAnalysis } from '../../../redux/actions/analysis';
import Analysis from './Analysis';
import AnalysisByDate from './AnalysisByDate';
import AnalysisByDateAll from './AnalysisByDateAll';
import UsageBarChart from './UsageBarChart';
import UsageStackedChart from './UsageStackedChart';
const unitOfTimes = ['days', 'weeks', 'months', 'months', 'months'];
const times = [7, 2, 1, 3, 6];
export default function UsageStats() {
  const dispatch = useDispatch();
  const [time1, setTime1] = useState(0);
  const [time2, setTime2] = useState(0);
  useEffect(() => {
    dispatch(getFileAnalysis());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className='mt-4'>
      <div className="row">
        <div className="col-sm-6 col-xs-12">
          <UsageStackedChart />
        </div>
        <div className="col-sm-6 col-xs-12">
          <Analysis />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6 col-xs-12" style={{ position: 'relative' }}>
          <div className='chart-card'>
            <AnalysisByDate bydate="created_at" unit_of_time={unitOfTimes[time1]} time={times[time1]} />
            <div className="analysis-buttons mt-4">
              <button type="button" onClick={() => setTime1(4)} className={`analysis-button ${time1 === 4 ? 'active' : undefined}`}>6 Months</button>
              <button type="button" onClick={() => setTime1(3)} className={`analysis-button ${time1 === 3 ? 'active' : undefined}`}>3 Months</button>
              <button type="button" onClick={() => setTime1(2)} className={`analysis-button ${time1 === 2 ? 'active' : undefined}`}>1 Months</button>
              <button type="button" onClick={() => setTime1(1)} className={`analysis-button ${time1 === 1 ? 'active' : undefined}`}>2 Weeks</button>
              <button type="button" onClick={() => setTime1(0)} className={`analysis-button ${time1 === 0 ? 'active' : undefined}`}>Last 7 days</button>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-xs-12" style={{ position: 'relative' }}>
          <div className='chart-card'>
            <AnalysisByDate bydate="updated_at" unit_of_time={unitOfTimes[time2]} time={times[time2]} />
            <div className="analysis-buttons mt-4">
              <button type="button" onClick={() => setTime2(4)} className={`analysis-button ${time2 === 4 ? 'active' : undefined}`}>6 Months</button>
              <button type="button" onClick={() => setTime2(3)} className={`analysis-button ${time2 === 3 ? 'active' : undefined}`}>3 Months</button>
              <button type="button" onClick={() => setTime2(2)} className={`analysis-button ${time2 === 2 ? 'active' : undefined}`}>1 Months</button>
              <button type="button" onClick={() => setTime2(1)} className={`analysis-button ${time2 === 1 ? 'active' : undefined}`}>2 Weeks</button>
              <button type="button" onClick={() => setTime2(0)} className={`analysis-button ${time2 === 0 ? 'active' : undefined}`}>Last 7 days</button>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6 col-xs-12">
          <AnalysisByDateAll />
        </div>
        <div className="col-sm-6 col-xs-12">
          <UsageBarChart />
        </div>
      </div>
    </div>
  );
}

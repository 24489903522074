import React, { useRef, useEffect, useState } from 'react';
import axios from 'axios';
import SkyLight from 'react-skylight';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight, MdFolder } from 'react-icons/md';
import PropTypes from 'prop-types';
import { emitter } from '../../utils/emitter';
import { POPUP_EVENTS } from '../../constants/events';
import { renderIf } from '../../utils/components';
import CONFIG from '../../constants/config';
import { useDispatch } from 'react-redux';
import { moveDocs } from '../../redux/actions/documents';
const FolderList = ({
  source,
  parentId,
  setParentId,
  newParentId,
  setNewParentId,
}) => {
  const parentFolder =
    parentId !== null ? source.find((f) => f.folder_id === parentId) : 0;
  return (
    <div
      style={{ flex: 2, height: 'calc(100% - 130px)', marginBottom: '6rem' }}
    >
      {renderIf(
        <>
          <div
            className="move-folder-list--head"
            onClick={() => setParentId(parentFolder.parent_id)}
          >
            <i className="icon-arrow-left"><MdKeyboardArrowLeft /></i>
            &nbsp;&nbsp;
            <i className="icon-folder"><MdFolder /></i>
            &nbsp;&nbsp;
            {parentFolder.folder_name}
          </div>
        </>,
        parentFolder
      )}
      {renderIf(
        <>
          <div className="move-folder-list--head" />
        </>,
        !parentFolder
      )}
      <div style={{ overflow: 'auto', height: '100%' }}>
        {source
          .filter((f) => f.parent_id === parentId)
          .map((f) => (
            <div
              className={`move-folder-list--body ${f.folder_id === newParentId ? 'is-active' : null
                }`}
              key={f.folder_id}
              onDoubleClick={() => {
                setParentId(f.folder_id);
              }}
              onClick={() => {
                setNewParentId(f.folder_id);
              }}
            >
              <i className="icon-folder"><MdFolder /></i>
              &nbsp;&nbsp;&nbsp;{f.folder_name}
              <span>
                <i
                  onClick={() => {
                    setParentId(f.folder_id);
                  }}
                  className="icon-arrow-right"
                  style={{ float: 'right' }}
                >
                  <MdKeyboardArrowRight />
                </i>
              </span>
            </div>
          ))}
      </div>
    </div>
  );
};
FolderList.propTypes = {
  source: PropTypes.array.isRequired,
  parentId: PropTypes.number,
  setParentId: PropTypes.func.isRequired,
  newParentId: PropTypes.number,
  setNewParentId: PropTypes.func.isRequired,
};

export default function MovePopup() {
  const popupRef = useRef(null);
  const [isVisible, setIsVisible] = useState(true);
  const [folderId, setFolderId] = useState(null);
  const [fileId, setFileId] = useState(null);
  const [folderList, setFolderList] = useState([]);
  const [parentId, setParentId] = useState(null);
  const [newParentId, setNewParentId] = useState(null);
  const newFolderPopupStyle = {
    width: '30%',
    // height: '50%',
    minHeight: 0,
    marginLeft: '-15%',
    borderRadius: '10px',
  };
  const dispatch = useDispatch();
  function getSetFolderList() {
    axios
      .get(`${CONFIG.BASE_URL}/list-folders-for-move`, {
        withCredentials: true,
      })
      .then(({ data: { data: res } }) => {
        setFolderList(res);
      });
  }
  function hidePopup() {
    popupRef.current.hide();
    setFolderId(null);
    setFileId(null);
    setParentId(null);
    setNewParentId(null);
    setIsVisible(false);
  }

  const handleMove = () => {
    dispatch(moveDocs(fileId, folderId, newParentId));
    hidePopup();
  };

  useEffect(() => {
    const showPopup = (fileIdParam, folderIdParam) => {
      if (popupRef.current) popupRef.current.show();
      getSetFolderList();
      setFileId(fileIdParam);
      setFolderId(folderIdParam);
    };
    function listenToTheEmitter() {
      emitter.on(POPUP_EVENTS.SHOW_MOVE_FILE_OR_FOLDER, showPopup);
    }
    listenToTheEmitter();
    return () => {
      emitter.off(POPUP_EVENTS.SHOW_MOVE_FILE_OR_FOLDER, showPopup);
    };
  }, []);

  return (
    <SkyLight
      dialogStyles={newFolderPopupStyle}
      ref={popupRef}
      isVisible={isVisible}
    >
      <div className="movePopup">
        <div className="movePopup--title">
          Move {fileId ? 'file' : 'folder'}
        </div>
        {renderIf(
          <FolderList
            parentId={parentId}
            setParentId={setParentId}
            source={folderList.filter((f) => f.folder_id !== folderId)}
            newParentId={newParentId}
            setNewParentId={setNewParentId}
          />,
          folderList.length
        )}
        {renderIf(
          <div
            style={{
              flex: 2,
              height: 'calc(100% - 130px)',
              marginBottom: '6rem',
            }}
          />,
          !folderList.length
        )}
        <div>
          <button onClick={hidePopup} type="button">
            Cancel
          </button>
          <button onClick={handleMove} type="button">
            Move {fileId ? 'file' : 'folder'}
          </button>
        </div>
      </div>
    </SkyLight>
  );
}


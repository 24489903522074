import React from 'react';
import PropTypes from 'prop-types';
// import { Row, Col } from 'react-bootstrap'
// import File from './File';
// import FileTable from './File.Table';
// import FolderItem from './Folder.Item';
import QuickAccess from './QuickAccess';
// import { useSelector } from 'react-redux';
// import { useHistory } from 'react-router-dom';
// import FilesComponent from '../FilesComponent';

export default function Dashboard({
    handleFileContextMenu,
    handleClick,
    files,
    folders,
    handleDrop,
    handleDragOver,
    handleFolderContextMenu,
    drop,
    allowDrop,
    dragStart,
    handleCreateFolders,
    handlePasteCopiedFile,
    onSelect,
    selectedFiles
}) {
    // const { accessed: recentlyAccessed, isLoading } = useSelector(
    //     (s) => s.quickAccess
    // );
    // const [folderCount, setFolderCount] = useState(6);
    // const history = useHistory();

    // const showFolderToggle = () => {
    //     setFolderCount(folderCount > 6 ? 6 : Array.isArray(folders) ? folders.length : 6)
    // }

    return (
        <div
            className="main-body"
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            tabIndex="-1"
            onKeyDown={(e) => {
                if (e.key === 'v' && e.ctrlKey) {
                    handlePasteCopiedFile();
                }
            }}
            onContextMenu={(e) => {
                if (
                    [
                        'main-body',
                        'mainNav',
                        'recently-access',
                        'folders',
                        'folders__title',
                        'folders__items',
                        'files',
                        // 'files-wraps',
                        'files__title',
                        'files__items',
                        'file-recent',
                        'folders__title',
                    ].includes(e.target.className)
                ) {
                    handleCreateFolders(e);
                }
            }}
        >
            <QuickAccess
                handleFileContextMenu={handleFileContextMenu}
                handleFolderContextMenu={handleFolderContextMenu}
                dragStart={dragStart}
                onDrop={drop}
                onDragOver={allowDrop}
                onSelect={onSelect}
                selectedFiles={selectedFiles}
            />
            {/* {folders.length ? (
                <div className="folders tab-panel-custom pt-0">
                    <div className="d-flex align-items-start justify-content-between mt-4">
                        <h3 className="file-title folders__title">Folder</h3>
                        <button className="btn-outline-primary" onClick={showFolderToggle}>{folderCount > 6 ? 'Less More' : 'Show all'}</button>
                    </div>
                    <div className='folders__items'>
                        {folders.map((item, i) => (
                            <FolderItem
                                key={item.folder_id}
                                folder={item}
                                handleClick={handleClick}
                                handleFolderContextMenu={handleFolderContextMenu}
                                dragStart={dragStart}
                                onDrop={drop}
                                onDragOver={allowDrop}
                            />
                        ))}
                    </div>
                </div>
            ) : null} */}
            {/* {files.length ? (
                <div className="files">
                    <div className="files__title">Files</div>
                    <Row>
                        {files.map((item, index) => (
                            <Col xs={12} sm={6} key={index}>
                                <FilesComponent
                                    key={item.file_id}
                                    handleFileContextMenu={(e) => handleFileContextMenu(e, item)}
                                    id={index + 10}
                                    file={item}
                                    drop={drop}
                                    allowDrop={allowDrop}
                                    dragStart={dragStart}
                                />
                            </Col>
                        ))}
                    </Row>
                </div>
            ) : null} */}
        </div>
    );
}

Dashboard.propTypes = {
    handleFileContextMenu: PropTypes.func.isRequired,
    handleClick: PropTypes.func.isRequired,
    folders: PropTypes.array.isRequired,
    files: PropTypes.array.isRequired,
    handleDrop: PropTypes.func.isRequired,
    handleDragOver: PropTypes.func.isRequired,
    handleFolderContextMenu: PropTypes.func.isRequired,
    drop: PropTypes.func.isRequired,
    allowDrop: PropTypes.func.isRequired,
    dragStart: PropTypes.func.isRequired,
    handleCreateFolders: PropTypes.func.isRequired,
    handlePasteCopiedFile: PropTypes.func.isRequired,
};

export const PINBAR_OPEN = 'PINBAR_OPEN';
export const PINBAR_CLOSE = 'PINBAR_CLOSE';
export const PINBAR_STICKY = 'PINBAR_STICKY';

export const openPinbar = () => ({
    type: PINBAR_OPEN
});
export const stickyPinbar = () => ({
    type: PINBAR_STICKY
});
export const closePinbar = () => ({
    type: PINBAR_CLOSE
});
export const ADD_fOLDER = 'ADD_fOLDER';
export const ADD_fOLDER_SUCCESS = 'ADD_fOLDER_SUCCESS';
export const ADD_fOLDER_FAILURE = 'ADD_fOLDER_FAILURE';
export const RENAME_FOLDER = 'RENAME_FOLDER';
export const RENAME_FOLDER_SUCCESS = 'RENAME_FOLDER_SUCCESS';
export const RENAME_FOLDER_FAILURE = 'RENAME_FOLDER_FAILURE';
export const MOVE_FOLDER_SUCCESS = 'MOVE_FOLDER_SUCCESS';
export const MOVE_FOLDER_FAILURE = 'MOVE_FOLDER_FAILURE';
export const DELETE_FOLDER = 'DELETE_FOLDER';
export const DELETE_FOLDER_SUCCESS = 'DELETE_FOLDER_SUCCESS';
export const DELETE_FOLDER_FAILURE = 'DELETE_FOLDER_FAILURE';
export const CHECK_FOLDER_FAVORITE = 'CHECK_FOLDER_FAVORITE';
export const CHECK_FOLDER_FAVORITE_SUCCESS = 'CHECK_FOLDER_FAVORITE_SUCCESS';
export const CHECK_FOLDER_FAVORITE_FAILURE = 'CHECK_FOLDER_FAVORITE_FAILURE';

export const addFolder = (name) => ({
  type: ADD_fOLDER,
  payload: name,
});

export const addFolderSuccess = () => ({
  type: ADD_fOLDER_SUCCESS,
});

export const addFolderFailure = () => ({
  type: ADD_fOLDER_FAILURE,
});

export const renameFolder = (folderId, newName) => ({
  type: RENAME_FOLDER,
  payload: {
    folderId,
    newName,
  },
});

export const renameFolderSuccess = () => ({
  type: RENAME_FOLDER_SUCCESS,
});

export const renameFolderFailure = () => ({
  type: RENAME_FOLDER_FAILURE,
});

export const checkFolderFavorite = (folderId, isFavorite, folder) => ({
  type: CHECK_FOLDER_FAVORITE,
  payload: {
    folderId,
    isFavorite,
    folder,
  },
});

export const moveFolderSuccess = () => ({
  type: MOVE_FOLDER_SUCCESS,
});

export const moveFolderFailure = () => ({
  type: MOVE_FOLDER_FAILURE,
});

export const deleteFolder = (folderId) => ({
  type: DELETE_FOLDER,
  payload: folderId,
});

export const deleteFolderSuccess = () => ({
  type: DELETE_FOLDER_SUCCESS,
});

export const deleteFolderFailure = () => ({
  type: DELETE_FOLDER_FAILURE,
});

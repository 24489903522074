import { all, call, put, takeEvery } from 'redux-saga/effects';
import { POPUP_EVENTS } from '../constants/events';
import {
  CHECK_IN_SELECTED_TASK,
    CHECK_IN_SELECTED_TASK_SUCCESS,
  CHECK_OUT_SELECTED_TASK,
  CHECK_OUT_SELECTED_TASK_SUCCESS,
  GET_TASKS_BY_PROJECT_ID,
  GET_TASKS_BY_PROJECT_ID_FAILURE,
  GET_TASKS_BY_PROJECT_ID_SUCCESS,
  VERIFY_CHECKOUT_TIME,
} from '../redux/actions/tasks';
import {
  checkInTask,
  checkOutTask,
  getTasksByProjectId,
  verifyCheckOutTime,
} from '../services/project';
import { emitter } from '../utils/emitter';

function *getTasksByProjectIdSaga({ payload }) {
  try {
    const { success, tasks } = yield call(getTasksByProjectId, payload);
    if (success) {
      yield put({
        type: GET_TASKS_BY_PROJECT_ID_SUCCESS,
        payload: { tasks },
      });
    }
  } catch (err) {
    yield put({
      type: GET_TASKS_BY_PROJECT_ID_FAILURE,
    });
    console.log(err);
  }
}
function *checkInSelectedTaskSaga({ payload }) {
  try {
    const { success } = yield call(checkInTask, payload);
    if (success) {
      yield put({
        type: CHECK_IN_SELECTED_TASK_SUCCESS,
        payload,
      });
      emitter.emit(POPUP_EVENTS.SHOW_REPORT, payload);
    }
  } catch (err) {
    console.log(err);
  }
}
function *verifyCheckOutTimeSaga({ payload }) {
  try {
    const { success } = yield call(verifyCheckOutTime, payload);
    if (success) {
      yield put({
        type: CHECK_OUT_SELECTED_TASK,
        payload,
      });
    }
  } catch (err) {
    console.log(err);
    emitter.emit(POPUP_EVENTS.SHOW_REPORT, { ...payload, isManual: true });
  }
}
function *checkOutSelectedTaskSaga({ payload }) {
  try {
    const { success } = yield call(checkOutTask, payload);
    if (success) {
      yield put({
        type: CHECK_OUT_SELECTED_TASK_SUCCESS,
        payload,
      });
      emitter.emit(POPUP_EVENTS.SHOW_REPORT, payload);
    }
  } catch (err) {
    console.log(err);
  }
}
export default function *tasksWatcher() {
  yield all([
    takeEvery(GET_TASKS_BY_PROJECT_ID, getTasksByProjectIdSaga),
    takeEvery(CHECK_IN_SELECTED_TASK, checkInSelectedTaskSaga),
    takeEvery(VERIFY_CHECKOUT_TIME, verifyCheckOutTimeSaga),
    takeEvery(CHECK_OUT_SELECTED_TASK, checkOutSelectedTaskSaga),
  ]);
}

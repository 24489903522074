export const GET_ALL_FOLDERS_DOCS = 'GET_ALL_FOLDERS_DOCS';
export const GET_ALL_FOLDERS_DOCS_SUCCESS = 'GET_ALL_FOLDERS_DOCS_SUCCESS';
export const CHECK_DOCS = 'CHECK_DOCS';
export const CHECK_DOCS_HIDDEN = 'CHECK_DOCS_HIDDEN';
export const MOVE_DOCS = 'MOVE_DOCS';
export const CHECK_DOCS_SUCCESS = 'CHECK_DOCS_SUCCESS';
export const CHECK_DOCS_FAILURE = 'CHECK_DOCS_FAILURE';

export const getAllDocs = () => ({
  type: GET_ALL_FOLDERS_DOCS
});
export const checkDocs = (folderId) => ({
  type: CHECK_DOCS,
  payload: folderId,
});

export const checkDocsHidden = (folderId) => ({
  type: CHECK_DOCS_HIDDEN,
  payload: folderId,
});

export const checkDocsSuccess = () => ({
  type: CHECK_DOCS_SUCCESS,
});

export const checkDocsFailed = () => ({
  type: CHECK_DOCS_FAILURE,
});

export const moveDocs = (fileId, folderId, newParentId) => ({
  type: MOVE_DOCS,
  payload: {
    fileId,
    folderId,
    newParentId,
  },
});

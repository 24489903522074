import {
  CHECK_NOTIFICATION_SUCCESS,
  DELETE_NOTIFICATIONS_SUCCESS,
  DELETE_NOTIFICATION_SUCCESS,
  GET_NOTIFICATIONS_SUCCESS,
} from '../actions/notifications';

const initialState = {
  data: [],
  unseen: 0,
};

export default function notifications(state, action) {
  switch (action.type) {
    case GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        unseen: action.payload.filter((n) => !n.status).length,
        data: [...action.payload],
      };
    case CHECK_NOTIFICATION_SUCCESS: {
      const notifications = state.data;
      let newArr = [];
      // let unseenCount = 0;
      if (Array.isArray(notifications) && notifications.length > 0) {
        newArr = notifications.map((item) => {
          if (item.notification_id === action.payload) {
            item.status = item.status ? false : true;

          }
          return item
        })

      }
      return {
        ...state,
        unseen: newArr.filter((n) => !n.status).length,
        data: newArr,
      };
    }
    // case CHECK_NOTIFICATION_SUCCESS: {
    //   const changedNoti = state.data.find(
    //     (n) => n.notification_id === action.payload
    //   );
    //   changedNoti.status = !changedNoti.status;
    //   return {
    //     ...state,
    //     unseen: state.unseen - 1 < 0 ? 0 : state.unseen - 1 < 0,
    //     data: [...state.data],
    //   };
    // }
    case DELETE_NOTIFICATION_SUCCESS:
      return {
        data: state.data.filter((s) => s.notification_id !== action.payload),
        unseen: state.unseen - 1 < 0 ? 0 : state.unseen - 1 < 0,
      };
    case DELETE_NOTIFICATIONS_SUCCESS:
      return { data: [], unseen: 0 };
    default:
      return state || initialState;
  }
}

import React, { useEffect, useState } from 'react';
import countries from '../../constants/countries';
import languages from '../../constants/languages';
import dummyUser from '../../assets/images/dummyUser.svg'
import timezones from '../../constants/timezones';
import { editOrgProfile, getOrgProfile } from '../../services/organization';
import { notifyError, notifyErrorMsg, notifySuccess } from '../common/Alert';
import { Button } from 'react-bootstrap';

export default function Organization() {
  const [data, setData] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const handleChange = (name) => (e) => {
    setData({ ...data, [name]: e.target.value });
  };
  const handleChangeLogo = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const [file] = e.target.files;
    reader.onloadend = () => {
      setData({ ...data, c_org_logo: reader.result });
    };
    reader.readAsDataURL(file);
  };
  const handleEdit = () => {
    if (isEdit) {
      editOrgProfile({
        org_name: data.c_org_name,
        country: data.c_country,
        language: data.c_language,
        timezone: data.c_timezone,
        org_logo: data.c_org_logo,
      })
        .then(({ data: res }) => {
          notifySuccess(res);
          getOrgProfile().then(({ orgInfo }) => {
            setData(orgInfo);
            setIsEdit(false);
          }).catch(err => {
            notifyError(err?.response?.data?.msg)
          })
        })
        .catch((err) => notifyErrorMsg(err));
    } else if (!isEdit) {
      setData({
        ...data,
        c_org_name: data.org_name,
        c_country: data.country,
        c_language: data.language,
        c_timezone: data.timezone,
        c_org_logo: data.org_logo,
      });
      setIsEdit(true);
    }
  };
  const renderTable = (
    <>
      {/* <table className="f-table mb-4">
        <tbody>
          <tr>
            <td>Organization Name:</td>
            <td>{data.org_name}</td>
          </tr>
          <tr>
            <td>Logo:</td>
            <td>
              <img
                alt=""
                style={{ width: '25%', display: 'block' }}
                src={dummyUser}
              />
            </td>
          </tr>
          <tr>
            <td>Country:</td>
            <td>{data.country}</td>
          </tr>
          <tr>
            <td>Language:</td>
            <td>{data.language}</td>
          </tr>
          <tr>
            <td>Time Zone:</td>
            <td>{data.timezone}</td>
          </tr>
        </tbody>
      </table> */}
      <div className='organization-wrap'>
        <div className='d-flex align-items-center'>
          <div className='org-img-wrap'>
            {data?.org_logo ?
              <img src={data.org_logo} alt='user' />
              :
              <span className='p-name'>{data?.org_name?.[0]}</span>
            }
          </div>
          <div >
            <h3 className='org-table-title'>{data.org_name}</h3>
            {/* <p className='org-table-text'>About Organization </p> */}
          </div>
        </div>
        <div className='org-detail'>Details Organization</div>
        <table>

          <tbody>
            <tr>
              <td>Country:</td>
              <td>{data.country}</td>
            </tr>
            <tr>
              <td>Language:</td>
              <td>{data.language}</td>
            </tr>
            <tr>
              <td>Time Zone:</td>
              <td>{data.timezone}</td>
            </tr>
          </tbody>

        </table>
      </div>

      <Button className="org-btn" onClick={handleEdit}>Change Profile</Button>
    </>
  );
  const renderForm = (
    <>

      <div className='d-flex  align-items-center justify-content-start'>
        <div className='organization-wrap organization-wrap-edit'>

          <h3 className='edit-title'>Edit Organization Profile</h3>

          <div className='d-flex mb-2' >
            <div className='org-img-wrap'>
              {data?.org_logo ?
                <img src={data.org_logo} alt='user' />
                :
                <span className='p-name'>{data?.org_name?.[0]}</span>
              }
            </div>
            <div >
              <h3 className='org-label-title'>Change logo</h3>
              {isEdit && (
                <input
                  id="logo"
                  type="file"
                  value={data.c_org_logo?.file_name}
                  onChange={handleChangeLogo}
                  className="user-input"
                  disabled={!isEdit}
                />
              )}
            </div>
          </div>

          <label htmlFor="name">Organization Name:</label>
          {isEdit && (
            <input
              id="name"
              value={data.c_org_name}
              onChange={handleChange('c_org_name')}
              className="primary-input"
              disabled={!isEdit}
            />
          )}


          <label htmlFor="name">About Organization :</label>
          {isEdit && (
            <input
              id="name"
              value={data.c_org_name}
              onChange={handleChange('c_org_name')}
              className="primary-input"
              disabled={!isEdit}
            />
          )}

          {!isEdit && data.org_name}






        </div>
        <div className='organization-wrap organization-wrap-edit'>

          <h3 className='edit-title'>Edit Detail Organization</h3>

          <div>
            <label htmlFor="country">Country:</label>
            {isEdit && (
              <input
                id="country"
                list="country-list"
                value={data.c_country}
                onChange={handleChange('c_country')}
                className="primary-input"
                disabled={!isEdit}
              />
            )}
            {!isEdit && data.country}
            <datalist id="country-list">
              {countries.map((c) =>
                <option key={c} value={c} />
              )}
            </datalist>
          </div>
          <div>
            <label htmlFor="language">Language:</label>
            {isEdit && (
              <input
                id="language"
                value={data.c_language}
                onChange={handleChange('c_language')}
                className="primary-input"
                list="language-list"
                disabled={!isEdit}
              />
            )}
            {!isEdit && data.language}
            <datalist id="language-list">
              {languages.map((l) =>
                <option key={l} value={l} />
              )}
            </datalist>
          </div>
          <div>
            <label htmlFor="timezone">Time Zone:</label>
            {isEdit && (
              <input
                id="timezone"
                value={data.c_timezone}
                onChange={handleChange('c_timezone')}
                className="primary-input"
                list="timezone-list"
                disabled={!isEdit}
              />
            )}
            {!isEdit && data.timezone}
            <datalist id="timezone-list">
              {timezones.map((t) =>
                <option key={t} value={t} />
              )}
            </datalist>
          </div>
        </div>
      </div>

      <div className='d-flex align-items-center justify-content-end mt-5'>
        <button className='cancel-btn' onClick={() => setIsEdit(false)}>Cancel </button>
        <button
          className='change-btn'
          onClick={handleEdit}
        >
          Change
        </button>
      </div>
    </>
  );
  useEffect(() => {
    getOrgProfile().then(({ orgInfo }) => {
      setData(orgInfo);
    }).catch(err => {
      notifyError(err?.response?.data?.msg)
    })
  }, []);
  return (
    <div>
      <div >
        {isEdit && renderForm}
        {!isEdit && renderTable}
      </div>
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import { Row, Col, Dropdown } from 'react-bootstrap'
// import File from './File';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import FilesComponent from '../FilesComponent';
import FolderItem from './Folder.Item';
import { getProjects } from '../../services/project';
import TaskGrid from '../TaskGrid';
import MenuIcon from '../../assets/images/grid-icon.svg'
import ListIcon from '../../assets/images/list-icon.svg'
import Folder from '../../assets/images/folder-icon.svg';
import { MdMoreVert, MdFolder, MdOutlineSchedule, MdLock, MdLink } from 'react-icons/md';
import { BsCloudArrowUp } from 'react-icons/bs'
import BootstrapTable from 'react-bootstrap-table-next';
import getDefaultThumbnail from '../../utils/getDefaultThumbnail';
import { bytesToSize, getFolderName } from '../../utils/string';
// import { formatDate } from '../../utils/datetime';
import { checkQuickAccess } from '../../redux/actions/quickAccess';
import { checkFavorite } from '../../redux/actions/files';
import { checkPath } from '../../redux/actions/path';
import { getLinkedFiles, resetLinkPopup } from '../../redux/actions/linkFilePopup';
import moment from 'moment';
import { nameSortfileOrFolderTable } from '../../helpers/sort';

// const styles = {
//   navButton: {
//     position: 'absolute',
//     width: '4rem',
//     height: '4rem',
//     borderRadius: '50%',
//     top: '40%',
//     backgroundColor: 'white',
//     zIndex: 98,
//     cursor: 'pointer',
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//   },
// };

const dropdownText = [
  { id: 1, name: 'Name', value: 'name' },
  { id: 2, name: 'File Type', value: 'file-type' },
  { id: 3, name: 'Size', value: 'size' }
]

// function getShortFilePath(filePath) {
//   const filePathArr = filePath.split('/');
//   if (filePathArr.length === 1) {
//     return 'Root';
//   }
//   if (filePathArr.length === 2) {
//     return `${filePathArr[filePathArr.length - 2]}`;
//   }
//   return `${filePathArr[filePathArr.length - 2]}`;
// }

export default function QuickAccess({
  handleFileContextMenu,
  handleFolderContextMenu,
  dragStart,
  onDrop,
  onDragOver,
  onSelect,
  selectedFiles
}) {

  const history = useHistory();
  const dispatch = useDispatch();

  const [showLimit, setShowLimit] = useState(4);
  const [quickAccessTabIndex, setQuickAccessTabIndex] = useState(0);
  const [projects, setProjects] = useState([]);
  const [count, setCount] = useState(4);
  const [gridView, setGridView] = useState(false);
  const [sortArray, setSortArray] = useState('');
  const [filesArray, setFilesArray] = useState([]);
  const [foldersArray, setFoldersArray] = useState([]);

  const {
    upload: recentlyUpload,
    accessed: recentlyAccessed,
    favorite: recentlyFavorite,
    checkout: recentlyCheckout,
    favoriteFolders: recentlyFavFolder,
    isLoading,
  } = useSelector((s) => s.quickAccess);

  const allFolders = useSelector((s) => s.allFolders);
  const isMultipleFiles = useSelector((s) => s.multiSelection.isMultipleFiles)

  useEffect(() => {
    setFoldersArray(recentlyFavFolder);
  }, [recentlyFavFolder])
  useEffect(() => {
    getProjects().then(({ projects: res }) => {

      setProjects(res)

    });

    // console.log(recentlyFavorite);
  }, []);

  const folderHandleClick = (id, name) => {
    dispatch(checkQuickAccess());
    dispatch(checkPath({ id, name }));
    history.push(`/files/folder-detail/${id}`);
  }

  function handleClick(e, row, rowIndex) {
    // console.log(row);
    dispatch(checkQuickAccess());
    history.push(`/file-detail/${row.file_id}`);
  }

  const headTabs = [
    {
      id: 0,
      name: `Task`,
      data: projects,
      notify: projects && projects.length > 0 ? projects.filter((p) => p.status === "pending").length : 0,
      nodata: 'There are no task',
    },

    {
      id: 1,
      name: `Favorites`,
      data: recentlyFavorite,
      notify: recentlyFavFolder.length + recentlyFavorite.length,
      nodata: 'There are no favorite files.',
    },
    {
      id: 2,
      name: `Checkout`,
      data: recentlyCheckout,
      notify: recentlyCheckout.length,
      nodata: 'There are no checkout files.',
    },
    {
      id: 3,
      name: 'Recently Upload',
      data: recentlyUpload,
      notify: 0,
      nodata: 'There are no recently upload files.',
    },
    {
      id: 4,
      name: 'Recently Accessed',
      data: recentlyAccessed,
      notify: 0,
      nodata: 'There are no recently accessed files.',
    }
  ];

  useEffect(() => {
    const windowWidth = window.outerWidth;
    if (windowWidth <= 1440) {
      setShowLimit(4);
    }
  }, []);

  const showFileToggle = (files) => {
    setShowLimit(showLimit > 4 ? 4 : Array.isArray(files) ? files.length : 4)
  }

  const showToggle = () => {
    setCount(count > 4 ? 4 : Array.isArray(projects) ? projects.length : 4)
  }

  const gridviewHandler = () => {
    setGridView(!gridView);
  }

  const sortHandler = (e, item) => {
    e.preventDefault();
    const sortVal = item.value;
    const data = headTabs[quickAccessTabIndex].data;
    if (sortArray === sortVal) {
      setSortArray('')
      setFilesArray(headTabs[quickAccessTabIndex].data)
    } else {
      setSortArray(sortVal);
      if (data && data.length > 0) {
        const fileSort = data.sort((a, b) => {
          switch (sortVal) {
            case 'name':
              if (a.new_name < b.new_name) { return -1; }
              if (a.new_name > b.new_name) { return 1; }
              return 0;
            case 'file-type':
              if (a.type < b.type) { return -1; }
              if (a.type > b.type) { return 1; }
              return 0;
            case 'size':
              if (a.size < b.size) { return -1; }
              if (a.size > b.size) { return 1; }
              return 0;
            default:
              return null;
          }

        });
        setFilesArray(fileSort);
      }
    }
  }

  function nameFormatter(cell, row) {
    return (
      <span className='d-flex align-items-center' data-tip={row.new_name || row.folder_name} data-for="fileName">
        <img className="folder-icon" src={row.folder_id ? Folder : getDefaultThumbnail(row.type)} alt="folder" />
        <span style={{ whiteSpace: 'nowrap', maxWidth: '100px', overflow: 'hidden', textOverflow: 'ellipsis' }}>{row.new_name || row.folder_name}</span>
        <ReactTooltip id="fileName" />
      </span>
    );
  }

  // const fileTypeFormatter = (cell, row) => {
  //   return row.folder_id ? <span className='folder-name'>File Folder</span> : <span className='text-uppercase'>{row.type}</span>
  // }

  const folderPathFormatter = (cell, row) => {
    let folderName = '';
    if (!row.parent_id) {
      folderName = 'Root'
    } else {
      folderName = getFolderName(row.parent_id, allFolders)
    }
    return (
      <span className="folder-path-wraps" data-for="folderName" data-tip={folderName}>
        <span className='folder-icon'><MdFolder /></span>
        <span style={{ whiteSpace: 'nowrap', maxWidth: '55px', overflow: 'hidden', textOverflow: 'ellipsis' }}>{folderName}</span>
        <ReactTooltip id="folderName" />
      </span>
    )
  }

  const sizeFormatter = (cell, row) => {
    return row.size ? bytesToSize(row.size) : '-'
  }

  const accessedFormatter = (cell, row) => {
    const date = row.updated_at ? new Date(row.updated_at) : '';
    return (
      row.updated_at ?
        <span className='icon-with-text'>
          <span className='icon'><MdOutlineSchedule /></span>
          {moment(date).format('MMM DD, YYYY')}
        </span>
        :
        '-'
    )
  }
  const uploadFormatter = (cell, row) => {
    const date = row.created_at ? new Date(row.created_at) : '';
    return (
      <>
        <span className='icon-with-text'>
          <span className='icon'><BsCloudArrowUp /></span>
          {moment(date).format('MMM DD, YYYY')}
        </span>
        <div className="files-dropdown table-td-drop">
          <button type="button" data-for="menu" data-tip="Menu" style={{ lineHeight: '0' }} onClick={(e) => handleFileContextMenu(e, row)}><MdMoreVert /></button>
          <ReactTooltip id="menu" />
        </div>
      </>
    )
  }

  const statusFormatter = (cell, row) => {
    return (
      <>
        <span className='status-td-div'>
          <button
            type="button"
            data-tip="favorite"
            data-for="favorite"
            className={`btns-likes ${row.favorite ? 'active' : ''}`}
            onClick={() =>
              dispatch(
                checkFavorite(row.file_id, !row.favorite, row)
              )
            }
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 5.09C13.09 3.81 14.76 3 16.5 3C19.58 3 22 5.42 22 8.5C22 12.2769 18.6056 15.3549 13.4627 20.0185L13.45 20.03L12 21.35L10.55 20.04L10.5105 20.0041C5.38263 15.3442 2 12.2703 2 8.5C2 5.42 4.42 3 7.5 3C9.24 3 10.91 3.81 12 5.09Z" fill="#DF5656" />
            </svg>

            <span>Favorite</span>
          </button>

          {row.checkout ?
            <button
              type="button"
              data-tip="checkout"
              data-for="checkout"
              className='btn-checkout-check'
            >
              <MdLock />
              <span>Checkout</span>
            </button>

            :
            null
          }
          {row.link ?

            <button
              type="button"
              style={{ lineHeight: '0' }}
              className='link-btns'
              data-tip=""
              data-for="linkedfile"
              onMouseOver={() => dispatch(getLinkedFiles(row.file_id))}
              onMouseLeave={() => dispatch(resetLinkPopup())}
            >
              <MdLink />
              <span>Linked</span>
            </button>
            :
            null
          }
        </span>
        <ReactTooltip id="linkedfile" />
        <ReactTooltip id="favorite" />
        <ReactTooltip id="checkout" />
      </>
    )
  }

  // const actionFormatter = (cell, row) => {
  //   return <div className="files-dropdown">
  //     <button type="button" data-for="menu" data-tip="Menu" style={{ lineHeight: '0' }} onClick={(e) => handleFileContextMenu(e, row)}><MdMoreVert /></button>
  //     <ReactTooltip id="menu" />
  //   </div>
  // }

  const handleOnSelect = (row, isSelect) => {
    onSelect(row)

  }
  const handleOnSelectAll = (isSelect, rows) => {
    onSelect(rows)

  }

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    onSelect: handleOnSelect,
    onSelectAll: handleOnSelectAll,
    hideSelectColumn: true,
    bgColor: '#f5f6f8',
    selected: selectedFiles && selectedFiles.length > 0 ? selectedFiles.map((item) => item.id) : []
    // onDoubleClick: viewFileClick
  }

  const rowEvents = {
    // onDoubleClick: handleClick,
    onContextMenu: (e) => {
      if (isMultipleFiles) {
        handleFileContextMenu(e, selectedFiles?.[0])
      }
    }
  }

  const columns = [

    {
      dataField: 'new_name',
      text: 'Name',
      formatter: nameFormatter,
      sort: true,
      sortFunc: nameSortfileOrFolderTable

    },
    {
      dataField: 'status',
      text: 'Status',
      sort: false,
      formatter: statusFormatter
    },
    // {
    //   dataField: 'type',
    //   text: 'File Type',
    //   sort: true,
    //   formatter: fileTypeFormatter
    // },
    {
      dataField: 'folder_name',
      text: 'Folder',
      sort: true,
      formatter: folderPathFormatter
    },
    // {
    //   dataField: 'member_icons',
    //   text: 'Member'
    // },
    {
      dataField: 'file_size',
      text: 'Size',
      sort: true,
      formatter: sizeFormatter
    },
    {
      dataField: 'updated_at',
      text: 'Last Accessed',
      sort: true,
      formatter: accessedFormatter
    },
    {
      dataField: 'created_at',
      text: 'Upload Date',
      sort: true,
      formatter: uploadFormatter
    },
    // {
    //   dataField: 'action',
    //   text: 'Action',
    //   sort: false,
    //   formatter: actionFormatter
    // }
  ];

  // console.log(headTabs[quickAccessTabIndex].data)

  return (
    <>
      <div className="mainNav custom-tab custom-tab2 is-desktop">
        {headTabs.map((t, i) => (

          <button
            key={i}
            type="button"
            className={`nav-link ${quickAccessTabIndex === t.id ? 'is-active' : ''}`}
            onClick={() => {
              setQuickAccessTabIndex(t.id);
              setShowLimit(4);
              setGridView(false);
              if (headTabs[t.id].name !== 'Task') {
                setFilesArray(headTabs[t.id].data)
              } else {
                setFilesArray([])
              }
            }}
          >
            {t.name}
            {t.notify > 0 ? <span className='notify-icons'>{t.notify}</span> : null}
          </button>
        ))}
      </div>
      <div className='mainNav custom-tab custom-tab2 is_mobile_tab border-0 w-100'>
        <Dropdown className="dropdown-wraps access-tabs">
          <Dropdown.Toggle id="dropdown-upload" className="dropdown-toggle btn-acccess-tabs">
            <span className='btn-names'>{headTabs[quickAccessTabIndex].name}</span>
            {headTabs[quickAccessTabIndex].notify > 0 ? <span className='notify-icons'>{headTabs[quickAccessTabIndex].notify}</span> : null}
          </Dropdown.Toggle>
          <Dropdown.Menu className='uploadBtn-menu'>
            {headTabs.map((t, i) => (
              <Dropdown.Item
                key={i}
                as="button"
                className={`tab-nav-btn ${quickAccessTabIndex === t.id ? 'is_active' : ''}`}
                onClick={() => {
                  setQuickAccessTabIndex(t.id);
                  setShowLimit(4);
                  setGridView(false);
                  if (headTabs[t.id].name !== 'Task') {
                    setFilesArray(headTabs[t.id].data)
                  } else {
                    setFilesArray([])
                  }
                }}
              >
                {t.name} {t.notify > 0 ? <span>{t.notify}</span> : null}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className="recently-access tab-panel-custom" style={{ position: 'relative' }}>
        <Row>


          <Col sm={12}>
            {headTabs[quickAccessTabIndex].name === 'Task' ?
              headTabs[quickAccessTabIndex].data.length ? <div className="d-flex align-items-start justify-content-between">
                <h3 className="file-title">Pending</h3>
                {headTabs[quickAccessTabIndex].data.length > 0 ? <button className="btn-outline-primary" onClick={showToggle}>{count > 4 ? 'Show Less' : 'Show all'}</button> : null}
              </div>
                :
                null
              :
              <>
                {headTabs[quickAccessTabIndex].name === 'Favorites' && Array.isArray(foldersArray) && foldersArray.length > 0 ?

                  <div className="folders pt-0 mb-5">
                    <div className="d-flex align-items-start justify-content-between mb-2">
                      <h3 className="file-title folders__title">Folder</h3>

                    </div>
                    <div className='folders__items'>
                      {foldersArray.map((item, i) =>
                        <FolderItem
                          key={item.folder_id}
                          folder={item}
                          handleClick={folderHandleClick}
                          handleFolderContextMenu={handleFolderContextMenu}
                          dragStart={dragStart}
                          onDrop={onDrop}
                          onDragOver={onDragOver}
                        />
                      )}
                    </div>
                  </div>
                  :
                  null
                }
                {headTabs[quickAccessTabIndex].data.length ?
                  <div className="d-flex align-items-start justify-content-between">

                    <h3 className="file-title">Files</h3>
                    {filesArray.length > 0 ?
                      <div className='d-flex'>
                        <button className="btn-outline-primary me-2" onClick={() => showFileToggle(headTabs[quickAccessTabIndex].data)}>{showLimit > 4 ? 'Show Less' : 'Show all'}</button>
                        <button type="button" className='bg-transparent border-0 me-2' onClick={gridviewHandler}>
                          <img src={gridView ? ListIcon : MenuIcon} alt="views" />
                        </button>
                        {gridView && <Dropdown className="files-dropdown">
                          <Dropdown.Toggle id="dropdown-button-dark-example1">
                            <MdMoreVert />
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="custom-dropdown custom-sort-dropmenu">
                            {dropdownText && dropdownText.length > 0 ?
                              dropdownText.map((item, i) =>
                                <Dropdown.Item
                                  href="#/action-1"
                                  key={i}
                                  className={`${item.value === sortArray ? 'is_active' : ''}`}
                                  onClick={(e) => sortHandler(e, item)}
                                >
                                  {item.name}
                                </Dropdown.Item>
                              )
                              :
                              null
                            }
                          </Dropdown.Menu>
                        </Dropdown>
                        }
                      </div>
                      :
                      null
                    }
                  </div>
                  :
                  null
                }
              </>
            }
          </Col>
          {headTabs[quickAccessTabIndex].data.length ?
            <>
              {headTabs[quickAccessTabIndex].name === 'Task' ?
                headTabs[quickAccessTabIndex].data.length > 0 ?
                  headTabs[quickAccessTabIndex].data
                    .filter((p) => p.status === "pending")
                    .slice(0, count)
                    .map((p, index) => (
                      <Col xs={12} sm={6} key={index}>
                        <TaskGrid
                          key={index}
                          project={p}
                        />
                      </Col>
                    ))
                  :
                  <Col xs={12} sm={12}>
                    <p>There is no project</p>
                  </Col>
                :
                gridView ?
                  <>
                    {

                      filesArray.length > 0 ?

                        filesArray
                          .slice(0, showLimit)
                          .map((d, index) => (
                            <Col xs={12} sm={6} key={index}>
                              <FilesComponent
                                key={d.file_id}
                                handleFileContextMenu={(e) => handleFileContextMenu(e, d)}
                                file={d}
                                dragStart={(e) => {
                                  e.preventDefault();
                                }}
                                onSelect={onSelect}
                                fileSelected={selectedFiles && selectedFiles.find(file => file.file_id === d.file_id) ? true : false}
                              />
                            </Col>
                          ))

                        :
                        headTabs[quickAccessTabIndex].name === "Favorites" && foldersArray.length > 0 ?
                          null
                          :
                          <Col xs={12} sm={12}>
                            <p>{headTabs[quickAccessTabIndex].nodata}</p>
                          </Col>
                    }
                  </>
                  :
                  <BootstrapTable keyField={'file_id'}
                    data={headTabs[quickAccessTabIndex].data}
                    columns={columns}
                    bordered={false}
                    classes={'table-search'}
                    rowEvents={rowEvents}
                    selectRow={selectRow}
                    noDataIndication="There is no data"
                    wrapperClasses='table-responsive'
                  />
              }
            </>
            : (
              headTabs[quickAccessTabIndex].name === "Favorites" && foldersArray.length > 0 ?
                null
                :
                <Col sm={12}>
                  <div
                    style={{
                      height: '20rem',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {isLoading
                      ? 'Your files are being loaded...'
                      : headTabs[quickAccessTabIndex].nodata}
                  </div>
                </Col>
            )}

        </Row>
      </div>
    </>
  );
}

QuickAccess.propTypes = {
  handleFileContextMenu: PropTypes.func.isRequired,
};

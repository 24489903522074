import {
    GET_WINDOW_WIDTH,
    MAIN_NAVIGATION_TOGGLE,
    ACTIVITES_SIDEBAR_TOGGLE
} from '../actions/layout';

const initialState = {
    width: 0,
    isMobileView: false,
    isMainNavOpen: false,
    isActivitiesSidebar: false
};

export default function layout(state, action) {
    switch (action.type) {
        case GET_WINDOW_WIDTH:
            return {
                ...state,
                width: action.payload,
                isMobileView: action.payload < 1023,
                isActivitiesSidebar: action.payload < 1023 ? false : null,
                isMainNavOpen: action.payload < 1023 ? false : null
            };
        case MAIN_NAVIGATION_TOGGLE:
            return {
                ...state,
                isMainNavOpen: state.isMobileView ? state.isMainNavOpen ? false : true : false,
                isActivitiesSidebar: false
            };
        case ACTIVITES_SIDEBAR_TOGGLE:
            return {
                ...state,
                isActivitiesSidebar: state.isMobileView ? state.isActivitiesSidebar ? false : true : false,
                isMainNavOpen: false
            };
        default:
            return state || initialState;
    }
}
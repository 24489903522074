import axios from 'axios';
import CONFIG from '../constants/config';

export const getPasswordPolicy = async () => {
  const response = await axios({
    method: 'GET',
    url: `${CONFIG.BASE_URL}/org-pwd-policy`,
    withCredentials: true,
  });
  return response.data;
};

export const createPasswordPolicy = async ({
  expiry_period,
  pwd_length,
  mixed_pwd,
  no_special_char,
  no_numberic_char,
  no_pwd_history,
}) => {
  const response = await axios({
    method: 'POST',
    url: `${CONFIG.BASE_URL}/org-pwd-policy`,
    withCredentials: true,
    data: {
      expiry_period: Number(expiry_period),
      pwd_length: Number(pwd_length),
      mixed_pwd,
      no_special_char: Number(no_special_char),
      no_numberic_char: Number(no_numberic_char),
      no_pwd_history: Number(no_pwd_history),
    },
  });
  return response.data;
};

export const updatePasswordPolicy = async ({
  expiry_period,
  pwd_length,
  mixed_pwd,
  no_special_char,
  no_numberic_char,
  no_pwd_history,
}) => {
  const response = await axios({
    method: 'PATCH',
    url: `${CONFIG.BASE_URL}/org-pwd-policy`,
    withCredentials: true,
    data: {
      expiry_period: Number(expiry_period),
      pwd_length: Number(pwd_length),
      mixed_pwd,
      no_special_char: Number(no_special_char),
      no_numberic_char: Number(no_numberic_char),
      no_pwd_history: Number(no_pwd_history),
    },
  });
  return response.data;
};

export const deletePasswordPolicy = async () => {
  const response = await axios({
    method: 'DELETE',
    url: `${CONFIG.BASE_URL}/org-pwd-policy`,
    withCredentials: true,
  });
  return response.data;
};

export const getAllowedIPs = async () => {
  const response = await axios({
    method: 'GET',
    url: `${CONFIG.BASE_URL}/org-allowed-ips`,
    withCredentials: true,
  });
  return response.data;
};

export const createAllowedIPs = async ({ ip }) => {
  const response = await axios({
    method: 'POST',
    url: `${CONFIG.BASE_URL}/org-allowed-ips`,
    withCredentials: true,
    data: { ip },
  });
  return response.data;
};

export const deleteAllowedIPs = async ({ ip }) => {
  const response = await axios({
    method: 'DELETE',
    url: `${CONFIG.BASE_URL}/org-allowed-ips`,
    withCredentials: true,
    data: { ip },
  });
  return response.data;
};

import React, { useRef, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import SkyLight from 'react-skylight';
import { emitter } from '../../utils/emitter';
import { notifyError, notifyErrorMsg, notifySuccess } from '../common/Alert';
import { POPUP_EVENTS } from '../../constants/events';
import CONFIG from '../../constants/config';
import { MdClear, MdOutlineShare } from 'react-icons/md';
import { removeSelectingFiles } from '../../redux/actions/files';

const sendEmailStyle = {
  left: '30%',
  marginLeft: 0,
  marginTop: 0,
  top: '25%',
  width: '40%',
  height: 'fit-content',
  minHeight: '0rem',
  borderRadius: 10,
};
export default function ShareFile({ fileId }) {

  // const isMultipleFiles = useSelector(s => s.multiSelection.isMultipleFiles);
  const selectedFiles = useSelector(s => s.multiSelection.selectedFiles);
  const dispatch = useDispatch()

  const [email, setEmail] = useState('');
  const [publish, setPublish] = useState(false);
  const [publishLink, setPublishLink] = useState('');
  const sendEmailPopup = useRef(null);

  function shareApiCall(file_id) {
    return axios({
      method: 'POST',
      url: `${CONFIG.BASE_URL}/share-file`,
      withCredentials: true,
      data: {
        file_id: file_id,
        permission_type: 'viewer',
        publish,
        share_email: email,
      },
    })
      .then(res => {
        return res.data;
      })
      .catch((err) => {
        return err?.response?.data;
      });
  }

  async function handleSend() {
    if (Array.isArray(selectedFiles) && selectedFiles.length > 0) {
      // if (isMultipleFiles) {
      let res;
      for (let i = 0; i < selectedFiles.length; i++) {
        res = await shareApiCall(selectedFiles[i].file_id);
        // console.log(res.msg);
        if (!res.success) {
          notifyError(res?.msg);
          return true;
        }
        setPublishLink(res.link_url);
      }
      if (res.success) {
        if (publish) {
          setEmail('');
          setPublish(false);
          notifySuccess(res.msg);
          dispatch(removeSelectingFiles())
          sendEmailPopup.current.hide();
        } else {
          sendEmailPopup.current.hide();
          dispatch(removeSelectingFiles())
          setEmail('');
          notifySuccess(res.msg);
        }
      } else {
        notifyError(res?.msg)
      }

      // } else {

      // }
    } else {
      notifyErrorMsg('File not selected. Please select the file');
    }

  }

  const showPopup = () => sendEmailPopup.current.show();

  function listenToTheEmitter() {
    emitter.on(POPUP_EVENTS.SHARE_FILE, showPopup);
  }
  useEffect(() => {
    listenToTheEmitter();
    return () => {
      emitter.off(POPUP_EVENTS.SHARE_FILE, showPopup);
    };
  });
  return (
    <SkyLight
      hideOnOverlayClicked
      overlayStyles={{ backgroundColor: 'rgba(0, 0, 0, 0.3)' }}
      ref={sendEmailPopup}
      dialogStyles={sendEmailStyle}
      afterClose={() => {
        setPublishLink('');
        setEmail('');
      }}
    >
      <div className="checkinout share-modal">
        <div className="check-success--title">
          <span><i className='icon-share'><MdOutlineShare /> </i> Send file to email</span>
          <i
            className="icon-close-modal"
            onClick={() => sendEmailPopup.current.hide()}
          >
            <MdClear />
          </i>
        </div>
        {!publishLink && (
          <div className="check-input">
            <input
              type="email"
              className="primary-input mb-4"
              required
              placeholder="Enter email here"
              value={email}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSend();
                }
              }}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        )}
        <div className="row ">
          {!publishLink && (
            <div className='checkbox-agree'>
              <input
                type="checkbox"
                id="publish"
                checked={publish}
                onChange={() => setPublish(!publish)}
              />
              <label htmlFor="publish">
                Publish this file
              </label>
            </div>
          )}
          {publishLink && (
            <>
              <div>Your publish link:</div>
              <div
                style={{
                  userSelect: 'text',
                  background: 'rgba(0 ,128, 0,0.1)',
                  fontWeight: 500,
                  borderRadius: '5px',
                  padding: '0.5rem',
                  color: 'green',
                }}
              >
                {publishLink}
              </div>
            </>
          )}
        </div>
        <div className="email-share">
          <button type="button" className='btn-cancel' onClick={() => {sendEmailPopup.current.hide(); dispatch(removeSelectingFiles())}}>
            Cancel
          </button>
          <button type="button" onClick={handleSend}>
            Send
          </button>
        </div>
      </div>
    </SkyLight>

  );
}

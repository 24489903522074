import axios from 'axios';
import CONFIG from '../constants/config';

export const checkMail = async (action) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${CONFIG.BASE_URL}/check-email`,
      withCredentials: true,
      data: {
        email: action.payload.email,
        captcha: action.payload.captcha,
      },
    });
    return response.data;
  } catch (err) {
    if (err.response) return err.response;
    return err.message;
  }
};

export const login = async (action) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${CONFIG.BASE_URL}/login`,
      withCredentials: true,
      data: {
        email: action.payload.email,
        password: action.payload.password,
      },
    });
    return response.data;
  } catch (err) {
    if (err.response) return err.response;
    return err.message;
  }
};

export const verifyOtp = async (action) => {
  const data = [];
  data.otp_token = action.payload.otp_token;
  data.email = action.payload.resetPassword ? action.payload.email : undefined;
  const response = await axios({
    method: 'post',
    url: `${CONFIG.BASE_URL}/verify-otp`,
    withCredentials: true,
    data: {
      ...data,
    },
  });
  return response.data;
};

export const sendEmail = async (action) => {
  const response = await axios({
    method: 'post',
    url: `${CONFIG.BASE_URL}/send-mail`,
    withCredentials: true,
    data: {
      email: action.payload.email,
    },
  });
  return response.data;
};

export const setPhoneNumber = async (action) => {
  const response = await axios({
    method: 'post',
    url: `${CONFIG.BASE_URL}/set-phonenumber`,
    withCredentials: true,
    data: {
      phone_number: action.payload.phone_number,
    },
  });
  return response.data;
};

export const resendOtp = async () => {
  const response = await axios({
    method: 'post',
    url: `${CONFIG.BASE_URL}/resend-otp`,
    withCredentials: true,
  });
  return response.data;
};

export const logOut = async () => {
  try {
    const response = await axios({
      method: 'get',
      url: `${CONFIG.BASE_URL}/logout`,
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    if (err.response) return err.response;
    return err.message;
  }
};

export const userInfo = async () => {
  const response = await axios({
    method: 'get',
    url: `${CONFIG.BASE_URL}/my-info`,
    withCredentials: true,
  });
  return response.data;
};

export const updateInfo = async (action) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${CONFIG.BASE_URL}/update-info`,
      withCredentials: true,
      data: {
        password: action.payload.password,
        file: action.payload.avatar,
      },
    });
    return response.data;
  } catch (err) {
    if (err.response) return err.response;
    return err.message;
  }
};

export const updatePassword = async (action) => {
  const response = await axios({
    method: 'post',
    url: `${CONFIG.BASE_URL}/update-password`,
    withCredentials: true,
    data: {
      password: action.payload.password,
      new_password: action.payload.new_password,
    },
  });
  return response.data;
};

export const getMembers = async () => {
  const response = await axios({
    method: 'get',
    url: `${CONFIG.BASE_URL}/group-members`,
    withCredentials: true,
  });
  return response.data;
};

export const orgMembers = async ({ org_id }) => {
  const response = await axios({
    method: 'get',
    url: `${CONFIG.BASE_URL}/member-list`,
    params: { org_id },
    withCredentials: true,
  });
  return response.data;
};

export const addMember = async ({
  payload: { first_name, last_name, email, password, phone_number, address },
}) => {
  const response = await axios({
    method: 'post',
    url: `${CONFIG.BASE_URL}/add-member`,
    withCredentials: true,
    data: { first_name, last_name, email, password, phone_number, address },
  });
  return response.data;
};

export const appToWeb = async ({ client_id }) => {
  const response = await axios({
    method: 'GET',
    url: `${CONFIG.BASE_URL}/app-to-web`,
    withCredentials: true,
    params: { client_id },
  });
  return response.data;
};

export const checkOrgPlan = async () => {
  const response = await axios({
    method: 'GET',
    url: `${CONFIG.BASE_URL}/check-org-plan`,
    withCredentials: true,
  });
  return response.data;
};

import axios from 'axios';
import CONFIG from '../constants/config';

export const createProject = async (payload) => {
  const {
    image,
    project_name,
    deadline,
    description,
    start_date,
    project_files,
    members,
    lead_ids,
  } = payload;
  const response = await axios({
    method: 'POST',
    url: `${CONFIG.BASE_URL}/project`,
    withCredentials: true,
    data: {
      image,
      project_name,
      start_date,
      deadline,
      description,
      project_files,
      members,
      lead_ids,
    },
  });
  return response.data;
};

export const editProject = async (payload) => {
  const {
    projectId,
    image,
    project_name,
    deadline,
    description,
    new_members,
    deleted_members,
    project_files
  } = payload;
  const response = await axios({
    method: 'PATCH',
    url: `${CONFIG.BASE_URL}/project/${projectId}`,
    withCredentials: true,
    data: {
      image,
      project_name,
      deadline,
      description,
      new_members,
      deleted_members,
      project_files
    },
  });
  return response.data;
};

export const deleteMember = async (payload) => {
  const { userId, projectId } = payload;
  const response = await axios({
    method: 'PATCH',
    url: `${CONFIG.BASE_URL}/project/${projectId}`,
    withCredentials: true,
    data: {
      deleted_members: [userId],
    },
  });
  return response.data;
};

export const deleteProject = async ({ projectId }) => {
  const response = await axios({
    method: 'DELETE',
    url: `${CONFIG.BASE_URL}/project/${projectId}`,
    withCredentials: true,
  });
  return response.data;
};

export const getProjects = async () => {
  const response = await axios({
    method: 'GET',
    url: `${CONFIG.BASE_URL}/projects`,
    withCredentials: true,
  });
  return response.data;
};

export const projectCount = async () => {
  const response = await axios({
    method: 'GET',
    url: `${CONFIG.BASE_URL}/count-project`,
    withCredentials: true,
  });
  return response.data;
};

export const projectTime = async (projectId) => {
  const response = await axios({
    method: 'GET',
    url: `${CONFIG.BASE_URL}/projects/${projectId}/work-time`,
    withCredentials: true,
  });
  return response.data;
};

export const getProject = async ({ projectId }) => {
  const response = await axios({
    method: 'GET',
    url: `${CONFIG.BASE_URL}/project/${projectId}`,
    withCredentials: true,
  });
  return response.data;
};

export const createTask = async ({
  payload: {
    projectId,
    task_name,
    description,
    member_ids,
    deadline,
    file_ids,
  },
}) => {
  const response = await axios({
    method: 'POST',
    url: `${CONFIG.BASE_URL}/project/${projectId}/task`,
    withCredentials: true,
    data: {
      task_name,
      description,
      member_ids,
      deadline,
      file_ids,
    },
  });
  return response.data;
};

export const getTasksByProjectId = async ({ projectId }) => {
  const response = await axios({
    method: 'GET',
    url: `${CONFIG.BASE_URL}/project/${projectId}/tasks`,
    withCredentials: true,
  });
  return response.data;
};

export const getTaskByProjectId = async ({
  payload: { projectId, taskId },
}) => {
  const response = await axios({
    method: 'GET',
    url: `${CONFIG.BASE_URL}/project/${projectId}/task/${taskId}`,
    withCredentials: true,
  });
  return response.data;
};

export const editTaskByProjectId = async ({
  projectId,
  taskId,
  task_name,
  description,
  deadline,
  member_ids,
  deleted_members,
  deleted_files,
  file_ids,
}) => {
  const response = await axios({
    method: 'PATCH',
    url: `${CONFIG.BASE_URL}/project/${projectId}/task/${taskId}`,
    withCredentials: true,
    data: {
      task_name,
      description,
      deadline,
      member_ids,
      deleted_members,
      deleted_files,
      file_ids,
    },
  });
  return response.data;
};

export const editDoneTask = async ({ projectId, taskId }) => {
  const response = await axios({
    method: 'PATCH',
    url: `${CONFIG.BASE_URL}/project/${projectId}/task/${taskId}`,
    withCredentials: true,
    data: {
      status: 'done',
    },
  });
  return response.data;
};

export const deleteTask = async ({ projectId, taskId }) => {
  const response = await axios({
    method: 'DELETE',
    url: `${CONFIG.BASE_URL}/project/${projectId}/task/${taskId}`,
    withCredentials: true,
    data: {
      status: 'done',
    },
  });
  return response.data;
};

export const checkInTask = async ({ taskId }) => {
  const response = await axios({
    method: 'POST',
    url: `${CONFIG.BASE_URL}/tasks/${taskId}/checkin`,
    withCredentials: true,
  });
  return response.data;
};

export const verifyCheckOutTime = async ({ taskId }) => {
  const response = await axios({
    method: 'GET',
    url: `${CONFIG.BASE_URL}/tasks/${taskId}/verify-checkout-time`,
    withCredentials: true,
  });
  return response.data;
};

export const taskCheckoutManual = async ({ taskId, checkOutTime }) => {
  const response = await axios({
    method: 'POST',
    url: `${CONFIG.BASE_URL}/tasks/${taskId}/manual-checkout`,
    withCredentials: true,
    data: {
      checkout_hour: checkOutTime,
    },
  });
  return response.data;
};

export const checkOutTask = async ({ taskId }) => {
  const response = await axios({
    method: 'POST',
    url: `${CONFIG.BASE_URL}/tasks/${taskId}/checkout`,
    withCredentials: true,
  });
  return response.data;
};

export const taskWorkTime = async ({ taskId }) => {
  const response = await axios({
    method: 'GET',
    url: `${CONFIG.BASE_URL}/tasks/${taskId}/work-time`,
    withCredentials: true,
  });
  return response.data;
};

export const getReports = async ({ taskId }) => {
  const response = await axios({
    method: 'GET',
    url: `${CONFIG.BASE_URL}/task/${taskId}/reports`,
    withCredentials: true,
  });
  return response.data;
};

export const getReportsMemberId = async ({ projectId, memberId }) => {
  const response = await axios({
    method: 'GET',
    url: `${CONFIG.BASE_URL}/project/${projectId}/member-reports/${memberId}`,
    withCredentials: true,
  });
  return response.data;
};

export const createReport = async ({
  taskId,
  jobWorked,
  nextTarget,
  percent = 0,
}) => {
  const response = await axios({
    method: 'POST',
    url: `${CONFIG.BASE_URL}/task/${taskId}/report`,
    withCredentials: true,
    data: {
      job_worked: jobWorked,
      next_target: nextTarget,
      percent,
    },
  });
  return response.data;
};

export const editReport = async ({
  taskId,
  reportId,
  jobWorked,
  nextTarget,
  percent = 0,
}) => {
  const response = await axios({
    method: 'PATCH',
    url: `${CONFIG.BASE_URL}/task/${taskId}/report/${reportId}`,
    withCredentials: true,
    data: {
      job_worked: jobWorked,
      next_target: nextTarget,
      percent,
    },
  });
  return response.data;
};

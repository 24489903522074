import React from 'react';
import { useSelector } from 'react-redux';
import { bytesToSize, bytesToSizeNumber, MBToSize } from '../../../utils/string';
import UserAvatar from '../../../assets/images/user-avatar.svg'
import FolderAvatar from '../../../assets/images/folder-big-icon.svg'
import ShareAvatar from '../../../assets/images/share-icon.svg'
import TextAvatar from '../../../assets/images/text-file-icon.svg'

export default function UsageStackedChart() {
  const analysis = useSelector((s) => s.analysis.fileAnalysis);
  const usedGB = !analysis.isLoading ?
    analysis?.data?.usage?.count_storage_used ?
      bytesToSizeNumber(analysis?.data?.usage?.count_storage_used)
      :
      0
    :
    0;
  const totalGB = !analysis.isLoading ?
    analysis?.data?.usage?.count_storage ?
      bytesToSizeNumber(analysis?.data?.usage?.count_storage)
      :
      0
    :
    0;
  // const percentage = (Math.round(usedGB) / Math.round(totalGB)) * 100;

  // console.log(Math.round(totalGB));
  // console.log(percentage);
  // const total = !analysis.isLoading ?
  //   analysis?.data?.usage?.count_storage_used ?
  //     MBToSize(
  //       Math.round(
  //         analysis?.data?.usage?.count_storage_used /
  //         analysis?.data?.usage?.count_storage
  //       )
  //     )
  //     :
  //     0
  //   :
  //   0;
  return (
    <div className='chart-card'>
      <span className='title_chart'>
        Summary details
      </span>
      {!analysis.isLoading && (<div className="chart-storage-area" style={{ marginTop: '56px' }}>
        <div className="gauge">
          <div className="gauge__fill__bg" style={{
            transform: `rotate(calc(1deg * ( -45 + ${analysis?.data?.usage ?
              (Number(Math.round(usedGB)) / Number(Math.round(totalGB))) * 100
              :
              0
              } * 1.8)))`
          }}></div>
          <div className="gauge__cover" style={{ fontSize: '18px' }}>
            {
              analysis?.data?.usage?.count_storage_used ?
                MBToSize(analysis?.data?.usage?.count_storage_used.toFixed(2))
                :
                0

            }
            <small style={{ color: '#016288' }}>of
              {
                analysis?.data?.usage?.count_storage ?
                  MBToSize(analysis?.data?.usage?.count_storage)
                  :
                  '0 GB'

              }
            </small>
          </div>
        </div>
      </div>
      )}

      {/* {!analysis.isLoading && (
        <div style={{ width: '80%', marginTop: '4rem', display: 'flex' }}>
          Usage&nbsp;
          <div
            style={{
              height: '3.5rem',
              border: '1px solid black',
              marginLeft: '1rem',
              marginRight: '1rem',
              flex: 1,
            }}
          >
            <div
              style={{
                width: `${MBToSize(
                  Math.round(
                    analysis?.data?.usage?.count_storage_used /
                    analysis?.data?.usage?.count_storage
                  )
                )}%`,
                backgroundColor: 'grey',
              }}
            />
          </div>
          <div>
            <div>{MBToSize(analysis?.data?.usage?.count_storage)}</div>
            <div>
              {MBToSize(
                Math.round(
                  analysis?.data?.usage?.count_storage_used /
                  analysis?.data?.usage?.count_storage
                )
              )}
              %
            </div>
          </div>
        </div>
      )} */}
      {!analysis.isLoading && (
        <div className='langend-wraps-chart'>
          <div className="d-flex">
            <div className='icon-chart-box yellow' />
            <div className='text-langend-wrap'>
              <p className='text-langend-light'>
                <span>Used Space</span>
                {MBToSize(analysis?.data?.usage?.count_storage_used.toFixed(2))}
              </p>
            </div>
          </div>
          <div className="d-flex">
            <div className='icon-chart-box gray' />
            <div className='text-langend-wrap'>
              <p className='text-langend-light'>
                <span>Free Space</span>
                {MBToSize(Math.round(analysis?.data?.usage?.count_storage - analysis?.data?.usage?.count_storage_used))}
              </p>
            </div>
          </div>
          <div className="d-flex">
            <div className='text-langend-wrap'>
              <p className='text-langend-light'>
                <span>Total Space</span>
                {MBToSize(analysis?.data?.usage?.count_storage)}
              </p>
            </div>
          </div>
        </div>
      )}
      <ul className='chart-wrap-details'>
        <li>
          <img src={UserAvatar} alt="" />
          <p><span>Users</span> {analysis?.data?.usage?.count_users}</p>
        </li>
        <li>
          <img src={ShareAvatar} alt="" />
          <p><span>Shared Documents</span> {analysis?.data?.usage?.count_shared_file}</p>
        </li>
        <li>
          <img src={TextAvatar} alt="" />
          <p><span>Documents</span> {analysis?.data?.usage?.count_files}</p>
        </li>
        <li>
          <img className='folder' src={FolderAvatar} alt="" />
          <p><span>Folders</span> {analysis?.data?.usage?.count_folders}</p>
        </li>
      </ul>
    </div>
  );
}

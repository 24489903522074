export const GET_FILE_ANALYSIS = 'GET_FILE_ANALYSIS';
export const GET_FILE_ANALYSIS_BY_DATE = 'GET_FILE_ANALYSIS_BY_DATE';
export const GET_FILE_ANALYSIS_SUCCESS = 'GET_FILE_ANALYSIS_SUCCESS';
export const GET_FILE_ANALYSIS_BY_DATE_SUCCESS =
  'GET_FILE_ANALYSIS_BY_DATE_SUCCESS';
export const GET_FILE_ANALYSIS_FAILURE = 'GET_FILE_ANALYSIS_FAILURE';
export const GET_FILE_ANALYSIS_BY_DATE_FAILURE =
  'GET_FILE_ANALYSIS_BY_DATE_FAILURE';

export const getFileAnalysis = () => ({
  type: GET_FILE_ANALYSIS,
});

export const getFileAnalysisByDate = ({ bydate, all, time, unit_of_time }) => ({
  type: GET_FILE_ANALYSIS_BY_DATE,
  payload: { bydate, all, time, unit_of_time },
});

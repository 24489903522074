import React, { useRef, useEffect } from 'react';
import SkyLight from 'react-skylight';
import { emitter } from '../../utils/emitter';
import { POPUP_EVENTS } from '../../constants/events';
import PropTypes from 'prop-types';
import { ISOstringToLocalTime } from '../../utils/datetime';

export default function ShowReminder({ time, items, selectReminderToEdit }) {
  const popupRef = useRef(null);
  const newFolderPopupStyle = {
    width: '30%',
    minHeight: 0,
    padding: 0,
    marginLeft: '-15%',
    marginTop: '-8%',
    borderRadius: '10px',
  };

  const showPopup = () => {
    if (popupRef.current) popupRef.current.show();
  };

  const selectReminder = (e, reminder)=> {
    selectReminderToEdit(e, reminder);
    popupRef.current.hide();
  };

  function listenToTheEmitter() {
    emitter.on(POPUP_EVENTS.SHOW_REMINDER, showPopup);
  }
  useEffect(() => {
    listenToTheEmitter();
    return () => {
      emitter.off(POPUP_EVENTS.SHOW_REMINDER, showPopup);
    };
  });
  return (
    <SkyLight
      dialogStyles={newFolderPopupStyle}
      ref={popupRef}
      hideOnOverlayClicked
    >
      <div className="show-reminder">
        <div className="show-reminder__time">{time}</div>
        <div className="show-reminder__items">
          {items.map(r => (
            <div key={r.reminder_id} className="show-reminder__item" onClick={(e) => selectReminder(e, r)}>
              <div className="stick" />
              <div className="info" style={{ flex: 1 }}>
                <div className="info__time">{r.all_day ? 'All day' : `${ISOstringToLocalTime(r.start_time)} - ${ISOstringToLocalTime(r.end_time)}`}</div>
                <div className="info__title">{r.title}</div>
              </div>
            </div>
          ))}
        </div>
        <div className="show-reminder__action">
          <button type="button" onClick={() => { emitter.emit(POPUP_EVENTS.CREATE_REMINDER); popupRef.current.hide(); }}>Create New Reminder</button>
        </div>
      </div>
    </SkyLight>
  );
}
ShowReminder.propTypes = {
  time: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  selectReminderToEdit: PropTypes.func.isRequired,
};

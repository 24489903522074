import React, {
    useCallback,
    useState,
    useRef,
    createRef,
    useEffect,
} from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { emitter } from '../utils/emitter';
import { EVENTS } from '../constants/events';
import { getItemLocalStorage, setItemLocalStorage, clearLocalStorage } from '../utils/localStorage';
import { verifyOtp, resendOtp } from '../redux/actions';
import CONFIG from '../constants/config';
import Logo from '../assets/images/Logo.svg';
import PCImg from '../assets/images/signin/pc.png';
import Arrow from '../assets/images/signin/Vector.png';
import OtpInput from 'react-otp-input';
// import CONFIG from '../constants/config';
// import { notifySuccess, notifyError } from '../components/common/Alert';

function Otp({ match, location }) {

    const history = useHistory();
    const dispatch = useDispatch();
    // const [code, setCode] = useState([]);
    const [errMsg, setErrMsg] = useState('');
    const [sendOtpMsg, setSendOtpMsg] = useState('');
    const [timeToResend, setTimeToResend] = useState(getItemLocalStorage('timeToResend') ? getItemLocalStorage('timeToResend') : 60);
    const refs = useRef([0, 1, 2, 3, 4, 5].map(() => createRef()));

    const [otp, setOtp] = useState('');
    const [seconds, setSeconds] = useState(60);

    useEffect(() => {
        if (seconds > 0) {
            setTimeout(() => setSeconds(seconds - 1), 1000);

        }
    });

    const handleVerifyOtp = useCallback((otp) => {
        let email, resetPassword;
        if (location.state) {
            ({ email, resetPassword } = location.state);
        }
        dispatch(verifyOtp({ otp_token: otp, resetPassword, email, history }));

    }, [dispatch, history, location]);

    const handleResendOtp = () => {
        setOtp('')
        dispatch(resendOtp());
        setSeconds(60);
    };

    // function handleDelete(index, e) {
    //     if (!code[index] && (e.keyCode === 46 || e.keyCode === 8)) {
    //         if (index !== 0) refs.current[index - 1].current.focus();
    //     }
    // }

    function listenToTheEvent() {
        emitter.on(EVENTS.VERIFY_OTP_FAIL, (msg) => { setErrMsg(msg); });
    }

    useEffect(() => {
        if (refs.current[0].current) refs.current[0].current.focus();
        axios.get(`${CONFIG.BASE_URL}/get-phonenumber`, { withCredentials: true }).then(({ data }) => setSendOtpMsg(data.msg));
        listenToTheEvent();
    }, []);

    useEffect(() => {
        const interval = setTimeout(() => {
            if (timeToResend) {
                setTimeToResend(timeToResend - 1);
                setItemLocalStorage('timeToSend', timeToResend - 1);
            } else {
                clearLocalStorage('timeToSend');
                clearInterval(interval);
            }
        }, 1000);
        return () => {
            clearLocalStorage('timeToSend');
            clearInterval(interval);
        };
    }, [timeToResend]);



    const prevPage = (e) => {
        history.push('/login');
        // setIsemailpage(true);
    }

    const handleChange = (otp) => {
        // console.log(otp);
        setOtp(otp);
        if (otp.length > 5) {
            handleVerifyOtp(otp);
        }
    }

    return (
        <section className="signin-section">
            <div className="container">
                <div className="signin-page">
                    <div className="row">
                        <div className="logo-wrap">
                            <img src={Logo} alt="logo" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6 col-xs-12">
                            <div className="signin -text-wrap">
                                <h3 className="title">Hey There</h3>
                                <p className="text">Welcome to DocQuik!</p>
                                <p className="text">You are just one step away to your feed.</p>
                                <div className="pc-img-wrap">
                                    <img src={PCImg} alt="pc-img" />
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-xs-12" >
                            <div className="form-wrap">
                                <div className="title-wrap">
                                    <button type="button" className="prev-btn" onClick={prevPage}>
                                        <img src={Arrow} alt="prev-btn" />
                                    </button>
                                    <h3 className="flex-fill form-title text-center"> Enter OTP</h3>
                                </div>
                                <h4 className="otp-text text-center">
                                    {location.state ? location.state.msg : sendOtpMsg},
                                    <br />
                                    Please enter the same here to login
                                </h4>
                                <div className="otp-wrap ">
                                    <OtpInput
                                        className={'otp'}
                                        value={otp}
                                        onChange={handleChange}
                                        numInputs={6}
                                        shouldAutoFocus={true}
                                    />
                                </div>
                                {seconds > 0 ?
                                    <h4 className="otp-time-text text-center"> Please wait  {seconds} second to resend</h4>
                                    :
                                    <h4 className="otp-time-text text-center">
                                        Didn't get the code?
                                        <br />
                                        <button
                                            type="button"
                                            onClick={handleResendOtp}
                                            style={{
                                                background: 'transparent',
                                                border: '0',
                                                padding: '0',
                                                color: '#008caa',
                                                fontWeight: '600',
                                                marginTop: '10px'
                                            }}
                                        >
                                            Send Back
                                        </button>
                                    </h4>
                                }
                            </div>
                        </div>
                    </div>
                </div>



            </div>
        </section>
    )
}

export default Otp

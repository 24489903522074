import React, { useState } from 'react';
import {
  getMemberDocuments,
  restoreDocuments,
} from '../../../services/governance';
import { notifyErrorMsg, notifySuccess } from '../../common/Alert';
import { Button, Form } from 'react-bootstrap';
import SelectMember from '../../task/admin/SelectMember';
import { BsCloudArrowUp } from 'react-icons/bs'
import { MdOutlineSchedule } from 'react-icons/md'
import getDefaultThumbnail from '../../../utils/getDefaultThumbnail';
import Folder from '../../../assets/images/folder.svg'
import moment from 'moment';
export default function RestoreDocuments({ memberList }) {
  const [selectedMember, setSelectedMember] = useState(null);
  const [docs, setDocs] = useState(false);
  const [checkedList, setCheckedList] = useState([]);
  const [folderCheckedList, setFolderCheckedList] = useState([]);
  const [hasRes, setHasRes] = useState(false);
  const handleSelectMember = (member) => {
    if (
      typeof member === 'object' &&
      member !== null &&
      Object.keys(member).length
    ) {
      setSelectedMember(member);
    }
  };

  const changeCheckedList = ({
    fileId = undefined,
    folderId = undefined,
  }) => () => {
    if (fileId) {
      if (checkedList.includes(fileId)) {
        setCheckedList(checkedList.filter((f) => f !== fileId));
      } else {
        setCheckedList([...checkedList, fileId]);
      }
    } else if (folderId) {
      if (folderCheckedList.includes(folderId)) {
        setFolderCheckedList(folderCheckedList.filter((f) => f !== folderId));
      } else {
        setFolderCheckedList([...folderCheckedList, folderId]);
      }
    }
  };
  const handleCheckAll = () => {
    if (checkedList.length + folderCheckedList.length === docs.length) {
      setCheckedList([]);
      setFolderCheckedList([]);
    } else {
      const arr = [];
      const arr2 = [];
      docs.forEach((d) => {
        if (d.file_id) {
          arr.push(d.file_id);
        } else if (d.folder_id) {
          arr2.push(d.folder_id);
        }
      });
      setCheckedList(arr);
      setFolderCheckedList(arr2);
    }
  };
  const findDocuments = () => {
    getMemberDocuments({
      member_id: selectedMember?.user_id,
    })
      .then(({ msg, docs: res }) => {
        setDocs(res);
        setHasRes(true);
        notifySuccess(msg);
      })
      .catch((err) => notifyErrorMsg(err));
  };
  const handleCancel = () => {
    setSelectedMember(null);
    setHasRes(false);
    setDocs([]);
    setCheckedList([]);
    setFolderCheckedList([]);
  };
  const handleRestore = () => {
    restoreDocuments({
      member_id: selectedMember.user_id,
      file_ids: checkedList,
      folder_ids: folderCheckedList,
    })
      .then(({ msg }) => {
        setDocs(docs.filter((f) => !checkedList.includes(f.file_id) && !folderCheckedList.includes(f.folder_id)));
        setCheckedList([]);
        setFolderCheckedList([]);
        notifySuccess(msg);
      })
      .catch((err) => notifyErrorMsg(err));
  };

  return (
    <div className="chart-card mt-4" style={{ borderRadius: 10 }}>
      {!hasRes && (
        <Form.Group>
          <Form.Label className="form-label2">Restore the documents of the user that were deleted.</Form.Label>
          <div className="d-flex">
            <div className="w-100 pe-3">
              <SelectMember
                data={memberList}
                callback={handleSelectMember}
                placeholder=""
              />
            </div>
            <Button style={{ padding: '11px 32px' }} onClick={findDocuments}>Submit</Button>
          </div>
        </Form.Group>
      )}
      {hasRes && (
        <div className="row mt-4 ai-center ml-3">
          <div className="col-sm-6"><span style={{ color: '#334D6E', fontWeight: '500', opacity: '0.7' }}>Owner:</span> <span style={{ color: '#334D6E', fontWeight: '500' }}>{selectedMember?.email}</span></div>
          <div className='col-sm-6'>
            <div className='text-end'>
              <Button
                style={{ marginRight: '1rem' }}
                disabled={!checkedList.length && !folderCheckedList.length}
                onClick={handleRestore}
              >
                Restore Documents
              </Button>
              <Button
                style={{
                  maxWidth: '75px'
                }}
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      )}
      <div className="">
        {docs.length ? (
          <table
            className="ml-3 c-table shadow-none"
            cellPadding="0"
            cellSpacing="0"
            border="0"
            style={{ width: '100%' }}
          >
            <thead>
              <tr>
                <th
                  style={{
                    width: '1%',
                    paddingLeft: '2rem',
                    paddingRight: '4rem',
                    textAlign: 'center',
                  }}
                >
                  <input
                    type="checkbox"
                    checked={
                      checkedList.length + folderCheckedList.length ===
                      docs.length
                    }
                    onChange={handleCheckAll}
                  />
                </th>
                <th style={{ textAlign: 'left' }}>Name</th>
                <th style={{ textAlign: 'center' }}>
                  Created Time
                </th>
                <th style={{ textAlign: 'center' }}>
                  Modified Time
                </th>
              </tr>
            </thead>
            <tbody>
              {docs.map((f) => (
                <tr key={f.file_id || f.folder_id}>
                  <td style={{ paddingLeft: '2rem', paddingRight: '4rem' }}>
                    <input
                      type="checkbox"
                      checked={
                        checkedList.includes(f.file_id) ||
                        folderCheckedList.includes(f.folder_id)
                      }
                      onChange={changeCheckedList({
                        fileId: f.file_id,
                        folderId: f.folder_id,
                      })}
                    />
                  </td>
                  <td>
                    <span className='d-flex align-items-center' data-tip={f.new_name || f.folder_name} data-for="fileName">
                      <img className="folder-icon" src={f.folder_id ? Folder : getDefaultThumbnail(f.type)} alt="folder" />
                      <span style={{ whiteSpace: 'nowrap', maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis' }}>{f.new_name || f.folder_name}</span>
                    </span>
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    <span className='d-flex align-items-center justify-content-center'>
                      <span style={{ marginRight: '8px', lineHeight: '0', fontSize: '14px' }}><BsCloudArrowUp /></span>{moment(f.created_at).format('MMM DD, YYYY')}
                    </span>
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    <span className='d-flex align-items-center justify-content-center'>
                      <span style={{ marginRight: '8px', lineHeight: '0', fontSize: '14px' }}><MdOutlineSchedule /></span>
                      {moment(f.updated_at).format('MMM DD, YYYY')}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : null}
      </div>
    </div>
  );
}


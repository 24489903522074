import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import { appToWeb, checkMail } from '../services/authentication';
import { login } from '../redux/actions';
import Logo from '../assets/images/Logo.svg';
import PCImg from '../assets/images/signin/pc.png';
import Microsoft from '../assets/images/signin/microsoft.png';
import Yahoo from '../assets/images/signin/Yahoo logo.png';
import Google from '../assets/images/signin/google.png';
import Arrow from '../assets/images/signin/Vector.png';
import Icon from '../assets/images/signin/icon.png';
import ReCAPTCHA from 'react-google-recaptcha';
import { CookieConsent } from '../components/common/CookieConsent';
import CONFIG from '../constants/config';
import { notifySuccess, notifyError } from '../components/common/Alert';
import { Formik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import SkyLight from 'react-skylight';

const pwdPopupStyle = {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '40%',
    margin: 'auto',
    height: 'fit-content',
    minHeight: '',
    borderRadius: '10px',
};

function Signin(props) {

    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();

    const [isValidEmail, setIsValidEmail] = useState(null);
    const [pwdExpired, setPwdExpired] = useState(false);
    const pwdPopup = useRef(null);
    const pwdFocus = useRef(null);
    const emailFocus = useRef(null);
    const [loginInput, setLoginInput] = useState({
        email: '',
        password: '',
        captcha: '',
    });

    const [isRevealPwd, setIsRevealPwd] = useState(false);
    const [seconds, setSeconds] = useState(60);
    const [recaptcha, setRecaptcha] = useState('');

    useEffect(() => {
        if (seconds > 0) {
            setTimeout(() => setSeconds(seconds - 1), 1000);

        }
    });

    const cookiAcceptHandler = () => {
        emailFocus?.current?.focus()
    }

    // useEffect(() => {
    //     emailFocus?.current?.focus()
    // }, [emailFocus])

    var reCaptCha = (value) => {
        setRecaptcha(value)
    };

    const handleCheckMail = useCallback(async (email) => {
        // console.log(email);

        const data = await checkMail({
            payload: { email: email, captcha: recaptcha },
        });
        if (data.success) {
            if (data.pwd_validity_period <= 0) {
                setPwdExpired(true);
                pwdPopup.current.show();

            }
            setIsValidEmail(true);
            setLoginInput((state) => ({ ...state, email: email, captcha: recaptcha }))
        } else {
            setIsValidEmail(false);
            notifyError(data?.data?.msg);
        }
    }, [recaptcha]);

    const showOtpScreen = useCallback((password) => {
        setLoginInput({ ...loginInput, password: password })
        dispatch(
            login({ email: loginInput.email, password: password, history })
        );
    }, [dispatch, history, loginInput, loginInput.email, loginInput.password]);

    const submitEmail = (values, actions) => {
        // e.preventDefault();
        if (recaptcha !== '') {
            if (isValidEmail) {
                pwdFocus?.current?.focus();
                showOtpScreen(values.password);

            }
            else {
                handleCheckMail(values.email);
                pwdFocus?.current?.focus();
            };
        }
    }

    const forget = () => {
        axios({
            url: `${CONFIG.BASE_URL}/forgot-password/`,
            method: 'POST',
            data: {
                email: loginInput.email,
            },
            withCredentials: true,
        })
            .then(({ data }) => {
                console.log(data);
                notifySuccess(data.msg);
                history.push('/otp', {
                    msg: data.msg,
                    resetPassword: true,
                    email: loginInput.email,
                });
            })
            .catch((err) => notifyError(err.response.data.msg));
    };

    useEffect(() => {
        if (isValidEmail) {
            pwdFocus?.current?.focus();
        }
    }, [isValidEmail])

    const prevPage = (e) => {
        e.preventDefault();
        setIsValidEmail(false);
        emailFocus?.current?.focus();
    }

    function googleSignIn() {
        window.open(CONFIG.GOOGLE_OAUTH, '_self');
    }

    function microsoftSignIn() {
        window.open(CONFIG.MICROSOFT_OAUTH, '_self');
    }

    function yahooSignIn() {
        window.open(CONFIG.YAHOO_OAUTH, '_self');
    }

    useEffect(() => {
        const { search = '' } = location;
        if (search.includes('client_id')) {
            appToWeb({ client_id: search.split('=')[1] })
                .then(() => {
                    window.location.assign('/home');
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    const SigninSchema = Yup.object().shape({
        email: Yup.string().email('Invalid email').required('Required'),
    });
    const passSchema = Yup.object().shape({
        password: Yup.string().required('Required'),
    });

    return (
        <section className="signin-section">
            <div className="container">
                <div className="signin-page">
                    <div className="row">
                        <div className="logo-wrap">
                            <img src={Logo} alt="logo" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6 col-xs-12">
                            <div className="signin -text-wrap">
                                <h3 className="title">Hey There</h3>
                                <p className="text">Welcome to DocQuik!</p>
                                <p className="text">You are just one step away to your feed.</p>
                                <div className="pc-img-wrap">
                                    <img src={PCImg} alt="pc-img" />
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-xs-12" >
                            <div className="form-wrap">

                                {isValidEmail ?
                                    <>
                                        <div className="title-wrap">
                                            <button type="button" className="prev-btn" onClick={prevPage}><img src={Arrow} alt="prev-btn" /></button>   <h3 className="flex-fill  form-title text-center"> Welcome To Docquick</h3>
                                        </div>
                                        <div className="password-email text-center"><img src={Icon} alt="user-icon" />{loginInput.email}</div>
                                        <Formik initialValues={{ password: '' }} validationSchema={passSchema} onSubmit={(values, actions) => submitEmail(values, actions)}>
                                            {({ errors, values, touched, handleBlur, handleChange, handleSubmit }) => (
                                                <form method="post" onSubmit={handleSubmit}>
                                                    <div className="password-wrap" style={{ marginTop: '25px' }}>

                                                        <input
                                                            name="password"
                                                            placeholder="Password"
                                                            type={isRevealPwd ? "text" : "password"}
                                                            value={values.password}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            ref={pwdFocus}
                                                        />
                                                        <i className="icon-eye" onClick={() => setIsRevealPwd(!isRevealPwd)}>
                                                            {isRevealPwd ?
                                                                <FaEyeSlash />
                                                                :
                                                                <FaEye />
                                                            }
                                                        </i>

                                                    </div>
                                                    {errors.password && touched.password ? <p className="text-danger mb-0">{errors.password}</p> : null}
                                                    <div className="ms-auto">
                                                        <Link to="/reset-password" onClick={e => { forget(); e.preventDefault(); }} className="forgot-pass" >Forgot Password?</Link>
                                                    </div>

                                                    <button type='submit' className="form-btn">Next</button>
                                                </form>
                                            )}
                                        </Formik>

                                    </>

                                    :
                                    <>

                                        <h3 className="form-title text-center"> Sign In</h3>
                                        <Formik initialValues={{ email: '' }} validationSchema={SigninSchema} onSubmit={(values, actions) => submitEmail(values, actions)}>
                                            {({ errors, values, touched, handleBlur, handleChange, handleSubmit }) => (
                                                <form method="post" onSubmit={handleSubmit}>
                                                    <div className="email-wrap" style={{ marginTop: '35px' }}>
                                                        <input ref={emailFocus} type="email" name="email" placeholder="Email" value={values.email} onChange={handleChange} onBlur={handleBlur} />
                                                        {errors.email && touched.email ? <p className="text-danger mb-0">{errors.email}</p> : null}
                                                    </div>
                                                    <div className="captcha-wrap text-center">
                                                        <ReCAPTCHA
                                                            sitekey="6LeoCbgZAAAAAIGYlsqBj92wrqWamB-9C_RDMKKB"
                                                            hl="en"
                                                            onChange={reCaptCha}
                                                        />
                                                    </div>

                                                    <button type='submit' className="form-btn">Next</button>
                                                </form>
                                            )}
                                        </Formik>
                                    </>
                                }
                                <p className="signin-link text-center">Other sign in with another account</p>
                                <div className="other-signin-btns">
                                    <button type="button" className="signin-btn" onClick={microsoftSignIn}>Microsoft <img src={Microsoft} alt="microsoft" /></button>
                                    <button type="button" className="signin-btn" onClick={yahooSignIn}>Yahoo <img src={Yahoo} alt="microsoft" /></button>
                                    <button type="button" className="signin-btn" onClick={googleSignIn}>Google <img src={Google} alt="microsoft" /></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



            </div>
            {props.match.url === '/login' && <CookieConsent acceptHandler={cookiAcceptHandler} />}

            <SkyLight ref={pwdPopup} dialogStyles={pwdPopupStyle}>
                <div className="w-100" style={{ paddingTop: '0.5em' }}>
                    Your password expired&nbsp;
                    <span
                        style={{
                            color: '#534cdd',
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            pwdPopup.current.hide();
                            setTimeout(() => {
                                forget();
                            }, 100);
                        }}
                    >
                        Reset your password
                    </span>
                    &nbsp; now.
                </div>
            </SkyLight>
        </section>
    )
}

export default Signin

import {
  CREATE_REPORT_SUCCESS,
  EDIT_REPORT_SUCCESS,
  GET_REPORTS_BY_TASK_ID_SUCCESS,
} from '../actions/reports';
const initialState = {};

export default function reports (state, { type, payload }) {
  switch (type) {
    case CREATE_REPORT_SUCCESS: {
      const { taskId, report } = payload;
      let reportsToUpdate = [];
      if (state[taskId]) reportsToUpdate = state[taskId];
      return {
        ...state,
        [taskId]: [...reportsToUpdate, report],
      };
    }
    case EDIT_REPORT_SUCCESS: {
      const { taskId, report } = payload;
      let reportsToUpdate = [];
      if (state[taskId]) reportsToUpdate = state[taskId];
      reportsToUpdate[reportsToUpdate.length - 1] = report;
      return {
        ...state,
        [taskId]: [...reportsToUpdate],
      };
    }
    case GET_REPORTS_BY_TASK_ID_SUCCESS: {
      const { taskId, reports } = payload;
      return { ...state, [taskId]: [...reports] };
    }
    default:
      return state || initialState;
  }
}


import React from 'react';
import { useSelector } from 'react-redux';
import { PieChart, Pie, Tooltip, Cell } from 'recharts';

const data02 = [];

function MyCustomTooltip({ payload }) {
    return (
        <div className="recharts-tooltip">
            <b>{payload?.[0]?.payload?.name}</b>
            <p>{payload?.[0]?.payload?.value} %</p>
        </div>
    );
}

export default function Analysis({ isHome }) {
    const analysis = useSelector((s) => s.analysis.fileAnalysis);
    const legends = {
        document_rate: 'document',
        txt_rate: 'txt',
        image_rate: 'image',
        powerpoint_rate: 'powerpoint',
        excel_rate: 'excel',
        pdf_rate: 'pdf',
        other_rate: 'other',
    };

    const colors = [
        '#393F93',
        '#95C675',
        '#FEE801',
        '#FFBD28',
        '#FF8A66',
        '#00A9FF',
        '#FF5A46',
    ];
    const mySVG = document.querySelector('#bar-chart .recharts-surface');
    if (mySVG) {
        mySVG.setAttribute('viewBox', '0 0 220 220');
    }
    return (
        <div
            style={{
                background: 'white',
                // marginTop: '1rem',
                textAlign: 'center',
                position: 'relative',
                width: '100%',
                // height: 500,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
            id="bar-chart"
        >
            {!analysis.isLoading && Object.keys(analysis?.data?.rate ?? {}).length ? (
                <PieChart width={200} height={200}>
                    <Pie
                        data={Object.keys(analysis.data.rate).map((k) => ({
                            name: legends[k],
                            value: analysis.data.rate[k],
                        }))}
                        innerRadius={60}
                        outerRadius={80}
                        fill="8884d8"
                    >
                        {Object.keys(analysis.data.rate)
                            .map((k) => ({
                                name: legends[k],
                                value: analysis.data.rate[k],
                            }))
                            .map((entry, index) => (
                                <Cell
                                    style={{ cursor: 'pointer' }}
                                    // onMouseEnter={(e) => console.log(e)}
                                    key={`cell-${index}`}
                                    fill={colors[index % colors.length]}
                                />
                            ))}
                    </Pie>
                    <Tooltip content={<MyCustomTooltip />} formatter />
                </PieChart>
            ) : (
                <PieChart width={400} height={200}>
                    <Pie data={[]} innerRadius={60} outerRadius={80} fill="8884d8">
                        {data02.map((entry, index) => (
                            <Cell
                                key={`cell-${index}`}
                                fill={colors[index % colors.length]}
                            />
                        ))}
                    </Pie>
                </PieChart>
            )}
            {!analysis.isLoading && Object.keys(analysis?.data?.rate ?? {}).length ? (
                <div className="legend">
                    {Object.keys(analysis.data.rate).map((k, i) => (
                        <div className="legend-item" key={k}>
                            {/* {console.log(analysis.data.rate)} */}
                            <div className="icon" style={{ backgroundColor: colors[i] }} />
                            <div className="label">
                                {legends[k]}
                                <span>
                                    <b>{analysis.data.rate[k]} %</b></span>
                            </div>
                        </div>
                    ))}
                </div>
            ) : null}
        </div>
    );
}


Analysis.defaultProps = {
    isHome: false
}
export const CREATE_REPORT = 'CREATE_REPORT';
export const CREATE_REPORT_SUCCESS = 'CREATE_REPORT_SUCCESS';
export const CREATE_REPORT_FAILURE = 'CREATE_REPORT_FAILURE';

export const EDIT_REPORT = 'EDIT_REPORT';
export const EDIT_REPORT_SUCCESS = 'EDIT_REPORT_SUCCESS';
export const EDIT_REPORT_FAILURE = 'EDIT_REPORT_FAILURE';

export const GET_REPORTS_BY_TASK_ID = 'GET_REPORTS_BY_TASK_ID';
export const GET_REPORTS_BY_TASK_ID_SUCCESS = 'GET_REPORTS_BY_TASK_ID_SUCCESS';
export const GET_REPORTS_BY_TASK_ID_FAILURE = 'GET_REPORTS_BY_TASK_ID_FAILURE';

export const createReport = (taskId, jobWorked, nextTarget) => ({
  type: CREATE_REPORT,
  payload: { taskId, jobWorked, nextTarget },
});

export const editReport = (taskId, reportId, jobWorked, nextTarget) => ({
  type: EDIT_REPORT,
  payload: { taskId, reportId, jobWorked, nextTarget },
});

export const getReportsByTaskId = (taskId) => ({
  type: GET_REPORTS_BY_TASK_ID,
  payload: { taskId },
});

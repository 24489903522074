import React from 'react';
import PropTypes from 'prop-types';
import folderIcon from '../../assets/images/folder-big-icon.svg';

export default function FolderItem({
    folder,
    checkBoxValue,
    onClick
}) {

    const { folder_name } = folder;

    return (
        <div
            className={`folders__item trash-folder-item ${checkBoxValue ? 'selected-folder' : ''}`}
            onClick={() => onClick(folder)}
        >
            <div className={`folder-name`}>
                <div className="folder-img-wrap">
                    <img alt="" src={folderIcon} />
                    <span className="count-files-folder">{folder?.total_file ? folder.total_file : 0} Items</span>
                </div>
                <p>{folder_name}
                    {/* <span className='size-folder'>0 MB</span> */}
                </p>
            </div>
        </div>
    );
}

FolderItem.propTypes = {
    handleClick: PropTypes.func.isRequired,
    folder: PropTypes.object.isRequired,
    checkBoxValue: PropTypes.bool
};

FolderItem.defaultProps = {
    checkBoxValue: false
}

import { CHECK_UPLOAD_FILES } from '../actions/uploadFiles';

const initialState = [{
    id: 0,
    name: '',
    size: '',
    percent: 0,
    loaded: 0,
    status: '',
  }];
export default function uploadFiles (state, action) {
  switch (action.type) {
    case CHECK_UPLOAD_FILES: {
        let newState = [...state];
        if (!action.payload) newState = [];
        const fileChange = newState.find(s => s.id === newState.id);
        fileChange.percent = newState.total;
        fileChange.loaded = newState.total;
        return {
            ...newState,
          };
    }
    default:
      return state || initialState;
  }
}

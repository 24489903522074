import axios from 'axios';
import CONFIG from '../constants/config';
export const getReminders = async () => {
  const response = await axios({
    url: `${CONFIG.BASE_URL}/reminders`,
    method: 'GET',
    withCredentials: true,
  });
  return response.data;
};

export const createReminder = async (payload) => {
  const { icon_link, title, start_time, end_time, detail, all_day, google_calendar_id } = payload;
  const response = await axios({
    url: `${CONFIG.BASE_URL}/reminders`,
    method: 'POST',
    withCredentials: true,
    data: {
      all_day,
      icon_link,
      title,
      start_time,
      end_time,
      detail,
      google_calendar_id: google_calendar_id ? google_calendar_id : ''
    },
  });
  return response.data;
};

export const createEventGoogleReminder = async (formData) => {
  const response = await axios({
    // url: `http://localhost:8000/api/calendar/insert`,
    url: `${CONFIG.BASE_URL}/googleeventadd`,
    method: 'POST',
    withCredentials: true,
    data: formData,
  });
  return response.data;
};
export const editEventGoogleReminder = async (formData) => {
  const response = await axios({
    url: `${CONFIG.BASE_URL}/googleeventupdate`,
    // url: `http://localhost:8000/api/calendar/update`,
    method: 'PUT',
    withCredentials: true,
    data: formData,
  });
  return response.data;
};
export const deleteEventGoogleReminder = async (formData) => {
  const response = await axios({
    url: `${CONFIG.BASE_URL}/googleeventdelete`,
    // url: `http://localhost:8000/api/calendar/delete`,
    method: 'DELETE',
    withCredentials: true,
    data: formData,
  });
  return response.data;
};

export const editReminder = async (payload) => {
  const { reminder_id, icon_link, status, title, start_time, end_time, detail, all_day } = payload;
  const response = await axios({
    url: `${CONFIG.BASE_URL}/reminders/${reminder_id}`,
    method: 'PATCH',
    withCredentials: true,
    data: {
      all_day,
      icon_link,
      title,
      start_time,
      end_time,
      detail,
      status,
    },
  });
  return response.data;
};
export const deleteReminder = async (payload) => {
  const response = await axios({
    url: `${CONFIG.BASE_URL}/reminders/${payload}`,
    method: 'DELETE',
    withCredentials: true,
  });
  return response.data;
};

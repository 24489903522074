import { all, call, put, takeEvery } from 'redux-saga/effects';
import { notifyError } from '../components/common/Alert';
import { getFileAnalysis, getFileAnalysisByDate } from '../services/analysis';
import {
  GET_FILE_ANALYSIS,
  GET_FILE_ANALYSIS_SUCCESS,
  GET_FILE_ANALYSIS_BY_DATE,
  GET_FILE_ANALYSIS_BY_DATE_SUCCESS,
} from '../redux/actions/analysis';

function *getFileAnalysisSaga(action) {
  try {
    const result = yield call(getFileAnalysis, action);
    if (result.success) {
      const payload = Object.assign(result);
      delete payload.success;
      yield put({ type: GET_FILE_ANALYSIS_SUCCESS, payload: { rate: payload.rate, usage: payload.usage } });
    } else {
      notifyError(result.data.msg);
    }
  } catch (err) {
    notifyError(err.response.data.msg);
  }
}
function *getFileAnalysisByDateSaga({ payload }) {
  try {
    const result = yield call(getFileAnalysisByDate, payload);
    if (result.success) {
      yield put({
        type: GET_FILE_ANALYSIS_BY_DATE_SUCCESS,
        payload: {
          bydate: payload.bydate,
          data: result.result.reverse(),
        },
      });
    } else {
      notifyError(result.data.msg);
    }
  } catch (err) {
    console.log(err);
    notifyError(err.response.data.msg);
  }
}

export default function *analysisWatcher() {
  yield all([
    takeEvery(GET_FILE_ANALYSIS, getFileAnalysisSaga),
    takeEvery(GET_FILE_ANALYSIS_BY_DATE, getFileAnalysisByDateSaga),
  ]);
}

import React, { useEffect, useState } from 'react';
import { Offcanvas, Accordion } from 'react-bootstrap';
import { BsPin, BsPinFill } from 'react-icons/bs';
// import { MdOutlineCloudUpload } from 'react-icons/md'
import { useSelector, useDispatch } from 'react-redux';
import { closePinbar, stickyPinbar } from '../redux/actions/pinbar';
import { getFileAnalysis } from '../redux/actions/analysis';
import { Scrollbars } from 'react-custom-scrollbars';
import * as scrollbar from '../helpers/scrollbar';
// import PDFIcon from '../assets/images/pdf-icon.svg';
// import DoughnutChart from './common/DoughnutChart';
import CONFIG from '../constants/config';
import Analysis from './common/PieChart';
import AnalysisByDate from './common/AnalysisByDate';
import { bytesToSize, 
    // bytesToSizeNumber, 
    // MBToSize 
} from '../utils/string';
// import { emitter } from '../utils/emitter';
// import { HOME_EVENTS } from '../constants/events';

const unitOfTimes = ['days', 'weeks', 'months', 'months', 'months'];
const times = [7, 2, 1, 3, 6];

export default function PinBar(props) {

    const pinbar = useSelector(s => s.pinbar);
    const usage = useSelector((s) => s.usage);
    const dispatch = useDispatch();

    const [isSticky, setIsSticky] = useState(false);
    const [accordionActive, setAccordionActive] = useState(null);
    const [files, setFiles] = useState([]);
    const [time2, setTime2] = useState(0);

    // console.log(pinbar)

    // const acceptMimeTypes = Object.values(CONFIG.ACCEPT_MIME_TYPES)
    //     .map((arr) => arr.join(','))
    //     .join(',');

    useEffect(() => {
        dispatch(getFileAnalysis());

    });

    const handleClose = () => {
        if (!isSticky) {
            dispatch(closePinbar());
        }
    }

    useEffect(() => {
        setIsSticky(pinbar.pinbarSticky)
    }, [pinbar.pinbarSticky])

    const pinedToggle = () => {
        dispatch(stickyPinbar())
    }

    const mouseOut = (e) => {
        var x = e.clientX;
        var offcanvasWidth = document.getElementsByClassName('pinbar-offcanvas')?.[0]?.offsetWidth;
        var right = window.innerWidth - offcanvasWidth;

        if (!isSticky) {
            if (x < right) {
                dispatch(closePinbar())
            }
        }
    }


    const accordionFunction = (active) => {
        setAccordionActive(accordionActive === active ? null : active)
    }

    useEffect(() => {
        document.addEventListener('mouseout', mouseOut)
        return () => {
            document.removeEventListener('mouseout', mouseOut)
        }
    });

    // const fileHandler = (e) => {
    //     const file = e.target.files;
    //     const fileArray = [];
    //     for (let i = 0; i < file.length; i++) {
    //         fileArray.push(file[i])
    //     }
    //     setFiles([...files, ...fileArray])
    // }

    let percentage = Math.round((usage?.storage_used * 100) / usage?.storage);

    return (
        <Offcanvas
            show={pinbar.pinbarSticky ? pinbar.pinbarOpen : pinbar.pinbarOpen}
            onHide={handleClose}
            placement={'end'}
            className="pinbar-offcanvas"
        >
            <Scrollbars
                renderView={scrollbar.renderView}
                renderTrackHorizontal={scrollbar.renderTrackHorizontal}
                renderTrackVertical={scrollbar.renderTrackVertical}
                renderThumbHorizontal={scrollbar.renderThumb}
                renderThumbVertical={scrollbar.renderThumb}
                universal={true}

            >
                <Offcanvas.Header className="pinbar-header-wrap">
                    <Offcanvas.Title>
                        <button
                            type="button"
                            className={`${pinbar.pinbarSticky ? 'btn-primary btn-pin' : "btn-outline-primary btn-pin"}`}
                            onClick={pinedToggle}
                        >
                            <i className="icon-pin">{pinbar.pinbarSticky ? <BsPinFill /> : <BsPin />}</i>
                            {pinbar.pinbarSticky ? 'Pined' : 'Pin panel'}
                        </button>
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className="pinbar-body-wrap">

                    <Accordion>
                        <Accordion.Item eventKey="0" className="pinbar-accordion-item">
                            <Accordion.Header className="pinbar-accordion-header" onClick={() => accordionFunction(0)}>Storage Used</Accordion.Header>
                            <Accordion.Body className="pinbar-accordion-body">
                                <div className="storage-pinbar-wraps">
                                    <div className="chart-storage-area">
                                        <div className="gauge">
                                            <div className="gauge__fill__bg" style={{ transform: `rotate(calc(1deg * ( -45 + ${percentage} * 1.8)))` }}></div>
                                            <div className="gauge__cover">{bytesToSize(usage.storage_used)}<small>of {bytesToSize(usage.storage)}</small></div>
                                        </div>
                                        <div className="plan-up-groups">
                                            <button type="button" className="btn-primary" style={{ padding: '17px 12px', fontSize: '12px' }}>Upgrade plan</button>
                                            <p className="plan-text">Upgrade your plan and get 128 GB</p>
                                        </div>
                                        {/* <ul className="upload-file-view">
                                            <li>
                                                <div className="file-part">
                                                    <div className="file-icon-wra">
                                                        <img src={PDFIcon} alt="PDF" />
                                                    </div>
                                                    <p className="text-file-names"><span>3 Files</span> PDF</p>
                                                </div>
                                                <p className="file-size-text">5 MB</p>
                                            </li>
                                            <li>
                                                <div className="file-part">
                                                    <div className="file-icon-wra">
                                                        <img src={PDFIcon} alt="PDF" />
                                                    </div>
                                                    <p className="text-file-names"><span>3 Files</span> PDF</p>
                                                </div>
                                                <p className="file-size-text">5 MB</p>
                                            </li>
                                            <li>
                                                <div className="file-part">
                                                    <div className="file-icon-wra">
                                                        <img src={PDFIcon} alt="PDF" />
                                                    </div>
                                                    <p className="text-file-names"><span>3 Files</span> PDF</p>
                                                </div>
                                                <p className="file-size-text">5 MB</p>
                                            </li>
                                        </ul>
                                        <div className="file-upload-section">
                                            <label htmlFor="file-upload" className="file-upload">
                                                <input type="file" multiple id="file-upload" name="files[]" onChange={fileHandler} />
                                                <span className="label-file-design">
                                                    <i className="upload-icon"><MdOutlineCloudUpload /></i>
                                                    Import Files
                                                </span>
                                            </label>
                                        </div> */}
                                    </div>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1" className="pinbar-accordion-item">
                            <Accordion.Header className="pinbar-accordion-header" onClick={() => accordionFunction(1)}>Total Document by Type</Accordion.Header>
                            <Accordion.Body className="pinbar-accordion-body">
                                <div className="document-type-chat">
                                    <Analysis isHome={true} />
                                    {/* <DoughnutChart className="chart-document" /> */}
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2" className="pinbar-accordion-item">
                            <Accordion.Header className="pinbar-accordion-header" onClick={() => accordionFunction(2)}>Total Updated by type</Accordion.Header>
                            <Accordion.Body className="pinbar-accordion-body">
                                <div className="storage-pinbar-wraps">
                                    <AnalysisByDate bydate="updated_at" unit_of_time={unitOfTimes[time2]} time={times[time2]} />
                                    <div className="chart-line-view-filters">
                                        <div className="analysis-buttons">
                                            <button type="button" onClick={() => setTime2(4)} className={`analysis-button ${time2 === 4 ? 'active' : ''}`}>6 Months</button>
                                            <button type="button" onClick={() => setTime2(3)} className={`analysis-button ${time2 === 3 ? 'active' : ''}`}>3 Months</button>
                                            <button type="button" onClick={() => setTime2(2)} className={`analysis-button ${time2 === 2 ? 'active' : ''}`}>1 Months</button>
                                            <button type="button" onClick={() => setTime2(1)} className={`analysis-button ${time2 === 1 ? 'active' : ''}`}>2 Weeks</button>
                                            <button type="button" onClick={() => setTime2(0)} className={`analysis-button ${time2 === 0 ? 'active' : ''}`}>Last 7 days</button>
                                        </div>
                                    </div>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </Offcanvas.Body>
            </Scrollbars>
        </Offcanvas>
    )
}

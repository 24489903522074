import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import Avatar from 'react-avatar-edit';
import SkyLight from 'react-skylight';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { MdKeyboardBackspace, MdOutlinePhotoCamera, MdClear } from 'react-icons/md';
import ImgPlaceholder from '../../../assets/images/img-placeholder.svg'
// import { triggerMouseEvent } from '../../../utils/domInteract';
import {
  createProject,
  editProject,
  getProject,
} from '../../../services/project';
import { getDocs } from '../../../services/documents';
import { getMembers } from '../../../services/authentication';
import { notifySuccess, notifyError } from '../../common/Alert';
// import DeadLineIcon from '../../../assets/images/deadlineicon.png';
import getDefaultThumbnail from '../../../utils/getDefaultThumbnail';
import SelectFile from './SelectFile';
import SelectMember from './SelectMember';
// import TimeIcon from '../../../assets/images/times-grey.png';
import ConfirmButton from '../common/ConfirmButton';
import { Form } from 'react-bootstrap';
// import { Scrollbars } from 'react-custom-scrollbars';
// import * as scrollbar from '../../../helpers/scrollbar';
const popupStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
};
const initialSelectedMember = { designation: '' };

const initialData = {
  projectName: '',
  projectDescription: '',
};
export default function NewProject() {
  const myId = useSelector((s) => s.userInfo.user_id);
  const history = useHistory();
  const {
    params: { id },
  } = useRouteMatch();
  const [avatar, setAvatar] = useState(null);
  const [data, setData] = useState({ ...initialData });
  const [deadLine, setDeadLine] = useState('');
  const [memberList, setMemberList] = useState([]);
  const [selectedMember, setSelectedMember] = useState({
    ...initialSelectedMember,
  });
  const [designationError, setDesignationError] = useState(false);
  const [memberError, setMemberError] = useState(false);
  const [memberClear, setMemberClear] = useState(false);
  const [selectedLeader, setSelectedLeader] = useState({});
  const [oldMembers, setOldMembers] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [selectedLeaders, setSelectedLeaders] = useState([]);
  const [selectedFile, setSelectedFile] = useState({});
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [files, setFiles] = useState([]);
  const popup = useRef(null);

  const handleChange = (name) => (e) => {
    setData({ ...data, [name]: e.target.value });
  };

  const handleChangeDeaLine = (e) => {
    const date = e?.target?.value;
    setDeadLine(date);
    // setDeadLine({ ...deadLine, [name]: e.target.value });
  };

  const onClose = () => {
    setAvatar(null);
  };

  const onCrop = (preview) => {
    setAvatar(preview);
  };

  const onBeforeFileLoad = (elem) => {
    if (elem.target.files[0].size > 1048576) {
      elem.target.value = '';
    }
  };

  const chooseAvatar = () => {
    popup.current.show();
  };

  const handleSelectMember = (member) => {
    if (
      typeof member === 'object' &&
      member !== null &&
      Object.keys(member).length
    ) {
      setSelectedMember({ ...selectedMember, ...member });
      setMemberError(false);
    }
  };

  const handleSelectLeader = (member) => {
    if (
      typeof member === 'object' &&
      member !== null &&
      Object.keys(member).length
    ) {
      setSelectedLeader(member);
    }
  };

  const handleSelectFiles = (file) => {
    setSelectedFile(file);
  };

  const getDataSelectLeader = () =>
    !id
      ? memberList.filter(
        (m) =>
          m.user_id !== myId &&
          !selectedLeaders.map((t) => t.user_id).includes(m.user_id)
      )
      : memberList.filter(
        (m) =>
          m.user_id !== myId &&
          m.active &&
          !selectedLeaders.map((t) => t.user_id).includes(m.user_id)
      );

  const getDataSelectMember = () =>
    !id
      ? memberList.filter(
        (m) =>
          !selectedMembers.map((t) => t.user_id).includes(m.user_id)
          // &&
          // !selectedLeaders.map((t) => t.user_id).includes(m.user_id) 
          &&
          m.user_id !== myId
      )
      : memberList.filter(
        (m) =>
          !selectedMembers.map((t) => t.user_id).includes(m.user_id) &&
          m.designation !== 'lead' &&
          m.active
          &&
          m.user_id !== myId
      );

  const addFile = () => {
    if (Object.keys(selectedFile).length !== 0) {
      setSelectedFiles([...selectedFiles, selectedFile]);
      setSelectedFile({});
    }
  };

  const removeFileFromList = (file_id) => {
    setSelectedFiles([...selectedFiles.filter((f) => f.file_id !== file_id)]);
  };

  const addMember = () => {
    if (selectedMember?.user_id && selectedMember?.designation !== '') {
      setMemberClear(true);
      setSelectedMembers([...selectedMembers, selectedMember]);
      setSelectedMember({ ...initialSelectedMember });
      setDesignationError(false)
      setMemberError(false)
      setMemberClear(false);

    }
    if (!selectedMember?.user_id) {
      setMemberError(true)
    }
    if (selectedMember?.designation === '') {
      setDesignationError(true)
    }
    // setTimeout(() => {
    //   const [, clearButton] = document.getElementsByClassName('clear-button');
    //   triggerMouseEvent(clearButton, 'mousedown');
    // }, 100);
  };

  const addLeader = () => {
    if (selectedLeader?.user_id) {
      setSelectedLeaders([...selectedLeaders, selectedLeader]);
      setSelectedLeader({});
    }
    // setTimeout(() => {
    //   const [clearButton] = document.getElementsByClassName('clear-button');
    //   triggerMouseEvent(clearButton, 'mousedown');
    // }, 100);
  };

  const removeMemberFromList = (user_id) => {
    setSelectedMembers([
      ...selectedMembers.filter((mem) => mem.user_id !== user_id),
    ]);
  };

  const removeLeaderFromList = (user_id) => {
    setSelectedLeaders([
      ...selectedLeaders.filter((mem) => mem.user_id !== user_id),
    ]);
  };

  function formatDayOrMonth(x) {
    return x.toString().padStart(2, '0');
  }
  const handleSubmit = () => {
    if (id) {
      // console.log(avatar);
      editProject({
        projectId: id,
        image: avatar ? avatar.search('cloudfront') !== -1 ? undefined : avatar : avatar,
        project_name: data.projectName,

        // deadline: `${deadLine.year}-${formatDayOrMonth(
        //   months.indexOf(deadLine.month) + 1
        // )}-${formatDayOrMonth(deadLine.day)}`,
        deadline: deadLine,
        description: data.projectDescription,
        project_files: selectedFiles.map((f) => f.file_id),
        new_members: selectedMembers
          .filter(
            (mem) => !oldMembers.map((o) => o.user_id).includes(mem.user_id)
          )
          .map((mem) => ({
            member_id: mem.user_id,
            designation: mem.designation,
          })),
        deleted_members: oldMembers
          .filter(
            (mem) =>
              !selectedMembers.map((o) => o.user_id).includes(mem.user_id)
          )
          .map((mem) => mem.user_id),
      })
        .then(({ msg }) => {
          notifySuccess(msg);
          history.goBack();
          // history.push(`/projects/${id}`);
        })
        .catch((err) => notifyError(err?.response?.data?.msg));
    } else {
      createProject({
        image: avatar,
        project_name: data.projectName,
        start_date: moment(Date.now()).format('YYYY-MM-DD'),
        deadline: deadLine,
        // deadline: `${deadLine.year}-${formatDayOrMonth(
        //   months.indexOf(deadLine.month) + 1
        // )}-${formatDayOrMonth(deadLine.day)}`,
        description: data.projectDescription,
        project_files: selectedFiles.map((f) => f.file_id),
        members: selectedMembers.map((mem) => ({
          member_id: mem.user_id,
          designation: mem.designation,
        })),
        lead_ids: selectedLeaders.map((l) => l.user_id),
      })
        .then(({ msg }) => {
          notifySuccess(msg);
          history.push('/projects');
        })
        .catch((err) => {
          notifyError(err?.response?.data?.msg)
        });
    }
  };

  const renderSubmitButton = id ? (
    <ConfirmButton
      buttonClass="button ml-4"
      buttonText="Edit"
      handleClick={handleSubmit}
      message="Are you sure you want to edit this project"
      buttonStyle={{ background: '#016288', borderColor: '#016288', color: "#FFF", marginLeft: '16px' }}
    >Edit</ConfirmButton>
  ) : (
    <button type="button" className="button" onClick={handleSubmit}>
      Create
    </button>
  );

  useEffect(() => {
    getMembers()
      .then(({ members }) => {
        setMemberList(members);
      })
      .catch((err) => {
        console.log(err);
      });
    getDocs()
      .then(({ files: res }) => {
        setFiles(res);
      })
      .catch((err) => {
        console.log(err);
      });
    if (id) {
      getProject({ projectId: id })
        .then(({ project }) => {
          const {
            project_name,
            description,
            image,
            deadline: dl,
            projectTeam,
            projectFiles
          } = project;
          setData({
            projectName: project_name,
            projectDescription: description,
          });
          setAvatar(image);
          setDeadLine(moment(dl).format('YYYY-MM-DD'));
          // setDeadLine({
          //   month: months[moment(dl).month()],
          //   day: moment(dl).date(),
          //   year: moment(dl).year(),
          // });
          setSelectedFiles(projectFiles)
          setSelectedMembers([
            ...projectTeam.filter((m) => m.designation !== 'lead'),
          ]);
          setOldMembers([
            ...projectTeam.filter((m) => m.designation !== 'lead'),
          ]);
        })
        .catch((err) => console.error(err));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderSelectedFiles = (f, i) => {
    let fileType = ''
    if (f.type) {
      fileType = f.type
    } else {
      const fileName = f.file_name?.split(".");
      fileType = fileName?.[1]
    }
    return <div
      className="add-member-wrap"
      key={i}
    >
      <div className='memeber-image-wrap'>
        <img
          alt=""
          src={
            f.thumbnail_path
              ? f.thumbnail_path
              : getDefaultThumbnail(fileType)
          }
        />
      </div>
      <div className="member-info file-name">
        <div className="text--small">{f.new_name}</div>
      </div>
      <button type='button' className='btn-remove-member' onClick={() => removeFileFromList(f.file_id)}>
        <MdClear />
      </button>

    </div>
  }

  return (
    <>
      {/* <Scrollbars
        renderView={scrollbar.renderView}
        renderTrackHorizontal={scrollbar.renderTrackHorizontal}
        renderTrackVertical={scrollbar.renderTrackVertical}
        renderThumbHorizontal={scrollbar.renderThumb}
        renderThumbVertical={scrollbar.renderThumb}
        universal={true}
      > */}
      <div className="create-project-section">
        <div
          className="w-100"
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          <div className='page-top-header workflow-top-header ps-0 pt-0' style={{ paddingBottom: '16px' }}>
            <h1 className="page-title">Project</h1>
          </div>
          <div className="d-flex align-center back-manu-handler">
            <i className="icon-arrow-left interactive" onClick={() => history.goBack()}><MdKeyboardBackspace /></i>
            <span className="heading--active">{id ? 'Edit project' : 'Create new project'}</span>
          </div>
          <div className="create-project-wraps" >
            <div className="project-info">
              <div className="heading--active">Information Project</div>
              <div className="project-thumbnail">
                <div className='project-image'>
                  {avatar ?
                    <img alt="" src={avatar} />
                    :
                    <span className='p-name'><MdOutlinePhotoCamera /></span>
                  }
                </div>
                <button type="button" className='file-select-btn' onClick={chooseAvatar}>
                  <i className="icon-camera"><MdOutlinePhotoCamera /></i>
                </button>
              </div>
              <Form.Group className="form-group2">
                <Form.Label className='form-label2 required'>Project name </Form.Label>
                <Form.Control
                  type="text"
                  className="primary-input"
                  value={data.projectName}
                  onChange={handleChange('projectName')}
                  placeholder="Project name"
                />
              </Form.Group>
              <Form.Group className="form-group2">
                <Form.Label className='form-label2 required'>Deadline</Form.Label>
                <Form.Control type="date" className="primary-input" value={deadLine} onChange={handleChangeDeaLine} />
              </Form.Group>
              {/* <div className="d-flex align-center mb-3">
                <img
                  alt=""
                  src={DeadLineIcon}
                  style={{ width: '2.2rem', marginRight: '1rem' }}
                />
                <div style={{ display: 'flex' }}>
                  <div>Deadline</div>
                  <div style={{ color: 'red', marginLeft: 5 }}>*</div>
                </div>
              </div>
              <div className="d-flex deadline-select">
                <select
                  value={deadLine.month}
                  onChange={handleChangeDeaLine('month')}
                >
                  <option value="" disabled>
                    Month
                  </option>
                  {months.map((m) => (
                    <option key={m} value={m}>
                      {m}
                    </option>
                  ))}
                </select>
                <select
                  value={deadLine.day}
                  onChange={handleChangeDeaLine('day')}
                >
                  <option value="" disabled>
                    Day
                  </option>
                  {days.map((d) => (
                    <option key={d} value={d}>
                      {d}
                    </option>
                  ))}
                </select>
                <select
                  value={deadLine.year}
                  onChange={handleChangeDeaLine('year')}
                >
                  <option value="" disabled>
                    Year
                  </option>
                  {years.map((y) => (
                    <option key={y} value={y}>
                      {y}
                    </option>
                  ))}
                </select>
              </div> */}
              <Form.Group className="form-group2">
                <Form.Label className='form-label2 required'>Project Description</Form.Label>
                <Form.Control
                  as="textarea"
                  className='primary-input'
                  rows={5}
                  value={data.projectDescription}
                  onChange={handleChange('projectDescription')}
                />
              </Form.Group>

            </div>
            <div className="project-task">
              {/* Section: Leaders */}
              {!id && (
                <>
                  <div className="heading--active">Leader</div>
                  <Form.Group className='form-group2'>
                    <Form.Label className='form-label2'>Select leader</Form.Label>

                    <div className="both-flex-group">
                      <div className="input-wraps">
                        <SelectMember
                          data={getDataSelectLeader()}
                          callback={handleSelectLeader}
                          placeholder="Select leader"
                        />
                      </div>
                      <div className="button-wraps" style={{ alignSelf: 'flex-end' }}>
                        <button
                          type="button"
                          className="button"
                          onClick={addLeader}
                        >
                          Add
                        </button>
                      </div>
                    </div>
                    {Array.isArray(selectedLeaders) && selectedLeaders.length > 0 ?
                      <div className="list-of-selected mt-3">
                        {selectedLeaders.map((mem) => (
                          <div key={mem.user_id} className="add-member-wrap"
                          >
                            <div style={{ width: 'calc(100% - 15px)', display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                              <div className='memeber-image-wrap'>
                                {mem.avatar_url ? (
                                  <img
                                    alt=""
                                    src={mem.avatar_url}
                                  // style={{
                                  //   width: '4.5rem',
                                  //   height: '4.5rem',
                                  //   marginRight: '1rem',
                                  // }}
                                  />
                                ) : (
                                  <span className='m-name'>{mem.first_name[0]}{mem.last_name[0]}</span>
                                )}
                              </div>
                              <div className="member-info" style={{ width: 'calc(100% - 44px)' }}>
                                <div className="title--bold">{mem.first_name}</div>
                                {mem?.designation ? <div className="text">{mem.designation}</div> : null}
                              </div>
                            </div>
                            <button type='button' className='btn-remove-member' onClick={() => removeLeaderFromList(mem.user_id)}>
                              <MdClear />
                            </button>

                          </div>
                        ))}
                      </div>
                      :
                      null
                    }
                  </Form.Group>
                </>
              )}

              {/* Section: Members */}
              <div className="heading--active" style={{ marginTop: '17px' }}>Members</div>
              <Form.Group className='form-group2'>
                <Form.Label className='form-label2'>Select member</Form.Label>

                <div className="d-flex row">
                  <div className="col-sm-5 col-xs-12 pe-0">
                    {/* <div className="both-flex-group"> */}
                    <div className="input-wraps">
                      <SelectMember
                        classNamePrefix={memberError ? 'border-danger' : ''}
                        data={getDataSelectMember()}
                        callback={handleSelectMember}
                        placeholder="Select member"
                        clear={memberClear}
                      />
                    </div>
                  </div>
                  <div className="col-sm-5 col-xs-12 pe-0">
                    <input
                      type="text"
                      className={`primary-input ${designationError ? 'border-danger' : ''}`}
                      style={{ marginBottom: 0 }}
                      value={selectedMember.designation}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value.length > 0) {
                          setDesignationError(false);
                        } else {
                          setDesignationError(true)
                        }
                        setSelectedMember({
                          ...selectedMember,
                          designation: e.target.value,
                        });
                      }}
                      placeholder="Designation"
                    />
                  </div>
                  <div className="col-sm-2 col-xs-6" style={{ alignSelf: 'flex-end' }}>
                    <div className="button-wraps d-flex justify-content-end" style={{ alignSelf: 'flex-end' }}>
                      <button type="button" className="button" onClick={addMember}>
                        Add
                      </button>
                    </div>
                  </div>
                </div>
                {Array.isArray(selectedMembers) && selectedMembers.length > 0 ?
                  <div className="list-of-selected mt-3">
                    {selectedMembers
                      .filter((s) => id ? s.active : s)
                      .map((mem) => (
                        <div
                          key={mem.user_id}
                          className="add-member-wrap"
                        >
                          <div style={{ width: 'calc(100% - 15px)', display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                            <div className='memeber-image-wrap'>
                              {mem.avatar_url ? (
                                <img
                                  alt=""
                                  src={mem.avatar_url}
                                // style={{
                                //   width: '4.5rem',
                                //   height: '4.5rem',
                                //   marginRight: '1rem',
                                // }}
                                />
                              ) : (
                                <span className='m-name'>{mem.first_name[0]}{mem.last_name[0]}</span>
                              )}
                            </div>

                            <div className="member-info">
                              <div className="title--bold">{mem.first_name}</div>
                              {mem?.designation ? <div className="text">{mem.designation}</div> : null}
                            </div>
                          </div>
                          <button type='button' className='btn-remove-member' onClick={() => removeMemberFromList(mem.user_id)}>
                            <MdClear />
                          </button>
                        </div>
                      ))}
                  </div>
                  :
                  null
                }
              </Form.Group>

              {/* Section: Files */}
              {/* {!id && (
                <> */}
              <div className="heading--active" style={{ marginTop: '32px' }}>Files</div>
              <Form.Group className='form-group2'>
                <Form.Label className='form-label2'>Select file</Form.Label>
                <div className="both-flex-group">
                  <div className="input-wraps">
                    <SelectFile
                      data={files.filter(
                        (f) =>
                          !selectedFiles
                            .map((fi) => fi.file_id)
                            .includes(f.file_id)
                      )}
                      callback={handleSelectFiles}
                    />
                  </div>
                  <div className="button-wraps" style={{ alignSelf: 'flex-end' }}>
                    <button
                      type="button"
                      className="button"
                      onClick={addFile}
                    >
                      Add
                    </button>
                  </div>
                </div>
                {Array.isArray(selectedFiles) && selectedFiles.length > 0 ?
                  <div className="list-of-selected list-of-fileSelected mt-3">
                    {selectedFiles.map((f, i) => renderSelectedFiles(f, i))}
                  </div>
                  :
                  null
                }
              </Form.Group>
              {/* </>
              )} */}
            </div>
          </div>
          <div className="create-project-form modal-footer-task">
            <button type='button' className='btn-cancel' onClick={() => history.goBack()}>
              Cancel
            </button>
            {renderSubmitButton}
          </div>
        </div>

        <SkyLight
          dialogStyles={popupStyle}
          ref={popup}
          hideOnOverlayClick
          className="choose-avatar"
        >
          <Avatar
            className="avatar-selection"
            width={595}
            height={295}
            onCrop={onCrop}
            onClose={onClose}
            onBeforeFileLoad={onBeforeFileLoad}
            labelStyle={{ lineHeight: '0' }}
            label={
              <div className='upload-image-placeholder'>
                <img src={ImgPlaceholder} alt="" />
                <p>Drop your image here, or <b>browse</b><span>JPG, GIF or PNG. Max size of 800kb</span></p>
              </div>
            }
          />
          <div className="button-group">
            <button
              type="button"
              className="button-close"
              onClick={() => {
                popup.current.hide();
              }}
            >
              Close
            </button>
            <button
              type="button"
              className="button-choose"
              onClick={() => {
                popup.current.hide();
              }}
            >
              Choose
            </button>
          </div>
        </SkyLight>
      </div >
      {/* </Scrollbars> */}
    </>
  );
}

import { CHECK_PATH, CHECK_PATH_BACK } from '../actions/path';

const initialState = [
  {
    name: 'Root',
    id: 0,
  },
];

export default function path (state, action) {
  switch (action.type) {
    case CHECK_PATH:
      if (state.findIndex((i) => i.id === action.payload.id) >= 0) {
        state.splice(state.findIndex((i) => i.id === action.payload.id));
        state.push(action.payload);
      } else {
        state.push(action.payload);
      }
      return [...state];
    case CHECK_PATH_BACK: {
      if (state.length > 1) { state.splice(-1); }
      return [...state];
    }
    default:
      return state || initialState;
  }
}

import React, { useRef, useState } from 'react';
import {
  getMemberDocuments,
  updateDocumentsOwner,
} from '../../../services/governance';
import { notifyError, notifyErrorMsg, notifySuccess } from '../../common/Alert';
import { Button, Form } from 'react-bootstrap';
import SelectMember from '../../task/admin/SelectMember';
import SkyLight from 'react-skylight';
import PropTypes from 'prop-types';
import moment from 'moment';
import { BsCloudArrowUp } from 'react-icons/bs'
import { MdClear, MdOutlineSchedule } from 'react-icons/md'
import getDefaultThumbnail from '../../../utils/getDefaultThumbnail';
import Folder from '../../../assets/images/folder.svg'

const popupStyle = {
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  margin: 'auto',
  minHeight: '',
  height: 'fit-content',
  width: '30%',
  borderRadius: '10px',
};
export default function TransferDocument({ memberList }) {
  const [selectedMember, setSelectedMember] = useState('');
  const [selectedMember2, setSelectedMember2] = useState('');
  const [checkedList, setCheckedList] = useState([]);
  const [hasRes, setHasRes] = useState(false);
  const [docs, setDocs] = useState(false);
  const popupRef = useRef();
  const handleCancel = () => {
    setHasRes(false);
    setDocs([]);
    setCheckedList([]);
  };

  const changeCheckedList = (fileId) => () => {
    if (checkedList.includes(fileId)) {
      setCheckedList(checkedList.filter((f) => f !== fileId));
    } else {
      setCheckedList([...checkedList, fileId]);
    }
  };
  const handleCheckAll = () => {
    if (checkedList.length === docs.length) {
      setCheckedList([]);
    } else {
      const arr = [];
      docs.forEach((d) => arr.push(d.file_id));
      setCheckedList(arr);
    }
  };
  const handleSelectMember = (member) => {
    if (
      typeof member === 'object' &&
      member !== null &&
      Object.keys(member).length
    ) {
      setSelectedMember(member);
    }
  };
  const handleSelectMember2 = (member) => {
    if (
      typeof member === 'object' &&
      member !== null &&
      Object.keys(member).length
    ) {
      setSelectedMember2(member.user_id);
    }
  };
  const handleFind = () => {
    getMemberDocuments({
      member_id: selectedMember.user_id,
      doc_type: 'file',
      is_lived: true,
    }).then(({ docs: res }) => {
      setDocs(res || []);
      setHasRes(true);
    }).catch(err => {
      notifyError(err?.response?.data?.msg)
    });
  };
  const submitTransferDocuments = () => {
    updateDocumentsOwner({
      old_owner_id: selectedMember.user_id,
      new_owner_id: selectedMember2,
      file_ids: checkedList,
    })
      .then(({ msg }) => {
        notifySuccess(msg);
        popupRef.current.hide();
        setDocs(docs.filter((f) => !checkedList.includes(f.file_id)));
        setCheckedList([]);
      })
      .catch((err) => notifyErrorMsg(err));
  };
  return (
    <div className="chart-card mt-4">
      {!hasRes && (
        <Form.Group>
          <Form.Label className="form-label2">Find all the documents created by a user to change ownership.</Form.Label>

          <div className="d-flex">
            <div className="w-100 pe-3">
              <SelectMember
                data={memberList}
                callback={handleSelectMember}
                placeholder=""
              />
            </div>
            <Button style={{ padding: '11px 32px' }} onClick={handleFind}>Submit</Button>

          </div>
        </Form.Group>
      )}
      {hasRes && (
        <div className="row mt-4   ai-center ml-3">
          <div className="col-sm-6"><span style={{ color: '#334D6E', fontWeight: '500', opacity: '0.7' }}>Owner:</span> <span style={{ color: '#334D6E', fontWeight: '500' }}>{selectedMember?.email}</span></div>
          <div className='col-sm-6'>
            <div className='text-end'>
              <Button
                style={{ marginRight: '1rem' }}
                disabled={!checkedList.length}
                onClick={() => popupRef.current.show()}
              >
                Change owner
              </Button>
              <Button
                style={{
                  maxWidth: '75px'
                }}
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      )}
      <div className="">
        {docs.length ? (
          <table
            className="mt-4 c-table shadow-none"
            cellPadding="0"
            cellSpacing="0"
            border="0"
            style={{ width: '100%' }}
          >
            <thead>
              <tr>
                <th
                  style={{
                    width: '1%',
                    paddingLeft: '2rem',
                    paddingRight: '4rem',
                    textAlign: 'center',
                  }}
                >
                  <input
                    type="checkbox"
                    checked={checkedList.length === docs.length}
                    onChange={handleCheckAll}
                  />
                </th>
                <th style={{ textAlign: 'left' }}>Name</th>
                <th style={{ textAlign: 'center' }}>
                  Created Time
                </th>
                <th style={{ textAlign: 'center' }}>
                  Modified Time
                </th>
              </tr>
            </thead>
            <tbody>
              {docs && docs.length > 0 ?
                docs.map((f) => (
                  <tr key={f.file_id}>
                    <td style={{ paddingLeft: '2rem', paddingRight: '4rem' }}>
                      <input
                        type="checkbox"
                        checked={checkedList.includes(f.file_id)}
                        onChange={changeCheckedList(f.file_id)}
                      />
                    </td>
                    <td>
                      <span className='d-flex align-items-center' data-tip={f.new_name || f.folder_name} data-for="fileName">
                        <img className="folder-icon" src={f.folder_id ? Folder : getDefaultThumbnail(f.type)} alt="folder" />
                        <span style={{ whiteSpace: 'nowrap', maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis' }}>{f.new_name || f.folder_name}</span>
                      </span>
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      <span className='d-flex align-items-center justify-content-center'>
                        <span style={{ marginRight: '8px', lineHeight: '0', fontSize: '14px' }}><BsCloudArrowUp /></span>{moment(f.created_at).format('MMM DD, YYYY')}
                      </span>
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      <span className='d-flex align-items-center justify-content-center'>
                        <span style={{ marginRight: '8px', lineHeight: '0', fontSize: '14px' }}><MdOutlineSchedule /></span>
                        {moment(f.updated_at).format('MMM DD, YYYY')}
                      </span>
                    </td>
                  </tr>
                ))
                :
                <tr>
                  <td colSpan={4}>No Record found.</td>
                </tr>
              }
            </tbody>
          </table>
        ) : null}
      </div>
      <SkyLight dialogStyles={popupStyle} ref={popupRef} hideOnOverlayClicked>
        <div className="detailPopup">
          <div className='file-properties__title'>
            <span style={{ fontSize:'18px' }}>
              Change Owner
            </span>
            <i
              className='close-modal-icon'
              onClick={() => popupRef.current.hide()}
            ><MdClear /></i>
          </div>
          <div className="newFolderPopup">
            <SelectMember
              data={memberList.filter(
                (m) => m.user_id !== selectedMember.user_id
              )}
              callback={handleSelectMember2}
              placeholder=""
            />

            <div className='btns-groups'>
              <button onClick={() => popupRef.current.hide()} className='btn-cancel' type="button">
                Cancel
              </button>
              <button onClick={submitTransferDocuments} type="button">
                OK
              </button>
            </div>
          </div>
          {/* <div className="d-flex mt-4 confirm-buttons">
            <button
              type="button"
              className="button"
              style={{ backgroundColor: 'white', color: 'black' }}
              onClick={() => popupRef.current.hide()}
            >
              Cancel
            </button>
            <button
              type="button"
              className="button ml-auto"
              onClick={submitTransferDocuments}
            >
              OK
            </button>
          </div> */}
        </div>
      </SkyLight>
    </div>
  );
}
TransferDocument.propTypes = {
  memberList: PropTypes.array.isRequired,
};

import axios from 'axios';
import CONFIG from '../constants/config';


export const getPlanList = async () => {
  const response = await axios({
    method: 'GET',
    url: `${CONFIG.STRAPI_URL}/Pricings`,
    // withCredentials: true,
  });
  // console.log(response.data);
  return response.data;
};

export const getLicenseRemain = async () => {
  const response = await axios({
    method: 'GET',
    url: `${CONFIG.BASE_URL}/license-remain`,
    withCredentials: true,
  });
  return response.data;
};

export const getAccountDetails = async () => {
  const response = await axios({
    method: 'GET',
    url: `${CONFIG.BASE_URL}/account-details`,
    withCredentials: true,
  });
  return response.data;
};

export const upgradeUser = async ({ member, token_id }) => {
  const response = await axios({
    method: 'PATCH',
    url: `${CONFIG.BASE_URL}/upgrade-user`,
    withCredentials: true,
    data: { member, token_id },
  });
  return response.data;
};

export const changePlan = async ({ plan, token_id }) => {
  const response = await axios({
    method: 'PATCH',
    url: `${CONFIG.BASE_URL}/change-plan`,
    withCredentials: true,
    data: { plan, token_id },
  });
  return response.data;
};

export const switchYearly = async ({ plan_type, token_id }) => {
  const response = await axios({
    method: 'PATCH',
    url: `${CONFIG.BASE_URL}/switchYearly`,
    withCredentials: true,
    data: { plan_type, token_id },
  });
  return response.data;
};

export const updateSubscription = async ({ plan_type, member, plan, token_id = undefined }) => {
  const response = await axios({
    method: 'PATCH',
    url: `${CONFIG.BASE_URL}/manage_subscription`,
    withCredentials: true,
    data: { plan_type, member, plan, token_id },
  });
  return response.data;
};

export const updateSubscriptionRazorpay = async ({ plan_type, member, plan, payment_id = undefined }) => {
  const response = await axios({
    method: 'PATCH',
    url: `${CONFIG.BASE_URL}/manage_subscription`,
    withCredentials: true,
    data: { plan_type, member, plan, payment_id },
  });
  return response.data;
};

export const cancelSubscription = async () => {
  // { plan_type, member, plan, subscription_id = undefined }
  const response = await axios({
    method: 'POST',
    url: `${CONFIG.BASE_URL}/cancel_subscription`,
    withCredentials: true,
    // data: { plan_type, member, plan, subscription_id },
  });
  return response.data;
};

export const createSubscription = async ({ plan_type, total_count, plan, customer_notify }) => {
  const response = await axios({
    method: 'POST',
    url: `${CONFIG.BASE_URL}/initiateUserSubscriptions`,
    withCredentials: true,
    data: { plan_type, customer_notify, total_count, plan },

    // data: { plan_type, member, plan, subscription_id },
  });
  return response.data;
};

export const createUserPayment = async ({ plan_type, total_count, plan, subscription_id, payment_id }) => {
  const response = await axios({
    method: 'POST',
    url: `${CONFIG.BASE_URL}/userNewSubscription`,
    withCredentials: true,
    data: { plan_type, subscription_id, payment_id, total_count, plan },

    // data: { plan_type, member, plan, subscription_id },
  });
  return response.data;
};

import React from 'react';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import AppRouter from './routes';
import reduxStore from './redux/index';
import 'react-phone-input-2/lib/material.css';
import 'react-image-lightbox/style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './socket';
// import Meeting from './components/meeting/Meeting';
// import { Route } from 'react-router-dom';
// import TheLayout from './components/TheLayout';

function App() {

  return (
    <Provider store={reduxStore}>
      <ToastContainer />
      <AppRouter />
      {/* <Route exact path="/meeting" render={(props) => <TheLayout {...props}><Meeting /></TheLayout>} /> */}
    </Provider>
  );
}

export default App;

import React from 'react';
import Lightbox from 'react-image-lightbox';
import { getItemLocalStorage } from '../utils/localStorage';

export default function PreviewImage() {
  return (
    <div>
      <Lightbox mainSrc={getItemLocalStorage('imgSrc')} clickOutsideToClose={false}/>
    </div>
  );
}

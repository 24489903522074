import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import TheSidebar from './TheSidebar';
import TheContent from './TheContent';
import { getWindowWidth } from '../redux/actions/layout';
import MobileHeader from './Headers/MobileHeader';

export default function TheLayout(props) {

    const layout = useSelector(s => s.layout)
    const dispatch = useDispatch();


    useEffect(() => {

        function checkWindowSize() {
            // console.log('is working')
            dispatch(getWindowWidth(window.innerWidth));
        }
        checkWindowSize();
        window.addEventListener('resize', checkWindowSize)
        return () => {
            window.removeEventListener('resize', checkWindowSize)
        }
    }, [])

    return (
        <main className="main-conatiner" onContextMenu={(e) => e.preventDefault()}>
            {layout.isMobileView ? <MobileHeader /> : null}
            <TheSidebar />
            <TheContent {...props} />
        </main>
    )
}

import CONFIG from '../constants/config';
import txt from '../assets/images/txt.svg';
import img from '../assets/images/img.svg';
import pdf from '../assets/images/pdf.svg';
import excel from '../assets/images/xls.svg';
import powerpoint from '../assets/images/ppt.svg';
import doc from '../assets/images/doc.svg';

export default function getDefaultThumbnail(type) {
  if (CONFIG.MIME_TYPES.text.includes(type)) { return txt; }
  else if (CONFIG.MIME_TYPES.excel.includes(type)) { return excel; }
  else if (CONFIG.MIME_TYPES.pdf.includes(type)) { return pdf; }
  else if (CONFIG.MIME_TYPES.image.includes(type)) { return img; }
  else if (CONFIG.MIME_TYPES.powerpoint.includes(type)) { return powerpoint; }
  else if (CONFIG.MIME_TYPES.document.includes(type)) { return doc; }
}

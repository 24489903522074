import React, { useState } from 'react';
import { getMemberDocuments } from '../../../services/governance';
import { Button, Form } from 'react-bootstrap';
import SelectMember from '../../task/admin/SelectMember';
import axios from 'axios';
import FileSaver from 'file-saver';
import CONFIG from '../../../constants/config';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import { notifyError } from '../../common/Alert';
import { BsDownload, BsCloudArrowUp } from 'react-icons/bs'
import { MdOutlineSchedule } from 'react-icons/md'
import getDefaultThumbnail from '../../../utils/getDefaultThumbnail';
import Folder from '../../../assets/images/folder.svg'

export default function DocumentFinder({ memberList }) {
  const [selectedMember, setSelectedMember] = useState('');
  const [docs, setDocs] = useState([]);
  const [hasRes, setHasRes] = useState(false);

  const handleSelectMember = (member) => {
    if (
      typeof member === 'object' &&
      member !== null &&
      Object.keys(member).length
    ) {
      setSelectedMember(member);
    }
  };

  const handleDownloadFile = (file_id) => {
    axios
      .get(`${CONFIG.BASE_URL}/file/download/${file_id}`, {
        withCredentials: true,
      })
      .then(({ data: { url, file_name } }) => FileSaver.saveAs(url, file_name));
  };

  const handleCancel = () => {
    setHasRes(false);
    setDocs([]);
  };

  const submitDocumentFinder = () => {
    getMemberDocuments({
      member_id: selectedMember.user_id,
      is_lived: true,
      doc_type: 'file',
    }).then(({ docs: res }) => {
      setDocs(res || []);
      setHasRes(true);
    }).catch(err => {
      notifyError(err.response.data.msg);
    })
  };
  return (
    <div className="chart-card mt-4">
      {
        !hasRes && (
          <Form.Group>
            <Form.Label className="form-label2">Find all the documents created by a user.</Form.Label>
            <div className="d-flex">
              <div className="w-100 pe-3">
                <SelectMember
                  data={memberList}
                  callback={handleSelectMember}
                  placeholder=""
                />
              </div>
              {/* <div className="col-2"> */}
              <Button style={{ padding: '11px 32px' }} onClick={submitDocumentFinder}>Submit</Button>
              {/* </div> */}
            </div>
          </Form.Group>
        )
      }
      {hasRes && (
        <div className="row mt-4  ai-center ml-3">
          <div className="col-sm-6"><span style={{ color: '#334D6E', fontWeight: '500', opacity: '0.7' }}>Owner:</span> <span style={{ color: '#334D6E', fontWeight: '500' }}>{selectedMember?.email}</span></div>
          <div className='col-sm-6'>
            <Button style={{
              margin: '0 0 0 auto',
              display: 'block',
              maxWidth: '75px'
            }} onClick={handleCancel}
            >Cancel
            </Button>
          </div>
        </div>
      )}
      <div className="">
        {docs.length ? (
          <table
            className="c-table mt-4 shadow-none"
            cellPadding="0"
            cellSpacing="0"
            border="0"
            style={{ width: '100%' }}
          >
            <thead>
              <tr>
                <th style={{ textAlign: 'left' }}>Name</th>
                <th style={{ textAlign: 'center' }}>Action</th>
                <th style={{ textAlign: 'center' }}>
                  Created Time
                </th>
                <th style={{ textAlign: 'center' }}>
                  Modified Time
                </th>
              </tr>
            </thead>
            <tbody>
              {docs && docs.length > 0 ?
                docs.filter((item) => item.is_trash !== true).map((f) => (
                  <tr key={f.file_id}>
                    <td>
                      <span className='d-flex align-items-center' data-tip={f.new_name || f.folder_name} data-for="fileName">
                        <img className="folder-icon" src={f.folder_id ? Folder : getDefaultThumbnail(f.type)} alt="folder" />
                        <span style={{ whiteSpace: 'nowrap', maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis' }}>{f.new_name || f.folder_name}</span>

                      </span>
                      {/* {f.new_name} */}
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      <i
                        onClick={() => handleDownloadFile(f.file_id)}
                        data-tip="Download"
                        data-for="download"
                        className="interactive ic-download"
                        style={{ cursor: 'pointer' }}
                      ><BsDownload /></i>
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      <span className='d-flex align-items-center justify-content-center'>
                        <span style={{ marginRight: '8px', lineHeight: '0', fontSize: '14px' }}><BsCloudArrowUp /></span>{moment(f.created_at).format('MMM DD, YYYY')}
                      </span>
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      <span className='d-flex align-items-center justify-content-center'>
                        <span style={{ marginRight: '8px', lineHeight: '0', fontSize: '14px' }}><MdOutlineSchedule /></span>
                        {moment(f.updated_at).format('MMM DD, YYYY')}
                      </span>
                    </td>
                  </tr>
                ))
                :
                <tr>
                  <td colSpan={4}>No Record found.</td>
                </tr>
              }
            </tbody>
            <ReactTooltip id="fileName" />
            <ReactTooltip
              place="top"
              effect="float"
              id="download"
            />
          </table>
        ) : null}
      </div>
    </div>
  );
}

import { GET_USER_INFO_SUCCESS, GET_USER_INFO_FAIL, UPDATE_USER_PLAN_MODAL } from '../actions/userInfo';

const initialState = {
  avatar: '',
  email: '',
  firstname: '',
  lastname: '',
  phone: '',
  role: '',
  user_id: '',
  hasValue: false,
  upgradeModal:false,
};

export default function userInfo (state, action) {
  switch (action.type) {
    case GET_USER_INFO_SUCCESS:
      return {
        ...state,
        avatar: action.payload.avatar,
        email: action.payload.email,
        firstname: action.payload.firstname,
        lastname: action.payload.lastname,
        phone: action.payload.phone,
        role: action.payload.role,
        user_id: action.payload.user_id,
        org_id: action.payload.org_id,
        plan: action.payload.plan,
        pwd_validity_period: action.payload.pwd_validity_period,
        org_pay_with: action.payload.org_pay_with,
        hasValue: true,
      };
    case GET_USER_INFO_FAIL:
      return {
        ...initialState,
      };
    case UPDATE_USER_PLAN_MODAL:
      return {
        ...state,
        upgradeModal:action.payload
      };
    default:
      return state || initialState;
  }
}

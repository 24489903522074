import axios from 'axios';
import CONFIG from '../constants/config';

export const getMemberDocuments = async ({ member_id, is_lived = undefined, doc_type = undefined }) => {
  const response = await axios({
    method: 'GET',
    url: `${CONFIG.BASE_URL}/members-documents/${member_id}`,
    withCredentials: true,
    params: {
      is_lived,
      doc_type,
    },
  });
  return response.data;
};

export const updateDocumentsOwner = async ({ old_owner_id, new_owner_id, file_ids }) => {
  const response = await axios({
    method: 'PATCH',
    url: `${CONFIG.BASE_URL}/owner-doc-transfer`,
    withCredentials: true,
    data: { old_owner_id, new_owner_id, file_ids },
  });
  return response.data;
};

export const updateFoldersOwner = async ({ old_owner_id, new_owner_id, folder_ids }) => {
  const response = await axios({
    method: 'PATCH',
    url: `${CONFIG.BASE_URL}/owner-folder-transfer`,
    withCredentials: true,
    data: { old_owner_id, new_owner_id, folder_ids },
  });
  return response.data;
};

export const restoreDocuments = async ({ member_id, folder_ids, file_ids }) => {
  const response = await axios({
    method: 'PATCH',
    url: `${CONFIG.BASE_URL}/doc-restore`,
    withCredentials: true,
    data: { member_id, folder_ids, file_ids },
  });
  return response.data;
};

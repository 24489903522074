import { all, call, put, takeEvery, throttle } from 'redux-saga/effects';
import { notifyError } from '../components/common/Alert';
import { CHECK_NOTIFICATION, CHECK_NOTIFICATION_SUCCESS, DELETE_NOTIFICATION, DELETE_NOTIFICATIONS, DELETE_NOTIFICATIONS_SUCCESS, DELETE_NOTIFICATION_SUCCESS, GET_NOTIFICATIONS, GET_NOTIFICATIONS_SUCCESS } from '../redux/actions/notifications';
import { checkNotification, deleteNotification, deleteNotifications, getNotifications } from '../services/notifications';

export function *getNotificationSaga() {
  try {
    const result = yield call(getNotifications);
    if (result.success) {
      yield put({
        type: GET_NOTIFICATIONS_SUCCESS,
        payload: result.notifications,
      });
    }
  } catch (err) {
    console.log(err);
  }
}
export function *checkNotificationSaga({ payload }) {
  try {
    const result = yield call(checkNotification, payload);
    if (result.success) {
      yield put({
        type: CHECK_NOTIFICATION_SUCCESS,
        payload
      });
    }
  } catch (err) {
    notifyError(err.response.data.msg);
  }
}
export function *deleteNotificationSaga({ payload }) {
  try {
    const result = yield call(deleteNotification, payload);
    if (result.success) {
      yield put({
        type: DELETE_NOTIFICATION_SUCCESS,
        payload,
      });
    }
  } catch (err) {
    notifyError(err.response.data.msg);
  }
}
export function *deleteNotificationsSaga({ payload }) {
  try {
    const result = yield call(deleteNotifications, payload);
    if (result.success) {
      yield put({
        type: DELETE_NOTIFICATIONS_SUCCESS,
        payload,
      });
    }
  } catch (err) {
    notifyError(err.response.data.msg);
  }
}

export default function *notificationsWatcher() {
  yield all([
    takeEvery(GET_NOTIFICATIONS, getNotificationSaga),
    takeEvery(DELETE_NOTIFICATION, deleteNotificationSaga),
    takeEvery(DELETE_NOTIFICATIONS, deleteNotificationsSaga),
    throttle(1000, CHECK_NOTIFICATION, checkNotificationSaga),
  ]);
}

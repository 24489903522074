import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useHistory, useRouteMatch } from 'react-router-dom';
// import projectOverview from '../../assets/images/projectOverview.png';
import ReportListPopup from '../../components/task/common/ReportListPopup';
import { POPUP_EVENTS } from '../../constants/events';
import { emitter } from '../../utils/emitter';
import CreateTask from './common/CreateTask';
import AddMember from './common/AddMember';
import { useDispatch, useSelector } from 'react-redux';
import { getTasksByProjectId } from '../../redux/actions/tasks';
import { getReportsByTaskId } from '../../redux/actions/reports';
import {
  deleteMember,
  deleteProject,
  projectTime,
  editDoneTask,
  deleteTask,
} from '../../services/project';
import MarkdownText from '../common/MarkdownText';
import { notifySuccess, notifyErrorMsg } from '../common/Alert';
import { changeSecondsToHours, daysRemaining } from '../../utils/datetime';
import Confirm from './common/Confirm';
import { MdKeyboardBackspace, MdAccessTime, MdCheck } from 'react-icons/md';
import { BsPlusLg } from 'react-icons/bs';
import { AiFillEye } from 'react-icons/ai';
import { HiOutlineDocumentReport } from 'react-icons/hi';
import { Scrollbars } from 'react-custom-scrollbars';
import * as scrollbar from '../../helpers/scrollbar';
import getDefaultThumbnail from '../../utils/getDefaultThumbnail';

export default function Project({ detail, isOwner, updateDetail, isProjectDeleted }) {
  const todorRef = useRef(null);


  const reports = useSelector((s) => s.reports);
  const tasks = useSelector((s) => s.tasks);
  const userInfo = useSelector((s) => s.userInfo);
  const {
    params: { id },
  } = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();
  const [teamMembers, setTeamMembers] = useState([]);
  const [selectedTask, setSelectedTask] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState('todo');
  const [totalTime, setTotalTime] = useState(null);
  const [taskIdToEdit, setTaskIdToEdit] = useState(0);
  const [selectedMember, setSelectedMember] = useState(null);
  // const showMemberInfo = () => {
  //   history.push('/task/member');
  // };
  const handleAddTask = () => {
    setTaskIdToEdit(null);
    emitter.emit(POPUP_EVENTS.CREATE_TASK);
  };
  const handleEditProject = () => {
    history.push(`/projects/${id}`);
  };
  const handleDeleteProject = () => {
    deleteProject({ projectId: id })
      .then(({ msg }) => {
        notifySuccess(msg);
        history.push('/projects');
      })
      .catch((err) => notifyErrorMsg(err));
  };
  const handleEditTask = (task_id) => () => {
    setTaskIdToEdit(task_id);
    setTimeout(() => emitter.emit(POPUP_EVENTS.CREATE_TASK), 100);
  };
  const handleDeleteTask = (task_id) => () => {
    deleteTask({ projectId: id, taskId: task_id })
      .then(({ msg }) => {
        notifySuccess(msg);
        dispatch(getTasksByProjectId(id));
      })
      .catch((err) => notifyErrorMsg(err));
  };
  const handleDone = (task_id) => () => {
    editDoneTask({ projectId: id, taskId: task_id })
      .then(({ msg }) => {
        notifySuccess(msg);
        dispatch(getTasksByProjectId(id));
      })
      .catch((err) => notifyErrorMsg(err));
  };
  const handleAddMember = () => {
    emitter.emit(POPUP_EVENTS.CREATE_ADD_MEMBER);
  };
  const callbackAddMember = (members) => {
    setTeamMembers([...teamMembers, ...members]);
  };
  const selectMember = (user_id) => () => {
    const res = teamMembers.find((m) => m.user_id === user_id);
    setSelectedMember(res);
  };
  const selectTask = (taskId) => () => {
    setSelectedTask(taskId);
    dispatch(getReportsByTaskId(taskId));
  };
  const handleDelete = (userId) => {
    deleteMember({ userId, projectId: id })
      .then(({ msg }) => {
        updateDetail();
        const teamMembersToUpdate = teamMembers.find(
          (t) => t.user_id === userId
        );
        teamMembersToUpdate.active = false;
        setTeamMembers([...teamMembers]);
        notifySuccess(msg);
      })
      .catch((err) => notifyErrorMsg(err));
  };
  const callback = () => {
    dispatch(getTasksByProjectId(id));
  };
  const rendersReportsByTaskId = () => {
    if (reports[selectedTask] && reports[selectedTask].length) {
      return (
        <>
          {reports[selectedTask]
            .filter(
              (r) =>
                moment(r.created_at).format('MM-DD-YYYY') ===
                moment().format('MM-DD-YYYY')
            )
            .map((report) => (
              <div className="b-white br-10 p-4 mb-4" key={report.report_id}>
                <div
                  className="d-flex ai-center mb-4"
                  style={{ justifyContent: 'space-between' }}
                >
                  <div className="d-flex ai-center">
                    {report.userInfo?.avatar_url ? (
                      <img
                        src={report.userInfo?.avatar_url}
                        alt=""
                        style={{
                          width: '7rem',
                          height: '7rem',
                          border: report.active
                            ? '2px solid #5845E5'
                            : '2px solid grey',
                          borderRadius: '50%',
                          filter: report.active ? undefined : 'brightness(0.5)',
                        }}
                      />
                    ) : (
                      <div
                        className="default-avatar"
                        style={{
                          width: '7rem',
                          height: '7rem',
                          border: report.active
                            ? '2px solid #5845E5'
                            : '2px solid grey',
                          filter: report.active ? undefined : 'brightness(0.5)',
                        }}
                      >
                        {report.userInfo?.first_name[0]}{' '}
                        {report.userInfo?.last_name[0]}
                      </div>
                    )}
                    <div className="ml-3">
                      <div className="text">{report.userInfo?.designation}</div>
                      <div className="title--bold">
                        {report.userInfo?.first_name}{' '}
                        {report.userInfo?.last_name}
                      </div>
                    </div>
                  </div>
                  {/* <div>
                            <div className="text">Total time work</div>
                            <div
                              style={{
                                fontSize: '2.8rem',
                                fontWeight: 'bold',
                                textAlign: 'center',
                              }}
                            >
                              18h
                            </div>
                          </div>
                          <div>
                            <div className="text">Process project</div>
                            <div
                              style={{
                                fontSize: '2.8rem',
                                fontWeight: 'bold',
                                textAlign: 'center',
                              }}
                            >
                              20%
                            </div>
                          </div> */}
                  {/* <div>
                        <div>Member in team</div>
                        <div style={{ marginTop: '1rem', textAlign: 'center' }}>
                          <img
                            src={ava1}
                            alt=""
                            style={{ width: '3.6rem', height: '3.6rem' }}
                          />
                          <img
                            src={ava1}
                            alt=""
                            style={{
                              width: '3.6rem',
                              height: '3.6rem',
                              marginLeft: '-1rem',
                            }}
                          />
                        </div>
                      </div> */}
                </div>
                <div className="d-flex b-secondary p-3">
                  <div>
                    <div className="title--bold">
                      {moment(report.created_at).format('MMM DD YYYY')}
                    </div>
                    <div className="title--bold mt-4">1. Job worked </div>
                    <MarkdownText value={report.job_worked} />
                    <div className="title--bold mt-4">2. Next target </div>
                    <MarkdownText value={report.next_target} />
                  </div>
                </div>
              </div>
            ))}
        </>
      );
    }

    return null;
  };
  useEffect(() => {
    setSelectedMember(detail.projectTeam[0]);
    setTeamMembers(detail.projectTeam);
    if (id) {
      dispatch(getTasksByProjectId(id));
      projectTime(id)
        .then(({ data }) => setTotalTime(data?.total_work_time ? data.total_work_time : ''))
        .catch((err) => console.error(err));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {

    function handleClickOutside(event) {
      if (todorRef.current && !todorRef.current.contains(event.target)) {
        setSelectedTask(null)
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    }

  })

  return (
    <section className='project-details-section'>
      <div className='left-wrap-projectd'>
        <div className='heading-page-title-wrap'>
          <h1>{detail?.project_name}</h1>
          <button type='button' onClick={() => history.goBack()} className='back-btn'><span><MdKeyboardBackspace /></span> Project details</button>
        </div>
        <div className='scroll-wrpas'>
          <Scrollbars
            renderView={scrollbar.renderView}
            renderTrackHorizontal={scrollbar.renderTrackHorizontal}
            renderTrackVertical={scrollbar.renderTrackVertical}
            renderThumbHorizontal={scrollbar.renderThumb}
            renderThumbVertical={scrollbar.renderThumb}
            universal={true}
          >
            <div className='scroll-wraps-padding'>
              <div className='details-card'>
                <div className='header-details'>
                  <div className='img-with-text-wrap'>
                    <div className='img-wraps'>
                      {detail?.image ?
                        <img src={detail?.image} alt="" />
                        :
                        <span className="p-name">{detail?.project_name[0]}</span>
                      }
                    </div>
                    <h2>{detail?.project_name}</h2>
                  </div>
                  {!isProjectDeleted
                    ?
                    <div className='button-wraps'>
                      <Confirm
                        message="Are you sure you want to delete this project?"
                        handleClick={handleDeleteProject}
                        btnClassName="btn-deletes"
                        popupclassName="modal-deletes"
                      >
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M19.3249 9.4668C19.3249 9.4668 18.7819 16.2018 18.4669 19.0388C18.3169 20.3938 17.4799 21.1878 16.1089 21.2128C13.4999 21.2598 10.8879 21.2628 8.27991 21.2078C6.96091 21.1808 6.13791 20.3768 5.99091 19.0458C5.67391 16.1838 5.13391 9.4668 5.13391 9.4668" stroke="#9EA8B6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M20.7082 6.23828H3.75024" stroke="#9EA8B6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M17.4407 6.239C16.6557 6.239 15.9797 5.684 15.8257 4.915L15.5827 3.699C15.4327 3.138 14.9247 2.75 14.3457 2.75H10.1127C9.5337 2.75 9.0257 3.138 8.8757 3.699L8.6327 4.915C8.4787 5.684 7.8027 6.239 7.0177 6.239" stroke="#9EA8B6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      </Confirm>
                      <button type="button" className='btn-edits' onClick={handleEditProject}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M11.4925 2.78906H7.75349C4.67849 2.78906 2.75049 4.96606 2.75049 8.04806V16.3621C2.75049 19.4441 4.66949 21.6211 7.75349 21.6211H16.5775C19.6625 21.6211 21.5815 19.4441 21.5815 16.3621V12.3341" stroke="#9EA8B6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                          <path fillRule="evenodd" clipRule="evenodd" d="M8.82788 10.9205L16.3009 3.4475C17.2319 2.5175 18.7409 2.5175 19.6719 3.4475L20.8889 4.6645C21.8199 5.5955 21.8199 7.1055 20.8889 8.0355L13.3799 15.5445C12.9729 15.9515 12.4209 16.1805 11.8449 16.1805H8.09888L8.19288 12.4005C8.20688 11.8445 8.43388 11.3145 8.82788 10.9205Z" stroke="#9EA8B6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M15.1653 4.60156L19.7313 9.16756" stroke="#9EA8B6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      </button>
                    </div>
                    :
                    null
                  }
                </div>
                <div className='deadline-wraps-block'>
                  <p className='deadline-text'>Deadline <span>{daysRemaining(detail?.deadline)}</span></p>
                  <p className='deadline-date'><span><MdAccessTime /></span> {`${moment(detail?.created_at).format('DD MMM, YYYY')} - ${moment(detail?.deadline).format('DD MMM, YYYY')}`}</p>
                </div>
                <div className='descriptin-wrap'>
                  <p className='title-description'>Project Description</p>
                  <div className='text-details'>
                    <p className=''>{detail?.description}</p>
                  </div>
                </div>
                <div className="d-flex" style={{ flexWrap: 'wrap' }}>
                  {detail?.projectFiles.map((f, i) => (
                    <div
                      className={`d-flex m-3 ${i === 0 ? 'ms-0' : ''}`}
                      key={f.file_id}
                      style={{
                        border: '1px solid rgba(0,0,0,0.2)',
                        borderRadius: '5px',
                        width: 'fit-content',
                        padding: '15px',
                        background: '#FFF',
                        boxShadow: '0 3px 8px rgba(0,0,0,0.2)'
                      }}
                    >
                      {/* <div
                        style={{
                          width: "2rem",
                          height: "2rem",
                          background: "purple",
                          opacity: ".2",
                        }}
                      /> */}
                      <div className="text--small ml-2 d-flex align-items-center" style={{ fontSize: '12px' }}>
                        <img style={{ height: '20px', marginRight: '8px' }} src={getDefaultThumbnail(f.file_name.split('.')?.[1])} alt="" />
                        {f.new_name || f.file_name}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className='card-both-wrap'>
                <div className='card-same-wrap todo-wraps'>
                  <div className='head-wraps-todo'>
                    <div className='button-groups-todo'>
                      <button type="button" onClick={() => setSelectedStatus('todo')} className={selectedStatus === 'todo' ? 'is_active' : ''}>
                        <span className='icon time-icon'><MdAccessTime /></span> To do
                        <span className='number'>{Array.isArray(tasks) ? tasks?.filter((t) => t.status === 'todo').length : 0}</span>
                      </button>
                      <button type="button" onClick={() => setSelectedStatus('done')} className={selectedStatus === 'done' ? 'is_active' : ''}>
                        <span className='icon done-icon'><MdCheck /></span>Done
                        <span className='number'>
                          {Array.isArray(tasks) ? tasks?.filter((t) => t.status === 'done').length : 0}
                        </span>
                      </button>
                    </div>
                    {!isProjectDeleted ?
                      <button type='button' className='create-btn' onClick={handleAddTask}>
                        <span className='icon'><BsPlusLg /></span> Create task
                      </button>
                      :
                      null
                    }
                  </div>
                  <ul className='todo-list'>
                    {Array.isArray(tasks) && tasks
                      .filter((t) => t.status === selectedStatus)
                      .map((t) => (
                        <li key={t.task_id} ref={todorRef}>
                          <div
                            className={`todo-task-grid ${selectedTask === t.task_id ? 'is_selected' : ''} ${selectedTask === t.task_id &&
                              selectedStatus === 'done' ? 'done' : ''}`}
                            onClick={selectTask(t.task_id)}
                          >
                            <h6>{t.task_name}</h6>
                            <p>{t.description}</p>
                            {!isProjectDeleted ?
                              <div className='groups-of-btns-todo'>
                                <button type="button" className='bton edit' onClick={handleEditTask(t.task_id)}>
                                  <svg className='big-icon-small' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.4925 2.78906H7.75349C4.67849 2.78906 2.75049 4.96606 2.75049 8.04806V16.3621C2.75049 19.4441 4.66949 21.6211 7.75349 21.6211H16.5775C19.6625 21.6211 21.5815 19.4441 21.5815 16.3621V12.3341" stroke="#9EA8B6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path fillRule="evenodd" clipRule="evenodd" d="M8.82788 10.9205L16.3009 3.4475C17.2319 2.5175 18.7409 2.5175 19.6719 3.4475L20.8889 4.6645C21.8199 5.5955 21.8199 7.1055 20.8889 8.0355L13.3799 15.5445C12.9729 15.9515 12.4209 16.1805 11.8449 16.1805H8.09888L8.19288 12.4005C8.20688 11.8445 8.43388 11.3145 8.82788 10.9205Z" stroke="#9EA8B6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M15.1653 4.60156L19.7313 9.16756" stroke="#9EA8B6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                  </svg>
                                </button>
                                <Confirm
                                  message="Are you sure you want to delete this task?"
                                  handleClick={handleDeleteTask(t.task_id)}
                                  btnClassName="delete bton"
                                  popupClass="modal-deletes"
                                >
                                  <svg className='big-icon-small' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M19.3249 9.4668C19.3249 9.4668 18.7819 16.2018 18.4669 19.0388C18.3169 20.3938 17.4799 21.1878 16.1089 21.2128C13.4999 21.2598 10.8879 21.2628 8.27991 21.2078C6.96091 21.1808 6.13791 20.3768 5.99091 19.0458C5.67391 16.1838 5.13391 9.4668 5.13391 9.4668" stroke="#9EA8B6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M20.7082 6.23828H3.75024" stroke="#9EA8B6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M17.4407 6.239C16.6557 6.239 15.9797 5.684 15.8257 4.915L15.5827 3.699C15.4327 3.138 14.9247 2.75 14.3457 2.75H10.1127C9.5337 2.75 9.0257 3.138 8.8757 3.699L8.6327 4.915C8.4787 5.684 7.8027 6.239 7.0177 6.239" stroke="#9EA8B6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                  </svg>
                                </Confirm>
                                {selectedTask === t.task_id &&
                                  selectedStatus !== 'done' ?
                                  <button type="button" className='bton done' onClick={handleDone(t.task_id)}>
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M7.66174 1.85938H5.16907C3.11907 1.85938 1.83374 3.31071 1.83374 5.36537V10.908C1.83374 12.9627 3.11307 14.414 5.16907 14.414H11.0517C13.1084 14.414 14.3877 12.9627 14.3877 10.908V8.22271" stroke="#9EA8B6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                      <path d="M5 6.375L8.375 9.75L14 3" stroke="#9EA8B6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                  </button>
                                  :
                                  <button type="button" className='bton done'>
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M7.66174 1.85938H5.16907C3.11907 1.85938 1.83374 3.31071 1.83374 5.36537V10.908C1.83374 12.9627 3.11307 14.414 5.16907 14.414H11.0517C13.1084 14.414 14.3877 12.9627 14.3877 10.908V8.22271" stroke="#9EA8B6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                      <path d="M5 6.375L8.375 9.75L14 3" stroke="#9EA8B6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                  </button>
                                }
                              </div>
                              :
                              null
                            }
                          </div>
                        </li>
                      ))}
                  </ul>
                </div>
                <div className='card-same-wrap recent-wraps'>
                  <div className='head-wraps-recent'>
                    <h3>Most recent reports <span className='number'>0</span></h3>
                    {selectedTask && (
                      <button
                        type="button"
                        className='create-btn view-icon-btns'
                        onClick={() => {
                          // console.log(reports[selectedTask]);
                          emitter.emit(
                            POPUP_EVENTS.REPORT_LIST,
                            reports[selectedTask]
                          );
                        }}>
                        <span className='icon'><AiFillEye /></span>View all reports
                      </button>
                    )}
                  </div>
                  <div>
                    {rendersReportsByTaskId()}
                  </div>
                </div>
              </div>
            </div>
          </Scrollbars>
        </div>
      </div >
      <div className='right-sidebar-details'>
        <Scrollbars
          renderView={scrollbar.renderView}
          renderTrackHorizontal={scrollbar.renderTrackHorizontal}
          renderTrackVertical={scrollbar.renderTrackVertical}
          renderThumbHorizontal={scrollbar.renderThumb}
          renderThumbVertical={scrollbar.renderThumb}
          universal={true}
        >
          <div className='overview-wraps'>
            <h3 className='title-over'>Overview</h3>
            <div className="progress_overview">
              <svg>
                <circle cx="80" cy="-80" r="70"></circle>
                <circle cx="80" cy="-80" r="70" style={{ strokeDashoffset: `calc(440 - (440 * ${detail?.project_completed ? detail?.project_completed : 0}) / 100)` }}></circle>
              </svg>
            </div>
            <p className='text-overview'>{detail?.project_completed ? detail?.project_completed : 0}% Task completed</p>
            <h4 className='hours-title'>{changeSecondsToHours(totalTime) || '0H 0M'}</h4>
            <div className='dead-line-block'>
              <p><span>Project time</span> {changeSecondsToHours(totalTime) || '0h 0m'}</p>
              <p><span>Project Deadline</span> {daysRemaining(detail?.deadline)} days</p>
            </div>
          </div>
          <div className='team-member-wrap'>
            <div className='head-members-wrap'>
              <p>Member<span>Now, task has {teamMembers.length} member</span></p>
              {!isProjectDeleted ?
                <button type='button' className='create-btn' onClick={handleAddMember}><span className='icon'><BsPlusLg /></span> Add member</button>
                :
                null
              }
            </div>
            <ul className='member-list-ul'>
              {teamMembers && teamMembers.map((mem) =>
                <li key={mem.user_id} >
                  <div className={`member-wraps ${mem.user_id === selectedMember?.user_id ? 'is_active' : ''} ${!mem?.active ? 'is_delete' : ''}`} onClick={selectMember(mem.user_id)}>
                    <div className='member-image-wrap'>
                      {mem.avatar_url ?
                        <img src={mem.avatar_url} alt='' />
                        :
                        <span className='m-name'>
                          {mem.first_name[0]}
                          {mem.last_name[0]}
                        </span>
                      }
                    </div>
                    <p>{mem.first_name} {mem.last_name} <span>{mem.designation}</span></p>

                    <div className='groups-of-btns-todo group-of-buttons'>
                      {selectedMember?.designation !== 'lead' && (
                        <button
                          type='button'
                          className='report'
                          onClick={() =>
                            emitter.emit(POPUP_EVENTS.REPORT_LIST, null, {
                              projectId: id,
                              memberId: selectedMember.user_id,
                            })
                          }
                        >
                          <HiOutlineDocumentReport />
                        </button>
                      )}
                      {!isProjectDeleted && !isOwner &&
                        selectedMember?.user_id !== userInfo.user_id &&
                        selectedMember?.active && (
                          <Confirm
                            message="Are you sure you want to delete this user?"
                            handleClick={() => handleDelete(selectedMember?.user_id)}
                            btnClassName="delete"
                            popupClass="modal-deletes"
                          >
                            <svg className='big-icon-small' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M19.3249 9.4668C19.3249 9.4668 18.7819 16.2018 18.4669 19.0388C18.3169 20.3938 17.4799 21.1878 16.1089 21.2128C13.4999 21.2598 10.8879 21.2628 8.27991 21.2078C6.96091 21.1808 6.13791 20.3768 5.99091 19.0458C5.67391 16.1838 5.13391 9.4668 5.13391 9.4668" stroke="#9EA8B6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M20.7082 6.23828H3.75024" stroke="#9EA8B6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M17.4407 6.239C16.6557 6.239 15.9797 5.684 15.8257 4.915L15.5827 3.699C15.4327 3.138 14.9247 2.75 14.3457 2.75H10.1127C9.5337 2.75 9.0257 3.138 8.8757 3.699L8.6327 4.915C8.4787 5.684 7.8027 6.239 7.0177 6.239" stroke="#9EA8B6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                          </Confirm>
                        )}
                    </div>
                  </div>
                </li>
              )}

            </ul>
          </div>
          <CreateTask
            memberList={teamMembers}
            projectId={id}
            taskId={taskIdToEdit}
            callback={callback}
            afterClose={() => setTaskIdToEdit(null)}
            projectFiles={detail.projectFiles}
          />
          <AddMember
            projectTeam={teamMembers}
            projectId={id}
            callback={callbackAddMember}
          />
          <ReportListPopup />
        </Scrollbars>
      </div>
    </section >

  );
}
Project.propTypes = {
  detail: PropTypes.object.isRequired,
  updateDetail: PropTypes.func.isRequired,
  isOwner: PropTypes.bool.isRequired,
};

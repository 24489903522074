import React, { useRef, useEffect, useState } from 'react';
import axios from 'axios';
import SkyLight from 'react-skylight';
import { AiOutlineInfo } from 'react-icons/ai';
import { MdClear, MdEdit } from 'react-icons/md';
import { emitter } from '../../utils/emitter';
// import { formatDate } from '../../utils/datetime';
import { getFileName, bytesToSize, getFolderName } from '../../utils/string';
import { notifyError, notifySuccess } from '../common/Alert';
import { POPUP_EVENTS } from '../../constants/events';
import CONFIG from '../../constants/config';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useSelector } from 'react-redux';

export default function FileProperties() {
  const popupRef = useRef(null);
  const [detail, setDetail] = useState({});
  const [isVisible, setIsVisible] = useState(false);
  const [editDescription, setEditDescription] = useState(false);

  const allFolders = useSelector((s) => s.allFolders);

  const newFolderPopupStyle = {
    width: '41%',
    // height: '',
    minHeight: 0,
    marginTop: '0px',
    marginLeft: '0%',
    borderRadius: '10px',
    transform: 'scale(0) translate(-50%, -50%)',
  };
  const history = useHistory();
  const handleClick = (id) => () => {
    history.push(`/file-detail/${id}`);
    popupRef.current.hide();
    setIsVisible(false)
  };
  const handleAddDescription = () => {
    axios({
      method: 'PATCH',
      url: `${CONFIG.BASE_URL}/file/${detail.file_id}`,
      withCredentials: true,
      data: {
        description: detail.description,
      },
    })
      .then(({ data }) => {
        setEditDescription(false);
        notifySuccess(data.msg);
      })
      .catch((err) => notifyError(err.response.data.msg));
  };

  useEffect(() => {
    const handleDetail = (fileId) => {
      axios
        .get(`${CONFIG.BASE_URL}/file/${fileId}`, { withCredentials: true })
        .then(({ data }) => {
          notifySuccess(data.msg);
          if (!data.document.description) setEditDescription(true);
          setDetail(data.document);
        })
        .catch((err) => {
          notifyError(err.response.data.msg);
        });
    };
    const showPopup = (fileIdParam) => {
      if (popupRef.current) {
        popupRef.current.show();
        setIsVisible(true)
      }
      handleDetail(fileIdParam);
    };
    function listenToTheEmitter() {
      emitter.on(POPUP_EVENTS.SHOW_FILE_PROPERTIES, showPopup);
    }
    listenToTheEmitter();
    return () => {
      emitter.off(POPUP_EVENTS.SHOW_FILE_PROPERTIES, showPopup);
    };
  }, []);

  return (
    <SkyLight
      dialogStyles={newFolderPopupStyle}
      className={`newDemoModal ${isVisible ? 'show' : ''}`}
      ref={popupRef}
      // hideOnOverlayClicked
      afterClose={() => setDetail({})}
    >
      <div className="file-properties">
        <div className="file-properties__title">
          <span><i className='icon-info'><AiOutlineInfo /></i>Properties</span>
          <i className='close-modal-icon' onClick={() => { popupRef.current.hide(); setIsVisible(false) }}><MdClear /></i>
        </div>
        <div className="file-properties__container">
          {Object.keys(detail).length ? (
            <table style={{ width: '100%' }}>
              <tbody>
                <tr>
                  <td>File Name</td>
                  <td>
                    <b>
                      {detail.new_name
                        ? detail.new_name
                        : getFileName(detail.file_name)}
                    </b>
                  </td>
                </tr>
                <tr>
                  <td>Folder</td>
                  <td>{detail.parent_id ? getFolderName(detail.parent_id, allFolders) : 'Root'}</td>
                </tr>
                <tr>
                  <td>Owners</td>
                  <td>Only you</td>
                </tr>

                <tr>
                  <td>Size</td>
                  <td>{bytesToSize(detail.size)}</td>
                </tr>
                <tr>
                  <td>Type</td>
                  <td>{detail.type}</td>
                </tr>
                <tr>
                  <td>Linked files</td>
                  <td>
                    {detail?.links ?
                      (detail.links || []).map((l) => (
                        <>
                          <span onClick={handleClick(l.file_id)}>{l.new_name}</span>
                          ,&nbsp;
                        </>
                      ))
                      :
                      <span>-</span>
                    }
                  </td>
                </tr>
                <tr>
                  <td>Tags</td>
                  <td>{detail?.tags ? detail.tags : '-'}</td>
                </tr>
                <tr>
                  <td>Created Time</td>
                  <td>{moment(detail.created_at).format('MMM DD, YYYY')}</td>
                </tr>
                <tr>
                  <td>Recently Accessed</td>
                  <td>{moment(detail.updated_at).format('MMM DD, YYYY')}</td>
                </tr>
                <tr className='descrip-td'>
                  <td>Description</td>
                  <td>
                    {editDescription ? (
                      <div className="description">
                        <textarea
                          value={detail.description}
                          // const
                          rows={5}
                          placeholder='Your description'
                          onChange={(e) =>
                            setDetail({
                              ...detail,
                              description: e.target.value,
                            })
                          }
                        />
                        <span onClick={handleAddDescription} className='btn-adddesc'>
                          Add description
                        </span>
                      </div>
                    ) : (
                      <div>
                        {detail.description}
                        <i
                          className="icon-edit-2"
                          onClick={() => setEditDescription(true)}
                        >
                          <MdEdit />
                        </i>
                      </div>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          ) : (
            <table style={{ width: '100%' }}>
              <tbody>
                <tr>
                  <td>File Name</td>
                  <td>...</td>
                </tr>
                <tr>
                  <td>Owners</td>
                  <td>...</td>
                </tr>

                <tr>
                  <td>Size</td>
                  <td>...</td>
                </tr>
                <tr>
                  <td>Type</td>
                  <td>...</td>
                </tr>
                <tr>
                  <td>Linked files</td>
                  <td>...</td>
                </tr>
                <tr>
                  <td>Tags</td>
                  <td>...</td>
                </tr>
                <tr>
                  <td>Created Time</td>
                  <td>...</td>
                </tr>
                <tr>
                  <td>Recently Accessed</td>
                  <td>...</td>
                </tr>
                <tr className='descrip-td null'>
                  <td>Description</td>
                  <td>...</td>
                </tr>
              </tbody>
            </table>
          )}
        </div>
      </div>
    </SkyLight>
  );
}

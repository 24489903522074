import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DocumentFinder from './governance/DocumentFinder';
import TransferDocument from './governance/TransferDocument';
import TransferFolder from './governance/TransferFolder';
import RestoreDocuments from './governance/RestoreDocuments';
import { useDispatch, useSelector } from 'react-redux';

import { updateUserPlanModal } from '../../redux/actions/userInfo';

export default function Governance({ memberList }) {

  const dispatch = useDispatch();
  const userInfo = useSelector(s=>s.userInfo)

  const [activeTab, setActiveTab] = useState(0);

  const upgradeModalEvent = () =>{
    dispatch(updateUserPlanModal(true))
  }

  const handleChangeTab = (index) => () => {
    if(userInfo.plan === 'BASIC'){
      if(index === 1 || index === 2) upgradeModalEvent()
    }else{
      setActiveTab(index);
    }
  };
  return (
    <div className='settings-settings-section'>
      <div className="d-flex">
        <div
          className={`setting-user-btn disable-back ${
            activeTab === 0 ? 'active' : ''
          }`}
          onClick={handleChangeTab(0)}
        >
          Find A Document
        </div>
        <div
          className={`setting-user-btn disable-back ${
            activeTab === 1 ? 'active' : ''
          }`}
          onClick={handleChangeTab(1)}
        >
          Transfer Document Ownership
        </div>
        <div
          className={`setting-user-btn disable-back ${
            activeTab === 2 ? 'active' : ''
          }`}
          onClick={handleChangeTab(2)}
        >
          Transfer Folder Ownership
        </div>
        <div
          className={`setting-user-btn disable-back ${
            activeTab === 3 ? 'active' : ''
          }`}
          onClick={handleChangeTab(3)}
        >
          Restore Document
        </div>
      </div>
      <div>
        {activeTab === 0 && <DocumentFinder memberList={memberList}/>}
        {activeTab === 1 && <TransferDocument memberList={memberList}/>}
        {activeTab === 2 && <TransferFolder memberList={memberList}/>}
        {activeTab === 3 && <RestoreDocuments memberList={memberList}/>}
      </div>
    </div>
  );
}
Governance.propTypes = {
  memberList: PropTypes.array.isRequired,
};

import React, { useRef, useEffect, useState } from 'react';
import axios from 'axios';
import SkyLight from 'react-skylight';
import { emitter } from '../../utils/emitter';
import { notifySuccess, notifyError } from '../common/Alert';
import { POPUP_EVENTS, HOME_EVENTS } from '../../constants/events';
import CONFIG from '../../constants/config';
import { useDispatch, useSelector } from 'react-redux';
import { checkDocs } from '../../redux/actions/documents';
export default function CreateFolderPopup() {
  const popupRef = useRef(null);
  const buttonRef = useRef(null);
  const inputRef = useRef(null);
  const [isVisible, setIsVisible] = useState(true);
  const [folderName, setFolderName] = useState('');
  const path = useSelector((s) => s.path);
  const dispatch = useDispatch();
  const newFolderPopupStyle = {
    width: '30%',
    // height: '35%',
    minHeight: 0,
    marginLeft: '-15%',
    marginTop:'-7%',
    borderRadius: '10px',
  };

  const showPopup = () => {
    emitter.emit(HOME_EVENTS.TOGGLE_SHOW_UPLOAD_OPTIONS, false);
    if (popupRef.current) popupRef.current.show();
    setTimeout(() => {
      inputRef.current.focus();
    }, 1);
  };
  function hidePopup() {
    popupRef.current.hide();
    setFolderName('');
    setIsVisible(false);
  }

  const createFolder = () => {
    const parentId = path.slice(-1)[0].id ? path.slice(-1)[0].id : null;
    if (folderName.length > 0) {
      axios
        .post(
          `${CONFIG.BASE_URL}/folder`,
          { folder_name: folderName, parent_id: parentId },
          { withCredentials: true }
        )
        .then(({ data }) => {
          notifySuccess(data.msg);
          dispatch(checkDocs(parentId));
          hidePopup();
        })
        .catch((err) => {
          notifyError(err.response.data.msg);
        });
    }else{
      notifyError('Folder name is required!');
    }
  };
  function hanldleEnterClick(e) {
    if (e.keyCode === 13) {
      buttonRef.current.click();
    }
  }
  function listenToTheEmitter() {
    emitter.on(POPUP_EVENTS.SHOW_CREATE_FOLDER, showPopup);
  }
  useEffect(() => {
    listenToTheEmitter();
    return () => {
      emitter.off(POPUP_EVENTS.SHOW_CREATE_FOLDER, showPopup);
    };
  });
  return (
    <SkyLight
      dialogStyles={newFolderPopupStyle}
      ref={popupRef}
      isVisible={isVisible}
    >
      <div className="newFolderPopup">
        <label>Create a new Folder</label>
        <input
          placeholder="New folder"
          type="text"
          value={folderName}
          className="form-control"
          onChange={(e) => setFolderName(e.target.value)}
          onKeyDown={hanldleEnterClick}
          ref={inputRef}
        />
        <div className="groups-of-btns">
          <button onClick={hidePopup} type="button">
            Cancel
          </button>
          <button onClick={createFolder} ref={buttonRef} type="button">
            Create
          </button>
        </div>
      </div>
    </SkyLight>
  );
}

import React, { useEffect, useState, useRef } from 'react';
import SkyLight from 'react-skylight';
import Avatar from 'react-avatar-edit';
import { useSelector } from 'react-redux';
import { Form } from 'react-bootstrap'
import { MdOutlineCameraAlt } from 'react-icons/md';
import ImgPlaceholder from '../../assets/images/img-placeholder.svg'
import {
  createGroup,
  deleteGroup,
  getGroups,
  updateGroup,
} from '../../services/groups';
import Confirm from '../task/common/Confirm';
import { notifyErrorMsg, notifyError } from '../common/Alert';
import PropTypes from 'prop-types';
import SelectMultiMembers from '../task/admin/SelectMultiMembers';
// import dummyUser from '../../assets/images/dummyUser.svg'

const popupStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
};
export default function Groups({ memberList }) {
  const [data, setData] = useState({});
  const [groupList, setGroupList] = useState([]);
  const userInfo = useSelector((s) => s.userInfo);
  const [avatar, setAvatar] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const popup = useRef(null);

  // #region  handle Avatar section
  const chooseAvatar = () => {
    popup.current.show();
  };
  const onClose = () => {
    setAvatar(null);
  };
  const onCrop = (preview) => {
    setAvatar(preview);
  };
  const onBeforeFileLoad = (elem) => {
    if (elem.target.files[0].size > 1048576) {
      elem.target.value = '';
    }
  };
  // #endregion

  // #region  handle select member
  const handleSelectMember = (member) => {
    if (
      typeof member === 'object' &&
      member !== null &&
      Object.keys(member).length
    ) {
      setSelectedMembers(member);
    }
  };
  const getDataSelectMember = () =>
    memberList.filter(
      (m) =>
        !selectedMembers.map((t) => t.user_id).includes(m.user_id) &&
        m.user_id !== userInfo.user_id
    );
  // #endregion handle select member

  const handleCreate = () => {
    setIsEdit(true);
    setIsNew(true)
    setData({});
  };

  const handleEdit = (groupData) => {
    setData(groupData.group);
    setAvatar(groupData.group.gr_logo);
    setSelectedMembers([]);
    setIsEdit(true);
    setIsNew(false)
  };

  const handleChangeData = (name) => (e) => {
    setData({ ...data, [name]: e.target.value });
  };

  const handleDelete = (group_id) => {
    deleteGroup({ group_id })
      .then(() =>
        setGroupList([
          ...groupList.filter((g) => g.group.group_id !== group_id),
        ])
      )
      .catch((err) => {
        console.log(err);
        notifyErrorMsg(err);
      });
  };

  const handleCancel = () => {
    setIsEdit(false);
    setIsNew(false);
    setAvatar('');
    setData({});
    setSelectedMembers([]);
  };

  const handleSubmit = () => {
    if (data.group_id) {
      updateGroup({
        gr_name: data.gr_name,
        gr_description: data.gr_description,
        member_ids: selectedMembers.map((m) => m.user_id),
        gr_logo: avatar,
        group_id: data.group_id,
      })
        .then(() => {
          getGroups().then(({ groups: res }) => {
            setGroupList(res);
            setData({});
            setAvatar('');
            setSelectedMembers([]);
            setIsEdit(false);
            setIsNew(false);
          })
            .catch(err => {
              console.log(err)
            })

        })
        .catch((err) => {
          notifyErrorMsg(err);
        });
    } else if (!data.group_id) {
      createGroup({
        gr_name: data.gr_name,
        gr_description: data.gr_description,
        member_ids: selectedMembers.map((m) => m.user_id),
        gr_logo: avatar,
      })
        .then(() => {
          getGroups().then(({ groups: res }) => {
            setGroupList(res);
            setData({});
            setAvatar('');
            setSelectedMembers([]);
            setIsEdit(false);
            setIsNew(false);
          });
        })
        .catch((err) => {
          notifyErrorMsg(err);
        });
    }
  };

  const renderGroupList = (
    <div className=''>
      {isLoading &&
        <div className='p-5' style={{ textAlign: 'center' }}>Loading ...</div>
      }

      {!isLoading && groupList && groupList.length === 0 &&
        <div className='p-5' style={{ textAlign: 'center' }}>There is no group.</div>
      }

      {Array.isArray(groupList) && groupList.length > 0 ?
        groupList.map((gr, index) => (
          <div className='group-item' key={gr.group.group_id}>
            {/* is_selected */}
            <div className='image-user-wrap'>
              {gr?.group?.gr_logo ?
                <img src={gr?.group?.gr_logo} alt="" />
                :
                <span className='p-name'>{gr?.group?.gr_name?.[0]}</span>
              }
            </div>
            <div className='group-content-wrap'>
              <div className='text-content-wraps'>
                <h3 className='title-group'>{gr?.group?.gr_name}</h3>
                <p className='group-desc'>{gr?.group?.gr_description}</p>
                {gr?.members && Array.isArray(gr.members) && gr.members.length > 0 ?
                  <div className='member-of-groups'>
                    <p className='member-label'>Member:</p>
                    <ul className='group_members'>
                      {gr.members.slice(0, 4).map((m, i) =>
                        <li key={i}>
                          {m?.avatar_url ?
                            <img src={m.avatar_url} alt="" />
                            :
                            <span className='p-name'>{m?.first_name?.[0]}{m?.last_name?.[0]}</span>
                          }
                        </li>
                      )}
                      {gr.members.length > 4 ? <li className='plus-label'>+{(gr.members.length - 4)}</li> : null}
                    </ul>
                  </div>
                  :
                  null
                }
              </div>
              <div className='group-actions-btns'>
                <button type="button" onClick={() => handleEdit(gr)}>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.4925 2.78906H7.75349C4.67849 2.78906 2.75049 4.96606 2.75049 8.04806V16.3621C2.75049 19.4441 4.66949 21.6211 7.75349 21.6211H16.5775C19.6625 21.6211 21.5815 19.4441 21.5815 16.3621V12.3341" stroke="#9EA8B6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M8.82788 10.9205L16.3009 3.4475C17.2319 2.5175 18.7409 2.5175 19.6719 3.4475L20.8889 4.6645C21.8199 5.5955 21.8199 7.1055 20.8889 8.0355L13.3799 15.5445C12.9729 15.9515 12.4209 16.1805 11.8449 16.1805H8.09888L8.19288 12.4005C8.20688 11.8445 8.43388 11.3145 8.82788 10.9205Z" stroke="#9EA8B6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M15.1653 4.60156L19.7313 9.16756" stroke="#9EA8B6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </button>
                <Confirm
                  message="Are you sure you want to delete this group?"
                  handleClick={() => handleDelete(gr.group.group_id)}
                  popupClass="modal-deletes"
                >
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19.3249 9.46777C19.3249 9.46777 18.7819 16.2028 18.4669 19.0398C18.3169 20.3948 17.4799 21.1888 16.1089 21.2138C13.4999 21.2608 10.8879 21.2638 8.27991 21.2088C6.96091 21.1818 6.13791 20.3778 5.99091 19.0468C5.67391 16.1848 5.13391 9.46777 5.13391 9.46777" stroke="#016288" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M20.7082 6.23926H3.75024" stroke="#016288" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M17.4407 6.23949C16.6557 6.23949 15.9797 5.68449 15.8257 4.91549L15.5827 3.69949C15.4327 3.13849 14.9247 2.75049 14.3457 2.75049H10.1127C9.5337 2.75049 9.0257 3.13849 8.8757 3.69949L8.6327 4.91549C8.4787 5.68449 7.8027 6.23949 7.0177 6.23949" stroke="#016288" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </Confirm>
              </div>
            </div>
          </div>
        ))
        :
        null
      }
    </div>
  );
  const renderGroupForm = (
    <div className='group-from-create'>
      <h3 className='form-title-group'>{isNew ? 'Create Group' : 'Edit Group'}</h3>
      <Form.Group className="form-group2">
        <div className="groups-thumbnail">
          <div className='avatar-wraps-group'>
            {avatar ?
              <img alt="" src={avatar} />
              : (
                <span className="default_avatar">
                  <MdOutlineCameraAlt />
                </span>
              )}
          </div>
          <button type="button" className='upload-bton' onClick={chooseAvatar}>
            <i className="icon-camera"><MdOutlineCameraAlt /></i>
          </button>
        </div>
      </Form.Group>
      <Form.Group className="form-group2">
        <Form.Label htmlFor='group-name' className="form-label2 required">Group Name</Form.Label>
        <Form.Control
          id="group-name"
          name="gr_name"
          placeholder='Enter group name'
          value={data.gr_name}
          className="primary-input"
          onChange={handleChangeData('gr_name')}
        />
      </Form.Group>
      <Form.Group className="form-group2">
        <Form.Label htmlFor="group-description" className="form-label2 required">Group Description</Form.Label>
        <Form.Control
          id="group-description"
          name="gr_description"
          value={data.gr_description}
          placeholder='Enter group description'
          className="primary-input "
          onChange={handleChangeData('gr_description')}
        />
      </Form.Group>
      {/* Section: Members */}
      <Form.Group className="form-group2">
        <Form.Label htmlFor="group-description" className="form-label2">Members</Form.Label>
        <SelectMultiMembers
          data={getDataSelectMember()}
          callback={handleSelectMember}
          existMembers={
            groupList.find((g) => g.group.group_id === data.group_id)?.members
          }
          placeholder="Select member"
        />
      </Form.Group>
      <SkyLight
        dialogStyles={popupStyle}
        ref={popup}
        hideOnOverlayClick
        className="choose-avatar"
      >
        <Avatar
          className="avatar-selection"
          width={390}
          height={295}
          onCrop={onCrop}
          onClose={onClose}
          onBeforeFileLoad={onBeforeFileLoad}
          labelStyle={{ lineHeight: '0' }}
          label={
            <div className='upload-image-placeholder'>
              <img src={ImgPlaceholder} alt="" />
              <p>Drop your image here, or <b>browse</b>
                {/* <span>JPG, GIF or PNG. Max size of 800kb</span> */}
              </p>
            </div>
          }
        />
        <div className="button-group">
          <button
            type="button"
            className="button-close"
            onClick={() => {
              popup.current.hide();
            }}
          >
            Close
          </button>
          <button
            type="button"
            className="button-choose"
            onClick={() => {
              popup.current.hide();
            }}
          >
            Choose
          </button>
        </div>
      </SkyLight>
      <div className='groups-button-block'>
        <button
          customStyle={{ marginLeft: '2rem', marginTop: '2rem' }}
          onClick={handleSubmit}
        >
          Save
        </button>
        <button
          customStyle={{
            marginLeft: '2rem',
            color: 'black',
            backgroundColor: 'white',
            border: '1px solid black',
          }}
          onClick={handleCancel}
        >
          Cancel
        </button>
      </div>
    </div>
  );

  useEffect(() => {
    async function groupsGet() {
      const res = await getGroups();
      if (res.success) {
        const groups = res.groups
        setGroupList(groups);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        notifyError(res.msg);
      }
    }
    groupsGet();
  }, []);

  return (
    <>
      {!isEdit ?
        <button type="button" onClick={handleCreate} className="setting-user-btn">
          Create group
        </button>
        :
        null
      }
      <div className={`groups-cards ${isEdit ? 'isEdit-wrap' : ''}`} >
        {isEdit ? renderGroupForm : renderGroupList}
      </div>
    </>
  );
}
Groups.propTypes = {
  memberList: PropTypes.array.isRequired,
};

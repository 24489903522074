import React, { useState, useEffect } from 'react';
import { orgMembers } from '../services/authentication';
import { useSelector } from 'react-redux';
import { notifyErrorMsg } from '../components/common/Alert';
import Activities from '../components/setting/reports/Activities';

export default function ActivitiesPage() {

    const [memberList, setMemberList] = useState([]);
    const userInfo = useSelector(s => s.userInfo);

    // const callback = () => {
    //     orgMembers({ org_id: userInfo.org_id })
    //         .then(({ data: { organization_members } }) => {
    //             setMemberList(organization_members);
    //         })
    //         .catch((err) => {
    //             notifyErrorMsg(err);
    //         });
    // };

    useEffect(() => {
        if (userInfo.org_id) {
            orgMembers({ org_id: userInfo.org_id })
                .then(({ data: { organization_members } }) => {
                    setMemberList(organization_members);
                })
                .catch((err) => {
                    notifyErrorMsg(err);
                });
        }
    }, [userInfo.org_id]);

    return (
        <>
            <div className='page-top-header'>
                <h1 className="page-title">Activities</h1>
            </div>
            <div className='profile-section activiti_section'>
                <Activities memberList={memberList} />
            </div>
        </>
    )
}

import React, { useState } from 'react';
import { useHistory, Redirect } from 'react-router-dom';
// import { useDispatch } from 'react-redux';
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import Logo from '../assets/images/Logo.svg';
import PCImg from '../assets/images/signin/pc.png';
import Arrow from '../assets/images/signin/Vector.png';
import CONFIG from '../constants/config';
import { notifySuccess, notifyError } from '../components/common/Alert';
import { Formik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';

function ResetPassword(props) {

    const history = useHistory();
    // const location = useLocation();
    // const dispatch = useDispatch();

    const [isShowPassword, setIsShowPassword] = useState(null);

    const prevPage = (e) => {
        history.push('/login');
    }

    const passSchema = Yup.object().shape({
        password: Yup.string().required('Required'),
    });

    const submitEmail = (values, actions) => {
        axios({
            url: `${CONFIG.BASE_URL}/reset_password/`,
            method: 'POST',
            params: {
                token: `${window.location.search.split('=').splice(-1, 1)}`,
            },
            data: {
                password: values.password,
            },
            withCredentials: true,
        }).then(({ data }) => {
            notifySuccess(data.msg);
            setTimeout(() => history.push('/login'), 2000);
        })
            .catch(err => notifyError(err.response.data.msg));
    };
    if (window.location.search.search('token=') < 0) return <Redirect to="/login" />;

    return (
        <section className="signin-section">
            <div className="container">
                <div className="signin-page">
                    <div className="row">
                        <div className="logo-wrap">
                            <img src={Logo} alt="logo" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="signin -text-wrap">
                                <h3 className="title">Hey There</h3>
                                <p className="text">Welcome to Dockquick!</p>
                                <p className="text">You are just one step away to your feed.</p>
                                <div className="pc-img-wrap">
                                    <img src={PCImg} alt="pc-img" />
                                </div>
                            </div>
                        </div>
                        <div className="col" >
                            <div className="form-wrap">
                                <div className="title-wrap">
                                    <button type="button" className="prev-btn" onClick={prevPage}>
                                        <img src={Arrow} alt="prev-btn" />
                                    </button>
                                    <h3 className="flex-fill  form-title text-center">Reset password</h3>

                                </div>
                                <div className="password-email text-center">Please enter your new password.</div>
                                <Formik initialValues={{ password: '' }} validationSchema={passSchema} onSubmit={(values, actions) => submitEmail(values, actions)}>
                                    {({ errors, values, touched, handleBlur, handleChange, handleSubmit }) => (
                                        <form method="post" onSubmit={handleSubmit}>
                                            <div className="password-wrap">

                                                <input
                                                    name="password"
                                                    placeholder="Password"
                                                    type={isShowPassword ? "text" : "password"}
                                                    value={values.password}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                <i className="icon-eye" onClick={() => setIsShowPassword(!isShowPassword)}>
                                                    {isShowPassword ?
                                                        <FaEyeSlash />
                                                        :
                                                        <FaEye />
                                                    }
                                                </i>

                                            </div>
                                            {errors.password && touched.password ? <p className="text-danger mb-0">{errors.password}</p> : null}

                                            <button type='submit' className="form-btn">Next</button>
                                        </form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>



            </div>
        </section>
    )
}

export default ResetPassword;

import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import axios from 'axios';
import CONFIG from '../constants/config';
import PropTypes from 'prop-types';
import { notifyError, notifySuccess } from './common/Alert';
import moment from 'moment';
// import { emitter } from '../utils/emitter';
// import { POPUP_EVENTS } from '../constants/events';
// import { checkLinkedFiles } from '../redux/actions/linkFilePopup';
// import { useDispatch } from 'react-redux';
// import FileSaver from 'file-saver';
import FileProperties from './Home/FileProperties';
import ShareFile from './Home/ShareFile';
import { MdOutlineSave, MdEdit } from 'react-icons/md'
import { BsTrash } from 'react-icons/bs';


export default function FileDetailSideBar({ fileId, initialComments, links }) {
  // const dispatch = useDispatch();
  // async function handleDownloadFile() {
  //   const {
  //     data: { url, file_name },
  //   } = await axios.get(`${CONFIG.BASE_URL}/file/download/${fileId}`, {
  //     withCredentials: true,
  //   });
  //   FileSaver.saveAs(url, file_name);
  // }
  // const handleViewDetail = () => {
  //   emitter.emit(POPUP_EVENTS.SHOW_FILE_PROPERTIES, fileId);
  // };
  // const handleShareFile = () => {
  //   emitter.emit(POPUP_EVENTS.SHARE_FILE);
  // };
  return (
    <div className="comments">
      <Comments fileId={fileId} initialComments={initialComments} />
    </div>
  );
}

FileDetailSideBar.propTypes = {
  fileId: PropTypes.number.isRequired,
  initialComments: PropTypes.array.isRequired,
};

function Comments({ fileId, initialComments }) {
  const [comment, setComment] = useState('');
  const [isSendingComment, setIsSendingComment] = useState(false);
  const [activeComment, setActiveComment] = useState([]);
  const [comments, setComments] = useState([]);
  const [isEdit, setIsEdit] = useState(null);
  const [edit, setEdit] = useState('');
  const styles = {
    content: {
      background: '#F5F6F8',
      padding: '.5rem 1rem .5rem 1rem',
      borderRadius: '5px',
      outline: 'none',
      wordBreak: 'break-word',
      color: '#394254'
    },
    textarea: {
      paddingLeft: '2rem',
      paddingRight: '2rem',
      paddingTop: '1rem',
    },
    avatar: { width: '3.5rem', height: '3.5rem', borderRadius: '50%' },
    avatarDefault: {
      backgroundColor: '#F5F6F8',
      width: '3rem',
      height: '3rem',
      borderRadius: '50%',
      color: '#016288',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontWeight: 'bold'
    },
  };
  const handleChange = (e) => {
    if (edit !== e.target.innerHTML) setEdit(e.target.innerHTML);
  };
  const handleEdit = (index, cmt) => {
    if (isEdit === null) {
      setIsEdit(index);
      setEdit(cmt.comment);
    } else if (isEdit === index) {
      if (edit !== cmt.comment) {
        axios({
          url: `${CONFIG.BASE_URL}/comment_file/${cmt.comment_id}`,
          method: 'PATCH',
          withCredentials: true,
          data: {
            comment: edit,
          },
        })
          .then(({ data }) => {
            comments.find(
              (f) => f.comment_id === cmt.comment_id
            ).comment = edit;
            setComments([...comments]);
            notifySuccess(data.msg);
            setEdit('');
          })
          .catch((err) => {
            setEdit('');
            document.getElementById(`contentEdit-${isEdit}`).innerHTML =
              cmt.comment;
            notifyError(err.response.data.msg);
          });
      }
      setIsEdit(null);
    }
  };
  const handleDelete = (commentId) => {
    axios({
      url: `${CONFIG.BASE_URL}/comment_file/${commentId}`,
      method: 'DELETE',
      withCredentials: true,
    })
      .then(({ data }) => {
        setIsSendingComment(false);
        setComments([...comments.filter((c) => c.comment_id !== commentId)]);
        notifySuccess(data.msg);
      })
      .catch((err) => {
        setIsSendingComment(false);
        setComment('');
        notifyError(err.response.data.msg);
      });
  };
  const handleComment = () => {
    setIsSendingComment(true);
    axios({
      url: `${CONFIG.BASE_URL}/comment_file/${fileId}`,
      method: 'POST',
      withCredentials: true,
      data: {
        comment,
      },
    })
      .then(({ data }) => {
        setIsSendingComment(false);
        setComment('');
        setComments([...comments, data.comments]);
        notifySuccess(data.msg);
      })
      .catch((err) => {
        setIsSendingComment(false);
        setComment('');
        notifyError(err.response.data.msg);
      });
  };
  useEffect(() => {
    setComments(initialComments);
  }, [initialComments]);
  return (
    <>

      <div className="comments-title">
        <p className='title'>Comment</p>
        <p className='small-text'>
          {comments.length} {comments.length <= 1 ? 'Comment' : 'Comments'}
        </p>
      </div>
      <ul className="comment-container">
        {comments.map((c, i) => (
          <li key={i}>
            <div
              key={c.comment_id}
              className={`files-comment ${activeComment === i && isEdit !== i ? 'is-active' : ''
                }`}
              onMouseOver={() => setActiveComment(i)}
              onMouseLeave={() => setActiveComment('')}
            >
              <div className='comited-user-images'>
                {c.avatar_url ?
                  <img alt="" src={c.avatar_url} style={styles.avatar} />
                  :
                  <div style={styles.avatarDefault}>{c.user_name[0]}</div>
                }
              </div>
              <div className='display-commite-wrap'>
                <div className='top-commitd-section'>

                  <div className="comment-name">{c.user_name}</div>
                  <div className="comment-time">
                    {moment(c.updated_at).fromNow()}
                  </div>
                  <div
                    className="group-btns-save text-purple"
                    style={{
                      flex: 1,
                      display:
                        (isEdit === null && activeComment === i) || isEdit === i
                          ? ''
                          : 'none',
                    }}
                  >
                    <span
                      className={`comment-handle ${isEdit !== null ? 'save' : ''}`}
                      onClick={() => handleEdit(i, c)}
                    >
                      {isEdit !== null ? <MdOutlineSave /> : <MdEdit />}
                    </span>
                    {/* &nbsp;|&nbsp; */}
                    <span
                      className="comment-handle delete"
                      onClick={() => handleDelete(c.comment_id)}
                    >
                      <BsTrash />
                    </span>
                  </div>
                </div>
                <div className="comment-content">
                  <div
                    id={`contentEdit-${i}`}
                    className='input-div'
                    contentEditable={isEdit !== null ? 'true' : 'false'}
                    onInput={handleChange}
                    style={
                      isEdit !== null && isEdit === i
                        ? { ...styles.content }
                        : { wordBreak: 'break-word' }
                    }
                    dangerouslySetInnerHTML={{ __html: c.comment }}
                  />
                </div>
              </div>

            </div>
          </li>
        ))}
      </ul>
      <div className='form-commit-wrap'>
        <textarea
          className="form-control"
          value={comment}
          placeholder="Add comment for file"
          onChange={(e) => setComment(e.target.value)}
          rows="4"
          style={styles.textarea}
        />
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            isLoading={isSendingComment}
            customStyle={{ width: '11rem', height: '3rem', marginTop: '1rem' }}
            onClick={handleComment}
          >
            Add
          </Button>
        </div>
      </div>
      <br />

      <FileProperties />
      <ShareFile fileId={fileId} />
    </>
  );
}

Comments.propTypes = {
  fileId: PropTypes.number.isRequired,
  initialComments: PropTypes.array.isRequired,
};

export const CHECK_QUICK_ACCESS = 'CHECK_QUICK_ACCESS';
export const CHECK_QUICK_ACCESS_HIDDEN = 'CHECK_QUICK_ACCESS_HIDDEN';
export const CHECK_QUICK_ACCESS_SUCCESS = 'CHECK_QUICK_ACCESS_SUCCESS';
export const CHECK_QUICK_ACCESS_FAILURE = 'CHECK_QUICK_ACCESS_FAILURE';

export const checkQuickAccess = () => ({
  type: CHECK_QUICK_ACCESS,
});

export const checkQuickAccessHidden = () => ({
  type: CHECK_QUICK_ACCESS_HIDDEN,
});

export const checkQuickAccessSuccess = () => ({
  type: CHECK_QUICK_ACCESS_SUCCESS,
});

export const checkQuickAccessFailure = () => ({
  type: CHECK_QUICK_ACCESS_FAILURE,
});

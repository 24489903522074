import FullCalendar from '@fullcalendar/react';
import React, { useEffect, useRef, useState } from 'react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
// import { useSelector } from 'react-redux';
import SkyLight from 'react-skylight';
import { emitter } from '../../../utils/emitter';
import { POPUP_EVENTS } from '../../../constants/events';
import TimeIcon from '../../../assets/images/times-grey.png';
import moment from 'moment';
import MarkdownText from '../../common/MarkdownText';
import { getReportsMemberId } from '../../../services/project';
import { MdKeyboardArrowRight, MdKeyboardArrowLeft, MdClear } from 'react-icons/md';

const newFolderPopupStyle = {
  minHeight: 0,
  top: '5%',
  left: '5%',
  marginTop: 0,
  marginLeft: 0,
  width: '90%',
  height: '90%',
  borderRadius: '10px',
  padding: 0,
};
const reportStyle = {
  overflow: 'hidden',
  wordBreak: 'break-word',
  whiteSpace: 'pre-wrap',
  marginBottom: '1rem',
};
export default function ReportListPopup() {
  // const reminders = useSelector((s) => s.reminders);
  const popupRef = useRef(null);
  const [monthText, setMonthText] = useState(
    moment(Date.now()).format('MMMM YYYY')
  );
  const [reports, setReports] = useState([]);

  const showPopup = (data, data2) => {
    // console.log({ data, data2 });
    // if (data && data2) {
      if (data && !data2) {
        setReports(data);
      } else if (data && !data2) {
        setReports([]);
      } else {
        const { projectId, memberId } = data2;
        getReportsMemberId({ projectId, memberId }).then(({ member_reports }) =>
          setReports(member_reports)
        );
      }
      if (popupRef.current) popupRef.current.show();
      const monthButton = document.querySelector('.fc-dayGridMonth-button');
      // monthButton.style.position = 'absolute';
      // monthButton.style.left = '-100000px';
      const headerToolbar = document.querySelector('.fc-header-toolbar');
      headerToolbar.style.display = 'none';
    // }
    // setTimeout(() => monthButton.click(), 200);
  };
  // eslint-disable-next-line react/prop-types
  const rendersReport = ({ event: { title } }) => {
    const { job_worked, next_target, user_name } = JSON.parse(title);
    return (
      <div className="mb-3">
        {user_name.search('undefined') === -1 ? (
          <>
            --------------------
            <div style={{ fontWeight: 'bold' }}>{user_name}</div>
            --------------------
          </>
        ) : null}
        <div>
          <div>Job worked:</div>
          <div style={reportStyle}>
            <MarkdownText value={job_worked} />
          </div>
        </div>
        <div>
          <div>Next target:</div>
          <div style={reportStyle}>
            <MarkdownText value={next_target} />
          </div>
        </div>
      </div>
    );
  };
  const getMonthText = () => {
    const toolbarTitle = document.querySelector('.fc-toolbar-title');
    return toolbarTitle?.innerHTML;
  };
  const prevClick = () => {
    const prevButton = document.querySelector('.fc-prev-button');
    prevButton.click();
    setTimeout(() => setMonthText(getMonthText()), 100);
  };
  const nextClick = () => {
    const nextButton = document.querySelector('.fc-next-button');
    nextButton.click();
    setTimeout(() => setMonthText(getMonthText()), 100);
  };
  useEffect(() => {
    function listenToTheEmitter() {
      emitter.on(POPUP_EVENTS.REPORT_LIST, showPopup);
    }
    listenToTheEmitter();
    return () => {
      setReports([]);
      emitter.off(POPUP_EVENTS.REPORT_LIST, showPopup);
    };
  }, []);
  return (
    <SkyLight
      dialogStyles={newFolderPopupStyle}
      ref={popupRef}
      hideOnOverlayClicked
      afterClosed={() => setReports([])}
      onClick={() => popupRef.current.show()}
    >
      <div
        style={{
          width: '100%',
          height: '100%',
          padding: '1rem',
          backgroundColor: 'white',
          borderRadius: 10,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div className='close-wrap-calender'>

          <button type='button' className='close-calender' onClick={() => popupRef.current.hide()}><MdClear /></button>

        </div>
        <div className="calendar-top-bar">
          <div className='left-bar'>
            <div className="interactive-btn" onClick={prevClick}>
              <i className="icon-arrow-left2"><MdKeyboardArrowLeft /></i>
            </div>
            <div className="interactive-btn" onClick={nextClick}>
              <i className="icon-arrow-right2"><MdKeyboardArrowRight /></i>
            </div>
            <div className='title-calender'>{monthText}</div>
          </div>
          <div className='calender-page-title'>Reports</div>
        </div>
        <div
          className="custom-vertical-scrollbar report-calendar"
          style={{ height: 'calc(100% - 5rem)', overflowY: 'auto' }}
        >
          <FullCalendar
            defaultView="dayGridMonth"
            plugins={[dayGridPlugin, interactionPlugin]}
            headerToolbar={{
              start: 'prev next title',
              center: '',
              end: '',
            }}
            weekends
            eventContent={(e) => rendersReport(e)}
            selectable
            eventTextColor="black"
            eventClassNames="fc-custom-event-class"
            events={reports.map((r) => ({
              id: r.report_id,
              title: JSON.stringify({
                user_name: `${r.userInfo?.first_name} ${r.userInfo?.last_name}`,
                job_worked: r.job_worked,
                next_target: r.next_target,
              }),
              start: moment(r.created_at).format('YYYY-MM-DD'),
            }))}
          />
        </div>
      </div>
    </SkyLight>
  );
}

import axios from 'axios';
import CONFIG from '../constants/config';

export const getFileAnalysis = async () => {
  const response = await axios({
    method: 'GET',
    url: `${CONFIG.BASE_URL}/file-analysis`,
    withCredentials: true,
  });
  return response.data;
};

export const getFileAnalysisByDate = async ({ bydate, all = undefined, time, unit_of_time }) => {
  const response = await axios({
    method: 'GET',
    url: `${CONFIG.BASE_URL}/file-analysis-by-date`,
    withCredentials: true,
    params: { bydate, all, time, unit_of_time },
  });
  return response.data;
};

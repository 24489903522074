import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { uploadAuth } from '../redux/actions/uploadAuth';

function CallBack(props) {

    const dispatch = useDispatch();

    const { dropboxLogin } = uploadAuth;

    useEffect(() => {
        var url = window.location.hash?.substring(1);
        var access_token = new URLSearchParams(url).get('access_token');
        var account_id = new URLSearchParams(url).get('account_id');
        const data = {
            access_token: access_token,
            account_id: account_id
        }
        localStorage.setItem('authDropbox', JSON.stringify(data))
        dispatch(dropboxLogin(data))
        // if (process.env.NODE_ENV === 'production') {
        //     props.history.push('/files');
        // } else {
            props.history.push('/files');
        // }
    }, [])
    return <div><button type='button' onClick={() => window.close()}>Go back</button></div >;
}

export default withRouter(CallBack);
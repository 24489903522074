export const EVENTS = {
  VERIFY_OTP_FAIL: 'VERIFY_OTP_FAIL',
};

export const HOMEPAGE_EVENTS = {
  SCROLL_TO_TOP: 'SCROLL_TO_TOP',
};

export const POPUP_EVENTS = {
  SHOW_FILE_CONTEXT_MENU: 'SHOW_FILE_CONTEXT_MENU',
  SHOW_FOLDER_CONTEXT_MENU: 'SHOW_FOLDER_CONTEXT_MENU',
  SHOW_FOLDER_AREA_CONTEXT_MENU: 'SHOW_FOLDER_AREA_CONTEXT_MENU',
  SHOW_CREATE_FOLDER: 'SHOW_CREATE_FOLDER',
  SHOW_SEARCH_FILTER_POPUP: 'SHOW_SEARCH_FILTER_POPUP',
  SHOW_UPLOAD_POPUP: 'SHOW_UPLOAD_POPUP',
  SHOW_RENAME_POPUP: 'SHOW_RENAME_POPUP',
  SHOW_TAG_POPUP: 'SHOW_TAG_POPUP',
  SHOW_DETAIL_FILE_OR_FOLDER: 'SHOW_DETAIL_FILE_OR_FOLDER',
  SHOW_FILE_PROPERTIES: 'SHOW_FILE_PROPERTIES',
  SHOW_CHECKOUT_SUCCESS: 'SHOW_CHECKOUT_SUCCESS',
  SHOW_CHECKIN: 'SHOW_CHECKIN',
  SHOW_LINK_FILES: 'SHOW_LINK_FILES',
  SHOW_REMINDER: 'SHOW_REMINDER',
  CREATE_REMINDER: 'CREATE_REMINDER',
  HIDE_FILE_CONTEXT_MENU: 'HIDE_FILE_CONTEXT_MENU',
  HIDE_CONFIRM_DELETE_FILE: 'HIDE_CONFIRM_DELETE_FILE',
  HIDE_CONFIRM_DELETE_FOLDER: 'HIDE_CONFIRM_DELETE_FOLDER',
  HIDE_FOLDER_CONTEXT_MENU: 'HIDE_FOLDER_CONTEXT_MENU',
  HIDE_UPLOAD_POPUP: 'HIDE_UPLOAD_POPUP',
  CREATE_TASK: 'CREATE_TASK',
  CREATE_ADD_MEMBER: 'CREATE_ADD_MEMBER',
  SHOW_REPORT: 'SHOW_REPORT',
  HIDE_REPORT: 'HIDE_REPORT',
  REPORT_LIST: 'REPORT_LIST',
  ADD_USER: 'ADD_USER',
  SHARE_FILE: 'SHARE_FILE',
  VERSION: 'VERSION',
};

export const HOME_EVENTS = {
  RESET_SEARCH_FORM_INPUT: 'RESET_SEARCH_FORM_INPUT',
  DROP_FILE_OR_FOLDER: 'DROP_FILE_OR_FOLDER',
  TOGGLE_SHOW_UPLOAD_OPTIONS: 'TOGGLE_SHOW_UPLOAD_OPTIONS',
  HANLDE_UPLOAD_FILE: 'HANLDE_UPLOAD_FILE',
  HANLDE_CHECKIN_FILE: 'HANLDE_CHECKIN_FILE',
  HANLDE_UPLOAD_FILES: 'HANLDE_UPLOAD_FILES',
  DELETE_MY_DOCUMENTS: 'DELETE_MY_DOCUMENTS',
  NEW_NOTIFICATION: 'NEW_NOTIFICATION',
};

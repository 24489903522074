import React, { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'react-bootstrap';
import AddUser from '../userInfo/AddUser';
import SkyLight from 'react-skylight';
import axios from 'axios';
import { notifyError, notifyErrorMsg, notifySuccess } from '../common/Alert';
import CONFIG from '../../constants/config';
import { emitter } from '../../utils/emitter';
import { POPUP_EVENTS } from '../../constants/events';
import FileSaver from 'file-saver';
import templateExcel from './template.xlsx';
import PropTypes from 'prop-types';
import ConfirmButton from '../task/common/ConfirmButton';
import { deleteUser, disableUser, enableUser } from '../../services/organization';
import { updateUserPlanModal } from '../../redux/actions/userInfo';
import dummyUser from '../../assets/images/dummyUser.svg'
import { MdClear } from 'react-icons/md';

const sendEmailStyle = {
  left: '30%',
  marginLeft: 0,
  marginTop: 0,
  top: '35%',
  width: '40%',
  // height: '25%',
  minHeight: '0rem',
  borderRadius: 10,
};
export default function Users({ memberList, callback }) {

  const dispatch = useDispatch();

  const { role } = useSelector((s) => s.userInfo);
  const userInfo = useSelector((s) => s.userInfo);
  const [keyword, setKeyword] = useState('');
  const [excel, setExcel] = useState({});
  const [isSendMail, setIsSendMail] = useState(false);
  const [email, setEmail] = useState('');
  const [invitedEmail, setInvitedEmail] = useState('');
  const sendEmailPopup = useRef(null);
  const invitePopup = useRef(null);
  const importPopup = useRef(null);
  function handleSend() {
    setIsSendMail(true);
    axios({
      method: 'POST',
      url: `${CONFIG.BASE_URL}/send-invitation`,
      withCredentials: true,
      data: {
        email,
      },
    })
      .then(({ data }) => {
        sendEmailPopup.current.hide();
        setIsSendMail(false);
        setEmail('');
        notifySuccess(data.msg);
      })
      .catch((err) => {
        setIsSendMail(false);
        setEmail('');
        notifyErrorMsg(err);
      });
  }
  const handleInvite = () => {
    setIsSendMail(true);
    axios({
      method: 'POST',
      url: `${CONFIG.BASE_URL}/organization/${userInfo.org_id}/invite`,
      withCredentials: true,
      data: { email: invitedEmail },
    })
      .then(({ data }) => {
        invitePopup.current.hide();
        setIsSendMail(false);
        setEmail('');
        notifySuccess(data.msg);
      })
      .catch((err) => {
        setIsSendMail(false);
        setEmail('');
        notifyError(err?.response?.data.data);
      });
  };
  const handleChangeExcel = (e) => {
    setExcel(e.target.files[0]);
  };
  const handleImport = () => {
    setIsSendMail(true);
    const formData = new FormData();
    formData.append('file', excel);
    axios({
      method: 'POST',
      url: `${CONFIG.BASE_URL}/import-member`,
      withCredentials: true,
      data: formData,
    })
      .then(({ data }) => {
        importPopup.current.hide();
        setIsSendMail(false);
        setEmail('');
        notifySuccess(data.msg);
      })
      .catch((err) => {
        setIsSendMail(false);
        setEmail('');
        notifyError(err?.response?.data);
      });
  };
  const handleDisable = (id) => {
    disableUser({ user_id: id })
      .then(() => callback())
      .catch((err) => notifyErrorMsg(err));
  };
  const handleEnable = (id) => {
    enableUser({ user_id: id })
      .then(() => callback())
      .catch((err) => notifyErrorMsg(err));
  };
  const handleDelete = (id) => {
    deleteUser({ user_id: id })
      .then(() => callback())
      .catch((err) => notifyErrorMsg(err));
  };

  const renderUser = (

    <div className='setting-user-li-wrap mt-4'>
      <div>
        {memberList
          .filter(
            (s) =>
              `${s.first_name} ${s.last_name}`.match(
                new RegExp(`${keyword}`, 'i')
              ) || s.email.match(new RegExp(`${keyword}`, 'i'))
          )
          .map(
            (mem, index) =>
              mem.user_id !== userInfo.user_id && (
                <>

                  <div key={mem.user_id} className="item d-flex" style={{ background: mem.disabled ? 'rgba(204,204,204,.1)' : undefined }}>
                    <div className="user-img-wrap">
                      <img src={dummyUser} alt="user" />
                      {/* {mem.avatar_url ?
                          <img alt="" src={mem.avatar_url} />
                          :
                          <div>{`${mem.first_name[0]}${mem.last_name[0]}`}</div>
                        } */}
                      {/* {index & 1 ? <div className="green-dot"/> : null} */}
                    </div>
                    <div className='member'>

                      <div className='margin'>
                        <div className="title--bold">{`${mem.first_name} ${mem.last_name}`}</div>
                        <div className="title-text">{mem.email}</div>
                      </div>
                      <div style={{ visibility: 'hidden' }}>Role</div>
                      <div style={{ visibility: 'hidden' }}>10/10/2020</div>
                      <div className='d-flex'>
                        {!mem.disabled && (
                          <>
                            <div className="">
                              <ConfirmButton
                                message="Are you sure you want to disable this user?"
                                buttonStyle={{
                                  border: 'none',
                                  background: 'none',

                                }}
                                buttonClass="btn-hover-underline"
                                buttonText="Disable"
                                handleClick={() => handleDisable(mem.user_id)}
                              >Disable</ConfirmButton>
                            </div>

                            &nbsp;|&nbsp;
                            <div className="">
                              <ConfirmButton
                                message="Are you sure you want to delete this user?"
                                buttonStyle={{
                                  border: 'none',
                                  background: 'none',
                                }}
                                buttonClass="btn-hover-underline"
                                buttonText="Delete"
                                handleClick={() => handleDelete(mem.user_id)}
                              >Delete</ConfirmButton>
                            </div>

                          </>
                        )}
                        {mem.disabled && (
                          <>
                            <div className="heading--active">
                              <ConfirmButton
                                message="Are you sure you want to enable this user?"
                                buttonStyle={{
                                  border: 'none',
                                  background: 'none',
                                }}
                                buttonClass="btn-hover-underline"
                                buttonText="Enable"
                                handleClick={() => handleEnable(mem.user_id)}
                              />
                            </div>
                            &nbsp;|&nbsp;
                            <div className="heading--active">
                              <ConfirmButton
                                message="Are you sure you want to delete this user?"
                                buttonStyle={{
                                  border: 'none',
                                  background: 'none',
                                }}
                                buttonClass="btn-hover-underline"
                                buttonText="Delete"
                                handleClick={() => handleDelete(mem.user_id)}
                              />
                            </div>

                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* {index !==
                    memberList.filter(
                      (s) =>
                        `${s.first_name} ${s.last_name}`.match(
                          new RegExp(`${keyword}`, 'i')
                        ) || s.email.match(new RegExp(`${keyword}`, 'i'))
                    ).length -
                    1 ?
                    <div className="hr" />
                    : null} */}

                </>
              )
          )}
      </div>
    </div>

  );

  const upgradeModalEvent = () => {
    dispatch(updateUserPlanModal(true))
  }

  const addMemberClick = () => {
    if (userInfo.plan === 'BASIC') {
      upgradeModalEvent();
    } else {
      emitter.emit(POPUP_EVENTS.ADD_USER)
    }
  }
  const inviteUserClick = () => {
    if (userInfo.plan === 'BASIC') {
      upgradeModalEvent();
    } else {
      invitePopup.current.show()
    }
  }
  const sendInvitionClick = () => {
    if (userInfo.plan === 'BASIC') {
      upgradeModalEvent();
    } else {
      sendEmailPopup.current.show()
    }
  }
  const importUserClick = () => {
    if (userInfo.plan === 'BASIC') {
      upgradeModalEvent();
    } else {
      importPopup.current.show()
    }
  }

  return (
    <div>
      <div id="member-list">
        {role === 'admin' && (
          <div>
            <div className="d-flex align-items-center  mb-4">
              <button className='setting-user-btn' onClick={addMemberClick}>
                Add member
              </button>



              <button className='setting-user-btn' onClick={inviteUserClick}>
                Invite user
              </button>


              <button className='setting-user-btn' onClick={sendInvitionClick}>
                Send Invitation
              </button>


              <button className='setting-user-btn me-auto' onClick={importUserClick}>
                Import Users
              </button>

              <div className="search d-inline-block ">
                <input
                  value={keyword}
                  className='search-input'
                  onChange={(e) => setKeyword(e.target.value)}
                  type="text"
                  placeholder="Search"
                />
              </div>
            </div>
            {renderUser}
          </div>
        )}
      </div>
      <AddUser callback={callback} />
      <SkyLight
        hideOnOverlayClicked
        ref={sendEmailPopup}
        dialogStyles={sendEmailStyle}
      >
        <div className="checkinout" style={{ paddingTop: '0.5em' }}>
          <div
            className="check-success--title add-ip-title"
            style={{ marginBottom: '2rem' }}
          >
            <span className='mb0' >Send an invite to Docquik to</span>
            <i
              className="icon-times-grey"
              onClick={() => sendEmailPopup.current.hide()}
            ><MdClear /></i>
          </div>
          <div className="check-input">
            <input
              type="email"
              className="primary-input"
              required
              placeholder="Email"
              value={email}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSend();
                }
              }}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="check-button">
            <button type="button" onClick={() => sendEmailPopup.current.hide()}>
              Cancel
            </button>
            <Button onClick={handleSend} isLoading={isSendMail}>
              OK
            </Button>
          </div>
        </div>
      </SkyLight>
      <SkyLight
        hideOnOverlayClicked
        ref={invitePopup}
        dialogStyles={sendEmailStyle}
      >
        <div className="checkinout" style={{ paddingTop: '0.5em' }}>
          <div
            className="check-success--title add-ip-title"
            style={{ marginBottom: '2rem' }}
          >
            <span style={{ marginBottom: '0px !important' }}>Invite an user to organization</span>
            <i
              className="icon-times-grey"
              onClick={() => invitePopup.current.hide()}
            ><MdClear /></i>
          </div>
          <div className="check-input">
            <input
              type="email"
              className="primary-input"
              required
              placeholder="Email"
              value={invitedEmail}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleInvite();
                }
              }}
              onChange={(e) => setInvitedEmail(e.target.value)}
            />
          </div>
          <div className="check-button">
            <button type="button" onClick={() => invitePopup.current.hide()}>
              Cancel
            </button>
            <Button onClick={handleInvite} isLoading={isSendMail}>
              OK
            </Button>
          </div>
        </div>
      </SkyLight>
      <SkyLight
        hideOnOverlayClicked
        ref={importPopup}
        dialogStyles={sendEmailStyle}
      >
        <div className="checkinout" style={{ paddingTop: '0.5em' }}>
          <div
            className="check-success--title add-ip-title"
            style={{ marginBottom: '2rem' }}
          >
            <span style={{ marginBottom: '0px !important' }}>Invite an user to organization</span>
            <i
              className="icon-times-grey"
              onClick={() => importPopup.current.hide()}
            ><MdClear /></i>
          </div>

          <div className="check-input">
            <div
              className="mb-3 interactive"
              style={{ color: '#534cdd' }}
              onClick={() => FileSaver.saveAs(templateExcel)}
            >
              Download Template
            </div>
            <input
              onChange={handleChangeExcel}
              className="mb-4"
              type="file"
              name="myfile"
              accept=".xls, .xlsb, .xlsm, .xltx, .xlsx"
            />
          </div>
          <div className="check-button">
            <button type="button" onClick={() => importPopup.current.hide()}>
              Cancel
            </button>
            <Button onClick={handleImport} isLoading={isSendMail}>
              OK
            </Button>
          </div>
        </div>
      </SkyLight>
    </div>
  );
}
Users.propTypes = {
  memberList: PropTypes.array.isRequired,
};

import { GET_USAGE_SUCCESS, GET_USAGE_FAIL } from '../actions/usage';

const initialState = {
  storage: 0,
  files: 0,
  storage_used: 0,
};

export default function usage (state, action) {
  switch (action.type) {
    case GET_USAGE_SUCCESS: return {
      ...state,
      storage: action.payload.storage,
      storage_used: action.payload.storage_used,
      files: action.payload.files,
    };
    case GET_USAGE_FAIL: return {
      ...initialState,
    };
    default: return state || initialState;
  }
}

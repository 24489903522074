import React, { useState, useRef, useEffect } from 'react';
// import { Dropdown } from 'react-bootstrap';
import { MdMoreVert, MdFolder } from 'react-icons/md';
import PropTypes from 'prop-types';
import { renderIf } from '../../utils/components';
// import folderIcon from '../../assets/images/folder-big-icon.svg';
import { renameFolder } from '../../redux/actions/folders';
import { useDispatch } from 'react-redux';
import { checkFolderFavorite } from '../../redux/actions/folders';
import { bytesToSize } from '../../utils/string';

export default function FolderItem({
  folder,
  handleClick,
  handleFolderContextMenu,
  onDrop,
  onDragOver,
  dragStart,
}) {
  const [folderSelected, setFolderSelected] = useState(false);
  const [newName, setNewName] = useState(null);
  const [quickRename, setQuickRename] = useState(false);
  const { folder_id, folder_name, favorite } = folder;
  const [isActive, setIsActive] = useState(false);
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const classes = {
    input: {
      width: '100%',
      display: quickRename ? 'block' : 'none',
      border: 'none',
      boxShadow: '1px 1px 2px grey',
    },
  };

  const handleQuickRename = (e) => {
    if (e.keyCode === 13) {
      if (folder_id) dispatch(renameFolder(folder_id, newName));
      setQuickRename(false);
    }
  };

  useEffect(() => {
    if (quickRename) {
      inputRef.current.focus();
    }
  }, [quickRename]);

  return (
    <div
      className={`folders__item ${isActive ? 'is-active' : ''} ${folderSelected ? 'folder-selected' : ''}`}
      onDoubleClick={() => {
        handleClick(folder_id, folder_name);
      }}
      onClick={() => setFolderSelected(true)}
      onBlur={() => setFolderSelected(false)}
      // key={folder_id}
      // onContextMenu={(e) => handleFolderContextMenu(folder_id, folder_name, e)}
      onKeyDown={(e) => {
        if (e.keyCode === 113) {
          setQuickRename(true);
        }
      }}
      tabIndex={0}
      data-drop
      draggable="true"
      onDrop={onDrop}
      onDragOver={onDragOver}
      onDragStart={dragStart}
      data-id={`fo-${folder_id}`}
      onMouseOver={() => setIsActive(true)}
      onMouseOut={() => setIsActive(false)}
    >
      <input
        ref={inputRef}
        onKeyDown={handleQuickRename}
        onBlur={() => {
          setQuickRename(false);
          setNewName(null);
        }}
        type="text"
        style={classes.input}
        value={newName !== null ? newName : ''}
        onChange={(e) => setNewName(e.target.value)}
      />
      {renderIf(
        <div className={`folder-name`}>
          <div className="folder-img-wrap">
            {/* <span className='icon-folder'><MdFolder /></span> */}
            <span className='icon-folder'>
              <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M44 12.375V37.125C44 39.4023 42.1523 41.25 39.875 41.25H4.125C1.84766 41.25 0 39.4023 0 37.125V6.875C0 4.59766 1.84766 2.75 4.125 2.75H17.875L23.375 8.25H39.875C42.1523 8.25 44 10.0977 44 12.375Z" fill={folder?.folder_name && folder?.folder_name === "Shared"?"#016288":"#FCAF18"} />
              </svg>
            </span>
            {/* <img alt="" src={folderIcon} /> */}
            <span className="count-files-folder">{folder?.total_file ? folder.total_file : 0} Items</span>
          </div>
          <p>{folder_name}
            <span className='size-folder'>{folder?.total_size ? bytesToSize(folder.total_size) : '0 MB'}</span>
          </p>
          <button
            data-for="favorites" data-tip="Favorites"
            type="button"
            className={`btn-likes folder-favrites ${favorite ? 'active' : ''}`}
            onClick={() => dispatch(checkFolderFavorite(folder_id, favorite ? false : true, folder))}
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 5.09C13.09 3.81 14.76 3 16.5 3C19.58 3 22 5.42 22 8.5C22 12.2769 18.6056 15.3549 13.4627 20.0185L13.45 20.03L12 21.35L10.55 20.04L10.5105 20.0041C5.38263 15.3442 2 12.2703 2 8.5C2 5.42 4.42 3 7.5 3C9.24 3 10.91 3.81 12 5.09Z" fill="#DF5656" />
            </svg>
          </button>
          <div className="files-dropdown folder-dropdown">
            <button type="button" style={{ lineHeight: '0' }} onClick={(e) => handleFolderContextMenu(folder_id, folder_name, e)}><MdMoreVert /></button>
          </div>
        </div>,
        !quickRename
      )}
      {/* <div className="files-count">{files.filter(file => file.parent_id === item.folder_id).length} files</div> */}
      {/* <div className="members-count">+3</div> */}
    </div>
  );
}

FolderItem.propTypes = {
  handleClick: PropTypes.func.isRequired,
  folder: PropTypes.object.isRequired,
  handleFolderContextMenu: PropTypes.func.isRequired,
  onDrop: PropTypes.func.isRequired,
  onDragOver: PropTypes.func.isRequired,
  dragStart: PropTypes.func.isRequired,
};

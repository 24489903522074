import React from 'react';
import { useSelector } from 'react-redux';
// import { Route } from 'react-router-dom'
// import { routes } from '../routes';

export default function TheContent(props) {

    const layout = useSelector(s => s.layout);

    return (
        <div className={`container-wrap ${layout.isMobileView ? 'ps-0' : ''}`} style={{ paddingTop: layout.isMobileView ? '65px' : '0' }}>
            {props.children}
            {/* {routes.map((route, i) =>
                <Route key={i} path={route.path} exact={route.exact} component={route.component} />
            )} */}
        </div>
    )
}

import { call, put, takeEvery } from 'redux-saga/effects';
import { GET_USAGE } from '../redux/actions/usage';
import { getUsage } from '../services/usage';

export function *getUsageSaga() {
  try {
    const result = yield call(getUsage);
    if (result.success) {
      yield put({
        type: 'GET_USAGE_SUCCESS',
        payload: {
          files: Number(result.total_file.total),
          storage: Number(result.storage.storage),
          storage_used: Number(result.storage.storage_used),
        },
      });
    }
    // console.log(result);
  } catch (err) {
    console.log(err);
  }
}

export default function *usageWatcher() {
  yield takeEvery(GET_USAGE, getUsageSaga);
}

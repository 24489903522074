import React, { useState, useEffect, useRef } from 'react';
import SkyLight from 'react-skylight';
import axios from 'axios';
import { emitter } from '../../utils/emitter';
import { notifySuccess, notifyError } from '../common/Alert';
import { POPUP_EVENTS } from '../../constants/events';
import CONFIG from '../../constants/config';

export default function TagPopup() {
  const popupRef = useRef(null);
  const buttonRef = useRef(null);
  const inputRef = useRef(null);
  const [isVisible, setIsVisible] = useState(true);
  const [fileId, setFileId] = useState(null);
  const [tag_name, setTagName] = useState('');

  async function showPopup(id) {
    try {
      const {
        data: { tag },
      } = await axios({
        method: 'GET',
        url: `${CONFIG.BASE_URL}/tags/${id}`,
        withCredentials: true,
      });
      setTagName(tag || '');
    } catch (e) {
      console.log(e);
      setTagName('');
    }
    setFileId(id);
    if (popupRef.current) {
      popupRef.current.show();
    }
    setTimeout(() => {
      inputRef.current.focus();
    }, 1);
  }

  function hidePopup() {
    popupRef.current.hide();
    setIsVisible(false);
  }

  async function submit() {
    try {
      const {
        data: { msg },
      } = await axios({
        method: 'POST',
        url: `${CONFIG.BASE_URL}/tags/${fileId}`,
        withCredentials: true,
        data: { tag_name },
      });
      notifySuccess(msg);
    } catch (e) {
      notifyError(e.response.data.msg);
    }
    hidePopup();
  }

  useEffect(() => {
    emitter.on(POPUP_EVENTS.SHOW_TAG_POPUP, showPopup);
    return () => emitter.off(POPUP_EVENTS.SHOW_TAG_POPUP, showPopup);
  });

  function handleKeyDown(e) {
    if (e.keyCode === 13) {
      buttonRef.current.click();
    }
  }

  return (
    <SkyLight
      dialogStyles={{
        width: '30%',
        // height: '30%',
        marginTop:'-50px',
        minHeight: 0,
        marginLeft: '-15%',
        borderRadius: '10px',
      }}
      ref={popupRef}
      isVisible={isVisible}
    >
      <div className="newFolderPopup">
        <div className='label'>Tag</div>
        <input
          type="text"
          value={tag_name}
          onChange={(e) => setTagName(e.target.value)}
          onKeyDown={handleKeyDown}
          ref={inputRef}
          className='form-control'
        />
        <div className='btns-groups'>
          <button onClick={hidePopup} className="btn-cancel" type="button">
            Cancel
          </button>
          <button ref={buttonRef} onClick={submit} type="button">
            Save
          </button>
        </div>
      </div>
    </SkyLight>
  );
}

import axios from 'axios';
import CONFIG from '../constants/config';

export const getRecentlyUpload = async () => {
  const response = await axios({
    method: 'GET',
    url: `${CONFIG.BASE_URL}/file?orderBy=desc&sortBy=created_at&limit=10`,
    withCredentials: true,
  });
  return response.data;
};

export const getRecentlyAccessed = async () => {
  const response = await axios({
    method: 'GET',
    url: `${CONFIG.BASE_URL}/file?orderBy=desc&sortBy=updated_at&limit=10`,
    withCredentials: true,
  });
  return response.data;
};

export const getFavoriteFiles = async () => {
  const response = await axios({
    method: 'GET',
    url: `${CONFIG.BASE_URL}/list_file_favorite`,
    withCredentials: true,
  });
  return response.data;
};

export const getFavoriteFolders = async () => {
  const response = await axios({
    method: 'GET',
    url: `${CONFIG.BASE_URL}/list_folder_favorite`,
    withCredentials: true,
  });
  return response.data;
};

export const getCheckoutFiles = async () => {
  const response = await axios({
    method: 'GET',
    url: `${CONFIG.BASE_URL}/list_file_checkout`,
    withCredentials: true,
  });
  return response.data;
};

import React, { useState } from 'react';
import ManageStorage from './settings/ManageStorage';
import Privileges from './settings/Privileges';
import SuperAdmin from './settings/SuperAdmin';
import PropTypes from 'prop-types';
export default function Settings({ memberList }) {
  const [activeTab, setActiveTab] = useState(0);

  const handleChangeTab = (index) => () => {
    setActiveTab(index);
  };
  return (
    <div className='settings-settings-section'>
      <div className="d-flex">
        <div
          className={`setting-user-btn disable-back ${activeTab === 0 ? 'active' : ''
            }`}
          onClick={handleChangeTab(0)}
        >
          Privileges
        </div>
        <div
          className={`setting-user-btn disable-back ${activeTab === 1 ? 'active' : ''
            }`}
          onClick={handleChangeTab(1)}
        >
          Manage Storage
        </div>
        <div
          className={`setting-user-btn disable-back ${activeTab === 2 ? 'active' : ''
            }`}
          onClick={handleChangeTab(2)}
        >
          Change Super Admin
        </div>
      </div>
      {/* <hr /> */}
      <div>
        {activeTab === 0 && <Privileges />}
        {activeTab === 1 && <ManageStorage />}
        {activeTab === 2 && <SuperAdmin memberList={memberList} />}
      </div>
    </div>
  );
}
Settings.propTypes = {
  memberList: PropTypes.array.isRequired,
};

import { all, call, put, takeEvery } from 'redux-saga/effects';
import {
  CREATE_REMINDER,
  CREATE_REMINDER_SUCCESS,
  DELETE_REMINDER,
  DELETE_REMINDER_SUCCESS,
  EDIT_REMINDER,
  EDIT_REMINDER_SUCCESS,
  GET_REMINDERS,
  GET_REMINDERS_SUCCESS,
} from '../redux/actions/reminders';
import {
  createReminder,
  getReminders,
  editReminder,
  deleteReminder,
} from '../services/reminders';
import { groupBy } from '../utils/array';
import { notifyError } from '../components/common/Alert';
export function *getRemindersSaga() {
  try {
    const result = yield call(getReminders);
    if (result.success) {
      const reminders = groupBy(result.data, 'status');
      yield put({
        type: GET_REMINDERS_SUCCESS,
        payload: { ...reminders },
      });
    }
  } catch (err) {
    console.log(err);
  }
}
export function *createReminderSaga(action) {
  try {
    const result = yield call(createReminder, action.payload);
    if (result.success) {
      yield put({
        type: CREATE_REMINDER_SUCCESS,
        payload: result.data[0],
      });
    }
  } catch (err) {
    notifyError(err.response.data.data);
  }
}
export function *editReminderSaga(action) {
  try {
    const result = yield call(editReminder, action.payload);
    if (result.success) {
      yield put({
        type: EDIT_REMINDER_SUCCESS,
        payload: result.data[0],
      });
    }
  } catch (err) {
    notifyError(err.response.data.data);
  }
}
export function *deleteReminderSaga(action) {
  try {
    const result = yield call(deleteReminder, action.payload);
    if (result.success) {
      yield put({
        type: DELETE_REMINDER_SUCCESS,
        payload: action.payload,
      });
    }
  } catch (err) {
    console.log(err);
  }
}

export default function *remindersWatcher() {
  yield all([
    takeEvery(GET_REMINDERS, getRemindersSaga),
    takeEvery(CREATE_REMINDER, createReminderSaga),
    takeEvery(EDIT_REMINDER, editReminderSaga),
    takeEvery(DELETE_REMINDER, deleteReminderSaga),
  ]);
}

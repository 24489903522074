import React, { useEffect, useRef } from 'react';
import Select, { components } from 'react-select';
import PropTypes from 'prop-types';
const Option = (props) => (
  <components.Option {...props}>
    <div className="list-select-member-wrap">
      <div className='memeber-image-wrap'>
        {props.data.avatar_url ? (
          <img
            alt=""
            src={props.data.avatar_url}
          />
        ) : (
          <span className='m-name'>
            {props.data.first_name[0]}{props.data.last_name[0]}
          </span>
          
        )}
      </div>
      <div className='name-of-mamber-wrap'>
        <p>
          <span className='m-text'>{props.data.first_name} {props.data.last_name}</span>
          {props?.data?.email?<span className='m-email'>{props.data.email}</span>:null}
        </p>
        {/* {props.data?.email && <div>{props.data?.email}</div>} */}
      </div>
    </div>
  </components.Option>
);
Option.propTypes = {
  data: PropTypes.object.isRequired,
};

const Control = (props) => <components.Control {...props} />;

const ClearIndicator = (props) =>
  <components.ClearIndicator className="clear-button" {...props} />;
export default function SelectMember({ data, callback, placeholder, clear, classNamePrefix }) {
  const handleChange = (value) => {
    callback(value);
  };
  const selectInputRef = useRef();

  useEffect(() => {
    if (!clear) {
      selectInputRef.current.clearValue()
      
      // selectInputRef.current.select.clearValue();
    }
  }, [clear]);

  return (
    <Select
      // menuIsOpen={true}
      ref={selectInputRef}
      closeMenuOnSelect
      components={{ Option, Control, ClearIndicator }}
      onChange={(value) => handleChange(value)}
      placeholder={placeholder}
      className="primary-select"
      classNamePrefix={classNamePrefix}
      isClearable
      setValue
      styles={{
        control: (base, { isFocused, hasValue }) => ({
          ...base,
          border: 'none',
          borderRadius: 'none',
          borderBottom:
            isFocused || hasValue ? '2px solid #534cdd ' : '2px solid #cccccc',
          height: '100%',
          boxShadow: 'none',
          '&:hover': {
            borderColor: '#534cdd ',
            borderWidth: '2px',
          },
        }),
        container: (base) => ({
          ...base,
          width: '100%',
        }),
      }}
      options={data}
      getOptionValue={(opt) => opt.user_id}
      getOptionLabel={(opt) => `${opt.first_name} ${opt.last_name}`}
    />
  );
}
SelectMember.propTypes = {
  data: PropTypes.array.isRequired,
  callback: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
};

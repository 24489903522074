import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getUserInfo } from '../../../redux/actions/userInfo';
import { updateSuperAdmin } from '../../../services/settings';
import { notifyError, notifySuccess } from '../../common/Alert';
import { Button, Form } from 'react-bootstrap';
import SelectMember from '../../task/admin/SelectMember';

export default function SuperAdmin({ memberList }) {
  const [email, setEmail] = useState('');
  const dispatch = useDispatch();
  const history = useHistory();
  const handleChange = () => {
    updateSuperAdmin({ email })
      .then(() => {
        history.push('/home');
        dispatch(getUserInfo());
        setEmail('');
        notifySuccess();
      })
      .catch((err) => notifyError(err?.response?.data?.data));
  };
  const handleSelectMember = (member) => {
    if (
      typeof member === 'object' &&
      member !== null &&
      Object.keys(member).length
    ) {
      setEmail(member.email);
    }
  };
  return (
    <div className="bg-white admin-super-wrap mt-4">
      <div className="row">
        <Form.Label className="form-label2 col-12">Select a new super admin.</Form.Label>
        <div className="col-6">
          <SelectMember
            data={memberList}
            callback={handleSelectMember}
            placeholder="Choose member"
            clear={email}
          />
        </div>
        <div className='col-sm-3'>
          <Button onClick={handleChange} style={{ padding:'12px 24px', fontSize:'12px', lineHeight:'14px'}}>Change</Button>
        </div>
      </div>
    </div>
  );
}

import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import Picker from 'emoji-picker-react';
import Modal from 'react-modal';
import FileSaver from 'file-saver';
import ReactTooltip from 'react-tooltip';
import ChatBlank from '../assets/images/chat-blank-icon.svg';
// import SideMenu from '../components/common/SideMenu';
// import Button from 'react-bootstrap/Button';
import { useDispatch, useSelector } from 'react-redux';
import { emitter } from '../utils/emitter';
import {
    ACTION_RECEIVER_MESSAGE,
    ACTION_RECEIVER_MEMBER_LIST,
    ACTION_OPEN_CHAT_DETAIL,
    ACTION_SEND_MESSAGE,
    ACTION_GET_MESSAGE_HISTORY,
    ACTION_OPEN_MESSAGE,
    ACTION_UPLOAD_FILES,
    ACTION_MESSAGE_HISTORY,
    ACTION_SHOW_NOTIFY_INFO,
    ACTION_MESSAGE_LIST,
    ACTION_SOCKET_CONNECTION
} from '../redux/actions/message';
import CONFIG from '../constants/config';
import getDefaultThumbnail from '../utils/getDefaultThumbnail';
import { Dropdown } from 'react-bootstrap';
import { HiOutlinePlus } from 'react-icons/hi';
import { MdMoreVert, MdAdd, MdMoreHoriz, MdArrowBack } from 'react-icons/md';
import { BsCursorFill } from 'react-icons/bs';
// import DummyUser from '../assets/images/dummyUser.svg';
// import ChatUser from '../../assets/images/chat-user.png';
import imgChatIcon from '../assets/images/image-chat-icon.svg';
import fileIcon from '../assets/images/file-chat-icon.svg';
import filmIcon from '../assets/images/film-chat-icon.svg';
// import Pdf from '../assets/images/pdf-icon.svg';

import { Scrollbars } from 'react-custom-scrollbars';
import * as scrollbar from '../helpers/scrollbar';

// const CHAT_ICON = require('../assets/chatIcon.png');
// const CHAT_DOCUMENT = require('../assets/chatDocument.png');
// const CHAT_MORE_VERTICAL = require('../assets/moreVertical.png');
// const CHAT_EMOJI = require('../assets/chatEmoji.png');

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

export default function Chat() {
    const [activeMess, setActiveMess] = useState(0);
    const [inputValue, setInputValue] = useState('');
    const [IsModalOpen, setIsModalOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [isChatView, setIsChatView] = useState(true);

    const acceptMimeTypes = Object.values(CONFIG.ACCEPT_MIME_TYPES)
        .map((arr) => arr.join(','))
        .join(',');

    const dispatch = useDispatch();
    const messageEl = useRef(null);

    const socket = useSelector((state) => state.message.socket);
    const updateChat = useSelector((state) => state.message.updateChat); // Chat History
    const updateChatList = useSelector((state) => state.message.updateChatList); // List member
    const isCommunication = useSelector((state) => state.message.isCommunication);
    const userInfo = useSelector((s) => s.userInfo);
    const layout = useSelector((s) => s.layout);

    const [search, setSearch] = useState('');
    // const [chatMessage, setChatMessage] = useState('');
    const [userList, setUserList] = useState([]);
    const [uploadMenu, setUploadMenu] = useState(false);

    useEffect(() => {
        dispatch({ type: ACTION_SOCKET_CONNECTION });
    }, [])

    useEffect(() => {
        if (layout.isMobileView) {
            setIsChatView(false)
        } else {
            setIsChatView(true);
        }
    }, [layout, layout.isMobileView])

    const userSearchSubmit = (e) => {
        const { value } = e.target;
        setSearch(value);

        if (value.length > 0) {
            const filterSearch = userList && userList.filter((item) => {
                if (item.project_id) {
                    return item.project_name.toLowerCase().indexOf(value.toLowerCase()) !== -1
                } else {
                    return item.first_name.toLowerCase().indexOf(value.toLowerCase()) !== -1 || item.last_name.toLowerCase().indexOf(value.toLowerCase()) !== -1
                }
            })

            setUserList(filterSearch);
        } else {
            if (updateChatList && updateChatList.chat_list) {
                setUserList(updateChatList.chat_list)
            }
        }
    }

    useEffect(() => {
        if (updateChatList && updateChatList.chat_list) {
            setUserList(updateChatList.chat_list)
        }
    }, [updateChatList])

    useEffect(() => {

        if (socket) {
            socket.on('alert', (payload) => {
                console.log('payload: ', payload);
            });

            socket.on('updateMessageHistory', (payload) => {
                dispatch({
                    type: ACTION_MESSAGE_HISTORY,
                    payload: payload.message_history,
                });
            });
            socket.on('updateChatList', (payload) => {
                dispatch({ type: ACTION_RECEIVER_MEMBER_LIST, payload });
            });
        }
    }, [socket]);

    useEffect(() => {
        if (socket) {
            socket.on('updateChat', (payload) => {
                dispatch({ type: ACTION_RECEIVER_MESSAGE, payload });
            });
        }
    }, [socket]);

    function arraySort(arr, old_index, new_index) {
        if (new_index >= arr.length) {
            let k = new_index - arr.length + 1;
            while (k--) {
                arr.Push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        return arr;
    }

    useEffect(() => {
        emitter.on(ACTION_SHOW_NOTIFY_INFO, (payload) => {
            // console.log('payload: ', payload);
            if (!updateChatList) return;
            const updatePosition = updateChatList.chat_list.findIndex(
                (e) =>
                    (payload.user_id && payload.user_id === e.user_id) ||
                    (payload.project_id && payload.project_id === e.project_id) ||
                    (payload.task_id && payload.task_id === e.task_id)
            ); // Position in array need to update
            const updateObject = updateChatList.chat_list[updatePosition]; // Object need to update
            if (!updateObject.last_message) {
                updateObject.last_message = {
                    content: payload.content,
                };
            } else {
                updateObject.last_message.content = payload.content;
            }
            const nextupdateChatList = {
                chat_list: arraySort([...updateChatList.chat_list], updatePosition, 0),
            };
            dispatch({
                type: ACTION_MESSAGE_LIST,
                payload: Object.assign(nextupdateChatList, {}),
            });
        });
    }, [updateChatList]);

    useEffect(() => {
        if (messageEl.current) {
            messageEl.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messageEl, updateChat]);

    function renderMemberName(n) {
        if (n.user_id) return `${n.first_name} ${n.last_name}`;
        if (n.project_id) return n.project_name;
        return n.task_name;
    }

    function renderAvatarChat(n) {
        if (n.avatar_url) return n.avatar_url;
        if (n.image) return n.image;
    }

    function handleChatWith(n) {
        let id, type;
        if (n.user_id) id = n.user_id;
        if (n.project_id) id = n.project_id;
        if (n.task_id) id = n.task_id;
        if (n.user_id) type = 'personal';
        if (n.project_id) type = 'project';
        if (n.task_id) type = 'task';
        dispatch({ type: ACTION_OPEN_CHAT_DETAIL, payload: { id, type } }); // activate socket chatWith
        const resultItem = updateChatList.chat_list.find(
            (e) =>
                (n.user_id && e.user_id === n.user_id) ||
                (n.project_id && e.project_id === n.project_id) ||
                (n.task_id && e.task_id === n.task_id)
        ); // chatWith Infomation
        dispatch({ type: ACTION_OPEN_MESSAGE, payload: resultItem }); // Open Chat Screen
        dispatch({
            type: ACTION_GET_MESSAGE_HISTORY,
            payload: { id, type, offset: '0' },
        }); // Get message history
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            if (selectedFile && selectedFile.name) {
                dispatch({
                    type: ACTION_UPLOAD_FILES,
                    payload: {
                        originalname: selectedFile.name,
                        type: selectedFile.name.split('.')[
                            selectedFile.name.split('.').length - 1
                        ],
                        parent_id: '',
                        file: selectedFile,
                    },
                });
            } else {
                if (inputValue === '') return;
                dispatch({
                    type: ACTION_SEND_MESSAGE,
                    payload: { content: inputValue, file_name: '', type: 'text' },
                });
            }
            setInputValue('');
            setSelectedFile(null);
        }
    };

    const handleSendMessage = (e) => {
        e.preventDefault();
        if (selectedFile && selectedFile.name) {
            dispatch({
                type: ACTION_UPLOAD_FILES,
                payload: {
                    originalname: selectedFile.name,
                    type: selectedFile.name.split('.')[
                        selectedFile.name.split('.').length - 1
                    ],
                    parent_id: '',
                    file: selectedFile,
                },
            });
        } else {
            if (inputValue === '') return;
            dispatch({
                type: ACTION_SEND_MESSAGE,
                payload: { content: inputValue, file_name: '', type: 'text' },
            });
        }
        setInputValue('');
        setSelectedFile(null);
    };

    function onChangeMessage(e) {
        setInputValue(e.target.value);
    }

    const renderLastMessage = (n) =>
        <div style={{ width: 100 }}>{n.last_message && n.last_message.content}</div>;
    function handleOpenModal() {
        setIsModalOpen(true);
    }

    function closeModal() {
        setIsModalOpen(false);
    }

    const onEmojiClick = (event, emojiObject) => {
        setInputValue(inputValue + emojiObject.emoji);
    };

    function handleUploadFiles(event) {
        if (event.target.files[0]) {
            setSelectedFile(event.target.files[0]);
            setInputValue(event.target.files[0].name);
        }
    }

    function handleDownloadFile(item) {
        FileSaver.saveAs(item.content, item.original_name);
    }

    function handleRenderContent(item) {
        if (item.type === 'jpg' || item.type === 'png') {
            return (
                <img
                    alt=""
                    style={{ maxWidth: '100%', height: 'auto', borderRadius: 20 }}
                    src={item.content}
                />
            );
        }
        if (
            item.type === 'doc' ||
            item.type === 'txt' ||
            item.type === 'pptx' ||
            item.type === 'pdf' ||
            item.type === 'xlsx' ||
            item.type === 'zip'
        ) {
            return (
                <div
                    onClick={() => handleDownloadFile(item)}
                    style={{ display: 'flex', alignItems: 'center' }}
                >
                    <img
                        alt=""
                        style={{ width: 35, height: 35, borderRadius: 20 }}
                        src={getDefaultThumbnail(item.type)}
                    />
                    <div style={{ marginLeft: 10 }}>{item.original_name}</div>
                </div>
            );
        }
        return item.content.trim();
    }

    return (
        <section className='chat-section-page'>
            {!isChatView ?
                <div className='chats-left-wrap'>
                    <div className='d-flex justify-content-between '>
                        <h1 className="page-title">Chat</h1>
                        <button type="button" className='chat-btn'> <HiOutlinePlus className='me-1' />Create new message</button>
                    </div>
                    <h3 className='message'> message
                        {/* <span>1 new</span> */}
                    </h3>
                    <input type='text' className='chat-input' placeholder='Search or start new chat' value={search} onChange={userSearchSubmit} />
                    <ul className='chat-users-list'>
                        <Scrollbars
                            renderView={scrollbar.renderView}
                            renderTrackHorizontal={scrollbar.renderTrackHorizontal}
                            renderTrackVertical={scrollbar.renderTrackVertical}
                            renderThumbHorizontal={scrollbar.renderThumb}
                            renderThumbVertical={scrollbar.renderThumb}
                            universal={true}
                        >
                            {/* {console.log(updateChatList)} */}
                            {/* {userList && */
                                userList.map((n, i) => (
                                    <li key={i}>
                                        <div
                                            className={`chat-li-wrap ${activeMess === i ? 'active' : ''}`}
                                            onMouseOver={() => {
                                                setActiveMess(i);
                                            }}
                                            onMouseLeave={() => setActiveMess(null)}
                                            onClick={() => { handleChatWith(n); setIsChatView(true) }}
                                        >
                                            <div className='d-flex'>
                                                <div className='chat-img-wrap'>
                                                    <div className='img-werp'>
                                                        {n?.avatar_url || n?.image ?
                                                            n?.avatar_url ?
                                                                <img src={renderAvatarChat(n)} alt='chat-user' />
                                                                :
                                                                <img src={n?.image} alt='chat-user' />
                                                            :
                                                            n.project_name ?
                                                                <span className='user-name-text'>
                                                                    {n?.project_name?.[0]}
                                                                </span>
                                                                :
                                                                <span className='user-name-text'>
                                                                    {n?.first_name?.[0]}
                                                                    {n?.last_name?.[0]}
                                                                </span>
                                                        }
                                                    </div>
                                                </div>
                                                <div className='user-name-out'>
                                                    <h3 className='chat-user-name hide-text'>{renderMemberName(n)}</h3>
                                                    <p className='chat-user-status hide-text'>{renderLastMessage(n)}</p>
                                                </div>
                                            </div>
                                            <div className='diflex'>
                                                <p className='chat-last-active'>
                                                    {n.last_message &&
                                                        moment(n.last_message.sent_at).format(
                                                            'DD/MM/YYYY'
                                                        )
                                                    }
                                                </p>
                                                {/* <span className='message-count'>8</span> */}
                                            </div>
                                        </div>
                                    </li>
                                ))}
                        </Scrollbars>
                    </ul>
                </div>
                :
                !layout.isMobileView ?
                    <div className='chats-left-wrap'>
                        <div className='d-flex justify-content-between '>
                            <h1 className="page-title">Chat</h1>
                            <button type="button" className='chat-btn'> <HiOutlinePlus className='me-1' />Create new message</button>
                        </div>
                        <h3 className='message'> message
                            {/* <span>1 new</span> */}
                        </h3>
                        <input type='text' className='chat-input' placeholder='Search or start new chat' value={search} onChange={userSearchSubmit} />
                        <ul className='chat-users-list'>
                            <Scrollbars
                                renderView={scrollbar.renderView}
                                renderTrackHorizontal={scrollbar.renderTrackHorizontal}
                                renderTrackVertical={scrollbar.renderTrackVertical}
                                renderThumbHorizontal={scrollbar.renderThumb}
                                renderThumbVertical={scrollbar.renderThumb}
                                universal={true}
                            >
                                {/* {console.log(updateChatList)} */}
                                {/* {userList && */
                                    userList.map((n, i) => (
                                        <li key={i}>
                                            <div
                                                className={`chat-li-wrap ${activeMess === i ? 'active' : ''}`}
                                                onMouseOver={() => {
                                                    setActiveMess(i);
                                                }}
                                                onMouseLeave={() => setActiveMess(null)}
                                                onClick={() => { handleChatWith(n); setIsChatView(true) }}
                                            >
                                                <div className='d-flex'>
                                                    <div className='chat-img-wrap'>
                                                        <div className='img-werp'>
                                                            {n?.avatar_url || n?.image ?
                                                                n?.avatar_url ?
                                                                    <img src={renderAvatarChat(n)} alt='chat-user' />
                                                                    :
                                                                    <img src={n?.image} alt='chat-user' />
                                                                :
                                                                n.project_name ?
                                                                    <span className='user-name-text'>
                                                                        {n?.project_name?.[0]}
                                                                    </span>
                                                                    :
                                                                    <span className='user-name-text'>
                                                                        {n?.first_name?.[0]}
                                                                        {n?.last_name?.[0]}
                                                                    </span>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className='user-name-out'>
                                                        <h3 className='chat-user-name hide-text'>{renderMemberName(n)}</h3>
                                                        <p className='chat-user-status hide-text'>{renderLastMessage(n)}</p>
                                                    </div>
                                                </div>
                                                <div className='diflex'>
                                                    <p className='chat-last-active'>
                                                        {n.last_message &&
                                                            moment(n.last_message.sent_at).format(
                                                                'DD/MM/YYYY'
                                                            )
                                                        }
                                                    </p>
                                                    {/* <span className='message-count'>8</span> */}
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                            </Scrollbars>
                        </ul>
                    </div>
                    :
                    null
            }
            {isChatView ?
                <div className='chats-right-wrap'>
                    {isCommunication ? (
                        <>
                            <div className='chat-noti'>
                                <div className='d-flex align-items-center'>
                                    {layout.isMobileView ?
                                        <button type="button" className='chat-back-mobile' onClick={() => setIsChatView(false)} ><MdArrowBack /></button>
                                        :
                                        null
                                    }
                                    <div className='chat-img-wrap'>
                                        <div className='img-werp'>
                                            {isCommunication &&
                                                (isCommunication.avatar_url || isCommunication.project_id) ?
                                                isCommunication.project_id ?
                                                    isCommunication.image ?
                                                        <img src={isCommunication.image} alt='chat-user' />
                                                        :
                                                        <span className='user-name-text'>
                                                            {isCommunication.project_name[0]}
                                                        </span>
                                                    :
                                                    <img src={isCommunication.avatar_url} alt='chat-user' />
                                                :
                                                isCommunication && isCommunication.user_id ?
                                                    <span className='user-name-text'>
                                                        {isCommunication.first_name[0]}
                                                        {isCommunication.last_name[0]}
                                                    </span>
                                                    :
                                                    isCommunication && isCommunication.project_id ?
                                                        <span className='user-name-text'>
                                                            {isCommunication?.project_name?.[0]}
                                                        </span>
                                                        :
                                                        <span className='user-name-text'></span>
                                            }
                                        </div>
                                    </div>
                                    <div className='user-name-out'>
                                        <h3 className='chat-user-name'>
                                            {isCommunication && isCommunication.user_id ?
                                                isCommunication.first_name + ' ' + isCommunication.last_name
                                                :
                                                isCommunication.project_id ?
                                                    isCommunication.project_name
                                                    :
                                                    null
                                            }
                                        </h3>
                                        {/* <p className='chat-user-status'>Online now</p> */}
                                    </div>
                                </div>
                                <button type="button" className='chat-btn'> Clear chat</button>
                            </div>
                            <div className=" chat-container">
                                <div className="chat-wraps">
                                    <div className="chats-div chats-page-area">
                                        <Scrollbars
                                            renderView={scrollbar.renderView}
                                            renderTrackHorizontal={scrollbar.renderTrackHorizontal}
                                            renderTrackVertical={scrollbar.renderTrackVertical}
                                            renderThumbHorizontal={scrollbar.renderThumb}
                                            renderThumbVertical={scrollbar.renderThumb}
                                            universal={true}
                                        >

                                            <div className="chat-text-wrap">
                                                {updateChat &&
                                                    updateChat.map((item, index) => (
                                                        item.user_id !== userInfo.user_id ?
                                                            <div className="chat-block-wrap" key={index}>
                                                                <div className="chat-left-page-wrap">
                                                                    <div className='chat-img-wrap'>
                                                                        <div className='img-werp'>
                                                                            {item.avatar_url ?
                                                                                <img src={renderAvatarChat(item)} alt='chat-user' />
                                                                                :
                                                                                <span className='user-name-text'>
                                                                                    {item.first_name && item.first_name[0]}
                                                                                    {item.last_name && item.last_name[0]}
                                                                                </span>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className='main-right-chat-wrp'>
                                                                        <div className="text-chat-wrap">
                                                                            <div className="message-chat-wrap">
                                                                                <div className="recieve-message-text">
                                                                                    <p className='recieve-text'>{item.content.trim()}</p>
                                                                                </div>
                                                                                {/* <Dropdown className="files-dropdown chat-drop page-chat">
                                                                                <Dropdown.Toggle id="dropdown-button-dark-example1">
                                                                                    <MdMoreHoriz />
                                                                                </Dropdown.Toggle>

                                                                                <Dropdown.Menu>
                                                                                    <Dropdown.Item href="#/action-1" active>
                                                                                        Action
                                                                                    </Dropdown.Item>
                                                                                    <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                                                                    <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                                                                    <Dropdown.Divider />
                                                                                    <Dropdown.Item href="#/action-4">Separated link</Dropdown.Item>
                                                                                </Dropdown.Menu>
                                                                            </Dropdown> */}
                                                                            </div>
                                                                            {/* <p className="date-text">3 days ago</p> */}
                                                                        </div>
                                                                        {item.original_name ?
                                                                            <div className="text-chat-wrap">
                                                                                <div className='message-chat-wrap '>
                                                                                    <div className='recieve-message-files'>
                                                                                        <div className="file-chat-wrap" onClick={() => handleDownloadFile(item)}>
                                                                                            <div className='recieve-pdf-wrap'>
                                                                                                <img src={getDefaultThumbnail(item.type)} alt="pdf-icon" />
                                                                                            </div>
                                                                                            <div>
                                                                                                <h4 className='recieve-file'>{item.original_name}</h4>
                                                                                                {/* <p className='r-file-size'>12 Mb</p> */}

                                                                                            </div>
                                                                                        </div>
                                                                                        {/* <div className="file-chat-wrap">
                                                                                        <div className='recieve-pdf-wrap'>
                                                                                            <img src={Pdf} alt="pdf-icon" />
                                                                                        </div>
                                                                                        <div>
                                                                                            <h4 className='recieve-file'>Presentation.pdf</h4>
                                                                                            <p className='r-file-size'>12 Mb</p>
                                                                                        </div>
                                                                                    </div> */}
                                                                                    </div>
                                                                                    <Dropdown className="files-dropdown chat-drop page-chat">
                                                                                        <Dropdown.Toggle id="dropdown-button-dark-example1">
                                                                                            <MdMoreHoriz />
                                                                                        </Dropdown.Toggle>

                                                                                        <Dropdown.Menu>
                                                                                            <Dropdown.Item href="#/action-1" active>
                                                                                                Action
                                                                                            </Dropdown.Item>
                                                                                            <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                                                                            <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                                                                            <Dropdown.Divider />
                                                                                            <Dropdown.Item href="#/action-4">Separated link</Dropdown.Item>
                                                                                        </Dropdown.Menu>
                                                                                    </Dropdown>
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            null
                                                                        }
                                                                        <p className="date-text">{moment(item.sent_at).format('h:mm a')}</p>
                                                                    </div>
                                                                </div>


                                                            </div>
                                                            :
                                                            <div className='chat-block-wrap' key={index}>
                                                                <div className="chat-right-wrap">
                                                                    <div className='main-right-chat-wrp'>
                                                                        <div className="text-chat-wra">
                                                                            <div className="message-chat-wrap">
                                                                                {/* <Dropdown className="files-dropdown chat-drop page-chat">
                                                                                <Dropdown.Toggle id="dropdown-button-dark-example1">
                                                                                    <MdMoreHoriz />
                                                                                </Dropdown.Toggle>

                                                                                <Dropdown.Menu>
                                                                                    <Dropdown.Item href="#/action-1" active>
                                                                                        Action
                                                                                    </Dropdown.Item>
                                                                                    <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                                                                    <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                                                                    <Dropdown.Divider />
                                                                                    <Dropdown.Item href="#/action-4">Separated link</Dropdown.Item>
                                                                                </Dropdown.Menu>
                                                                            </Dropdown> */}
                                                                                <div className="send-message-text">
                                                                                    <p className='send-text'>{item.content.trim()}</p>
                                                                                </div>

                                                                            </div>
                                                                            {/* <p className="date-text">3 days ago</p> */}

                                                                        </div>
                                                                        {item.original_name ?
                                                                            <div className="text-chat-wra">
                                                                                <div className="message-chat-wrap">
                                                                                    {/* <Dropdown className="files-dropdown chat-drop page-chat">
                                                                                    <Dropdown.Toggle id="dropdown-button-dark-example1">
                                                                                        <MdMoreHoriz />
                                                                                    </Dropdown.Toggle>

                                                                                    <Dropdown.Menu>
                                                                                        <Dropdown.Item href="#/action-1" active>
                                                                                            Action
                                                                                        </Dropdown.Item>
                                                                                        <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                                                                        <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                                                                        <Dropdown.Divider />
                                                                                        <Dropdown.Item href="#/action-4">Separated link</Dropdown.Item>
                                                                                    </Dropdown.Menu>
                                                                                </Dropdown> */}
                                                                                    <div className="send-message-text">
                                                                                        <div className='recieve-message-files'>
                                                                                            <div className="file-chat-wrap" onClick={() => handleDownloadFile(item)}>
                                                                                                <div className='recieve-pdf-wrap'>
                                                                                                    <img src={getDefaultThumbnail(item.type)} alt="pdf-icon" />
                                                                                                </div>
                                                                                                <div>
                                                                                                    <h4 className='recieve-file'>{item.original_name}</h4>
                                                                                                    {/* <p className='r-file-size'>12 Mb</p> */}

                                                                                                </div>
                                                                                            </div>
                                                                                            {/* <div className="file-chat-wrap">
                                                                                            <div className='recieve-pdf-wrap'>
                                                                                                <img src={Pdf} alt="pdf-icon" />
                                                                                            </div>
                                                                                            <div>
                                                                                                <h4 className='recieve-file'>Presentation.pdf</h4>
                                                                                                <p className='r-file-size'>12 Mb</p>
                                                                                            </div>
                                                                                        </div> */}
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                                <p className="date-text">{moment(item.sent_at).format('h:mm a')}</p>

                                                                            </div>
                                                                            :
                                                                            null
                                                                        }

                                                                    </div>
                                                                </div>
                                                            </div>
                                                    ))}
                                            </div>

                                        </Scrollbars>
                                    </div>
                                </div>
                                <div className="chat-inputs-wrap">
                                    <form method="post" onSubmit={handleSendMessage}>
                                        <div className="chat-upload-wrap">
                                            <div className="uplode-drodown">
                                                <button type="button" className={`btn-dropdown ${uploadMenu ? 'active' : ''}`} onClick={() => setUploadMenu(!uploadMenu)} data-tip="Attach Files" data-for="attachFile">
                                                    <MdAdd />
                                                </button>
                                                <ReactTooltip id="attachFile" />
                                                <ul className={`upload-dropdown-menu ${uploadMenu ? 'show' : ''}`}>
                                                    <li>
                                                        <input
                                                            type="file"
                                                            name="myfile"
                                                            onChange={handleUploadFiles}
                                                            accept={acceptMimeTypes}
                                                            id="filmButton"
                                                        />
                                                        <label htmlFor="filmButton"><img src={filmIcon} alt="user" /></label>
                                                    </li>
                                                    <li>
                                                        <input
                                                            type="file"
                                                            name="myfile"
                                                            onChange={handleUploadFiles}
                                                            accept={acceptMimeTypes}
                                                            id="imageButton"
                                                        />
                                                        <label htmlFor="imageButton"><img src={imgChatIcon} alt="user" /></label>
                                                    </li>
                                                    <li>
                                                        <input
                                                            type="file"
                                                            name="myfile"
                                                            onChange={handleUploadFiles}
                                                            accept={acceptMimeTypes}
                                                            id="imageButton2"
                                                        />
                                                        <label htmlFor="imageButton2"><img src={fileIcon} alt="user" /></label>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="input-chat-page-module">
                                                <input
                                                    type="text"
                                                    className="chat-input-message"
                                                    name="chat-message"
                                                    onChange={onChangeMessage}
                                                    value={inputValue}
                                                    onKeyPress={handleKeyDown}
                                                    placeholder="Type a message here"
                                                />
                                            </div>
                                            <button type="button" className="emoji-btn" onClick={handleOpenModal}>
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="10" cy="10" r="8.75" stroke="#707C97" strokeOpacity="0.5" strokeWidth="2.5" />
                                                    <path d="M6.36475 11.3647C7.04818 12.7153 8.42009 13.6375 10.0011 13.6375C11.5821 13.6375 12.954 12.7153 13.6375 11.3647" stroke="#707C97" strokeOpacity="0.5" strokeWidth="2.5" strokeLinecap="round" />
                                                    <circle cx="7.27384" cy="7.27384" r="0.909091" fill="#707C97" fillOpacity="0.5" />
                                                    <circle cx="12.7279" cy="7.27384" r="0.909091" fill="#707C97" fillOpacity="0.5" />
                                                </svg>
                                                <ReactTooltip id="openEmoji" />

                                            </button>
                                            <button type="submit" className="chat-submit-btn" data-tip="Send Message" data-for="sendMessage">
                                                <BsCursorFill />
                                            </button>
                                            <ReactTooltip id="sendMessage" />
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </>
                    )
                        :
                        !layout.isMobileView ?
                            <div className='blank-chat-wrap'>
                                <img src={ChatBlank} alt="chat" />
                                <h3>Start your message</h3>
                                <p>Send everything and private messages to friend or groups</p>
                                <button type="button" className='chat-btn'> <HiOutlinePlus className='me-1' />Create new message</button>
                            </div>
                            :
                            null
                    }
                </div>
                :
                null
            }
            < Modal
                isOpen={IsModalOpen}
                onRequestClose={closeModal}
                style={customStyles}
            >
                <Picker
                    onEmojiClick={onEmojiClick}
                    disableAutoFocus
                    disableSearchBar
                    disableSkinTonePicker
                    groupNames={{ smileys_people: 'PEOPLE' }}
                />
            </Modal>
        </section >
    );
}

import {
  CHECK_DOCS,
  CHECK_DOCS_HIDDEN,
  CHECK_DOCS_SUCCESS,
  CHECK_DOCS_FAILURE,
} from '../actions/documents';

const initialState = {
  isLoading: false,
  error: '',
  docsChecked: [],
};

export default function documents (state, action) {
  switch (action.type) {
    case CHECK_DOCS:
      return {
        ...state,
        isLoading: true,
      };
    case CHECK_DOCS_HIDDEN:
      return {
        ...state,
        isLoading: false,
      };
    case CHECK_DOCS_SUCCESS:
      state.docsChecked.push(action.payload.docChecked);
      return {
        ...state,
        isLoading: false,
      };
    case CHECK_DOCS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state || initialState;
  }
}

import {
  CHECK_IN_SELECTED_TASK_FAILURE,
  CHECK_IN_SELECTED_TASK_SUCCESS,
  CHECK_OUT_SELECTED_TASK_FAILURE,
  CHECK_OUT_SELECTED_TASK_SUCCESS,
  GET_TASKS_BY_PROJECT_ID_FAILURE,
  GET_TASKS_BY_PROJECT_ID_SUCCESS,
} from '../actions/tasks';

const initialState = [];
export default function tasks (state, { type, payload }) {
  switch (type) {
    case GET_TASKS_BY_PROJECT_ID_SUCCESS:
      return [...payload.tasks];
    case GET_TASKS_BY_PROJECT_ID_FAILURE:
      return initialState;
    case CHECK_IN_SELECTED_TASK_SUCCESS: {
      const { taskId } = payload;
      const taskToUpdate = state.find((t) => t.task_id === taskId);
      taskToUpdate.is_checked_in = true;
      return [...state];
    }
    case CHECK_IN_SELECTED_TASK_FAILURE:
      return initialState;
    case CHECK_OUT_SELECTED_TASK_SUCCESS: {
      const { taskId } = payload;
      const taskToUpdate = state.find((t) => t.task_id === taskId);
      taskToUpdate.is_checked_in = false;
      return [...state];
    }
    case CHECK_OUT_SELECTED_TASK_FAILURE:
      return initialState;
    default:
      return state || initialState;
  }
}

import React, { useEffect, useState } from 'react';
// import SideMenu from '../../components/common/SideMenu';
import { NavLink, Link, Route, Switch, useRouteMatch } from 'react-router-dom';
import Organization from '../components/setting/Organization';
import Users from '../components/setting/Users';
import Groups from '../components/setting/Groups';
import Settings from '../components/setting/Settings';
import Security from '../components/setting/Security';
import Reports from '../components/setting/Reports';
import Governance from '../components/setting/Governance';
import { orgMembers } from '../services/authentication';
import { useSelector } from 'react-redux';
import { notifyErrorMsg } from '../components/common/Alert';
import UpgradePlanModal from '../components/setting/UpgradePlanModal';
export default function SettingsPage() {
  const { url } = useRouteMatch();
  const [activeTab, setActiveTab] = useState(0);
  const [memberList, setMemberList] = useState([]);
  const userInfo = useSelector(s => s.userInfo);
  const renderTabs = (
    <>
      {/* 
        ${activeTab === 0 ? 'is-active' : ''} 
        ${activeTab === 1 ? 'is-active' : ''}
        ${activeTab === 2 ? 'is-active' : ''}
        ${activeTab === 3 ? 'is-active' : ''}
        ${activeTab === 4 ? 'is-active' : ''}
        ${activeTab === 5 ? 'is-active' : ''}
        ${activeTab === 6 ? 'is-active' : ''}
    */}
      <NavLink className={`setting-link`} activeClassName="is-active" onClick={() => setActiveTab(0)} to={`${url}/organization`}>Organization Profile</NavLink>
      <NavLink className={`setting-link `} activeClassName="is-active" onClick={() => setActiveTab(1)} to={`${url}/users`}>Users</NavLink>
      <NavLink className={`setting-link `} activeClassName="is-active" onClick={() => setActiveTab(2)} to={`${url}/groups`}>Groups</NavLink>
      <NavLink className={`setting-link `} activeClassName="is-active" onClick={() => setActiveTab(3)} to={`${url}/settings`}>Settings</NavLink>
      <NavLink className={`setting-link `} activeClassName="is-active" onClick={() => setActiveTab(4)} to={`${url}/security`}>Security</NavLink>
      <NavLink className={`setting-link `} activeClassName="is-active" onClick={() => setActiveTab(5)} to={`${url}/reports`}>Reports</NavLink>
      <NavLink className={`setting-link `} activeClassName="is-active" onClick={() => setActiveTab(6)} to={`${url}/governance`}>Governance</NavLink>
    </>
  );
  const callback = () => {
    orgMembers({ org_id: userInfo.org_id })
      .then(({ data: { organization_members } }) => {
        setMemberList(organization_members);
      })
      .catch((err) => {
        notifyErrorMsg(err);
      });
  };
  useEffect(() => {
    if (userInfo.org_id) {
      orgMembers({ org_id: userInfo.org_id })
        .then(({ data: { organization_members } }) => {
          setMemberList(organization_members);
        })
        .catch((err) => {
          notifyErrorMsg(err);
        });
    }
  }, [userInfo.org_id]);

  //   console.log(url);

  return (
    <div id="main">
      {/* <SideMenu activeTab={5} /> */}
      <div className="content ml-0" id="admin">

        <div className="page-top-header">
          <h5 className="page-title ">Settings</h5>
        </div>


        <div className="d-flex custom-tab custom-tabs2 ">{renderTabs}</div>
        <div className='setting-page-padding'>
          <Switch>
            <Route exact path={`${url}/organization`}>
              <Organization />
            </Route>
            <Route exact path={`${url}/users`}>
              <Users memberList={memberList} callback={callback} />
            </Route>
            <Route exact path={`${url}/groups`}>
              <Groups memberList={memberList} />
            </Route>
            <Route exact path={`${url}/settings`}>
              <Settings memberList={memberList} />
            </Route>
            <Route exact path={`${url}/security`}>
              <Security />
            </Route>
            <Route exact path={`${url}/reports`}>
              <Reports memberList={memberList} />
            </Route>
            <Route exact path={`${url}/governance`}>
              <Governance memberList={memberList} />
            </Route>
          </Switch>
        </div>
      </div>
      <UpgradePlanModal />
    </div>
  );
}

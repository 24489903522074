import {
  GET_FILE_ANALYSIS,
  GET_FILE_ANALYSIS_SUCCESS,
  GET_FILE_ANALYSIS_BY_DATE,
  GET_FILE_ANALYSIS_BY_DATE_SUCCESS,
} from '../actions/analysis';

const initialState = {
  fileAnalysis: {
    isLoading: false,
    data: {},
  },
  by_created_at: {
    isLoading: false,
    data: [],
  },
  by_updated_at: {
    isLoading: false,
    data: [],
  },
};

export default function analysis (state, action) {
  switch (action.type) {
    case GET_FILE_ANALYSIS:
      return { ...state, fileAnalysis: { isLoading: true, data: {} } };
    case GET_FILE_ANALYSIS_BY_DATE:
      return {
        ...state,
        [`by_${action.payload.bydate}`]: { isLoading: true, data: [] },
      };
    case GET_FILE_ANALYSIS_SUCCESS:
      return {
        ...state,
        fileAnalysis: { data: { ...action.payload }, isLoading: false },
      };
    case GET_FILE_ANALYSIS_BY_DATE_SUCCESS:
      return {
        ...state,
        [`by_${action.payload.bydate}`]: {
          data: [...action.payload.data],
          isLoading: false,
        },
      };
    default:
      return state || initialState;
  }
}

import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FaRegCalendarAlt } from 'react-icons/fa';
import PropTypes from 'prop-types';
import { daysRemaining } from '../utils/datetime';
import moment from 'moment';

function TaskGrid({
    project: p
}) {

    const history = useHistory();
    const userInfo = useSelector((s) => s.userInfo);

    function handleClick() {
        history.push(`/project/${p.project_id}`, {
            ownerId: p.owner_id,
        })
    }



    return (
        <div
            className={`files-wraps project-wrasp`}
            onClick={handleClick}
        >
            <div className="top-files">
                <div className="files-text project-text">
                    <div className="icon">
                        {p?.project_image ?
                            <img src={p.project_image} alt="demo img" />
                            :
                            <span className='user-icon'>
                                {p.project_name[0] || ''}
                            </span>
                        }
                    </div>
                    <p>{p.project_name}</p>
                </div>
            </div>
            <div className="bottom-text project-grids">
                <div className="users-wrap">
                    <ul className="user-ul">
                        <li>
                            <div className="user-picture-wrap">
                                {userInfo?.avatar ?
                                    <img src={userInfo.avatar} alt="user" />
                                    :
                                    <span className='user-icon'>
                                        {userInfo.firstname[0] || ''}
                                        {userInfo.lastname[0] || ''}
                                    </span>
                                }
                                {/* <img src={dummySmallImg} alt="user" /> */}
                            </div>
                        </li>
                        {/* <li>
                            <div className="user-picture-wrap">
                                <img src={dummySmallImg} alt="user" />
                            </div>
                        </li>
                        <li>
                            <div className="user-picture-wrap">
                                <img src={dummySmallImg} alt="user" />
                            </div>
                        </li> */}
                    </ul>
                    {/* <p>Modified {formatDate(file.updated_at)}</p> */}
                </div>
                <div className="dates-wrap">
                    <p>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.9998 3C8.02976 3 3.99976 7.03 3.99976 12H0.999756L4.88976 15.89L4.95976 16.03L8.99976 12H5.99976C5.99976 8.13 9.12976 5 12.9998 5C16.8698 5 19.9998 8.13 19.9998 12C19.9998 15.87 16.8698 19 12.9998 19C11.0698 19 9.31976 18.21 8.05976 16.94L6.63976 18.36C8.26976 19.99 10.5098 21 12.9998 21C17.9698 21 21.9998 16.97 21.9998 12C21.9998 7.03 17.9698 3 12.9998 3ZM11.9998 8V13L16.2498 15.52L17.0198 14.24L13.4998 12.15V8H11.9998Z" fill="#C2CFE0" stroke="#F5F6F8" strokeWidth="0.6" />
                        </svg>
                        <b>
                            {/* Nov, 30 */}
                            {moment(p?.start_date ? p.start_date : p?.created_at).format('DD MMM, YYYY')} - {moment(p?.deadline).format('DD MMM, YYYY')}
                        </b>
                    </p>
                    <p style={{ margin: 0 }}><i className='deadline-icon'><FaRegCalendarAlt /></i> <b>{daysRemaining(p?.deadline)} days</b></p>
                </div>
            </div>
        </div>
    )
}

TaskGrid.propTypes = {
    project: PropTypes.object.isRequired,
};

export default TaskGrid;
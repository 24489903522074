import axios from 'axios';
import CONFIG from '../constants/config';

export const getNotifications = async () => {
  const response = await axios({
    url: `${CONFIG.BASE_URL}/notifications`,
    method: 'GET',
    withCredentials: true,
  });
  return response.data;
};
export const checkNotification = async (id) => {
  const response = await axios({
    url: `${CONFIG.BASE_URL}/notification/${id}`,
    method: 'GET',
    withCredentials: true,
  });
  return response.data;
};
export const deleteNotification = async (id) => {
  const response = await axios({
    url: `${CONFIG.BASE_URL}/notification/${id}`,
    method: 'DELETE',
    withCredentials: true,
  });
  return response.data;
};
export const deleteNotifications = async () => {
  const response = await axios({
    url: `${CONFIG.BASE_URL}/notification/`,
    method: 'DELETE',
    withCredentials: true,
  });
  return response.data;
};

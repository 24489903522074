export const GET_USAGE = 'GET_USAGE';
export const GET_USAGE_SUCCESS = 'GET_USAGE_SUCCESS';
export const GET_USAGE_FAIL = 'GET_USAGE_FAIL';

export const getUsage = () => ({
	type: GET_USAGE,
});

export const getUsageSuccess = payload => ({
	type: GET_USAGE_SUCCESS,
	payload,
});

export const getUsageFail = () => ({
	type: GET_USAGE_FAIL,
});

export const ACTION_MESSAGE_LIST = 'ACTION_MESSAGE_LIST';
export const ACTION_GET_MY_INFO = 'ACTION_GET_MY_INFO';
export const ACTION_SOCKET_CONNECTION = 'ACTION_SOCKET_CONNECTION';
export const ACTION_SOCKET_CONNECTED = 'ACTION_SOCKET_CONNECTED';
export const ACTION_OPEN_CHAT_DETAIL = 'ACTION_OPEN_CHAT_DETAIL';
export const ACTION_SEND_MESSAGE = 'ACTION_SEND_MESSAGE';
export const ACTION_RECEIVER_MESSAGE = 'ACTION_RECEIVER_MESSAGE';
export const ACTION_MESSAGE_HISTORY = 'ACTION_MESSAGE_HISTORY';

export const ACTION_RENDER_MY_INFO = 'ACTION_RENDER_MY_INFO';
export const ACTION_GET_MEMBER_LIST = 'ACTION_GET_MEMBER_LIST';
export const ACTION_RECEIVER_MEMBER_LIST = 'ACTION_RECEIVER_MEMBER_LIST';

export const ACTION_GET_MESSAGE_HISTORY = 'ACTION_GET_MESSAGE_HISTORY';
export const ACTION_OPEN_MESSAGE = 'ACTION_OPEN_MESSAGE';

export const ACTION_UPDATE_MESSAGE = 'ACTION_UPDATE_MESSAGE';
export const ACTION_UPLOAD_FILES = 'ACTION_UPLOAD_FILES';

export const ACTION_SHOW_NOTIFY_INFO = 'ACTION_SHOW_NOTIFY_INFO';
export const ACTION_GET_NOTIFY_INFO = 'ACTION_GET_NOTIFY_INFO';

import { CHECK_POPUPS } from '../actions/popups';
import { FILE_DETAIL } from '../../constants/popups';

const initialState = {
    [FILE_DETAIL]: {
        isShow: false,
        state: {},
    },
};
export default function popups (state, action) {
  switch (action.type) {
    case CHECK_POPUPS:
      return { ...state, ...action.payload };
    default:
      return state || initialState;
  }
}

import React from 'react';
import * as Showdown from 'showdown';
import PropTypes from 'prop-types';
const converter = new Showdown.Converter({
  tables: true,
  simplifiedAutoLink: true,
  strikethrough: true,
  tasklists: true,
});

export default function MarkdownText({ value }) {
  return (
    <div
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: converter.makeHtml(value),
      }}
    />
  );
}
MarkdownText.propTypes = {
  value: PropTypes.string.isRequired,
};

import moment from 'moment';
export function formatDate(ISOdate) {
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  const date = new Date(ISOdate);
  // const year = date.getFullYear();
  const month = date.getMonth();
  const dt = date.getDate();
  return `${monthNames[month]}, ${dt}`;
}

export function ISOstringToLocalTime(time) {
  return moment.utc(time).local().format('HH:mm');
}

export function ISOStringToLocalDate(time) {
  return moment.utc(time).local().format('YYYY-MM-DD');
}

export function timeLocalToISOString(date, time) {
  return moment(`${date} ${time}`).utc().format('YYYY-MM-DD HH:mm');
}

export function changeSecondsToHours(seconds) {
  let hour = Math.floor(seconds / 3600);
  const minute = Math.round((seconds % 3600) / 60);
  hour = minute === 60 ? hour + 1 : hour;
  return `${hour}h ${minute}m`;
}

export function daysRemaining(deadLine) {
  const eventdate = moment(deadLine);
  const todaysdate = moment();
  return eventdate.diff(todaysdate, 'days');
}

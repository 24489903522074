import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import moment from 'moment';
import PropTypes from 'prop-types';
// import SideMenu from '../common/SideMenu';
import { emitter } from '../../utils/emitter';
import { POPUP_EVENTS } from '../../constants/events';
import { useDispatch, useSelector } from 'react-redux';
import {
  checkInSelectedTask,
  getTasksByProjectId,
  verifyCheckOutTime,
} from '../../redux/actions/tasks';
import { getReportsByTaskId } from '../../redux/actions/reports';
import ReportPopup from './common/ReportPopup';
import ReportListPopup from './common/ReportListPopup';
import MarkdownText from '../common/MarkdownText';
import { taskWorkTime } from '../../services/project';
import { changeSecondsToHours, daysRemaining } from '../../utils/datetime';

import { MdKeyboardBackspace, MdAccessTime, MdCheck } from 'react-icons/md';
import { BsPlusLg } from 'react-icons/bs';
import { AiFillEye } from 'react-icons/ai';
import { HiOutlineDocumentReport } from 'react-icons/hi';
import { Scrollbars } from 'react-custom-scrollbars';
import * as scrollbar from '../../helpers/scrollbar';
import getDefaultThumbnail from '../../utils/getDefaultThumbnail';

const initialWorkTime = {
  dayTime: '',
  weekTime: '',
};
export default function Project({ detail }) {
  const history = useHistory();
  const outSideRef = useRef(null);
  const {
    params: { id },
  } = useRouteMatch();
  const tasks = useSelector((s) => s.tasks);
  const reports = useSelector((s) => s.reports);
  const dispatch = useDispatch();
  const [selectedTask, setSelectedTask] = useState(null);
  const [workTime, setWorkTime] = useState(initialWorkTime);
  const selectTask = (taskId) => () => {
    if (!tasks.filter((t) => t.is_checked_in).length) {
      setSelectedTask(taskId);
      if (!reports[taskId]) {
        dispatch(getReportsByTaskId(taskId));
      }
      if (taskId) {
        taskWorkTime({ taskId }).then(({ dayTime, weekTime }) =>
          setWorkTime({
            dayTime: dayTime[0].work_time,
            weekTime: weekTime[0].work_time,
          })
        );
      }
    }
  };
  const checkInCheckOut = () => {
    if (selectedTask) {
      if (tasks.find((t) => t.task_id === selectedTask)?.is_checked_in) {
        const [lastReport] = [...reports[selectedTask]?.reverse()];
        dispatch(
          verifyCheckOutTime({
            taskId: selectedTask,
            jobWorked: lastReport.job_worked,
            nextTarget: lastReport.next_target,
          })
        );
      } else {
        dispatch(checkInSelectedTask(selectedTask));
      }
    }
  };
  const rendersRecentReport = () => {
    if (reports[selectedTask] && reports[selectedTask].length) {
      const [report] = [...reports[selectedTask]].reverse();
      return (
        <div className="wrap-report-task">
          <div className='d-flex align-items-start justify-content-between'>
            <p className='date-task'>{moment(report?.created_at).format('MMM DD YYYY')}</p>
            <button
              type="button"
              className='btn-edits'
              onClick={() =>
                emitter.emit(POPUP_EVENTS.SHOW_REPORT, {
                  taskId: selectedTask,
                  jobWorked: reports[selectedTask]?.find(
                    (r) =>
                      moment(r.created_at).format('MM-DD-YYYY') ===
                      moment().format('MM-DD-YYYY')
                  )?.job_worked,
                  nextTarget: reports[selectedTask]?.find(
                    (r) =>
                      moment(r.created_at).format('MM-DD-YYYY') ===
                      moment().format('MM-DD-YYYY')
                  )?.next_target,
                })
              }
            >
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.4925 2.78906H7.75349C4.67849 2.78906 2.75049 4.96606 2.75049 8.04806V16.3621C2.75049 19.4441 4.66949 21.6211 7.75349 21.6211H16.5775C19.6625 21.6211 21.5815 19.4441 21.5815 16.3621V12.3341" stroke="#9EA8B6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path fillRule="evenodd" clipRule="evenodd" d="M8.82788 10.9205L16.3009 3.4475C17.2319 2.5175 18.7409 2.5175 19.6719 3.4475L20.8889 4.6645C21.8199 5.5955 21.8199 7.1055 20.8889 8.0355L13.3799 15.5445C12.9729 15.9515 12.4209 16.1805 11.8449 16.1805H8.09888L8.19288 12.4005C8.20688 11.8445 8.43388 11.3145 8.82788 10.9205Z" stroke="#9EA8B6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M15.1653 4.60156L19.7313 9.16756" stroke="#9EA8B6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </button>
          </div>
          <div className="text-report-selected-task">
            <div className="title--bold mt-4">1. Job worked </div>
            <div className='text-report-tasks'><MarkdownText value={report?.job_worked} /></div>
            <div className="title--bold mt-4">2. Next target </div>
            <div className='text-report-tasks'><MarkdownText value={report?.next_target} /></div>
          </div>
        </div>
      );
    }
    return null;
  };
  useEffect(() => {
    dispatch(getTasksByProjectId(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  useEffect(() => {
    const initialSelectedTask = tasks.find((t) => t.is_checked_in)?.task_id;
    if (initialSelectedTask) {
      setSelectedTask(initialSelectedTask);
      dispatch(getReportsByTaskId(initialSelectedTask));
      taskWorkTime({ taskId: initialSelectedTask }).then(
        ({ dayTime, weekTime }) =>
          setWorkTime({
            dayTime: dayTime[0].work_time,
            weekTime: weekTime[0].work_time,
          })
      );
    } else {
      setSelectedTask(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tasks]);

  // useEffect(() => {
  //   function handleClickOutside(event) {
  //     if (outSideRef.current && !outSideRef.current.contains(event.target)) {
  //       setSelectedTask(null);
  //     }
  //   }

  //   document.addEventListener("click", handleClickOutside);
  //   return () => {
  //     // Unbind the event listener on clean up
  //     document.removeEventListener("click", handleClickOutside);
  //   };
  // }, [])
  return (
    <div id="main">
      <div className="content project-details-section" id="task">
        <div className="body left-wrap-projectd">
          <div className='heading-page-title-wrap'>
            <h1>{detail?.project_name}</h1>
            <button type='button' onClick={() => history.goBack()} className='back-btn'><span><MdKeyboardBackspace /></span> Project details</button>
          </div>
          <div className='scroll-wrpas'>
            <Scrollbars
              renderView={scrollbar.renderView}
              renderTrackHorizontal={scrollbar.renderTrackHorizontal}
              renderTrackVertical={scrollbar.renderTrackVertical}
              renderThumbHorizontal={scrollbar.renderThumb}
              renderThumbVertical={scrollbar.renderThumb}
              universal={true}
            >
              <div className='scroll-wraps-padding'>
                <div className='details-card'>
                  <div className='header-details'>
                    <div className='img-with-text-wrap'>
                      <div className='img-wraps'>
                        {detail?.image ?
                          <img src={detail?.image} alt="" />
                          :
                          <span className="p-name">{detail?.project_name[0]}</span>
                        }
                      </div>
                      <h2>{detail?.project_name}</h2>
                    </div>
                  </div>
                  <div className='deadline-wraps-block'>
                    <p className='deadline-text'>Deadline <span>{daysRemaining(detail?.deadline)}</span></p>
                    <p className='deadline-date'><span><MdAccessTime /></span> {`${moment(detail?.created_at).format('DD MMM, YYYY')} - ${moment(detail?.deadline).format('DD MMM, YYYY')}`}</p>
                  </div>
                  <div className='descriptin-wrap'>
                    <p className='title-description'>Project Description</p>
                    <div className='text-details'>
                      <p className=''>{detail?.description}</p>
                    </div>
                  </div>
                  <div className="d-flex" style={{ flexWrap: 'wrap' }}>
                    {detail?.projectFiles.map((f, i) => (
                      <div
                        className={`d-flex m-3 ${i === 0 ? 'ms-0' : ''}`}
                        key={f.file_id}
                        style={{
                          border: '1px solid rgba(0,0,0,0.2)',
                          borderRadius: '5px',
                          width: 'fit-content',
                          padding: '15px',
                          background: '#FFF',
                          boxShadow: '0 3px 8px rgba(0,0,0,0.2)'
                        }}
                      >
                        {/* <div
                        style={{
                          width: "2rem",
                          height: "2rem",
                          background: "purple",
                          opacity: ".2",
                        }}
                      /> */}
                        <div className="text--small ml-2 d-flex align-items-center" style={{ fontSize: '12px' }}>
                          <img style={{ height: '20px', marginRight: '8px' }} src={getDefaultThumbnail(f.file_name.split('.')?.[1])} alt="" />
                          {f.new_name || f.file_name}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className='card-both-wrap'>
                  <div className='card-same-wrap todo-wraps' style={{ maxWidth: '100%' }}>
                    <div className='head-wraps-todo'>
                      <div className="heading--active mb-3">Your work</div>
                      <button type='button' className='create-btn' onClick={() => history.goBack()}>
                        View all Project
                      </button>
                    </div>
                    <ul className='todo-list' ref={outSideRef}>
                      {tasks && tasks
                        .filter((t) => t.status !== 'done')
                        .map((t) => (
                          <li key={t.task_id}>
                            <div

                              className={`todo-task-grid ${t.is_checked_in || t.task_id === selectedTask ? 'is_selected' : ''} `}
                              onClick={selectTask(t.task_id)}
                            >
                              <h6>{t.task_name}</h6>
                              <p>{t.description}</p>

                            </div>
                          </li>
                        ))
                      }
                    </ul>
                  </div>
                </div>
              </div>
            </Scrollbars>
          </div>
        </div>
        <div className='right-sidebar-details'>
          <Scrollbars
            renderView={scrollbar.renderView}
            renderTrackHorizontal={scrollbar.renderTrackHorizontal}
            renderTrackVertical={scrollbar.renderTrackVertical}
            renderThumbHorizontal={scrollbar.renderThumb}
            renderThumbVertical={scrollbar.renderThumb}
            universal={true}
          >
            <div className='overview-wraps'>
              <div className="task-menu-timer">
                <div className="title">
                  {/* Switch &ldquo;on&rdquo; to enter work! */}
                  {tasks && tasks.filter((t) => t.is_checked_in).length
                    ? 'You have checked in.'
                    : 'Select a task to check in.'}
                </div>
                <div className="ml-auto">

                  <label className="project-switch m-auto" ref={outSideRef}>
                    <input
                      type="checkbox"
                      id="chekBox"
                      className={`${tasks.find((t) => t.task_id === selectedTask)
                        ?.is_checked_in
                        ? 'checked'
                        : 'unchecked'
                        }`}
                      checked={Boolean(
                        tasks.find((t) => t.task_id === selectedTask)
                          ?.is_checked_in
                      )}
                    // onChange={checkInCheckOut}
                    // onChange={e=>e}
                    />
                    <span
                      htmlFor="chekBox"
                      className="slider round"
                      onClick={checkInCheckOut}
                    />
                  </label>
                </div>
              </div>
              <div className="timer-wraps">
                <div className="time-blocks">
                  <div className="title--bold">Time Today</div>
                  <p
                    className="time-text"

                  >
                    {workTime.dayTime
                      ? changeSecondsToHours(workTime.dayTime)
                      : '0h 0m'}
                  </p>
                </div>
                <div className="time-blocks">
                  <div className="title--bold">This week</div>
                  <p
                    className="time-text"

                  >
                    {workTime.weekTime
                      ? changeSecondsToHours(workTime.weekTime)
                      : '0h 0m'}
                  </p>
                </div>
              </div>
            </div>
            <div className='team-member-wrap'>
              <div className='head-wraps-todo d-flex align-items-center justify-content-between'>
                <div className="heading--active mb-0">Project Report</div>
                <button
                  type='button'
                  className='create-btn'
                  onClick={() =>
                    emitter.emit(
                      POPUP_EVENTS.REPORT_LIST,
                      reports[selectedTask]
                    )
                  }
                  disabled={!selectedTask}
                >
                  View all reports
                </button>
                
              </div>
              <div className='list-of-report'>
                {rendersRecentReport()}
              </div>
            </div>
          </Scrollbars>
        </div>
        <ReportPopup />
        <ReportListPopup />
      </div>
    </div>
  );
}
Project.propTypes = {
  detail: PropTypes.object.isRequired,
};

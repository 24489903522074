import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { emitter } from '../../utils/emitter';
import { HOME_EVENTS, POPUP_EVENTS } from '../../constants/events';
import { notifyError } from '../common/Alert';
import CONFIG from '../../constants/config';
import SearchFilterPopup from './SearchFilterPopup';

export default function SearchForm({ searchInputFocus, setSearched, setIsSearching, setSearchResults, setSearchInputFocus, searched }) {

  const [searchKey, setSearchKey] = useState('');
  const [filter, setFilter] = useState({
    from_date_access: null,
    to_date_access: null,
    type: null,
    folder_id: null,
    from_date_create: null,
    to_date_create: null,
    size: null,
  });
  const searchButtonRef = useRef(null);
  const layout = useSelector(s => s.layout);

  function handleSearchKey(e) {
    setSearchKey(e.target.value);
  }
  function searchItem(e) {
    e.preventDefault();
    if (!searchKey) {
      return notifyError("Seach keyword can't be empty!");
    }
    setSearched(true);
    setIsSearching(true);
    let min, max;
    switch (filter.size) {
      case '1':
        min = 0;
        max = 1;
        break;
      case '2':
        min = 1;
        max = 10;
        break;
      case '3':
        min = 10;
        max = 100;
        break;
      case '4':
        min = 100;
        max = 99999;
        break;
      default:
        min = null;
        max = null;
        break;
    }
    // axios
    // .get(`${CONFIG.BASE_URL}/search-file`, {
    //   withCredentials: true,
    //   params: {
    //     folder_id: filter.folder_id,
    //     keyword: searchKey,
    //     file_type: filter.type,
    //     from_date_access: filter.from_date_access,
    //     to_date_access: filter.to_date_access,
    //     from_date_create: filter.from_date_create,
    //     to_date_create: filter.to_date_create,
    //     size_min: min ? min * 1048576 : null,
    //     size_max: max ? max * 1048576 : null,
    //   },
    // })
    axios({
      method: 'GET',
      url: `${CONFIG.BASE_URL}/search-file`,
      withCredentials: true,
      params: {
        folder_id: filter.folder_id,
        keyword: searchKey,
        file_type: filter.type,
        from_date_access: filter.from_date_access,
        to_date_access: filter.to_date_access,
        from_date_create: filter.from_date_create,
        to_date_create: filter.to_date_create,
        size_min: min ? min * 1048576 : null,
        size_max: max ? max * 1048576 : null,
      }
    })
      .then(({ data }) => {
        // console.log(data);
        if (data.success) {
          setIsSearching(false);
          const filesData = data.documents;
          const files = filesData && filesData.length > 0 ? filesData.filter(item => item.is_trash !== true) : []
          // ...data.folders, 
          const filteredData = [...files];
          setSearchResults(filteredData);
        } else {
          notifyError(data.msg);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsSearching(false);
        setSearchResults([]);
      });
  }


  function showFilterPopup() {
    if (searched) emitter.emit(POPUP_EVENTS.SHOW_SEARCH_FILTER_POPUP);
    return false;
  }


  function handleFocus() {
    // console.log('focus');
    setSearchInputFocus(true);
  }


  function handleSearchEnter(e) {
    if (e.keyCode === 13) {
      searchButtonRef.current.click();
    }
  }

  function resetSearchFormInput() {
    setSearchKey('');
    setSearched(false);
    setSearchInputFocus(false);
    setFilter({
      from_date_access: null,
      to_date_access: null,
      type: null,
      folder_id: null,
      from_date_create: null,
      to_date_create: null,
      size: null,
    });
  }
  function handleBlur() {
    if (searchKey === '') {
      resetSearchFormInput();
    }
  }
  function listenToTheEmitter() {
    emitter.on(HOME_EVENTS.RESET_SEARCH_FORM_INPUT, resetSearchFormInput);
  }

  useEffect(() => {
    listenToTheEmitter();
    return () => {
      emitter.off(HOME_EVENTS.RESET_SEARCH_FORM_INPUT, resetSearchFormInput);
    };
  });

  useEffect(() => {
    if (!searchInputFocus && !searched) {
      resetSearchFormInput();
    }
  }, [searchInputFocus, searched])
  return (
    <>
      <form method="POST" onSubmit={searchItem} className={(searchInputFocus || searched) ? 'w-100' : ''}>
        <div className={`form-search-wrap ${(searchInputFocus || searched) ? 'w-100' : ''}`}>
          <input
            type="text"
            name="search"
            className={`search-input`}
            placeholder="Search"
            value={searchKey}
            onFocus={handleFocus}
            onChange={handleSearchKey}
            onBlur={handleBlur}
            onKeyUp={handleSearchEnter}
          />
          <button type="submit" className="search-btn" ref={searchButtonRef}>
            <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5.98674 12.4735C7.31503 12.4732 8.60505 12.0285 9.65138 11.2103L12.9411 14.5L13.9993 13.4418L10.7095 10.1521C11.5282 9.10569 11.9732 7.81538 11.9735 6.48674C11.9735 3.1858 9.28768 0.5 5.98674 0.5C2.6858 0.5 0 3.1858 0 6.48674C0 9.78768 2.6858 12.4735 5.98674 12.4735ZM5.98674 1.99669C8.46301 1.99669 10.4768 4.01048 10.4768 6.48674C10.4768 8.96301 8.46301 10.9768 5.98674 10.9768C3.51048 10.9768 1.49669 8.96301 1.49669 6.48674C1.49669 4.01048 3.51048 1.99669 5.98674 1.99669Z" fill="#C2CFE0" />
            </svg>
          </button>
        </div>
      </form>

      {(searchInputFocus || searched) ?
        <button type="button" className="filter-btn" style={{ margin: layout.isMobileView ? '0 16px' : '0 40px 0 16px' }} onClick={showFilterPopup}>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.4136 1.14258H1.86463C1.6732 1.14258 1.4896 1.21863 1.35424 1.35399C1.21887 1.48936 1.14282 1.67295 1.14282 1.86439V3.73388C1.14282 4.11138 1.29657 4.48239 1.56364 4.74946L5.47368 8.65951V14.1352C5.47382 14.2581 5.50535 14.379 5.56528 14.4864C5.62521 14.5938 5.71155 14.6841 5.81614 14.7488C5.92073 14.8135 6.0401 14.8504 6.16294 14.8561C6.28579 14.8618 6.40805 14.836 6.51814 14.7812L9.40538 13.3376C9.65008 13.2149 9.80454 12.9651 9.80454 12.6915V8.65951L13.7146 4.74946C13.9817 4.48239 14.1354 4.11138 14.1354 3.73388V1.86439C14.1354 1.67295 14.0594 1.48936 13.924 1.35399C13.7886 1.21863 13.605 1.14258 13.4136 1.14258V1.14258ZM8.57241 7.85036C8.50525 7.91728 8.45199 7.99681 8.4157 8.0844C8.3794 8.17198 8.36079 8.26587 8.36092 8.36068V12.2455L6.9173 12.9673V8.36068C6.91744 8.26587 6.89883 8.17198 6.86253 8.0844C6.82623 7.99681 6.77297 7.91728 6.70581 7.85036L2.58644 3.73388V2.5862H12.6925L12.6939 3.72882L8.57241 7.85036V7.85036Z" fill="#016288" />
          </svg>

        </button>
        :
        null
      }
      <SearchFilterPopup filter={filter} setFilter={setFilter} hanldeFilterSearchResults={searchItem} />
    </>
    // <div className="content-header__input" style={outerSearchBoxStyle}>
    //   <div className="search" style={searchBoxStyle}>
    //     <button type="submit" className="seacrhBtn" onClick={searchItem} ref={searchButtonRef}><i className="ic-search"/></button>
    //     <input type="text" placeholder="Search.." name="search" style={inputStyle} value={searchKey}
    //       onFocus={handleFocus} onChange={handleSearchKey} onBlur={handleBlur} onKeyUp={handleSearchEnter}
    //     />
    //   </div>
    //   <button type="button" className="filter" onClick={showFilterPopup}><i className="ic-filter"/>
    //     {/* {searched && <div className="filter-count" type="button">{Object.values(filter).filter(k => k).length}</div>} */}
    //   </button>
    //   <SearchFilterPopup filter={filter} setFilter={setFilter} hanldeFilterSearchResults={searchItem}/>
    // </div>
  );
}

SearchForm.propTypes = {
  setSearched: PropTypes.func.isRequired,
  setIsSearching: PropTypes.func.isRequired,
  setSearchResults: PropTypes.func.isRequired,
  setSearchInputFocus: PropTypes.func.isRequired,
  searched: PropTypes.bool.isRequired,
};

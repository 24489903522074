export function setItemLocalStorage(key, value) {
  if (typeof value === typeof Object() && !Array.isArray(value)) {
    try {
      localStorage.setItem(key, JSON.stringify(value));
    }
    catch {
      localStorage.setItem(key, JSON.stringify({}));
    }
  }

  else if (typeof value === typeof Object() && Array.isArray(value)) {
    try {
      localStorage.setItem(key, JSON.stringify(value));
    }
    catch {
      localStorage.setItem(key, JSON.stringify([]));
    }
  }

  else {
    try {
      localStorage.setItem(key, JSON.stringify(value));
    }
    catch {
      localStorage.setItem(key, '0');
    }
  }
}

export function getItemLocalStorage(key) {
  let result = '';
  try {
    result = JSON.parse(localStorage.getItem(key));
  }
  catch {
    result = '';
  }
  return result;
}

export function clearLocalStorage(key) {
  if (key) {
    localStorage.removeItem(key);
  }
  else { localStorage.clear(); }
}

import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import analysis from './reducers/analysis';
import copiedFile from './reducers/copiedFile';
import documents from './reducers/documents';
import files from './reducers/files';
import folders from './reducers/folders';
import allFolders from './reducers/allFolders';
import linkFilePopup from './reducers/linkFilePopup';
import path from './reducers/path';
import popups from './reducers/popups';
import quickAccess from './reducers/quickAccess';
import message from './reducers/message';
import notifications from './reducers/notifications';
import uploadFiles from './reducers/uploadFiles';
import usage from './reducers/usage';
import userInfo from './reducers/userInfo';
import reminders from './reducers/reminders';
import tasks from './reducers/tasks';
import reports from './reducers/reports';
import pinbar from './reducers/pinbar';
import layout from './reducers/layout';
import uploadAuth from './reducers/uploadAuth';
import multiSelection from './reducers/multiSelection';
// import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import rootSaga from '../saga';
const sagaMiddleware = createSagaMiddleware();

// const logger = createLogger({
// ...options
// });
const reducers = combineReducers({
  uploadAuth,
  analysis,
  copiedFile,
  documents,
  files,
  folders,
  allFolders,
  linkFilePopup,
  path,
  popups,
  quickAccess,
  message,
  notifications,
  uploadFiles,
  usage,
  userInfo,
  reminders,
  tasks,
  reports,
  pinbar,
  layout,
  multiSelection
});
/* eslint-disable no-underscore-dangle */
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
/* eslint-enable */
const reduxStore = createStore(
  reducers,
  composeEnhancers(applyMiddleware(sagaMiddleware))
  // composeEnhancers(applyMiddleware(logger, sagaMiddleware))
);
sagaMiddleware.run(rootSaga);

export default reduxStore;

import {
  CREATE_REMINDER_SUCCESS,
  DELETE_REMINDER_SUCCESS,
  EDIT_REMINDER_SUCCESS,
  GET_REMINDERS_SUCCESS,
  SELECT_REMINDER,
  RESET_REMINDER,
} from '../actions/reminders';
import moment from 'moment';

const initialState = {
  todo: [],
  inprocess: [],
  done: [],
  selected: {},
};

function sortByTime(a, b) {
  return moment(a.start_time) - moment(b.start_time);
}

export default function reminders (state, action) {
  switch (action.type) {
    case GET_REMINDERS_SUCCESS: {
      const todoState = action.payload.todo || [];
      const processState = action.payload.inprocess || [];
      const doneState = action.payload.done || [];
      return {
        ...state,
        todo: [...todoState.sort(sortByTime)],
        inprocess: [...processState.sort(sortByTime)],
        done: [...doneState.sort(sortByTime)],
      };
    }
    case CREATE_REMINDER_SUCCESS:
      state.todo.unshift(action.payload);
      return { ...state, todo: [...state.todo.sort(sortByTime)] };
    case SELECT_REMINDER: {
      return { ...state, selected: action.payload };
    }
    case EDIT_REMINDER_SUCCESS: {
      const editIndex = state[action.payload.status].findIndex(
        (r) => r.reminder_id === action.payload.reminder_id
      );
      state[action.payload.status][editIndex] = action.payload;
      return { ...state };
    }
    case DELETE_REMINDER_SUCCESS:
      return {
        ...state,
        todo: [...state.todo.filter((r) => r.reminder_id !== action.payload)],
        inprocess: [
          ...state.inprocess.filter((r) => r.reminder_id !== action.payload),
        ],
      };
    case RESET_REMINDER:
      return {
        ...initialState,
      };
    default:
      return state || initialState;
  }
}

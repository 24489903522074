import CONFIG from '../constants/config';

export function getFileName(string) {
  const a = string.split('-');
  if (a.length > 1) a.splice(0, 1);
  const b = a.join('-').split('.');
  b.splice(b.length - 1, 1);
  return b.join('.');
}

export function getFileName2(string) {
  const b = string.split('.');
  b.splice(b.length - 1, 1);
  return b.join('.');
}

export function getFileType(string) {
  const b = string.split('.');
  return b.slice(-1)[0];
}

export function bytesToSize(bytes) {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return 0;
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  if (i === 0) return `${bytes} ${sizes[i]}`;
  let res = (bytes / Math.pow(1024, i)).toFixed(1);
  res = res.split('.')[1] === '0' ? res.split('.')[0] : res;
  return `${res} ${sizes[i]}`;
}
export function bytesToSizeNumber(bytes) {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return 0;
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  if (i === 0) return `${bytes}`;
  let res = (bytes / Math.pow(1024, i)).toFixed(1);
  res = res.split('.')[1] === '0' ? res.split('.')[0] : res;
  return res;
}
export function MBToSize(bytes) {
  const sizes = ['MB', 'GB', 'TB'];
  if (bytes === 0) return 0;
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  if (i === 0) return `${bytes} ${sizes[i]}`;
  let res = (bytes / Math.pow(1024, i)).toFixed(1);
  res = res.split('.')[1] === '0' ? res.split('.')[0] : res;
  return `${res} ${sizes[i]}`;
}

export function fixPercent(percent) {
  return (Math.round(percent * 100) / 100).toFixed(2);
}

export function avgPercent(percents) {
  let totalLoaded = 0;
  let totalSize = 0;
  if (percents.length) {
    for (const per of percents) {
      totalLoaded += per.loaded;
      totalSize += per.size;
    }
    const result = (totalLoaded / totalSize) * 100;
    return result >= 100 ? 100 : result.toFixed(2);
  }
  return 0;
}

export function uploadTotalLoaded(percents) {
  let totalLoaded = 0;
  if (percents.length) {
    for (const per of percents) {
      totalLoaded += per.loaded;
    }
    return totalLoaded;
  }
  return 0;
}

export function uploadTotalSize(percents) {
  let totalLoaded = 0;
  if (percents.length) {
    for (const per of percents) {
      totalLoaded += per.size;
    }
    return totalLoaded;
  }
  return 0;
}

export function checkUploadTypeByFileName(fileName) {
  const fileType = fileName
    ? fileName.toLowerCase().split('.')[fileName.split('.').length - 1]
    : -1;
  const listAcceptFileType = Object.values(CONFIG.MIME_TYPES)
    .map((x) => x.join(', '))
    .join(', ');
  if (listAcceptFileType.search(fileType) !== -1) {
    return true;
  }
  return false;
}

export function truncate(string, length) {
  return `${string.slice(0, length)}...`;
}

export function shallowMergeArrayByKey(a, b, parent_id, key) {
  const x = [...a.filter((d) => d.parent_id !== parent_id)];
  const y = [...x, ...b];
  return y;
}

export function getFolderName(folder_id, folders) {
  if (folders && folders.length > 0) {
    return folders.find((item) => item.folder_id === folder_id)?.folder_name
  }
  return null;
}
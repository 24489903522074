/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFileAnalysisByDate } from '../../../redux/actions/analysis';
import {
  Tooltip,
  LineChart,
  XAxis,
  YAxis,
  Legend,
  Line,
  ResponsiveContainer,
} from 'recharts';
import PropTypes from 'prop-types';

const CustomizedAxisTick = ({ x, y, payload }) => (
  <g transform={`translate(${x},${y})`}>
    <text
      x={0}
      y={0}
      dy={16}
      textAnchor="end"
      fill="#666"
      transform="rotate(-50)"
    >
      {payload.value}
    </text>
  </g>
);

function renderCusomizedLegend(props) {
  // console.log(props);
  if (props.payload && props.payload.length > 0) {
    return <ul className={props.className}>
      {props.payload.map((item, i) =>
        <li className='legend-item-line' key={i}>
          <span className='icon-style' style={{ border: `3px solid ${item.color}` }}></span>
          <p>{item.value}</p>
        </li>
      )}
    </ul>
  }

  return null;
}

export default function AnalysisByDate({ bydate, all, time, unit_of_time }) {
  const dispatch = useDispatch();
  const analysis = useSelector((s) => s.analysis[`by_${bydate}`]);
  useEffect(() => {
    dispatch(getFileAnalysisByDate({ bydate, all, time, unit_of_time }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time, unit_of_time]);
  const colors = [
    '#393F93',
    '#95C675',
    '#FEE801',
    '#FFBD28',
    '#FF8A66',
    '#00A9FF',
    '#FF5A46',
  ];
  return (
    <div style={{
      textAlign: 'center',
      position: 'relative',
      width: '100%',
      height: 450,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      // paddingTop: '10rem',
    }}>
      <span className='title_chart'>Documents {bydate === 'created_at' ? 'created' : 'updated'} by type</span>
      <div className='line-charts-reports'>
        {!analysis.isLoading && analysis.data.length ? (
          <ResponsiveContainer width={'100%'} height={'100%'}>
            <LineChart
              width={550}
              height={380}
              data={analysis.data}
              // margin={{ top: 20, right: 30, left: 20, bottom: 80 }}
              wrapperStyle={{ marginTop: '10rem', background: 'red' }}
            >
              <XAxis
                dataKey="day"
                height={60}
                tick={<CustomizedAxisTick />}
                interval={0}
              />
              <YAxis />
              <Tooltip />
              <Legend
                iconType="circle"
                className='legend-line-report'
                wrapperStyle={{
                  position:'relative',
                  left:'inherit',
                  bottom:'inherit',
                  margin:'15px auto'
                }}
                content={renderCusomizedLegend}
              />
              {/* <div className='legend-line-report'> */}
              <Line type="monotone" dataKey="document" stroke={colors[0]} />
              <Line type="monotone" dataKey="txt" stroke={colors[1]} />
              <Line type="monotone" dataKey="image" stroke={colors[2]} />
              <Line type="monotone" dataKey="powerpoint" stroke={colors[3]} />
              <Line type="monotone" dataKey="excel" stroke={colors[4]} />
              <Line type="monotone" dataKey="pdf" stroke={colors[5]} />
              {/* </div> */}
            </LineChart>
          </ResponsiveContainer>
        ) :
          null
        }
      </div>
    </div>
  );
}
AnalysisByDate.propTypes = {
  bydate: PropTypes.oneOf(['created_at', 'update_at']).isRequired,
  all: PropTypes.bool,
  time: PropTypes.number,
  unit_of_time: PropTypes.string,
};
AnalysisByDate.defaultProps = {
  all: undefined,
  time: undefined,
  unit_of_time: undefined,
};

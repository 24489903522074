/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { getFileAnalysisByDate } from '../../../services/analysis';

import {
  Tooltip,
  LineChart,
  XAxis,
  YAxis,
  Legend,
  Line,
  ResponsiveContainer,
} from 'recharts';

const CustomizedAxisTick = ({ x, y, payload }) => (
  <g transform={`translate(${x},${y})`}>
    <text
      x={0}
      y={0}
      dy={16}
      textAnchor="end"
      fill="#666"
      transform="rotate(-50)"
    >
      {payload.value}
    </text>
  </g>
);

function renderCusomizedLegend(props) {
  // console.log(props);
  if (props.payload && props.payload.length > 0) {
    return <ul className={props.className}>
      {props.payload.map((item, i) =>
        <li className='legend-item-line' key={i}>
          <span className='icon-style' style={{ border: `3px solid ${item.color}` }}></span>
          <p>{item.value}</p>
        </li>
      )}
    </ul>
  }

  return null;
}

export default function AnalysisByDateAll() {
  const [analysis, setAnalysis] = useState({ isLoading: true, data: [] });
  useEffect(() => {
    getFileAnalysisByDate({ bydate: 'created_at', all: true }).then(
      ({ result }) => {
        setAnalysis({ isLoading: false, data: result.reverse() });
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const colors = [
    '#393F93',
    '#95C675',
    '#FEE801',
    '#FFBD28',
    '#FF8A66',
    '#00A9FF',
    '#FF5A46',
  ];
  const [mySVG] = document.getElementsByClassName('recharts-surface');
  if (mySVG) mySVG.setAttribute('viewBox', '90 90 220 220');
  return (
    <div
      className='chart-card'
      style={{
        background: 'white',
        // marginTop: '1rem',
        textAlign: 'center',
        position: 'relative',
        width: '100%',
        height: 500,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        // paddingTop: '10rem',
      }}
    >
      <span className='title_chart'>Total documents created by type</span>
      <div className='line-charts-reports'>
        {!analysis.isLoading && analysis.data.length ? (
          <ResponsiveContainer>
            <LineChart
              width={600}
              height={300}
              data={analysis.data}
              margin={{ top: 20, right: 30, left: 20, bottom: 10 }}
              wrapperStyle={{ marginTop: '10rem', background: 'red' }}
            >
              <XAxis
                dataKey="day"
                height={60}
                tick={<CustomizedAxisTick />}
                interval={0}
              />
              <YAxis />
              <Tooltip />
              <Legend
                iconType="circle"
                className='legend-line-report'
                wrapperStyle={{
                  position:'relative',
                  left:'inherit',
                  bottom:'inherit',
                  margin:'15px auto'
                }}
                content={renderCusomizedLegend}
              />
              <Line type="monotone" dataKey="document" stroke={colors[0]} />
              <Line type="monotone" dataKey="txt" stroke={colors[1]} />
              <Line type="monotone" dataKey="image" stroke={colors[2]} />
              <Line type="monotone" dataKey="powerpoint" stroke={colors[3]} />
              <Line type="monotone" dataKey="excel" stroke={colors[4]} />
              <Line type="monotone" dataKey="pdf" stroke={colors[5]} />
            </LineChart>
          </ResponsiveContainer>
        ) : null}
      </div>
    </div>
  );
}


import React, { useEffect } from 'react';

export default function Meeting() {

    useEffect(() => {
        const videoChat = () => {

            const api = new window.JitsiMeetExternalAPI("8x8.vc", {
                roomName: "vpaas-magic-cookie-1dd3c49c153a4154bcc806c203960be0/SampleAppSeniorDesiresIncurDiscreetly",
                parentNode: document.querySelector('.videoChatting')
            });

        }

        videoChat()
    }, [])

    return (
        <>
            <div className='videoChatting'></div>
        </>
    )
}

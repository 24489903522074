import React, { useEffect, useState, useRef } from 'react';
// import Button from '../common/Button';
import { emitter } from '../../utils/emitter';
import { MdLinkOff } from 'react-icons/md';
import { POPUP_EVENTS } from '../../constants/events';
import SkyLight from 'react-skylight';
import getDefaultThumbnail from '../../utils/getDefaultThumbnail';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteLink,
  getLinkedFiles,
  linkFile,
  minimizePopup,
  resetLinkPopup,
} from '../../redux/actions/linkFilePopup';
import { useHistory } from 'react-router-dom';
import { MdClear, MdLink } from 'react-icons/md';
import { bytesToSize } from '../../utils/string';
import { removeSelectingFiles } from '../../redux/actions/files'

export default function LinkFiles({ hideAction }) {
  const popupRef = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const linkFilePopup = useSelector((s) => s.linkFilePopup);
  const selectedFiles = useSelector((s) => s.multiSelection.selectedFiles);

  const [linkedFiles, setLinkedFiles] = useState([]);

  const popupStyle = {
    left: '20%',
    marginLeft: 0,
    marginTop: 0,
    top: '20%',
    width: '60%',
    // height: hideAction ? '' : '0%',
    minHeight: hideAction ? '0rem' : '0rem',
    borderRadius: 10,
  };

  useEffect(() => {
    if (linkFilePopup && linkFilePopup.linked_files && linkFilePopup.linked_files.length > 0) {
      setLinkedFiles(linkFilePopup.linked_files)
    }

  }, [linkFilePopup])

  function showPopup() {
    popupRef.current.show();
  }

  function hidePopup() {
    dispatch(removeSelectingFiles());
    dispatch(resetLinkPopup());
    popupRef.current.hide();
  }

  function listenToTheEmitter() {
    emitter.on(POPUP_EVENTS.SHOW_LINK_FILES, showPopup);
  }

  const handleLinkFile = () => {
    if (selectedFiles && selectedFiles.length > 0) {
      for (let i = 0; i < selectedFiles.length; i++) {
        dispatch(linkFile(linkFilePopup.file_id, selectedFiles[i]));
      }
      // dispatch(linkFile(linkFilePopup.file_id, linkFilePopup.file_to_link));
      // dispatch(resetLinkPopup());
      // dispatch(removeSelectingFiles())
    }
    // dispatch(linkFile(linkFilePopup.file_id, linkFilePopup.file_to_link));
  };

  const handleDeleteLink = (file_id_to_delete) => {
    setLinkedFiles(linkedFiles.filter(item => item.file_id !== file_id_to_delete))
    dispatch(deleteLink(linkFilePopup.file_id, file_id_to_delete));
  };

  useEffect(() => {
    if (!linkFilePopup.file_id) return;
    dispatch(getLinkedFiles(linkFilePopup.file_id));
  }, [dispatch, linkFilePopup.file_id]);

  useEffect(() => {
    listenToTheEmitter();
    return () => {
      emitter.off(POPUP_EVENTS.SHOW_LINK_FILES, showPopup);
    };
  });

  return (
    <SkyLight
      // hideOnOverlayClicked
      ref={popupRef}
      dialogStyles={popupStyle}
      afterClose={() => {
        if (!linkFilePopup.minimize) dispatch(resetLinkPopup());
      }}
    >
      <div className="checkinout">
        {!hideAction && (
          <>
            <div className="check-success--title">
              <span className="link-title-modal"><i className="icons-link"><MdLink /></i>Link file</span>
              <i onClick={hidePopup}><MdClear /></i>
            </div>
            <div className="check-success--sub-title">
              {linkFilePopup.file_to_link ?
                linkFilePopup.file_to_link.new_name
                : (
                  <>
                    Create a new link, select the file you want to link them
                    together.
                    <span
                      style={{ color: '#016288', cursor: 'pointer' }}
                      onClick={() => {
                        popupRef.current.hide();
                        dispatch(minimizePopup(true));
                      }}
                    >
                      &nbsp;Select files
                    </span>
                  </>
                )}
            </div>
            <button type="button" className="btn-primary link"
              onClick={handleLinkFile}
            >
              Link
            </button>
          </>
        )}
        <div
          style={{
            fontWeight: 'bold',
            fontSize: '1rem',
            paddingLeft: '0rem',
            marginBottom: '1rem',
          }}
        >
          List link ({linkedFiles.length})
        </div>
        {linkedFiles && linkedFiles.length > 0 ?
          <div className="main-file-modal-wrap" >
            {linkedFiles.map((f, i) => (
              <div key={i} style={{ marginLeft: i > 0 ? '15px' : '0', marginBottom: '15px', display: "inline-block" }}>
                <div
                  key={f.file_id}
                  className="file-small-wrap"
                >
                  <div className="file-small-content" onClick={() => {
                    history.push(`/file-detail/${f.file_id}`);
                    hidePopup();
                  }}>
                    <div className="file-icon-div">
                      <img
                        alt=""
                        src={getDefaultThumbnail(f.type)}
                      />
                    </div>
                    <p>{f.new_name} <span>{bytesToSize(f.size)}</span></p>
                  </div>
                  {hideAction ? null : (
                    <i
                      className="link-delete-icon"
                      onClick={() => handleDeleteLink(f.file_id)}
                    >
                      <MdLinkOff />
                    </i>

                  )}
                </div>
              </div>
            ))}
          </div>
          :
          null
        }
      </div>
    </SkyLight>
  );
}

import axios from 'axios';
import CONFIG from '../constants/config';

export const getMyInfo = async () => {
  const response = await axios({
    method: 'GET',
    url: `${CONFIG.BASE_URL}/my-info`,
    withCredentials: true,
  });
  return response.data;
};

export const getNemberList = async (groupId, projectId, taskId) => {
  const response = await axios({
    method: 'GET',
    url: `${CONFIG.BASE_URL}/member-list?group_id=${groupId}&project_id=${projectId}&task_id=${taskId}`,
    withCredentials: true,
  });
  return response.data;
};

export const getMessageHistory = async (id, type, offset) => {
  const response = await axios({
    method: 'GET',
    url: `${CONFIG.BASE_URL}/message_history?id=${id}&type=${type}&offset=${offset}`,
    withCredentials: true,
  });
  return response.data;
};

export const getUploadFile = async (originalname, type, parent_id) => {
  const response = await axios({
    method: 'GET',
    url: `${CONFIG.BASE_URL}/file_upload_link?originalname=${originalname}&type=${type}&parent_id=${parent_id}`,
    withCredentials: true,
  });
  return response.data;
};

export const putUpload = async (upload_url, file) =>
  axios.put(upload_url, file, {
    headers: {
      'Content-Type': file.type,
    },
  });

export const getToken = async () => {
  const response = await axios({
    method: 'GET',
    url: `${CONFIG.BASE_URL}/reminder_token`,
    withCredentials: true,
  });
  return response.data;
};

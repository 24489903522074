import React, { useState } from 'react';
import AllowedIPs from './security/AllowedIPs';
import PasswordPolicy from './security/PasswordPolicy';

export default function Security() {
  const [activeTab, setActiveTab] = useState(0);
  const handleChangeTab = (index) => () => {
    setActiveTab(index);
  };

  return (
    <div className='settings-settings-section'>
      <div className="d-flex mb-4">
        <div className={`setting-user-btn disable-back ${activeTab === 0 ? 'active' : ''}`} onClick={handleChangeTab(0)}>
          Password Policy
        </div>
        <div className={`setting-user-btn disable-back ${activeTab === 1 ? 'active' : ''}`} onClick={handleChangeTab(1)}>
          Allowed IPs
        </div>
      </div>
      <div>
        {activeTab === 0 && <PasswordPolicy />}
        {activeTab === 1 && <AllowedIPs />}
      </div>
    </div>
  );
}

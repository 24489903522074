import React, { useRef } from 'react';
import SkyLight from 'react-skylight';
import PropTypes from 'prop-types';
const popupStyle = {
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  margin: 'auto',
  minHeight: '',
  height: 'fit-content',
  width: 'fit-content',
  borderRadius: '10px',
};
export default function ConfirmButton({
  buttonClass,
  buttonStyle,
  buttonText,
  message,
  handleClick,
  children
}) {
  const popupRef = useRef();
  const onConfirm = () => {
    handleClick();
    popupRef.current.hide();
  };
  return (
    <>
      <button
        onClick={() => popupRef.current.show()}
        type="button"
        className={buttonClass}
        style={buttonStyle}
      >
        {children}
      </button>
      <div style={{ position: 'absolute' }}>
        <SkyLight dialogStyles={popupStyle} ref={popupRef} hideOnOverlayClicked>
          <div className='confirm-modals'>
            <div style={{ textAlign:'center',}}>{message}</div>
            <div className="modal-buttons-wraps d-flex  justify-content-end">
              <button
                type="button"
                className="button mr-5"
                // style={{ backgroundColor: 'white', color: 'black', border:'0' ,marginRight:"15px",fontSize:'13PX' ,padding:'4px'}}
                onClick={() => popupRef.current.hide()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="button "
                // style={{backgroundColor:'rgb(1, 98, 136)', color:'white',border:'0',padding:'0px 12PX' , fontSize:'13px',borderRadius:'6X' }}
                onClick={onConfirm}
              >
                OK
              </button>
            </div>
          </div>
        </SkyLight>
      </div>
    </>
  );
}

ConfirmButton.propTypes = {
  buttonClass: PropTypes.string,
  buttonStyle: PropTypes.object,
  buttonText: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
};

ConfirmButton.defaultProps = {
  buttonClass: '',
  buttonStyle: {},
};

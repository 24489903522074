import {
  CHECK_FILE_TO_LINK,
  CHECK_ID_LINKED,
  LINK_FILE_SUCCESS,
  MINIMIZE_POPUP,
  DELETE_LINK_SUCCESS, RESET_LINK_POPUP, GET_LINKED_FILES_SUCCESS, CHECK_LINK_FILES,
} from '../actions/linkFilePopup';

const initialState = {
  minimize: false,
  file_id: null,
  file_to_link: null,
  linked_files: [],
};
export default function linkFilePopup (state, action) {
  switch (action.type) {
    case MINIMIZE_POPUP:
      return { ...state, minimize: action.payload };
    case CHECK_ID_LINKED:
      return { ...state, file_id: action.payload };
    case CHECK_FILE_TO_LINK:
      return {
        ...state,
        file_to_link: action.payload,
      };
    case LINK_FILE_SUCCESS:
      return { ...initialState };
    case DELETE_LINK_SUCCESS:
    return {
      ...state,
      file_id: null,
      file_to_link: null,
      linked_files: state.linked_files.filter(f => f.file_id !== action.payload.file_id),
    };
    case CHECK_LINK_FILES:
     return { ...state, linked_files: [...action.payload] };
    case GET_LINKED_FILES_SUCCESS:
      return { ...state, linked_files: [...action.payload] };
    case RESET_LINK_POPUP:
    return { ...initialState };
    default:
      return state || initialState;
  }
}

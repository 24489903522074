import React, { useRef, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import FileSaver from 'file-saver';
import SkyLight from 'react-skylight';
import { checkQuickAccess } from '../../redux/actions/quickAccess';
import { emitter } from '../../utils/emitter';
import { POPUP_EVENTS } from '../../constants/events';
import CONFIG from '../../constants/config';
import { checkFile } from '../../redux/actions/files';
import { useHistory } from 'react-router-dom';
import { MdClear, MdOutlineRemoveRedEye } from 'react-icons/md';
import { BsDownload } from 'react-icons/bs';

export default function CheckoutPopup() {
  const popupRef = useRef(null);
  const popupStyle = {
    left: '30%',
    marginLeft: 0,
    marginTop: 0,
    top: '25%',
    width: '40%',
    // height: '30%',
    minHeight: '0rem',
    borderRadius: 10,
  };
  const [fileId, setFileId] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  function handlePreviewFile() {
    history.push(`/file-detail/${fileId}`);
    dispatch(checkQuickAccess());
  }

  async function handleDownloadFile() {
    popupRef.current.hide();
    const {
      data: { url, file_name },
    } = await axios.get(`${CONFIG.BASE_URL}/file/download/${fileId}`, {
      withCredentials: true,
    });
    FileSaver.saveAs(url, file_name);
  }

  function handleDiscardCheckout() {
    dispatch(checkFile(fileId, false));
    popupRef.current.hide();
  }

  useEffect(() => {
    const showPopup = (id) => {
      if (popupRef.current) popupRef.current.show();
      setFileId(id);
    };
    function listenToTheEmitter() {
      emitter.on(POPUP_EVENTS.SHOW_CHECKOUT_SUCCESS, showPopup);
    }
    listenToTheEmitter();
    return () => {
      emitter.off(POPUP_EVENTS.SHOW_CHECKOUT_SUCCESS, showPopup);
    };
  }, []);

  return (
    <SkyLight
      hideOnOverlayClicked
      overlayStyles={{ backgroundColor: 'rgba(49,49,49,.5)' }}
      ref={popupRef}
      dialogStyles={popupStyle}
    >
      <div style={{ paddingTop: '0.5em' }} className="checkinout">
        <div className="check-success--title">
          <span>Check out</span>
          <i onClick={() => popupRef.current.hide()}><MdClear /></i>
        </div>
        <div className="check-success--sub-title">
          The document is Checked - Out successfully!
        </div>
        <div className="check-success-buttons2">
          <button type="button" onClick={handleDownloadFile}>
            Download&nbsp;&nbsp;
            <i className="icon-download-white"><BsDownload /></i>
          </button>
          <button type="button" onClick={handlePreviewFile}>
            View&nbsp;&nbsp;
            <i className="ic-view-details"><MdOutlineRemoveRedEye /></i>
          </button>
        </div>
        <div className="check-success--sub-title">
          <span>
            If you do not want to revise, <span onClick={handleDiscardCheckout}>Discard Check - Out</span>
          </span>
        </div>
      </div>
    </SkyLight>
  );
}

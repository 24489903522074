import { all, takeEvery, put, call, select, takeLatest } from 'redux-saga/effects';
import io from 'socket.io-client';
import { getMyInfo, getUploadFile, getToken, putUpload } from '../services/chat';
import { notifyError } from '../components/common/Alert';
import {
  ACTION_GET_MY_INFO, ACTION_GET_MEMBER_LIST, ACTION_RENDER_MY_INFO,
  ACTION_SOCKET_CONNECTION, ACTION_SOCKET_CONNECTED, ACTION_OPEN_CHAT_DETAIL,
  ACTION_RECEIVER_MESSAGE, ACTION_RECEIVER_MEMBER_LIST, ACTION_SEND_MESSAGE, ACTION_MESSAGE_LIST,
  ACTION_MESSAGE_HISTORY, ACTION_GET_MESSAGE_HISTORY, ACTION_UPLOAD_FILES,
} from '../redux/actions/message';

function* getMyInfoSaga() {
  try {
    const result = yield call(getMyInfo);
    if (result.success) {
      yield put({ type: ACTION_RENDER_MY_INFO, payload: result.userInfo.group_id });
    }
  } catch (err) {
    notifyError(err.response.data.msg);
  }
}

function* getUpload(action) {
  try {
    const socket = yield select(state => state.message.socket);
    const { payload: { originalname, type, parent_id, file } } = action;
    const result = yield call(getUploadFile, originalname, type, parent_id);
    const { upload_url, filename } = result;
    yield call(putUpload, upload_url, file);
    if (!socket) return;
    yield socket.emit('sentMessage', { content: '', original_name: originalname, file_name: filename, type });
  } catch (err) {
    notifyError(err.response.data.msg);
  }
}

function* socketConnectionSaga() {
  try {
    const result = yield call(getToken);
    const token = result.data.token;
    const socket = yield io('https://cms.docquik.com/chat_messages', { query: { token } });
    // console.log(socket);
    yield put({ type: ACTION_SOCKET_CONNECTED, payload: socket });
    yield put({ type: ACTION_GET_MEMBER_LIST });
  } catch (err) {
    notifyError(err.response.data.msg);
  }
}
// mo danh sach tin nhan
function* getMemberListSaga() {
  try {
    const socket = yield select(state => state.message.socket);
    if (!socket) return;
    yield socket.emit('getChatList');
  } catch (err) {
    notifyError(err.response.data.msg);
  }
}
// mo tin nhan
function* onOpenChatDetail(action) {
  try {
    const { payload: { id, type } } = action;
    const socket = yield select(state => state.message.socket);
    if (!socket) return;
    yield socket.emit('chatWith', { id, type });
  } catch (err) {
    notifyError(err.response.data.msg);
  }
}
// gui tin nhan
function* onSendMessage(action) {
  try {
    const { payload: { content, file_name, type } } = action;
    const socket = yield select(state => state.message.socket);
    if (!socket) return;
    yield socket.emit('sentMessage', { content, file_name, type });
  } catch (err) {
    notifyError(err.response.data.msg);
  }
}

function* onReceiverMemberList(action) {
  try {
    const { payload } = action;
    yield put({ type: ACTION_MESSAGE_LIST, payload });
  } catch (err) {
    notifyError(err.response.data.msg);
  }
}

function* onReceiverMessage(action) {
  try {
    const { payload } = action;
    const prevUpdateChat = yield select(state => state.message.updateChat);
    const nextUpdateChat = [...prevUpdateChat, payload];
    yield put({ type: ACTION_MESSAGE_HISTORY, payload: nextUpdateChat });
  } catch (err) {
    notifyError(err.response.data.msg);
  }
}

function* onGetMessageHistory(action) {
  try {
    const { payload: { id, type, offset } } = action;
    const socket = yield select(state => state.message.socket);
    if (!socket) return;
    yield socket.emit('getMessageHistory', { id, type, offset });
  } catch (err) {
    notifyError(err.response.data.msg);
  }
}

export default function* chatWatcher() {
  yield all([
    takeEvery(ACTION_GET_MY_INFO, getMyInfoSaga),
    takeEvery(ACTION_GET_MEMBER_LIST, getMemberListSaga),
    takeEvery(ACTION_SOCKET_CONNECTION, socketConnectionSaga),
    takeEvery(ACTION_OPEN_CHAT_DETAIL, onOpenChatDetail),
    takeEvery(ACTION_SEND_MESSAGE, onSendMessage),
    takeEvery(ACTION_RECEIVER_MEMBER_LIST, onReceiverMemberList),
    takeLatest(ACTION_RECEIVER_MESSAGE, onReceiverMessage),
    takeEvery(ACTION_GET_MESSAGE_HISTORY, onGetMessageHistory),
    takeEvery(ACTION_UPLOAD_FILES, getUpload),
  ]);
}

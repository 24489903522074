export const CHECK_COPIED_FILE = 'CHECK_COPIED_FILE';
export const PASTE_COPIED_FILE = 'PASTE_COPIED_FILE';
export const PASTE_COPIED_FILE_SUCCESS = 'PASTE_COPIED_FILE_SUCCESS';
export const PASTE_COPIED_FILE_FAILURE = 'PASTE_COPIED_FILE_FAILURE';

export const checkFileCopied = (fileId) => ({
    type: CHECK_COPIED_FILE,
    payload: fileId,
});

export const pasteFileCopied = (fileId, folderId) => ({
    type: PASTE_COPIED_FILE,
    payload: {
        fileId,
        folderId,
    },
});

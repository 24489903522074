import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { bytesToSize, getFileName } from '../../utils/string';
import getDefaultThumbnail from '../../utils/getDefaultThumbnail';

import CONFIG from '../../constants/config';
import moment from 'moment'

function FilesItem({
    checkBoxValue,
    file,
    onClick
}) {

    const {
        file_id,
        new_name,
        file_name,
        created_at
    } = file;

    const userInfo = useSelector((s) => s.userInfo);
    const showUpName = new_name ? new_name : getFileName(file_name);
    // console.log(userInfo);

    const thumbnail = file.thumbnail_path
        ? `${CONFIG.CLOUDFRONT_URL}/${file.thumbnail_path}`
        : getDefaultThumbnail(file.type);

    return (
        <div
            className={`files-wraps files__items trash-file ${checkBoxValue ? 'selected-file' : ''}`}
            tabIndex={file_id}
            onClick={() => onClick(file)}
        >
            <div className="top-files">
                <div className="files-text">
                    <div className="icon">
                        <img src={thumbnail} alt="" />
                    </div>
                    <p>{showUpName}</p>


                </div>
            </div>
            <div className="bottom-text">
                <div className="users-wrap">
                    <ul className="user-ul">
                        <li>
                            <div className="user-picture-wrap">
                                {userInfo?.avatar ?
                                    <img src={userInfo.avatar} alt="user" />
                                    :
                                    <span className='user-icon'>
                                        {userInfo.firstname[0] || ''}
                                        {userInfo.lastname[0] || ''}
                                    </span>
                                }
                            </div>
                        </li>
                    </ul>
                    <p>Modified {moment(file.updated_at).format('MMM DD, YYYY')}</p>
                </div>
                <div className="dates-wrap">
                    <p>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.9998 3C8.02976 3 3.99976 7.03 3.99976 12H0.999756L4.88976 15.89L4.95976 16.03L8.99976 12H5.99976C5.99976 8.13 9.12976 5 12.9998 5C16.8698 5 19.9998 8.13 19.9998 12C19.9998 15.87 16.8698 19 12.9998 19C11.0698 19 9.31976 18.21 8.05976 16.94L6.63976 18.36C8.26976 19.99 10.5098 21 12.9998 21C17.9698 21 21.9998 16.97 21.9998 12C21.9998 7.03 17.9698 3 12.9998 3ZM11.9998 8V13L16.2498 15.52L17.0198 14.24L13.4998 12.15V8H11.9998Z" fill="#C2CFE0" stroke="#F5F6F8" strokeWidth="0.6" />
                        </svg>
                        <b>
                            {/* Nov, 30 */}
                            {moment(created_at).format('MMM DD, YYYY')}
                        </b>
                    </p>
                    <span>{bytesToSize(file?.size)}</span>
                </div>
            </div>
        </div >
    )
}

FilesItem.propTypes = {
    file: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
    checkBoxValue: PropTypes.bool
};

FilesItem.defaultProps = {
    checkBoxValue: false,
};

export default FilesItem;
import React from 'react'

export default function ProgressBar({
    title,
    project,
    totalProject
}) {

    const numberProject = project && project.length > 0 ? project.length : 0;
    const numberTotalProject = totalProject && totalProject.length > 0 ? totalProject.length : 0;
    const done = (numberProject * 100) / numberTotalProject;
    const during = 100 - done;
    return (
        <div className='main-project-tab'>
            <div className="progress_overview ">
                <svg>
                    <circle cx="80" cy="-80" r="70"></circle>
                    <circle cx="80" cy="-80" r="70" style={{ strokeDashoffset: `calc(440 - (440 * ${during ? during : 0}) / 100)` }}></circle>
                </svg>
            </div>
            <div className='lagend-text-main'>
                <p>{title}</p>
            </div>
            <div className='lagend-overview-chat'>
                <div className='langend-text-wrap'>
                    <div className='shape-color'></div>
                    <p>During<span><b>{during ? Math.round(during) : 0}%</b></span></p>
                </div>
                <div className='langend-text-wrap'>
                    <div className='shape-color'></div>
                    <p>Done<span><b>{done ? Math.round(done) : 0}%</b></span></p>
                </div>
            </div>
        </div>
    )
}

import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PhoneInput from 'react-phone-input-2';
import Select from 'react-select';
import { FiEdit } from 'react-icons/fi';
import { Form, FormGroup, FormLabel } from 'react-bootstrap';
import dummyUser from '../assets/images/dummyUser.svg';
import Avatar from 'react-avatar-edit';
import SkyLight from 'react-skylight';
import Modal from 'react-bootstrap/Modal';
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import { MdOutlinePhotoCamera, MdPhone, MdEmail } from 'react-icons/md'
import {
    updateUserInfo,
    updatePassword,
    getUserInfo,
} from '../redux/actions/userInfo';
import moment from 'moment';
import {
    getAccountDetails,
    getLicenseRemain,
    getPlanList,
    // updateSubscription,
    updateSubscriptionRazorpay,
    cancelSubscription,
    createSubscription,
    createUserPayment
} from '../services/subscription';
import { notifyError, notifySuccess } from '../components/common/Alert';
import { netBanking, netBankingConfirm } from '../services/payment';

const { bolt } = window;

// razorpay load script
function loadScript(src) {
    return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = src;
        script.onload = () => {
            resolve(true);
        };
        script.onerror = () => {
            resolve(false);
        };
        document.body.appendChild(script);
    });
}

function Profile() {

    const userInfo = useSelector((s) => s.userInfo);
    const [member, setMember] = useState(0);
    const [planList, setPlanList] = useState([]);
    const [isPaymentSuccModal, setIsPaymentSuccModal] = useState(false);
    const [successInfo, setSuccessInfo] = useState({});
    const [cancelButtonView, setCancelButtonView] = useState(false);
    const [userInput, setUserInput] = useState({
        avatar: '',
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        password: '',
        newPassword: '',
    });
    const [avatar, setAvatar] = useState(null);
    const [isCancelSubscriptionModal, setIsCancelSubscriptionModal] = useState(false);
    const [isSuccessCancel, setIsSuccessCancel] = useState(false);
    const [displayPassword, setDisplayPassword] = useState([false, false]);
    const [isChangePassword, setIsChangePassword] = useState(false);
    const [subscription, setSubscription] = useState({});
    const [remain, setRemain] = useState('');
    const [ischange, setIsChange] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [cardInfo, setCardInfo] = useState({});
    const btnRef = useRef(null);
    const dispatch = useDispatch();
    const popup = useRef(null);
    const popupStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    };

    useEffect(() => {
        if (userInfo) {
            setUserInput({
                avatar: userInfo.avatar,
                firstname: userInfo.firstname,
                lastname: userInfo.lastname,
                email: userInfo.email,
                phone: userInfo.phone,
                password: '',
                newPassword: '',
            })
        }
    }, [userInfo])

    const handleChangePhone = (e) => {
        setUserInput({
            ...userInput,
            phone: e,
        });
    };

    const toggleChangePassword = () => {
        setIsChangePassword(!isChangePassword);
    };

    function handleChange(event) {
        const { name, value } = event.target;
        setUserInput({ ...userInput, [name]: value });
    }

    function handleDisplayPassword(index) {
        displayPassword[index] = !displayPassword[index];
        setDisplayPassword([...displayPassword]);
    }

    const onClose = () => {
        setAvatar(null);
    };

    const onCrop = (preview) => {
        setAvatar(preview);
    };

    const onBeforeFileLoad = (elem) => {
        if (elem.target.files[0].size > 1048576) {
            elem.target.value = '';
        }
    };

    function handleChangeAvatar() {
        if (avatar) {
            dispatch(
                updateUserInfo({
                    avatar,
                })
            );
        }
        setIsChangePassword(false);
    }

    function hanldleEnterClick(e) {
        if (e.keyCode === 13) {
            btnRef.current.click();
        }
    }

    function handleChangePassword() {
        dispatch(
            updatePassword({
                password: userInput.password,
                new_password: userInput.newPassword,
            })
        );
        setTimeout(() => dispatch(getUserInfo()), 100);
    }

    const chooseAvatar = () => {
        popup.current.show();
    };

    const manageSubscription = () => {
        // if (userInfo.org_pay_with === 'PayU') {
        //   manageSubscriptionPayU();
        // } else {
        //   manageSubscriptionStripe();
        // }

        if (ischange) {
            // console.log()
            manageSubscriptionRazorpay();
        } else {
            setIsChange(true);
            const plan_name = subscription.change_plan || subscription.plan;
            const member = subscription.change_user_licences || subscription.user_licences;
            if (plan_name === "BASIC" && member <= 1) {
                setSubscription({ ...subscription, change_plan: "STANDARD", change_user_licences: 2 })
            }

            // console.log(subscription);

            // if (plan_name === "STANDARD" && member <= 1) {
            //   setSubscription({ ...subscription, change_user_licences: 2 })
            // }
        }
    };

    const createNewSubscription = async () => {
        const rozerPayScript = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

        if (!rozerPayScript) {
            notifyError("Razorpay SDK failed to load. Are you online?");
            return;
        }
        if (rozerPayScript) {

            const memberCount = Number(subscription.change_user_licences) || Number(subscription.user_licences)

            const userData = {
                plan_type: (subscription.change_plan_type || subscription.plan_type),
                total_count: memberCount,
                plan: (subscription.change_plan || subscription.plan),
                customer_notify: 1,
            }

            // create sunscriptions api calling;
            const initialsData = await createSubscription(userData);

            if (initialsData.success) {
                const { data } = initialsData;
                // console.log(initialsData);
                const options = {
                    // key: "rzp_live_0U7lAWn6cGnQRU", // Enter the Key ID generated from the Dashboard
                    key: 'rzp_live_YaTMFfSQxp8C8E',
                    total_count: data.total_count,
                    // plan_id: "plan_H4f2DqMXcZ9eUT",
                    quantity: data.quantity,
                    surl: 'https://landing.docquik.com/thankyou_signup',
                    furl: 'https://landing.docquik.com/thankyou_signup',
                    payment_type: 'create_subscriptions',
                    date_end_plan_at: null,
                    plan_id: data.plan_id,
                    subscription_id: data.id,
                    handler: async function (response) {
                        const { razorpay_payment_id, razorpay_signature, razorpay_subscription_id } = response;

                        if (razorpay_payment_id && razorpay_subscription_id) {
                            createUserPayment({
                                plan_type: (subscription.change_plan_type || subscription.plan_type),
                                total_count: memberCount,
                                plan: (subscription.change_plan || subscription.plan),
                                subscription_id: razorpay_subscription_id,
                                payment_id: razorpay_payment_id
                            }).then((res) => {
                                // console.log(res);
                                if (res.success) {

                                    setSuccessInfo(res.data);
                                    // openPyamentSuccModal();
                                    setIsPaymentSuccModal(true);
                                    // notifySuccess('Registration successful');
                                    setIsChange(false);
                                } else {
                                    // setIsLoading(false);
                                    notifyError(res.message);
                                }
                            })
                            // setIsLoading(true);
                            // submitPayment({
                            //   // address: input.address,
                            //   // email: input.email,
                            //   // first_name: input.firstname,
                            //   // last_name: input.lastname,
                            //   // phone_number: input.phonenumber,
                            //   // message: input.message,
                            //   // captcha: input.captcha,
                            //   // password: input.password,
                            //   // plan_id: input.plan_id,
                            //   plan_type:(subscription.change_plan_type || subscription.plan_type),
                            //   plan_name:(subscription.change_plan || subscription.plan),
                            //   member,
                            //   subscription_id: razorpay_subscription_id,
                            //   payment_id: razorpay_payment_id,
                            //   // token_id: id,
                            //   // storage: input.storage,
                            //   // cost: input.cost,
                            //   // role: input.role,
                            // }).then((res) => {
                            //   // console.log(paymentSucce);
                            //   // console.log(res);
                            //   // if (paymentSucce.success) {
                            //   if (res.success) {
                            //     setSuccessData(res.data);
                            //     setIsLoading(false);
                            //     modalSuccessOpen();
                            //     // window.location.href = 'https://landing.docquik.com/thankyou_signup';
                            //     // console.log("hello");
                            //     // this.props.location.history.push('/thankyou_signup');
                            //     // window.scrollTo(0, 0);
                            //     // window.open(
                            //     //   'https://landing.docquik.com/thankyou_signup'
                            //     //   );
                            //     notifySuccess('Registration successful');
                            //   } else {
                            //     setIsLoading(false);
                            //     notifyError(paymentSucce.data.msg);
                            //   }

                            // }).catch(err => {
                            //   setIsLoading(false);
                            //   notifyError(err.message);
                            // })

                        } else {
                            // setIsLoading(false);
                            console.log('else...');
                        }

                    }
                };

                const paymentObject = new window.Razorpay(options);
                paymentObject.open();
            }

        }
    }


    const createNewSinglePayment = async () => {
        const rozerPayScript = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

        if (!rozerPayScript) {
            notifyError("Razorpay SDK failed to load. Are you online?");
            return;
        }
        if (rozerPayScript) {

            // const userInfo = dispatch(getUserInfo())
            
            const memberCount = Number(subscription.change_user_licences) || Number(subscription.user_licences)
            let selectedPlan = {};
            for (var i = 0; i < planList.length; i++) {
                const planObj = planList[i].plan
                const planTy = planList[i].pricing_type?.Name;
                if ((planObj === subscription.change_plan || planObj === subscription.plan) && (planTy === subscription.change_plan_type || planTy === subscription.plan_type)){
                    selectedPlan = planList[i];
                }
            }
            // const selectedPlan = planList.find(info => (info.plan === subscription.change_plan || info.plan === subscription.plan) && (info.pricing_type?.Name === subscription.change_plan_type || info.pricing_type?.Name === subscription.plan_type))
            
            let totalAmount = 0;
            if (selectedPlan) {
                const plan_cost = selectedPlan ? parseInt(selectedPlan.cost) : 0
                // console.log(selectedPlan);
                if (selectedPlan.pricing_type.Name === "Year") totalAmount = (plan_cost * memberCount) * 12
                if (selectedPlan.pricing_type.Name === "Quarterly") totalAmount = (plan_cost * memberCount) * 3
            }
            const options = {
                // key: 'rzp_live_DoDai8FKjS5JMH',
                key: "rzp_test_Lq0XJmYMUZvKH9",
                total_count: memberCount,
                amount: Number(`${totalAmount}00`),
                description: `${subscription.change_plan || subscription.plan} / ${subscription.change_plan_type || subscription.plan_type}`,
                currency: "INR",
                // plan_id: "plan_H4f2DqMXcZ9eUT",
                // quantity: data.quantity,
                surl: 'https://landing.docquik.com/thankyou_signup',
                furl: 'https://landing.docquik.com/thankyou_signup',
                prefill: {
                    name: `${userInput?.firstname} ${userInput?.lastname}`,
                    email: `${userInput?.email}`,
                    contact: `${userInput?.phone}`
                },
                options: {
                    checkout: {
                        readonly: {
                            email: 1,
                            contact: 0
                        }
                    }
                },
                // payment_type: 'create_subscriptions',
                // date_end_plan_at: null,
                plan_id: selectedPlan.id,
                // subscription_id: data.id,
                handler: async function (response) {
                    const { razorpay_payment_id } = response;

                    if (razorpay_payment_id) {
                        // console.log(razorpay_payment_id);
                        updateSubscriptionRazorpay({
                            plan_type: (subscription.change_plan_type || subscription.plan_type),
                            member: (Number(subscription.change_user_licences) || Number(subscription.user_licences)),
                            plan: (subscription.change_plan || subscription.plan),
                            payment_id: razorpay_payment_id
                        }).then((res) => {
                            // console.log(res);
                            setSuccessInfo(res.data);
                            setIsPaymentSuccModal(true);
                            notifySuccess('Subscription Update successful');
                            setIsChange(false);
                        }).catch(err => {
                            if (err.response) {
                                notifyError(err.response.data.msg);
                            } else {
                                notifyError();
                            }
                        })

                    } else {
                        // setIsLoading(false);
                        console.log('else...');
                    }

                }
            };

            const paymentObject = new window.Razorpay(options);
            paymentObject.open();
        }

    }

    // razorpay function
    const manageSubscriptionRazorpay = () => {
        // console.log(subscription);
        if ((subscription.change_plan && subscription.change_plan === "FREE") || (subscription.plan && subscription.plan === 'FREE')) {

            // console.log('create new subscription');
            createNewSubscription();

        } else {

            // console.log('Update subscription');
            createNewSinglePayment();
        }

    }

    const openSubscriptionModal = () => {
        setIsCancelSubscriptionModal(true)
    }
    const closeSubscriptionModal = () => {
        setIsCancelSubscriptionModal(false);
        setIsSuccessCancel(false);
    }

    const openPyamentSuccModal = () => {
        setIsPaymentSuccModal(true)
    }
    const closePyamentSuccModal = () => {
        setIsPaymentSuccModal(false);
        window.location.reload();
    }

    const confirmCancleSubscription = () => {
        // {
        //   plan_type: subscription.change_plan_type || subscription.plan_type,
        //   member: Number(subscription.change_user_licences || subscription.user_licences),
        //   plan: subscription.change_plan || subscription.plan,
        //   subscription_id: subscription.subscription_id
        // }
        cancelSubscription().then((res) => {
            // console.log(res);
            if (res.success) {
                setIsSuccessCancel(true);
                setIsCancelSubscriptionModal(false);
                getAccountDetails().then(({ account_details }) => {
                    // console.log(account_details);
                    // setMember(subscription)
                    notifySuccess('Subscription cancel successful');
                    setSubscription({ ...subscription, ...account_details })
                    const start = moment(new Date('2020-09-30'));
                    const end = moment(new Date());
                    const diff = end.diff(start, 'days')
                    if (account_details?.plan_type === 'Year') {

                        if (diff > 365) {
                            setCancelButtonView(true);
                        }

                    } else if (account_details?.plan_type === 'Quarterly') {

                        if (diff > 90) {
                            setCancelButtonView(true);
                        }

                    } else {
                        setCancelButtonView(false);
                    }
                });
            }
        }).catch(err => {
            if (err.response) {
                notifyError(err.response.data.msg);
            } else {
                notifyError();
            }
        });
        // console.log(subscription.subscription_id);
        // setIsCancelSubscriptionModal(false);
    }

    const handleCancelSubs = () => {
        setIsChange(false);
    };

    const handleChangeSubs = (name) => (e) => {
        if (name === 'change_user_licences') {
            // console.log(e.target.value);
            setSubscription({ ...subscription, [name]: e.target.value });
        } else if (name === "change_plan") {
            const planType = e.target.value;
            if (planType === "PRO") {
                setSubscription({ ...subscription, [name]: planType, change_user_licences: 10 });
            } else if (planType === "STANDARD") {
                setSubscription({ ...subscription, [name]: planType, change_user_licences: 2 });
            } else {
                setSubscription({ ...subscription, [name]: planType, change_user_licences: 1 });
            }

        } else {

            setSubscription({ ...subscription, [name]: e.target.value });
        }
    };

    // console.log(subscription);

    const handleSetMember = (e) => {
        // plus handler code: (subscription.change_user_licences || subscription.user_licences) + (subscription.change_plan === 'PRO' ? 10 : 1)
        // mines handler code: (subscription.change_user_licences || subscription.user_licences) - (subscription.change_plan === 'PRO' ? 10 : 1)

        // let nextValue = Number(e?.target?.value ?? e);
        // console.log(e.target);

        // new code
        let nextValue = subscription.change_user_licences || subscription.user_licences;
        const plan_name = subscription.change_plan || subscription.plan;
        if (nextValue > 300) {
            nextValue = 300;
        } else if (plan_name === 'BASIC' && nextValue < 1) {
            if (e === 'plus') nextValue += 1;
            if (e === 'mines') nextValue -= 1;
        } else if (plan_name === 'STANDARD') {

            if (nextValue === 1) {
                nextValue = 2
            }

            if (nextValue > 0 || (nextValue >= 2 && nextValue <= 9)) {
                if (nextValue <= 8) if (e === 'plus') nextValue += 1;
                if (nextValue > 2) if (e === 'mines') nextValue -= 1;
                // if(nextValue < 9){
                // }
                // console.log(nextValue);
            }
        } else if (plan_name === 'PRO') {
            if (e === 'plus') nextValue += 10;
            if (nextValue > 10) if (e === 'mines') nextValue -= 10;
        }


        // old
        // if (nextValue > 300) {
        //   nextValue = 300;
        // } else if (subscription.change_plan === 'STANDARD' && nextValue < 1) {
        //   // && nextValue < 1

        //   nextValue = 1;
        // } else if (subscription.change_plan === 'PRO' && nextValue < 10) {
        //   nextValue = 10;
        // }
        // console.log(nextValue);
        setSubscription({ ...subscription, change_user_licences: nextValue });
        setMember(nextValue);
    };

    const handleChangeCard = (name) => (e) => {
        setCardInfo({ ...cardInfo, [name]: e.target.value });
    };

    const handlePayU = () => {
        netBanking({
            member: subscription.user_licences,
            plan_type: subscription.plan_type,
            plan: subscription.plan,
        })
            .then(({ data }) => {
                bolt.launch(
                    {
                        ...data,
                        surl: 'https://app.docquik.com/',
                        furl: 'https://app.docquik.com/',
                    },
                    {
                        responseHandler: ({
                            response: {
                                key,
                                txnid,
                                amount,
                                productinfo,
                                firstname,
                                email,
                                udf5,
                                status,
                                hash,
                                addedon,
                            },
                        }) => {
                            netBankingConfirm({
                                member: subscription.user_licences,
                                plan_type: subscription.plan_type,
                                plan: subscription.plan,
                                payu: {
                                    key,
                                    txnid,
                                    amount,
                                    productinfo,
                                    firstname,
                                    email,
                                    udf5,
                                    status,
                                    hash,
                                    addedon,
                                },
                            })
                                .then(() => {
                                    notifySuccess('Registration successful');
                                })
                                .catch((err) => {
                                    if (err.response) {
                                        notifyError(err.response.data.msg);
                                    } else {
                                        notifyError();
                                    }
                                });
                        },
                        catchException: (exc) => {
                            notifyError(exc);
                        },
                    }
                );
            })
            .catch((err) => {
                if (err.response) {
                    notifyError(err.response.data.msg);
                } else {
                    notifyError();
                }
            });
    };

    const finPlan = (plan_name, plan_type) => {
        if (planList && planList.length > 0) {
            const plan = planList.find(info => info.plan === plan_name && info.pricing_type.Name === plan_type);
            // console.log(plan);
            return plan ? plan.cost : 0
        }
        return 0;
    }

    useEffect(() => {
        getLicenseRemain().then(({ count }) => setRemain(count));
        getAccountDetails().then(({ account_details }) => {
            // console.log(account_details);
            const accountDetails = {
                ...account_details,
                plan_type: account_details.plan_type === 'Month' ? "Quarterly" : account_details.plan_type
            }
            // console.log(accountDetails)
            setSubscription({ ...subscription, ...accountDetails })
            const start = moment(new Date('2020-09-30'));
            const end = moment(new Date());
            const diff = end.diff(start, 'days')
            if (account_details?.plan_type === 'Year') {

                if (diff > 365) {
                    setCancelButtonView(true);
                }

            } else if (account_details?.plan_type === 'Quarterly') {

                if (diff > 90) {
                    setCancelButtonView(true);
                }

            } else {
                setCancelButtonView(false);
            }

            // setSubscription({ ...subscription, ...account_details })
        });

        getPlanList().then((res) => {
            // console.log(res);
            setPlanList(res);
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const changePasswordView = (
        <>
            <Form.Group className='form-group2 mt-3'>
                <Form.Label className='form-label2 '>Password</Form.Label>


                <input
                    className="primary-input  "
                    placeholder="Password (At least 8 characters)"
                    type={displayPassword[0] ? 'text' : 'password'}
                    name="password"
                    onChange={handleChange}
                    value={userInput.password}
                />
                <i
                    onClick={() => handleDisplayPassword(0)}
                    style={{
                        position: 'absolute',
                        top: '1rem',
                        right: '1rem',
                        cursor: 'pointer',
                    }}
                >
                    {!displayPassword[0] ?
                        <FaEyeSlash />
                        :
                        <FaEye />
                    }
                </i>
            </Form.Group>

            <Form.Group className='form-group2 mt-3'>
                <Form.Label className='form-label2 '>New Password</Form.Label>
                <input
                    className="primary-input"
                    placeholder="New Password (At least 8 characters)"
                    type={displayPassword[1] ? 'text' : 'password'}
                    name="newPassword"
                    onChange={handleChange}
                    value={userInput.newPassword}
                    onKeyDown={hanldleEnterClick}
                />
                <i
                    onClick={() => handleDisplayPassword(1)}
                    style={{
                        position: 'absolute',
                        top: '1rem',
                        right: '1rem',
                        cursor: 'pointer',
                    }}
                >
                    {!displayPassword[0] ?
                        <FaEyeSlash />
                        :
                        <FaEye />
                    }
                </i>
            </Form.Group>
            <Form.Group className="d-flex">
                <button
                    type="button"
                    className='btn btn-primary p-3 me-2'
                    ref={btnRef}
                    onClick={() => {
                        handleChangePassword();
                    }}
                >
                    Change
                </button>
                <button type="button" className='cancel-btn p-3 me-0' onClick={toggleChangePassword}>Cancel</button>
            </Form.Group>
        </>
    )

    const infoView = (
        <>
            <Form.Group className="d-flex align-items center justify-content-between mt-3" controlId="">
                <div className='d-block w-100 me-3'>
                    <Form.Label className='form-label2 '>First Name</Form.Label>
                    <Form.Control
                        type="text"
                        className="primary-input "
                        placeholder="Enter first name"
                        name="firstname"
                        onChange={handleChange}
                        value={userInput.firstname}
                        disabled={!isEdit ? true : false}
                    />
                </div>
                <div className='d-block w-100 '>
                    <Form.Label className='form-label2 '>Last Name</Form.Label>
                    <Form.Control
                        type="text"
                        className="primary-input"
                        placeholder="Enter last name"
                        name="lastname"
                        onChange={handleChange}
                        value={userInput.lastname}
                        disabled={!isEdit ? true : false}
                    />
                </div>
            </Form.Group>
            <Form.Group className='mt-3'>
                <Form.Label className='form-label2 d-block'>Email</Form.Label>
                <Form.Control
                    type="email"
                    className="primary-input d-block"
                    placeholder="Enter email"
                    value={userInput?.email}
                    disabled
                />
            </Form.Group>

            <Form.Group className='d-block mt-3'>

                <Form.Label className='form-label2 '> Phone Number</Form.Label>
                <PhoneInput
                    inputClass="primary-input"
                    country="in"
                    inputClass={`phone-input ${userInput.phone ? 'is-active' : ''}`}
                    inputProps={{
                        name: 'phone',
                        required: true,
                        autoFocus: true,
                    }}
                    value={userInput.phone}
                    countryCodeEditable={false}
                    onChange={handleChangePhone}
                    disabled={!isEdit ? true : false}
                />

            </Form.Group>

            {isEdit && <Form.Group className="d-flex mt-5">
                <button
                    type="button"
                    className='btn btn-primary p-3 ps-4 pe-4 me-3'
                    ref={btnRef}
                >
                    Save
                </button>
                <button type="button" className='cancel-btn p-3 me-0' onClick={() => setIsEdit(false)}>Cancel</button>
            </Form.Group>
            }

        </>
    )

    const renderSubscription = (
        <div className='info-wrap '>
            <h3 className="info-title mb-4">Account Details</h3>
            {ischange ?
                <>

                    <FormGroup>
                        <FormLabel className='form-label2 mt-3'>Plan</FormLabel>
                        {/* <Select options={options} /> */}
                        <select
                            onChange={handleChangeSubs('change_plan')}
                            list="plan-list"
                            id="selected_plan"
                            value={subscription.change_plan}
                            defaultValue={subscription.selected_plan}
                            className="primary-input"
                        >
                            {/* {console.log(subscription.plan)} */}
                            {subscription.plan === "Basic" ? <option value="Basic">Basic</option> : null}
                            {subscription.plan !== "PRO" ? <option value="STANDARD">STANDARD</option> : null}
                            <option value="PRO">PRO</option>
                        </select>
                    </FormGroup>

                    <FormGroup>
                        <FormLabel className='form-label2 mt-3'>Plan Type</FormLabel>
                        {/* <Select options={options} /> */}
                        <select
                            onChange={handleChangeSubs('change_plan_type')}
                            id="payment_frequency"
                            list="plan-type-list"
                            value={subscription.change_plan_type}
                            defaultValue={subscription.plan_type}
                            className="primary-input"
                        >
                            {/* <option value="Month">Month</option> */}
                            <option value="Quarterly">Quarterly</option>
                            <option value="Year">Year</option>
                        </select>
                    </FormGroup>
                    {subscription.plan !== "FREE" || (subscription.change_plan && subscription.change_plan !== "FREE") ?
                        <FormGroup className='mt-3'>
                            <FormLabel className='form-label2 mt-3 me-3'>User Licences</FormLabel>
                            <button type="button" className='acc-btn' onClick={() => handleSetMember('mines')}>-</button>
                            {/* <span className='mx-2'>{subscription.change_user_licences || subscription.user_licences}</span> */}
                            <input
                                type="number"
                                style={{
                                    fontWeight: 'bold',
                                    maxWidth: '4.5rem',
                                    border: 'none',
                                    textAlign: 'center',
                                }}
                                value={subscription.change_user_licences}
                                defaultValue={subscription.user_licences}
                                // onChange={handleChangeSubs('change_user_licences')}
                                disabled
                            />
                            <button type="button" className='acc-btn' onClick={() => handleSetMember('plus')}>+</button>
                        </FormGroup>
                        :
                        null
                    }

                    {subscription.plan !== "FREE" || (subscription.change_plan && subscription.change_plan !== "FREE") ?
                        <table className='account-table d-flex justify-content-end mt-5 ms-auto'>
                            <tbody>
                                <tr>
                                    <td>  <h3 className=' pass-title me-5 '>Membership fee : </h3></td>
                                    <td>
                                        <h3 className='pass-title number'>
                                            {(subscription.change_plan_type || subscription.plan_type) !== 'Quarterly' ?
                                                (subscription.change_plan === "PRO" || subscription.plan === "PRO") ?
                                                    finPlan('PRO', 'Year')
                                                    :
                                                    finPlan('STANDARD', 'Year')
                                                :
                                                (subscription.change_plan === "PRO" || subscription.plan === "PRO") ?
                                                    finPlan('PRO', 'Quarterly')
                                                    :
                                                    finPlan('STANDARD', 'Quarterly')
                                            }*{subscription.change_user_licences || subscription.user_licences}*{(subscription.change_plan_type || subscription.plan_type) !== 'Quarterly' ? 12 : 3}
                                        </h3>
                                    </td>
                                </tr>
                                <tr>
                                    <td>  <h3 className=' pass-title me-5 '>Total : </h3></td>
                                    <td>
                                        <h3 className='pass-title number'>
                                            <b>₹
                                                {
                                                    (subscription.change_plan_type || subscription.plan_type) !== "Quarterly" ?
                                                        (subscription.change_plan === "PRO" || subscription.plan === "PRO") ?
                                                            ((subscription.change_user_licences || subscription.user_licences) * parseInt(finPlan('PRO', 'Year')) * 12)
                                                            :
                                                            ((subscription.change_user_licences || subscription.user_licences) * parseInt(finPlan('STANDARD', 'Year')) * 12)
                                                        :
                                                        (subscription.change_plan === "PRO" || subscription.plan === "PRO") ?
                                                            ((subscription.change_user_licences || subscription.user_licences) * parseInt(finPlan('PRO', 'Quarterly')) * 3)
                                                            :
                                                            ((subscription.change_user_licences || subscription.user_licences) * parseInt(finPlan('STANDARD', 'Quarterly')) * 3)
                                                }
                                            </b>
                                        </h3>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        :
                        null
                    }

                    {!userInfo.org_id && (
                        <>
                            <hr />
                            <div className="row">
                                <div className="col-3" style={{ alignSelf: 'flex-end' }}>
                                    <label htmlFor="cnumber" style={{ marginBottom: '1.2rem' }}>
                                        Card number
                                    </label>
                                </div>
                                <div className="col-8">
                                    <input
                                        onChange={handleChangeCard('number')}
                                        id="cnumber"
                                        value={cardInfo.number}
                                        className="primary-input mb-3"
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-3" style={{ alignSelf: 'flex-end' }}>
                                    <label
                                        htmlFor="exp_month"
                                        style={{ marginBottom: '1.2rem' }}
                                    >
                                        Expired Month
                                    </label>
                                </div>
                                <div className="col-8">
                                    <input
                                        onChange={handleChangeCard('exp_month')}
                                        id="exp_month"
                                        value={cardInfo.exp_month}
                                        className="primary-input mb-3"
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-3" style={{ alignSelf: 'flex-end' }}>
                                    <label
                                        htmlFor="exp_year"
                                        style={{ marginBottom: '1.2rem' }}
                                    >
                                        Expired Year
                                    </label>
                                </div>
                                <div className="col-8">
                                    <input
                                        onChange={handleChangeCard('exp_year')}
                                        id="exp_year"
                                        value={cardInfo.exp_year}
                                        className="primary-input mb-3"
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-3" style={{ alignSelf: 'flex-end' }}>
                                    <label htmlFor="cvc" style={{ marginBottom: '1.2rem' }}>
                                        CVC
                                    </label>
                                </div>
                                <div className="col-8">
                                    <input
                                        onChange={handleChangeCard('cvc')}
                                        id="cvc"
                                        type="password"
                                        value={cardInfo.cvc}
                                        className="primary-input mb-3"
                                    />
                                </div>
                            </div>
                        </>
                    )}

                    <div className='d-flex align-items-center justify-content-start mt-4'>
                        <button
                            type='button'
                            className='change-btn  p-3 ps-4 pe-4 me-2'
                            onClick={manageSubscription}
                        >
                            {ischange ? 'Save' : 'Manage Subscriptions'}
                        </button>
                        <button
                            type="button"
                            className='cancel-btn p-3 ps-4 pe-4'
                            onClick={handleCancelSubs}
                        >
                            Cancel
                        </button>
                    </div>
                </>
                :
                <>

                    <table className='account-table'>
                        <tbody>
                            <tr>
                                <td>Selected Plan</td>
                                <td>{subscription.plan}</td>
                            </tr>
                            <tr>
                                <td>User Licences</td>
                                <td>
                                    {subscription.user_licences}{' '}
                                    {remain > 0 &&
                                        `(${remain} user ${remain > 1 ? 'licences are' : 'license is'} remaining.)`}

                                </td>
                            </tr>
                            <tr>
                                <td>Extra Storage</td>
                                <td>{subscription.extra_storage}</td>
                            </tr>
                            <tr>
                                <td>Payment Frequency</td>
                                <td>{subscription.payment_frequency}</td>
                            </tr>
                        </tbody>
                    </table>


                    <button type='button' className='change-btn mt-3' onClick={manageSubscription}>{ischange ? 'Save' : 'Manage Subscriptions'}</button>
                    {cancelButtonView ?
                        <button type="button" className='cancel-sub-btn' onClick={openSubscriptionModal}>
                            {'Cancel Subscriptions'}
                        </button>
                        :
                        null
                    }

                </>
            }


        </div>
    )

    const renderPayU = (
        <div className='info-wrap '>
            <h3 className="info-title">Account Details</h3>
            <div style={{ borderRadius: '0px 0px 10px 10px' }}>
                <div className="mb-3">
                    Your subscription is exprired. Please pay to continue use this plan.
                </div>
                <button
                    type="button"
                    className='btn btn-primary'
                    disabled={!Object.keys(subscription).length}
                    onClick={handlePayU}
                >
                    Continue
                </button>
            </div>
        </div>
    )

    return (

        <>
            <div className='page-top-header'>
                <h1 className="page-title">Account</h1>
            </div>
            <div className='profile-section'>
                <div className='container-fluid '>
                    <div className='row'>
                        <div className='col ps-0'>
                            <div className='info-wrap '>
                                <div className="d-flex align-items-center justify-content-between mb-4 ">
                                    <h3 className="info-title">Information</h3>
                                    <div className='d-flex align-items-center justify-content-end'>
                                        {/* <button type="button" className='btn-style d-flex' onClick={() => setIsEdit(!isEdit)}>
                                            <FiEdit className='info-title' /> <h3 className='info-title mb-0'>Edit  </h3>
                                        </button> */}
                                        {!isChangePassword && <button type='button' className='btn-style d-flex ms-3' onClick={toggleChangePassword}>
                                            <FiEdit className='pass-title' /> <h3 className='info-title mb-0'>Change Password  </h3>
                                        </button>
                                        }
                                    </div>
                                </div>
                                <div className='d-flex align-items-start justify-content-start mt-5 mb-5'>
                                    <div className='org-images-wrap'>
                                        <div className='org-img-wrap '>
                                            {userInfo.avatar ?
                                                <img src={userInfo.avatar} alt='user' />
                                                :
                                                <span className='p-name'>
                                                    {userInfo.firstname[0] || ''}
                                                    {userInfo.lastname[0] || ''}
                                                </span>
                                            }
                                        </div>
                                        <button onClick={chooseAvatar} className='btn-upload-camera' type="button">
                                            <i className="icon-camera"><MdOutlinePhotoCamera /></i>
                                        </button>
                                    </div>
                                    <div className='user_info'>
                                        <h2>{userInput.firstname} {userInput.lastname}</h2>
                                        <p><span><MdEmail /></span>{userInput.email}</p>
                                        <p><span><MdPhone /></span>{userInput.phone}</p>
                                    </div>
                                </div>
                                {isChangePassword ? changePasswordView : null}
                                {/* {isChangePassword ? changePasswordView : infoView} */}

                            </div>
                        </div>
                        <div className='col pe-0'>
                            {userInfo.disabled ? renderPayU : renderSubscription}

                        </div>
                    </div>
                    <Modal show={isCancelSubscriptionModal} size="md" aria-labelledby="contained-modal-title-vcenter" centered >
                        <Modal.Body>
                            {isSuccessCancel ?
                                <h4 style={{ textAlign: 'center', padding: '25px 0', lineHeight: '1.9', fontSize: '25px' }}>
                                    Subscription canceled Successfully
                                </h4>
                                :
                                <h4 style={{ textAlign: 'center', padding: '25px 0', lineHeight: '1.9', fontSize: '25px' }}>
                                    Are you sure? <span style={{ display: 'block' }}>You want to cancel Plan</span>
                                </h4>
                            }
                        </Modal.Body>
                        <Modal.Footer style={{ justifyContent: 'space-between', padding: '0.75rem 2rem' }}>
                            <button type="button" className='btn btn-primary m-0 p-3' style={{ background: '#F5F6F8', color: '#016288', border: '1px solid #C2CFE0', float: 'left', fontWeight: 'bold' }} onClick={closeSubscriptionModal}>Close</button>
                            {!isSuccessCancel ? <button type="button" className='btn btn-primary p-3 m-0' onClick={confirmCancleSubscription}>Confirm</button> : null}
                        </Modal.Footer>
                    </Modal>
                    {isPaymentSuccModal ?
                        <Modal show={isPaymentSuccModal} size="md" aria-labelledby="contained-modal-title-vcenter" centered >
                            <Modal.Body>
                                {/* {console.log(successInfo)} */}
                                <div className="price_wrap" >
                                    <p>₹<span className="price-span">{successInfo.total_amount}</span></p>
                                </div>
                                <div className="success_wrap">
                                    <span className="icon-wrap"><svg className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"></path></svg></span>
                                    <p>Paid Successfully</p>
                                </div>
                                <ul className="details_pay">
                                    <li>Payment ID: <span>{successInfo.payment_id}</span></li>
                                    {/* <li>Paid On: <span>22 Feb, 2021</span></li> */}
                                    {/* <li>Method: <span>{successInfo.method.bank !== null ? successInfo.method.bank : null}{successInfo.method.card_id !== null ? successInfo.method.card_id : null} {successInfo.method.vpa !== null ? successInfo.method.vpa : null}{successInfo.method.wallet !== null ? successInfo.method.wallet : null}</span></li> */}
                                    <li>Email <span>{successInfo.email}</span></li>
                                    <li>Mobile Number: <span>{successInfo.mobile}</span></li>
                                </ul>
                                <p className="last-text">For any order related queries, please reach out to us at <a href={'mailto:registration@docquik.com'}>registration@docquik.com</a></p>
                            </Modal.Body>
                            <Modal.Footer style={{ justifyContent: 'flex-end', padding: '0.75rem 2rem' }}>
                                <button type='button' className='btn btn-primary p-3 pt-2 pb-2 m-0' style={{ background: 'transparent', color: '#000', border: '0', paddingLeft: '2rem', borderRadius: '8px', float: 'left' }} onClick={() => closePyamentSuccModal()}>Close</button>
                            </Modal.Footer>
                        </Modal> : null}
                    <SkyLight
                        dialogStyles={popupStyle}
                        ref={popup}
                        hideOnOverlayClick
                        className="choose-avatar"
                    >
                        <Avatar
                            width={390}
                            height={295}
                            onCrop={onCrop}
                            onClose={onClose}
                            onBeforeFileLoad={onBeforeFileLoad}
                        />
                        <div className="button-group">
                            <button
                                type="button"
                                className="button-close"
                                onClick={() => {
                                    popup.current.hide();
                                }}
                            >
                                Close
                            </button>
                            <button
                                type="button"
                                className="button-choose"
                                onClick={() => {
                                    handleChangeAvatar();
                                    popup.current.hide();
                                }}
                            >
                                Choose
                            </button>
                        </div>
                    </SkyLight>
                </div>
            </div >

        </>
    )
}

export default Profile

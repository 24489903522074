import axios from 'axios';
import CONFIG from '../constants/config';

export const createGroup = async ({
  gr_name,
  gr_description,
  member_ids,
  gr_logo,
}) => {
  const response = await axios({
    method: 'POST',
    url: `${CONFIG.BASE_URL}/org-groups`,
    withCredentials: true,
    data: {
      gr_name,
      gr_description,
      member_ids,
      gr_logo,
    },
  });
  return response.data;
};

export const getGroups = async () => {
  try {
    const response = await axios({
      method: 'GET',
      url: `${CONFIG.BASE_URL}/org-groups`,
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    // console.log(err.response.data.msg);
    return { success: false, msg: err.response.data.msg }
  }
};

export const updateGroup = async ({
  gr_name,
  gr_description,
  gr_logo,
  group_id,
  member_ids,
}) => {
  if (gr_logo && gr_logo.search('base64') === -1) gr_logo = undefined;
  const response = await axios({
    method: 'PATCH',
    url: `${CONFIG.BASE_URL}/org-groups/${group_id}`,
    withCredentials: true,
    data: {
      gr_name,
      gr_description,
      gr_logo,
      member_ids,
    },
  });
  return response.data;
};

export const deleteGroup = async ({ group_id }) => {
  const response = await axios({
    method: 'DELETE',
    url: `${CONFIG.BASE_URL}/org-groups/${group_id}`,
    withCredentials: true,
  });
  return response.data;
};


export function resizeSidebar() {
  const element = document.getElementById('resizable');
  const [sidebar] = document.getElementsByClassName('sidebar');
  const existResizer = document.getElementsByClassName('draghandle').length;
  const homeWidth = document.getElementById('home').clientWidth;

  function Resize(e) {
    element.style.cursor = 'col-resize';
    let elemWidth = e.clientX - element.offsetLeft;
    elemWidth = elemWidth >= homeWidth - 250 ? homeWidth - 250 : elemWidth;
    elemWidth = elemWidth <= homeWidth - 400 ? homeWidth - 400 : elemWidth;
    sidebar.style.width = `${
      document.getElementById('home').clientWidth - elemWidth
    }px`;
    if (sidebar.style.width < '300px') {
      const [usageItem] = document.getElementsByClassName('usage-item');
      if (usageItem) usageItem.className = 'usage-item small-info';
    } else {
      const [usageItem] = document.getElementsByClassName(
        'usage-item small-info'
      );
      if (usageItem) usageItem.className = 'usage-item';
    }
    element.style.width = `${elemWidth}px`;
  }

  function stopResize() {
    element.style.cursor = '';
    window.removeEventListener('mousemove', Resize, false);
    window.removeEventListener('mouseup', stopResize, false);
  }

  function initResize() {
    window.addEventListener('mousemove', Resize, false);
    window.addEventListener('mouseup', stopResize, false);
  }

  if (element && !existResizer) {
    const resizer = document.createElement('div');
    resizer.className = 'draghandle';
    resizer.style.width = '10px';
    resizer.style.height = '100vh';
    resizer.style.position = 'absolute';
    resizer.style.zIndex = '100';
    resizer.style.top = '0';
    resizer.style.bottom = '0';
    resizer.style.right = '-8px';
    resizer.style.float = 'right';
    resizer.style.cursor = 'col-resize';
    element.appendChild(resizer);
    resizer.addEventListener('mousedown', initResize, false);
  }
}

/**
 *
 * @param {Element} node
 * @param {string} eventType - "mousedown" || "mouseup"
 */
export function triggerMouseEvent (node, eventType) {
  const clickEvent = document.createEvent('MouseEvents');
  clickEvent.initEvent(eventType, true, true);
  node.dispatchEvent(clickEvent);
}

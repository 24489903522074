import React, { useState, useEffect, useRef } from 'react';
import SkyLight from 'react-skylight';
import { emitter } from '../../utils/emitter';
import { POPUP_EVENTS } from '../../constants/events';
import { useDispatch } from 'react-redux';
import { renameFile } from '../../redux/actions/files';
import { renameFolder } from '../../redux/actions/folders';

export default function RenamePopup() {
  const popupRef = useRef(null);
  const buttonRef = useRef(null);
  const inputRef = useRef(null);
  const [isVisible, setIsVisible] = useState(true);
  const [newName, setNewName] = useState('');
  const [fileId, setFileId] = useState('');
  const [folderId, setFolderId] = useState('');
  const dispatch = useDispatch();
  const newFolderPopupStyle = {
    width: '30%',
    // height: '30%',
    minHeight: 0,
    marginLeft: '-15%',
    borderRadius: '10px',
  };

  function showPopup(filesId, foldersId, name) {
    setFileId(filesId);
    setFolderId(foldersId);
    setNewName(name);
    if (popupRef.current) {
      popupRef.current.show();
    }
    setTimeout(() => {
      inputRef.current.focus();
    }, 1);
  }

  function hidePopup() {
    popupRef.current.hide();
    setIsVisible(false);
  }

  function handleRename() {
    if (fileId) dispatch(renameFile(fileId, newName));
    else if (folderId) dispatch(renameFolder(folderId, newName));
    hidePopup();
  }

  function listenToTheEmitter() {
    emitter.on(POPUP_EVENTS.SHOW_RENAME_POPUP, showPopup);
  }

  useEffect(() => {
    listenToTheEmitter();
    return () => {
      emitter.off(POPUP_EVENTS.SHOW_RENAME_POPUP, showPopup);
    };
  });

  function handleEnter(e) {
    if (e.keyCode === 13) {
      buttonRef.current.click();
    }
  }

  return (
    <SkyLight
      dialogStyles={newFolderPopupStyle}
      ref={popupRef}
      isVisible={isVisible}
    >
      <div className="newFolderPopup">
        <div className='label'>Remane</div>
        <input
          type="text"
          className='form-control'
          value={newName}
          onChange={(e) => setNewName(e.target.value)}
          onKeyDown={handleEnter}
          ref={inputRef}
        />
        <div className='btns-groups'>
          <button onClick={hidePopup} className='btn-cancel' type="button">
            Cancel
          </button>
          <button ref={buttonRef} onClick={handleRename} type="button">
            Save
          </button>
        </div>
      </div>
    </SkyLight>
  );
}

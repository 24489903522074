import React, { useRef, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { BsPlay, BsDownload } from 'react-icons/bs'
import { FiTrash, FiEye, FiSend } from 'react-icons/fi'
import { FaRegCopy, FaDropbox } from 'react-icons/fa'
import { BiGitRepoForked, BiLinkAlt } from 'react-icons/bi'
import { IoPricetagOutline } from 'react-icons/io5'
import { MdDriveFileMoveOutline, MdEdit, MdLogout, MdLogin, MdOutlineAddToDrive } from 'react-icons/md'
import axios from 'axios';
import FileSaver from 'file-saver';
// import { Modal } from 'react-bootstrap';
import SkyLight from 'react-skylight';
import { emitter } from '../../utils/emitter';
import { POPUP_EVENTS } from '../../constants/events';
import CONFIG from '../../constants/config';
import { getFileName } from '../../utils/string';
import { useDispatch, useSelector } from 'react-redux';
import { checkFile, removeFile } from '../../redux/actions/files';
import { checkQuickAccess } from '../../redux/actions/quickAccess';
import { checkFileCopied } from '../../redux/actions/copiedFile';
import { checkIdLinked } from '../../redux/actions/linkFilePopup';
import { removeSelectingFiles } from '../../redux/actions/files';
import ShareFile from './ShareFile';
import GoogleAuth from '../common/GoogleAuth';
import { uploadFileOnGDrive, uploadFileOnDropbox } from '../../services/files';
import { notifyError, notifySuccess } from '../common/Alert';
import { uploadAuth } from '../../redux/actions/uploadAuth';

export default function FileContextMenu() {
  const [dylogLeft, setDylogLeft] = useState(0);
  const [dylogWidth, setDylogWidth] = useState(0);
  const [clientX, setClientX] = useState(0);
  const [clientY, setClientY] = useState(0);
  const [file, setFile] = useState(null);
  const confirmDetele = useRef(null);
  const contextMenu = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();

  const { googleLoginError, dropboxLoginError } = uploadAuth;

  const isMultipleFiles = useSelector(s => s.multiSelection.isMultipleFiles)
  const selectedFiles = useSelector(s => s.multiSelection.selectedFiles)
  const googleDrive = useSelector((s) => s.uploadAuth.googleDrive);
  const dropbox = useSelector((s) => s.uploadAuth.dropbox);

  const contextMenuStyle = {
    // height: '34rem',
    left: clientX,
    marginLeft: 0,
    marginTop: '-10%',
    minHeight: 0,
    top: clientY,
    width: '15rem',
    zIndex: 9999,
    borderRadius: 10,
  };

  const confirmDeteleStyle = {
    left: '40%',
    marginLeft: 0,
    marginTop: 0,
    top: '35%',
    width: '25%',
    // height: '15%',
    minHeight: '0',
    padding: '25px',
    borderRadius: 10,
  };
  useEffect(() => {
    const modalClss = document.getElementsByClassName('file__menu');
    if (modalClss) {
      const diylog = modalClss?.[0]?.children?.[1]
      if (diylog) {
        setDylogLeft(diylog.offsetLeft)
        setDylogWidth(diylog.offsetWidth)
      }
    }
  }, []);
  function handlePreviewFile() {
    history.push(`/file-detail/${file.file_id}`);
    dispatch(checkQuickAccess());
  }

  async function handleDownloadFile() {
    if (Array.isArray(selectedFiles) && selectedFiles.length > 0) {
      for (let i = 0; i < selectedFiles.length; i++) {
        const {
          data: { url, file_name },
        } = await axios.get(`${CONFIG.BASE_URL}/file/download/${selectedFiles[i].file_id}`, {
          withCredentials: true,
        });
        FileSaver.saveAs(url, file_name);
      }
      dispatch(removeSelectingFiles())
      contextMenu.current.hide();
    }
  }

  function showConfirmRemove() {
    confirmDetele.current.show();
    contextMenu.current.hide();
  }

  function handleRemoveFile() {
    // console.log(selectedFiles);
    if (Array.isArray(selectedFiles) && selectedFiles.length > 0) {

      for (let i = 0; i < selectedFiles.length; i++) {

        dispatch(removeFile(selectedFiles[i].file_id));
      }
      dispatch(removeSelectingFiles())
      contextMenu.current.hide();
    }
  }

  const showFileContextListener = (_file, x, y) => {
    const docW = window.innerWidth;
    var isEntirelyVisible = (dylogLeft + dylogWidth <= docW);
    setFile(_file);
    setClientX(!isEntirelyVisible ? x : x - 250);
    setClientY(y);
    if (contextMenu.current) {
      contextMenu.current.show();
    } else {
      const interval = setInterval(() => {
        if (contextMenu.current) {
          contextMenu.current.show();
          clearInterval(interval);
        }
      }, 1);
    }
  };

  const hideFileContextListener = () => contextMenu.current.hide();

  const hideConfirmDeleteListener = () => confirmDetele.current.hide();

  function handleMoveFile() {
    if (Array.isArray(selectedFiles) && selectedFiles.length > 0) {
      for (let i = 0; i < selectedFiles.length; i++) {
        emitter.emit(POPUP_EVENTS.SHOW_MOVE_FILE_OR_FOLDER, selectedFiles[i].file_id, null);
      }
      hideFileContextListener();
    }
  }

  function handleViewDetail() {
    emitter.emit(POPUP_EVENTS.SHOW_FILE_PROPERTIES, file.file_id);
    hideFileContextListener();
  }

  function listenToTheEmitter() {
    emitter.on(POPUP_EVENTS.SHOW_FILE_CONTEXT_MENU, showFileContextListener);
    emitter.on(POPUP_EVENTS.HIDE_FILE_CONTEXT_MENU, hideFileContextListener);
    emitter.on(
      POPUP_EVENTS.HIDE_CONFIRM_DELETE_FILE,
      hideConfirmDeleteListener
    );
  }

  function handleRename() {
    const name = file.new_name ? file.new_name : getFileName(file.file_name);
    emitter.emit(POPUP_EVENTS.SHOW_RENAME_POPUP, file.file_id, null, name);
    hideFileContextListener();
  }

  function handleTagFile() {
    emitter.emit(POPUP_EVENTS.SHOW_TAG_POPUP, file.file_id);
    hideFileContextListener();
  }

  function handleCheckFile() {
    if (file.checkout) {
      emitter.emit(POPUP_EVENTS.SHOW_CHECKIN, file.file_id);
    } else {
      dispatch(checkFile(file.file_id, !file.checkout, file));
    }
    hideFileContextListener();
  }

  function handleCopyFile() {
    if (Array.isArray(selectedFiles) && selectedFiles.length > 0) {
      for (let i = 0; i < selectedFiles.length; i++) {
        dispatch(checkFileCopied(selectedFiles[i].file_id));
      }
      contextMenu.current.hide();
    }
  }

  useEffect(() => {
    listenToTheEmitter();
    return () => {
      emitter.off(POPUP_EVENTS.SHOW_FILE_CONTEXT_MENU, showFileContextListener);
      emitter.off(POPUP_EVENTS.HIDE_FILE_CONTEXT_MENU, hideFileContextListener);
      emitter.off(
        POPUP_EVENTS.HIDE_CONFIRM_DELETE_FILE,
        hideConfirmDeleteListener
      );
    };
  });

  if (!file) return null;

  const uploadGDrive = async () => {
    if (googleDrive.isGoogleLogin) {
      const files = selectedFiles;
      let count = 0;
      for (let i = 0; i < files.length; i++) {
        const fileUrl = await fetch(files[i].path);
        fileUrl.blob().then((res) => {
          // console.log(res)
          let blob = res;
          blob.name = files[i]?.new_name || files[i]?.file_name;
          var formData = new FormData();
          formData.append('access_token', googleDrive.googleUser.access_token)
          formData.append('fileName', files[i]?.new_name || files[i]?.file_name)
          formData.append('file_path', blob)
          formData.append('fileType', files[i].type);
          uploadFileOnGDrive(formData)
            .then((res) => {
              console.log(res);
              if (res.success || res.status) {

                count += i;
                if (count === files.length) {

                  count = 0;
                }
                hideFileContextListener();
                dispatch(removeSelectingFiles())
                notifySuccess(res.message || res.msg);
                // dispatch(removeSelectingFiles())
                // notifySuccess(res.message || res.msg);
              } else {
                console.log(res.message)
                notifyError(res.message || res.msg);
              }
            })
            .catch(err => {
              if (err?.response?.data?.message) {
                return notifyError(err?.response?.data?.message);
              }

              localStorage.removeItem('authGDrive');
              dispatch(googleLoginError());
              console.log(err.message);
              notifyError('Please connect with Google');
            })
        })
      }
    } else {
      notifyError('Please connect to Google Drive');
    }
  }

  const uploadDropbox = async () => {
    if (dropbox.isDropboxLogin) {
      const files = selectedFiles;
      let count = 0;
      for (let i = 0; i < files.length; i++) {
        const fileUrl = await fetch(files[i].path);
        fileUrl.blob().then((res) => {
          // console.log(res)
          let blob = res;
          blob.name = files[i]?.new_name || files[i]?.file_name;
          var formData = new FormData();
          formData.append('access_token', dropbox.dropboxUser.access_token)
          formData.append('fileName', files[i]?.new_name || files[i]?.file_name)
          formData.append('file_path', blob)
          formData.append('fileType', files[i].type);
          uploadFileOnDropbox(formData)
            .then((res) => {
              if (res.success || res.status) {

                count += i;
                if (count === files.length) {
                  contextMenu.current.hide();
                  dispatch(removeSelectingFiles())
                  notifySuccess(res.message || res.msg);
                  count = 0;
                }
              } else {
                console.log(res.message)
                notifyError(res.message || res.msg);
                contextMenu.current.hide();
                dispatch(removeSelectingFiles())
              }
            })
            .catch(err => {

              if (err?.response?.data?.message) {
                return notifyError(err?.response?.data?.message);
              }
              contextMenu.current.hide();
              dispatch(removeSelectingFiles())
              localStorage.removeItem('authDropbox');
              dispatch(dropboxLoginError());
              console.log(err.message);
              notifyError('Please connect to Dropbox');
              return true;
            })
        })
      }
    } else {
      notifyError('Please connect to Dropbox');
    }
  }

  const googleDriveOption = () => {
    // if (googleDrive.isGoogleLogin) {
    return (
      <button className='bg-transparent border-0 option' onClick={uploadGDrive}>
        <span className="option-icon">
          <i><MdOutlineAddToDrive /></i>
        </span>
        <span
          className="text-icons"
          style={{
            alignSelf: 'left',
          }}
        >
          Upload to Goole Drive
        </span>
      </button>
    )
    // }

    // return (
    //   <GoogleAuth className='bg-transparent border-0 option'>

    //     <span className="option-icon">
    //       <i><MdOutlineAddToDrive /></i>
    //     </span>
    //     <span
    //       className="text-icons"
    //       style={{
    //         alignSelf: 'left',
    //       }}
    //     >
    //       Upload to Goole Drive
    //     </span>

    //   </GoogleAuth>
    // )
  }
  const dropboxOption = () => {

    return (
      <button type='button' className='bg-transparent border-0 option' onClick={uploadDropbox}>

        <span className="option-icon">
          <i><FaDropbox /></i>
        </span>
        <span
          className="text-icons"
          style={{
            alignSelf: 'left',
          }}
        >
          Upload to Dropbox
        </span>

      </button>
    )
  }

  const options = isMultipleFiles ?
    [
      {
        id: 0,
        name: 'Download',
        iconClass: BsDownload,
        onClick: handleDownloadFile,
        view: true
      },
      {
        id: 1,
        name: 'Remove',
        iconClass: FiTrash,
        onClick: showConfirmRemove,
        view: true
      },
      {
        id: 2,
        name: 'Move to',
        iconClass: MdDriveFileMoveOutline,
        onClick: handleMoveFile,
        view: true
      },
      {
        id: 3,
        name: 'Send to mail',
        iconClass: FiSend,
        onClick: () => {
          contextMenu.current.hide();
          emitter.emit(POPUP_EVENTS.SHARE_FILE);
        },
        view: true
      },
      {
        id: 4,
        name: 'Link file',
        iconClass: BiLinkAlt,
        onClick: () => {
          emitter.emit(POPUP_EVENTS.SHOW_LINK_FILES);
          dispatch(checkIdLinked(file.file_id));
          hideFileContextListener();
        },
        view: true
      },
      // {
      //   id: 3,
      //   name: 'Copy',
      //   iconClass: FaRegCopy,
      //   onClick: handleCopyFile,
      // },
    ]
    :
    [
      {
        id: 0,
        name: 'Preview',
        iconClass: BsPlay,
        onClick: handlePreviewFile,
        view: true
      },
      {
        id: 1,
        name: 'Download',
        iconClass: BsDownload,
        onClick: handleDownloadFile,
        view: true
      },
      {
        id: 2,
        name: 'Remove',
        iconClass: FiTrash,
        onClick: showConfirmRemove,
        view: true
      },
      {
        id: 3,
        name: 'Tag',
        iconClass: IoPricetagOutline,
        onClick: handleTagFile,
        view: true
      },
      {
        id: 4,
        name: 'Move to',
        iconClass: MdDriveFileMoveOutline,
        onClick: handleMoveFile,
        view: true
      },
      {
        id: 5,
        name: 'Rename',
        iconClass: MdEdit,
        onClick: handleRename,
        view: true
      },
      {
        id: 6,
        name: 'View details',
        iconClass: FiEye,
        onClick: handleViewDetail,
        view: true
      },
      {
        id: 7,
        name: 'Send to mail',
        iconClass: FiSend,
        onClick: () => {
          contextMenu.current.hide();
          emitter.emit(POPUP_EVENTS.SHARE_FILE);
        },
        view: true
      },
      {
        id: 8,
        name: 'Copy',
        iconClass: FaRegCopy,
        onClick: handleCopyFile,
        view: true
      },
      {
        id: 9,
        name: 'Version',
        iconClass: BiGitRepoForked,
        onClick: () => {
          emitter.emit(POPUP_EVENTS.VERSION, file.file_id);
          hideFileContextListener();
        },
        view: true
      },
      {
        id: 10,
        name: 'Link file',
        iconClass: BiLinkAlt,
        onClick: () => {
          emitter.emit(POPUP_EVENTS.SHOW_LINK_FILES);
          dispatch(checkIdLinked(file.file_id));
          hideFileContextListener();
        },
        view: true
      }
    ];

  return (
    <>
      <SkyLight
        hideOnOverlayClicked
        overlayStyles={{ backgroundColor: 'inherit' }}
        ref={contextMenu}
        dialogStyles={contextMenuStyle}
        className="file__menu"
      >
        <div className="context-menu">
          {options.map((o) => (
            o.view ?
              <div onClick={o.onClick} key={o.id} className="option">
                <span className="option-icon">
                  <i><o.iconClass /></i>
                </span>
                <span
                  className="text-icons"
                  style={{
                    alignSelf: 'left',
                  }}
                >
                  {o.name}
                </span>
              </div>
              :
              null
          ))}
          {googleDriveOption()}
          {dropboxOption()}
          {!isMultipleFiles ?
            <>
              <div
                style={{
                  borderBottom: '1.5px solid rgba(112,112,112,0.2)',
                  marginBottom: '0.8rem',
                  height: '.5rem',
                }}
              />
              <div onClick={handleCheckFile} className="option">
                <span className="option-icon">
                  <i>{file.checkout ? <MdLogin /> : <MdLogout />}</i>
                  {/* <i className={`ic-${file.checkout ? 'checkin' : 'checkout'}`}/> */}
                </span>
                <span
                  style={{
                    alignSelf: 'center',
                  }}
                >
                  {file.checkout ? 'Check-in' : 'Checkout'}
                </span>
              </div>
            </>
            :
            null
          }
        </div>
      </SkyLight>
      <SkyLight
        // hideOnOverlayClicked
        overlayStyles={{ backgroundColor: 'inherit' }}
        ref={confirmDetele}
        dialogStyles={confirmDeteleStyle}
      >
        <div style={{ paddingTop: '0.8em', textAlign: 'center', fontWeight: '500', fontSize: '18px' }}>
          Do you want to remove this file?
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              margin: '3.5em auto 0',
              maxWidth: '240px',
              fontSize: '15px'
            }}
          >
            <button
              onClick={() => { confirmDetele.current.hide(); dispatch(removeSelectingFiles()) }}
              type="button"
              style={{
                border: '1px solid #C2CFE0',
                backgroundColor: '#F5F6F8',
                padding: '12px 35px',
                borderRadius: '8px',
                color: '#016288'
              }}
            >
              Cancel
            </button>
            <button
              onClick={handleRemoveFile}
              type="button"
              style={{
                // border: 'none',
                backgroundColor: '#016288',
                borderRadius: '8px',
                border: '1px solid #016288',
                // width: '5em',
                marginLeft: '.5em',
                color: 'white',
                fontWeight: 500,
                padding: '12px 35px'
                // height: '2.2em',
              }}
            >
              OK
            </button>
          </div>
        </div>
      </SkyLight>
      <ShareFile fileId={file.file_id} />
    </>
  );
}

/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React from 'react';
import { useSelector } from 'react-redux';
import { PieChart, Pie, Tooltip, Cell, ResponsiveContainer } from 'recharts';

const data02 = [];

function MyCustomTooltip({ payload }) {
  return (
    <div className="recharts-tooltip">
      <b>{payload?.[0]?.payload?.name}</b>
      <p>{payload?.[0]?.payload?.value} %</p>
    </div>
  );
}

export default function Analysis() {
  const analysis = useSelector((s) => s.analysis.fileAnalysis);
  const legends = {
    document_rate: 'document',
    txt_rate: 'txt',
    image_rate: 'image',
    powerpoint_rate: 'powerpoint',
    excel_rate: 'excel',
    pdf_rate: 'pdf',
    other_rate: 'other',
  };

  const colors = [
    '#016288',
    '#0AC963',
    '#FF8000',
    '#E5252A',
    '#0263D1',
    '#E03303',
    '#E03303',
    '#FCAF18',
  ];
  const mySVG = document.querySelector('#bar-chart .recharts-surface');
  if (mySVG) {
    mySVG.setAttribute('viewBox', '90 90 220 220');
  }
  return (
    <div className='chart-card' id="bar-chart">
      <span
        className='title_chart'
      >
        Total documents by type
      </span>
      <div className='charts-pie'>
        <ResponsiveContainer width="100%" height="100%">
          {!analysis.isLoading && Object.keys(analysis?.data?.rate ?? {}).length ? (
            <PieChart className='pie-chart' width={200} height={200}>
              <Pie
                data={Object.keys(analysis.data.rate).map((k) => ({
                  name: legends[k],
                  value: analysis.data.rate[k],
                }))}
                cx="60%"
                cy="80%"
                innerRadius={50}
                outerRadius={70}
                fill="8884d8"
                dataKey="value"
              >
                {Object.keys(analysis.data.rate)
                  .map((k) => ({
                    name: legends[k],
                    value: analysis.data.rate[k],
                  }))
                  .map((entry, index) => (
                    <Cell
                      style={{ cursor: 'pointer' }}
                      // onMouseEnter={(e) => console.log(e)}
                      key={`cell-${index}`}
                      fill={colors[index % colors.length]}
                    />
                  ))}
              </Pie>
              <Tooltip content={<MyCustomTooltip />} formatter />
            </PieChart>
          ) : (
            <PieChart width={400} height={400}>
              <Pie data={[]} innerRadius={45} outerRadius={60} fill="8884d8">
                {data02.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={colors[index % colors.length]}
                  />
                ))}
              </Pie>
            </PieChart>
          )}
        </ResponsiveContainer>
      </div>
      {!analysis.isLoading && Object.keys(analysis?.data?.rate ?? {}).length ? (
        <div className="legend-chart">
          {Object.keys(analysis.data.rate).map((k, i) => (
            <div className="legend-item" key={k}>
              <div className="icon" style={{ backgroundColor: colors[i] }} />
              <div className="label">{legends[k]}</div>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
}

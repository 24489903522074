import axios from 'axios';
import CONFIG from '../constants/config';

export const updateSuperAdmin = async ({ email }) => {
  const response = await axios({
    method: 'POST',
    url: `${CONFIG.BASE_URL}/change-admin`,
    withCredentials: true,
    data: { email },
  });
  return response.data;
};

export const getManageStorage = async () => {
  const response = await axios({
    method: 'GET',
    url: `${CONFIG.BASE_URL}/manage-storage`,
    withCredentials: true,
    params: {
        offset: 0,
    },
  });
  return response.data;
};

export const getPriviliges = async () => {
  const response = await axios({
    method: 'GET',
    url: `${CONFIG.BASE_URL}/privileges`,
    withCredentials: true,
  });
  return response.data;
};

export const updatePriviliges = async ({ share_document, receive_document, publish }) => {
  const response = await axios({
    method: 'PATCH',
    url: `${CONFIG.BASE_URL}/privileges`,
    withCredentials: true,
    data: { share_document, receive_document, publish },
  });
  return response.data;
};

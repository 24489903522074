import {
  ACTION_MESSAGE_LIST, ACTION_RENDER_MY_INFO, ACTION_SOCKET_CONNECTED, ACTION_MESSAGE_HISTORY,
  ACTION_OPEN_MESSAGE, ACTION_UPDATE_MESSAGE,
} from '../actions/message';

const initState = {
  updateChatList: null,
  isMyInfo: [],
  socket: null,
  updateChat: [],
  isCommunication: null,
  isUpdateMessage: null,
};

export default function message(prevState = initState, action) {
  const { type, payload } = action;
  switch (type) {
    case ACTION_MESSAGE_LIST:
      return { ...prevState, updateChatList: payload };
    case ACTION_MESSAGE_HISTORY:
      return { ...prevState, updateChat: payload };
    case ACTION_SOCKET_CONNECTED:
      return { ...prevState, socket: payload };
    case ACTION_RENDER_MY_INFO:
      return { ...prevState, isMyInfo: payload };
    case ACTION_OPEN_MESSAGE:
      return { ...prevState, isCommunication: payload };
    case ACTION_UPDATE_MESSAGE:
      return { ...prevState, isUpdateMessage: payload };
    default:
      return prevState;
  }
};

import { all } from 'redux-saga/effects';
import analysisWatcher from './analysis';
import authenticationWatcher from './authentication';
import documentsWatcher from './documents';
import filesWatcher from './files';
import foldersWatcher from './folders';
import notificationsWatcher from './notifications';
import quickAccessWatcher from './quickAccess';
import remindersWatcher from './reminders';
import reportsWatcher from './reports';
import tasksWatcher from './tasks';
import usageWatcher from './usage';
import chatWatcher from './chat';
import allFolderWatcher from './allFolders'

function *rootSaga() {
  yield all([
    analysisWatcher(),
    authenticationWatcher(),
    documentsWatcher(),
    filesWatcher(),
    foldersWatcher(),
    allFolderWatcher(),
    notificationsWatcher(),
    quickAccessWatcher(),
    remindersWatcher(),
    usageWatcher(),
    tasksWatcher(),
    reportsWatcher(),
    chatWatcher(),
  ]);
}

export default rootSaga;

import React, { useRef, useEffect, useState } from 'react';
import axios from 'axios';
import SkyLight from 'react-skylight';
import { AiOutlineInfo } from 'react-icons/ai';
import { MdClear } from 'react-icons/md';
import { emitter } from '../../utils/emitter';
// import { formatDate } from '../../utils/datetime';
import { getFileName, bytesToSize } from '../../utils/string';
import { notifyError, notifySuccess } from '../common/Alert';
import { POPUP_EVENTS } from '../../constants/events';
import CONFIG from '../../constants/config';
import moment from 'moment'

export default function DetailPopup() {
  const popupRef = useRef(null);
  const [detail, setDetail] = useState({});
  const [isVisible, setIsVisible] = useState(false);

  const newFolderPopupStyle = {
    // width: '30%',
    // height: '40%',
    minHeight: 0,
    marginTop:'0',
    marginLeft: '0%',
    borderRadius: '10px',
  };

  useEffect(() => {
    const detailFile = (fileId) => {
      axios
        .get(`${CONFIG.BASE_URL}/file/${fileId}`, { withCredentials: true })
        .then(({ data }) => {
          notifySuccess(data.msg);
          setDetail(data.document);
        })
        .catch((err) => {
          notifyError(err.response.data.msg);
        });
    };

    const detailFolder = (folderId) => {
      axios
        .get(`${CONFIG.BASE_URL}/folder_detail/${folderId}`, {
          withCredentials: true,
        })
        .then(({ data }) => {
          notifySuccess(data.msg);
          setDetail(data.folder);
        })
        .catch((err) => {
          notifyError(err.response.data.msg);
        });
    };
    const handleDetail = (fileId, folderId) => {
      if (fileId) {
        detailFile(fileId);
      } else if (folderId) {
        detailFolder(folderId);
      }
    };
    const showPopup = (fileIdParam, folderIdParam) => {
      if (popupRef.current) {
        popupRef.current.show();
        setIsVisible(true)
      }
      handleDetail(fileIdParam, folderIdParam);
    };
    function listenToTheEmitter() {
      emitter.on(POPUP_EVENTS.SHOW_DETAIL_FILE_OR_FOLDER, showPopup);
    }
    listenToTheEmitter();
    return () => {
      emitter.off(POPUP_EVENTS.SHOW_DETAIL_FILE_OR_FOLDER, showPopup);
    };
  }, []);

  return (
    <SkyLight
      dialogStyles={newFolderPopupStyle}
      className={`newDemoModal ${isVisible ? 'show' : ''}`}
      ref={popupRef}
      hideOnOverlayClicked
      afterClose={() => { setDetail({}); setIsVisible(false) }}
    >
      <div className="detailPopup">
        <div className='file-properties__title'
          // style={{
          //   fontSize: '2rem',
          //   fontWeight: 'bold',
          //   marginBottom: '2rem',
          // }}
        >
          <span><i className='icon-info'><AiOutlineInfo /></i>Details</span>
          <i className='close-modal-icon' onClick={() => {popupRef.current.hide(); setIsVisible(false)}}><MdClear /></i>
          
        </div>
        <div className='file-properties__container' style={{ display: 'flex' }}>
          {detail.file_id ? (
            <table style={{ width: '100%' }}>
              <tbody>
                <tr>
                  <td style={{ width: '40%' }}>File Name</td>
                  <td style={{ width: '60%' }}>
                    {detail.new_name
                      ? detail.new_name
                      : getFileName(detail.file_name)}
                  </td>
                </tr>
                <tr>
                  <td style={{ width: '40%' }}>Owners</td>
                  <td style={{ width: '60%' }}>Only you</td>
                </tr>
                <tr>
                  <td style={{ width: '40%' }}>Size</td>
                  <td style={{ width: '60%' }}>{bytesToSize(detail.size)}</td>
                </tr>
                <tr>
                  <td style={{ width: '40%' }}>Type</td>
                  <td style={{ width: '60%' }}>{detail.type}</td>
                </tr>
                <tr>
                  <td style={{ width: '40%' }}>Tags</td>
                  <td style={{ width: '60%' }}>{detail.tags}</td>
                </tr>
                <tr>
                  <td style={{ width: '40%' }}>Created Time</td>
                  <td style={{ width: '60%' }}>
                    {moment(detail.created_at).format('MMM DD, YYYY')}
                  </td>
                </tr>
                <tr>
                  <td style={{ width: '40%' }}>Recently Accessed</td>
                  <td style={{ width: '60%' }}>
                    {moment(detail.updated_at).format('MMM DD, YYYY')}
                  </td>
                </tr>
              </tbody>
            </table>
          ) : null}
          {detail.folder_id ? (
            <table style={{ width: '100%' }}>
              <tbody>
                <tr>
                  <td style={{ width: '40%' }}>Folder Name</td>
                  <td style={{ width: '60%' }}>{detail.folder_name}</td>
                </tr>
                <tr>
                  <td style={{ width: '40%' }}>Owners</td>
                  <td style={{ width: '60%' }}>Only you</td>
                </tr>
                <tr>
                  <td style={{ width: '40%' }}>Created Time</td>
                  <td style={{ width: '60%' }}>
                    {moment(detail.created_at).format('MMM DD, YYYY')}
                  </td>
                </tr>
              </tbody>
            </table>
          ) : null}
        </div>
      </div>
    </SkyLight>
  );
}

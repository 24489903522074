import React, { useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
// import SkyLight from 'react-skylight';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { updateUserPlanModal } from '../../../redux/actions/userInfo';

function UpgradePlanModal(props) {

    // const popupRef = useRef(null);

    // const newFolderPopupStyle = {
    //     minHeight: 0,
    //     width: '40%',
    //     left: '45%',
    //     marginLeft: '-15%',
    //     borderRadius: '10px',
    //     padding: 0,
    // };

    const rediractClick = () => {
        props.history.push('/user')
    }

    return (
        <Modal show={props.upgradeModal} onHide={() => props.modalToggle(false)} size={'md'} centered>
            <Modal.Body className="p-5">
                <p style={{ fontSize: '1.8rem' }}>You have subscribed to the <b style={{ fontSize: '1.8rem' }}>Basic</b> edition of Docquik.  This feature is not available in this version</p>
                <p style={{ marginBottom: '50px', fontSize: '1.8rem' }}>You need to upgrade to a higher edition to activate and use this feature.  To upgrade, kindly click on the Manage Subscription button</p>
                <button
                    style={{ backgroundColor: 'rgb(83, 76, 221)', paddingLeft: '2rem', paddingRight: '2rem', borderRadius: '30px', color: 'white', height: '4rem', borderWidth: '0px' }}
                    type="button"
                    onClick={rediractClick}
                >
                    Upgrade Plan
                </button>
                <button
                    type="button"
                    style={{ backgroundColor: 'rgb(83, 76, 221)', paddingLeft: '2rem', paddingRight: '2rem', borderRadius: '30px', color: '#212121', height: '4rem', borderWidth: '0px', fontSize: '16px', lineHeight: '1' }}
                    className="btn bg-transparent border-0 fs-4 text-danger" onClick={() => props.modalToggle(false)}>Cancel</button>
            </Modal.Body>
        </Modal>
    )
}

const mapStateToProps = (state) => ({
    upgradeModal: state.userInfo.upgradeModal
});

const mapDispatchToProps = dispatch => ({
    modalToggle: (bool) => dispatch(updateUserPlanModal(bool))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UpgradePlanModal))
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { withRouter, useHistory, useLocation, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { MdUploadFile, MdOutlineCreateNewFolder, MdKeyboardBackspace, MdFolder, MdClear } from 'react-icons/md'
import { BsFiles } from 'react-icons/bs'
import { Tabs, Tab, Row, Col, Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { emitter } from '../utils/emitter';
import { GET_USAGE } from '../redux/actions/usage';
import { POPUP_EVENTS, HOME_EVENTS } from '../constants/events';
import CreateFolderPopup from '../components/Home/CreateFolderPopup';
import DetailPopup from '../components/Home/DetailPopup';
import FileContextMenu from '../components/Home/FileContextMenu';
import FolderContextMenu from '../components/Home/FolderContextMenu';
import FolderDetail from '../components/Home/FolderDetail';
import MovePopup from '../components/Home/MovePopup';
import Activites from '../components/Home/Activites';
// import FilesComponent from '../components/FilesComponent';
import { Scrollbars } from 'react-custom-scrollbars';
import * as scrollbar from '../helpers/scrollbar';
import ChatModule from '../components/Home/ChatModule';
import PinBar from '../components/PinBar';
import { openPinbar } from '../redux/actions/pinbar';
import SearchResult from '../components/Home/SearchResult';
import SearchForm from '../components/Home/SearchForm';
// import FavoritesComponent from '../components/Home/FavoritesComponent';
// import RecentlyUpload from '../components/Home/RecentlyUpload';
// import RecentlyAccess from '../components/Home/RecentlyAccess';
// import Checkout from '../components/Home/Checkout';
import UploadPopup from '../components/Home/UploadPopup';
import RenamePopup from '../components/Home/RenamePopup';
import TagPopup from '../components/Home/TagPopup';
import CheckoutPopup from '../components/Home/CheckoutPopup';
import CONFIG from '../constants/config';
import noFile from '../assets/images/nofile.png';
import {
    checkDocs,
    checkDocsHidden,
    moveDocs,
    getAllDocs
} from '../redux/actions/documents';
import {
    checkQuickAccess,
    checkQuickAccessHidden,
} from '../redux/actions/quickAccess';
import CheckinPopup from '../components/Home/CheckinPopup';
import { pasteFileCopied } from '../redux/actions/copiedFile';
import FileProperties from '../components/Home/FileProperties';
import { checkPath, checkPathBack } from '../redux/actions/path';
import FolderAreaContextMenu from '../components/Home/FolderAreaContextMenu';
import LinkFiles from '../components/Home/LinkFiles';
import LinkFilesMinimize from '../components/Home/LinkFilesMinimize';
import SkyLight from 'react-skylight';
import { checkOrgPlan } from '../services/authentication';
import { notifyError } from '../components/common/Alert';
import { logout } from '../redux/actions';
import VersionPopup from '../components/Home/VersionPopup';
import Dashboard from '../components/Home/Dashboard';
// import TaskTabs from '../components/Home/TaskTabs';
import { activitiesBarToggle } from '../redux/actions/layout';
import { multipleSelectingFiles, singleSelectingFiles, removeSelectingFiles } from '../redux/actions/files'

const pwdPopupStyle = {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '40%',
    margin: 'auto',
    height: 'fit-content',
    minHeight: '',
    borderRadius: '10px',
};

function Home(props) {
    const { match } = props;
    const history = useHistory();
    const dispatch = useDispatch();
    const pwdPopup = useRef(null);
    const location = useLocation();
    const params = useParams();
    const folder_id = params.id ? params.id : null;
    // console.log(folder_id)
    // const folder_id = match.params.id ? match.params.id : null;
    const [firstId, setFirstId] = useState(null);
    // const [quickAccessTabIndex, setQuickAccessTabIndex] = useState(0);
    const [isSearching, setIsSearching] = useState(false);
    const [searched, setSearched] = useState(false);
    const [searchInputFocus, setSearchInputFocus] = useState(false);
    const [searchResults, setSearchResults] = useState([]);
    const { docsChecked: documentR, isLoading } = useSelector((s) => s.documents);
    const selectedFiles = useSelector((s) => s.multiSelection.selectedFiles)
    const copiedFile = useSelector((s) => s.copiedFile);
    const files = useSelector((s) => s.files);
    const folders = useSelector((s) => s.folders);
    const path = useSelector((s) => s.path);
    const userInfo = useSelector((s) => s.userInfo);
    const layout = useSelector((s) => s.layout);
    // const {
    //     upload: recentlyUpload,
    //     accessed: recentlyAccessed,
    //     favorite: recentlyFavorite,
    //     checkout: recentlyCheckout,
    //     // isLoading,
    // } = useSelector((s) => s.quickAccess);

    const acceptMimeTypes = Object.values(CONFIG.ACCEPT_MIME_TYPES)
        .map((arr) => arr.join(','))
        .join(',');

    const pinbarOpen = () => {
        dispatch(openPinbar())
    }

    function allowDrop(event) {
        event.preventDefault();
    }

    const handleFileContextMenu = (e, file) => {
        e.preventDefault();
        let { clientY } = e;
        if (clientY + 335 >= window.innerHeight) {
            clientY = window.innerHeight - 335;
        }
        emitter.emit(POPUP_EVENTS.SHOW_FILE_CONTEXT_MENU, file, e.clientX, clientY);
    };

    const handleFolderContextMenu = (id, name, e) => {
        e.preventDefault();
        const { clientX } = e;
        let { clientY } = e;
        if (clientY + 300 >= window.innerHeight) {
            clientY = window.innerHeight - 300;
        }
        emitter.emit(
            POPUP_EVENTS.SHOW_FOLDER_CONTEXT_MENU,
            id,
            name,
            clientX,
            clientY
        );
    };

    function dragStart(event) {
        setFirstId(event.currentTarget.dataset.id);
    }

    function drop(event) {
        const [first_Type, first_Id] = firstId.split('-');
        const [first_Type1, first_Id1] = event.currentTarget.dataset.id.split('-');
        if (first_Type1 === 'fo') {
            if (first_Type === 'fi') {
                dispatch(moveDocs(first_Id, null, first_Id1));
            } else if (first_Type === 'fo') {
                dispatch(moveDocs(null, first_Id, first_Id1));
            }
        }
        event.preventDefault();
    }

    function dropHandler(ev) {
        emitter.emit(HOME_EVENTS.DROP_FILE_OR_FOLDER, ev);
    }
    function dragOverHandler(ev) {
        ev.preventDefault();
    }

    const handleGetUsage = useCallback(() => dispatch({ type: GET_USAGE }), [
        dispatch,
    ]);

    const showCreateFolderPopup = () => emitter.emit(POPUP_EVENTS.SHOW_CREATE_FOLDER, folder_id);

    const getMyDocs = useCallback(() => {
        handleGetUsage();
        if (documentR.includes(folder_id)) {
            // dont recall api
        } else {
            dispatch(checkDocs(folder_id));
            dispatch(getAllDocs());
            if (!folder_id) {
                dispatch(checkQuickAccess());
            }
        }
    }, [dispatch, documentR, folder_id, handleGetUsage]);

    function handleClick(id, name) {
        dispatch(checkQuickAccess());
        dispatch(checkPath({ id, name }));
        history.push(`/home/folder-detail/${id}`);
    }
    const handleClickPath = (id, name) => {
        if (id === 0) {
            dispatch(checkPath({ id: 0, name: 'Root' }));
            history.push('/home');
        } else if (path.findIndex((i) => i.id === id) === path.length - 1) {
            return false;
        } else {
            dispatch(checkPath({ id, name }));
            history.push(`/home/folder-detail/${id}`);
        }
    };

    useEffect(() => {
        checkOrgPlan()
            .then(() => { })
            .catch((err) => {
                if (err.response.data && err.response.data.status_code === 402) {
                    notifyError(err.response.data.data);
                    history.push('/user');
                } else if (err.response.data && err.response.data.status_code === 410) {
                    notifyError(err.response.data.data);
                    dispatch(logout());
                }
            });
        const interval = setInterval(() => {
            dispatch(checkDocsHidden(folder_id));
            if (!folder_id && folder_id === undefined) {
                console.log('if')
                dispatch(checkQuickAccessHidden());
            }
        }, 30000);
        return () => {
            clearInterval(interval);
        };

    }, []);

    useEffect(() => {
        getMyDocs();
    }, [getMyDocs]);

    useEffect(() => {
        if (userInfo.pwd_validity_period <= 7 && pwdPopup.current) {
            pwdPopup.current.show();
        }
    }, [userInfo]);

    const handlerSelectMultiple = (file) => {
        if (window.event.ctrlKey) {
            dispatch(multipleSelectingFiles(file))
        } else {
            dispatch(singleSelectingFiles(file))
        }
    }

    function handleUploadFile(e) {
        emitter.emit(HOME_EVENTS.HANLDE_UPLOAD_FILE, e);
    }

    function handleUploadFiles(e) {
        emitter.emit(HOME_EVENTS.HANLDE_UPLOAD_FILES, e);
    }

    const propmtNewFolderName = () => {
        showCreateFolderPopup();
    };

    function handleCreateFolders(e) {
        e.preventDefault();
        const { clientX } = e;
        let { clientY } = e;
        if (clientY + 300 >= window.innerHeight) {
            clientY = window.innerHeight - 300;
        }
        emitter.emit(POPUP_EVENTS.SHOW_FOLDER_AREA_CONTEXT_MENU, clientX, clientY);
    }

    function handlePasteCopiedFile() {
        if (copiedFile > 0) {
            dispatch(pasteFileCopied(copiedFile, folder_id));
        }
    }
    const btnId = document.getElementById('dropdown-upload');
    if (btnId) {
        btnId.className = "";
        btnId.className = "dropdown-toggle btn-uploads";
        // btnId.classList.remove('.dropdown-toggle .btn .btn-primary')
    }

    // function closeDropdownUpload() {
    // const btnClassDropWrap = document.querySelector('.dropdown-wraps');
    // const btnClassDrop = document.querySelector('.uploadBtn-menu');
    // const btnClass = document.querySelector('.btn-uploads');
    // btnClassDropWrap.classList.remove('show');
    // btnClassDrop.classList.remove('show');
    // btnClass.setAttribute('aria-expanded', false);
    // }

    const handleGoBack = () => {
        dispatch(checkPathBack());
        history.goBack();
    };

    const clearSearch = () => {
        setIsSearching(false)
        setSearchInputFocus(false)
        setSearchResults([]);
        setSearched(false);
    }

    return (
        <div className="d-flex d-xs-block">

            <section
                className={`main-section home-page-section content ${layout.isMobileView ? 'is-mobile' : ''}`}
                id="main"
                onDrop={dropHandler}
                onDragOver={dragOverHandler}
                tabIndex="-1"
                onKeyDown={(e) => {
                    if (e.key === 'v' && e.ctrlKey) {
                        handlePasteCopiedFile();
                    }
                }}
            // onLoad={resizeSidebar}
            >
                <Scrollbars
                    renderView={scrollbar.renderView}
                    renderTrackHorizontal={scrollbar.renderTrackHorizontal}
                    renderTrackVertical={scrollbar.renderTrackVertical}
                    renderThumbHorizontal={scrollbar.renderThumb}
                    renderThumbVertical={scrollbar.renderThumb}
                    universal={true}
                >
                    <div className={`page-top-header ${searched ? 'mw-75' : ''}`}>
                        {!searchInputFocus && <h1 className="page-title">My Documents</h1>}
                        <SearchForm
                            searchInputFocus={searchInputFocus}
                            setSearched={setSearched}
                            setIsSearching={setIsSearching}
                            searched={searched}
                            setSearchResults={setSearchResults}
                            setSearchInputFocus={setSearchInputFocus}
                        />
                        {searched ?
                            <button type="button" className={!layout.isMobileView ? 'dropdown-toggle btn-uploads' : 'filter-btn'} style={{ background: '#FCAF18', margin: '0' }} onClick={clearSearch}>{!layout.isMobileView ? 'Clear Search' : <span style={{ fontSize: '18px', lineHeight: '0', color: "#FFF" }}><MdClear /></span>}</button>
                            :
                            !layout.isMobileView && <Dropdown className="dropdown-wraps">
                                <Dropdown.Toggle id="dropdown-upload" className="dropdown-toggle btn-uploads">
                                    {/* <button type="button"  className="dropdown-toggle btn-uploads"> */}
                                    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_118_1284)">
                                            <path d="M11.4286 5.42857H7.78571C7.66738 5.42857 7.57143 5.33262 7.57143 5.21429V1.57143C7.57143 0.979736 7.09169 0.5 6.5 0.5C5.90831 0.5 5.42857 0.979736 5.42857 1.57143V5.21429C5.42857 5.33262 5.33262 5.42857 5.21429 5.42857H1.57143C0.979736 5.42857 0.5 5.90831 0.5 6.5C0.5 7.09169 0.979736 7.57143 1.57143 7.57143H5.21429C5.33262 7.57143 5.42857 7.66738 5.42857 7.78571V11.4286C5.42857 12.0203 5.90831 12.5 6.5 12.5C7.09169 12.5 7.57143 12.0203 7.57143 11.4286V7.78571C7.57143 7.66738 7.66738 7.57143 7.78571 7.57143H11.4286C12.0203 7.57143 12.5 7.09169 12.5 6.5C12.5 5.90831 12.0203 5.42857 11.4286 5.42857Z" fill="white" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_118_1284">
                                                <rect width="12" height="12" fill="white" transform="translate(0.5 0.5)" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    Upload File
                                    {/* </button> */}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className='uploadBtn-menu'>
                                    <Dropdown.Item as="button" className="uploadBtn">
                                        {/* <button type="button" className="uploadBtn" onClick={closeDropdownUpload}> */}
                                        <i className='icon-upload'><MdUploadFile /></i>
                                        Upload file
                                        <input
                                            type="file"
                                            name="myfile"
                                            id="uploadfile"
                                            onChange={handleUploadFile}
                                            accept={acceptMimeTypes}
                                        />
                                        {/* </button> */}
                                    </Dropdown.Item>
                                    <Dropdown.Item as="button" className="uploadBtn">
                                        {/* <button type="button" className="" onClick={closeDropdownUpload}> */}
                                        <i className='icon-upload'><BsFiles /></i>
                                        Upload files
                                        <input
                                            type="file"
                                            name="files[]"
                                            id="uploadfile"
                                            multiple="multiple"
                                            onChange={handleUploadFiles}
                                            accept={acceptMimeTypes}
                                        />
                                        {/* </button> */}
                                    </Dropdown.Item>
                                    <Dropdown.Item as="button" className="uploadBtn" onClick={propmtNewFolderName}>
                                        {/* <button type="button" className='uploadBtn' onClick={propmtNewFolderName}> */}
                                        <i className='icon-upload'><MdOutlineCreateNewFolder /></i>
                                        Create folder
                                        {/* </button> */}
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        }
                    </div>
                    {
                        searched ?
                            <SearchResult
                                searchResults={searchResults}
                                isSearching={isSearching}
                                handleFileContextMenu={handleFileContextMenu}
                            />
                            :
                            null
                    }
                    {
                        isLoading ? (
                            <div
                                className="main-body no-file text-center"
                                onDrop={dropHandler}
                                onDragOver={dragOverHandler}

                            >
                                <img alt="" src={noFile} />
                                <p>Your documents are loading...</p>
                            </div>
                        ) : null
                    }
                    {
                        !folders.filter((f) => f.parent_id === folder_id).length &&
                            !files.filter((f) => f.parent_id === folder_id).length &&
                            !searched ? (
                            <div
                                className={`main-body no-file page-bottom-header tab-panel-custom`}
                                tabIndex="-1"
                                onKeyDown={(e) => {
                                    if (e.key === 'v' && e.ctrlKey) {
                                        handlePasteCopiedFile();
                                    }
                                }}
                                onContextMenu={(e) => {
                                    if (
                                        ['main-body no-file mt-3', 'main-body no-file '].includes(
                                            e.target.className
                                        ) ||
                                        ['DIV', 'IMG', 'P'].includes(e.target.tagName)
                                    ) {
                                        handleCreateFolders(e);
                                    }
                                }}
                                onDrop={dropHandler}
                                onDragOver={dragOverHandler}
                            >
                                {path.length > 1 && folder_id ? (
                                    <div className="mainNav back-mainNav mb-3">
                                        <i
                                            className="icon-arrow-left"
                                            onClick={handleGoBack}
                                        >
                                            <MdKeyboardBackspace />
                                        </i>
                                        <div className="path">
                                            <i className="icon-folder"><MdFolder /></i>
                                            &nbsp;
                                            {path.map((i) => (
                                                <span
                                                    key={i.id}
                                                    onClick={() => handleClickPath(i.id, i.name)}
                                                >
                                                    {i.id ? '/' : ''}&nbsp;{i.name}&nbsp;
                                                </span>
                                            ))}
                                        </div>
                                    </div>
                                ) :
                                    ''
                                }
                                {!isLoading ?
                                    <>
                                        <img src={noFile} alt="" />
                                        <p>There are no documents.</p>
                                        <p>
                                            <strong>Drop files</strong> anywhere to uploador{' '}
                                            <strong>Upload files.</strong>
                                        </p>
                                    </>
                                    :
                                    null
                                }
                            </div>
                        ) : null
                    }

                    {
                        match.url.split('/')[2] === 'folder-detail' &&
                            !searched &&
                            !isLoading &&
                            (folders.filter((f) => f.parent_id === folder_id).length ||
                                files.filter((f) => f.parent_id === folder_id).length) ? (
                            <FolderDetail
                                handleFileContextMenu={handleFileContextMenu}
                                handlePasteCopiedFile={handlePasteCopiedFile}
                                handleClick={handleClick}
                                handleClickPath={handleClickPath}
                                handleDrop={dropHandler}
                                handleDragOver={dragOverHandler}
                                handleFolderContextMenu={handleFolderContextMenu}
                                handleCreateFolders={handleCreateFolders}
                                drop={drop}
                                allowDrop={allowDrop}
                                dragStart={dragStart}
                                files={files.filter((f) => f.parent_id === folder_id)}
                                folders={folders.filter((f) => f.parent_id === folder_id)}
                                onSelect={handlerSelectMultiple}
                            />
                        ) : null
                    }
                    {
                        match.url.split('/')[2] !== 'folder-detail' &&
                            !searched &&
                            !isLoading &&
                            (folders.filter((f) => f.parent_id === folder_id).length ||
                                files.filter((f) => f.parent_id === folder_id).length) ?
                            <div className="page-bottom-header main-body">
                                <Dashboard
                                    handleFileContextMenu={handleFileContextMenu}
                                    handlePasteCopiedFile={handlePasteCopiedFile}
                                    handleClick={handleClick}
                                    handleDrop={dropHandler}
                                    handleFolderContextMenu={handleFolderContextMenu}
                                    handleCreateFolders={handleCreateFolders}
                                    handleDragOver={dragOverHandler}
                                    drop={drop}
                                    allowDrop={allowDrop}
                                    dragStart={dragStart}
                                    files={files.filter((f) => f.parent_id === folder_id)}
                                    folders={folders.filter((f) => f.parent_id === folder_id)}
                                    onSelect={handlerSelectMultiple}
                                    selectedFiles={selectedFiles}
                                />
                                <LinkFilesMinimize />
                            </div>
                            :
                            null
                    }
                </Scrollbars >
            </section >
            {!searched ? !layout.isMobileView ? <hr className="hr-verticle" /> : null : null}
            {
                !searched && <>
                    <div onClick={() => { layout.isActivitiesSidebar && dispatch(activitiesBarToggle()); }} className={`overlay-wrap ${layout.isMobileView && layout.isActivitiesSidebar ? 'is_active' : ''}`}></div>
                    <section className={`right-side-section ${layout.isMobileView ? 'is-mobile' : ""} ${layout.isActivitiesSidebar ? 'is_open' : ''}`}>
                        <Scrollbars
                            renderView={scrollbar.renderView}
                            renderTrackHorizontal={scrollbar.renderTrackHorizontal}
                            renderTrackVertical={scrollbar.renderTrackVertical}
                            renderThumbHorizontal={scrollbar.renderThumb}
                            renderThumbVertical={scrollbar.renderThumb}
                            universal={true}
                        >
                            <Activites />
                            <ChatModule />
                        </Scrollbars>
                    </section>
                </>
            }
            <MovePopup />
            <UploadPopup folder_id={params.id} />
            <CreateFolderPopup />
            <FileContextMenu />
            <FolderContextMenu />
            <RenamePopup />
            <TagPopup />
            <DetailPopup />
            <CheckoutPopup />
            <VersionPopup />
            <CheckinPopup
                dropHandler={dropHandler}
                dragOverHandler={dragOverHandler}
            />
            <FileProperties />
            <FolderAreaContextMenu />
            <LinkFiles />
            <SkyLight
                hideOnOverlayClicked
                ref={pwdPopup}
                dialogStyles={pwdPopupStyle}
            >
                <div className="w-100" style={{ paddingTop: '0.5em' }}>
                    Your password will expire in {userInfo.pwd_validity_period}{' '}
                    days.&nbsp;
                    <span
                        style={{
                            color: '#534cdd',
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            pwdPopup.current.hide();
                            setTimeout(() => {
                                history.push('/user');
                            }, 100);
                        }}
                    >
                        Change your password
                    </span>
                    &nbsp; now.
                </div>
            </SkyLight>
            <PinBar />
        </div >
    )
}

Home.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.object.isRequired,
        url: PropTypes.string.isRequired,
    }).isRequired,
};

export default withRouter(Home);
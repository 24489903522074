import React from 'react';
import Select, { components } from 'react-select';
import PropTypes from 'prop-types';
const Option = (props) => (
  <components.Option {...props}>
    <div className="list-select-member-wrap">
      <div className='memeber-image-wrap'>
        {props.data.avatar_url ? (
          <img
            alt=""
            src={props.data.avatar_url}
          />
        ) : (
          <span className='m-name'>
            {props.data.first_name[0]}{props.data.last_name[0]}
          </span>
        )}
      </div>
      <div className='name-of-mamber-wrap'>
        <p>
          {props.data.first_name} {props.data.last_name}
        </p>
        {/* {props.data?.email && <div>{props.data?.email}</div>} */}
      </div>
    </div>
  </components.Option>
);
Option.propTypes = {
  data: PropTypes.object.isRequired,
};

const Control = (props) => <components.Control {...props} />;

const ClearIndicator = (props) =>
  <components.ClearIndicator className="clear-button" {...props} />;
export default function SelectMultiMembers({ data, callback, placeholder, existMembers }) {
  const handleChange = (value) => {
    callback(value);
  };
  return (
    <Select
      closeMenuOnSelect
      components={{ Option, Control, ClearIndicator }}
      onChange={(value) => handleChange(value)}
      placeholder={placeholder}
      defaultValue={existMembers}
      isClearable
      styles={{
        control: (base, { isFocused, hasValue }) => ({
          ...base,
          border: 'none',
          borderRadius: 'none',
          borderBottom:
            isFocused || hasValue ? '1px solid #016288 ' : '2px solid #cccccc',
          height: '100%',
          boxShadow: 'none',
          '&:hover': {
            borderColor: '#016288 ',
            borderWidth: '1px',
          },
        }),
        container: (base) => ({
          ...base,
          width: '100%',
        }),
      }}
      options={data}
      isMulti
      getOptionValue={(opt) => opt.user_id}
      getOptionLabel={(opt) => `${opt.first_name} ${opt.last_name}`}
    />
  );
}
SelectMultiMembers.propTypes = {
  data: PropTypes.array.isRequired,
  callback: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  existMembers: PropTypes.array.isRequired,
};

import { all, call, put, takeEvery } from 'redux-saga/effects';
import {
    GET_ALL_FOLDERS_DOCS_SUCCESS,
    GET_ALL_FOLDERS_DOCS,
} from '../redux/actions/documents';
import {
    getDocsRootFolder
  } from '../services/documents';

function* checkAllDocsSaga(action) {
    // const usage = useSelector(s => s.usage)

    try {

        const result = yield call(getDocsRootFolder);
        if (result.success) {
            const files = result.files;
            yield put({
                type: GET_ALL_FOLDERS_DOCS_SUCCESS,
                payload: {
                    // docChecked: action.payload,
                    // folders: result.folders,
                    // files: result.files,
                    allFolders: result.folders
                },
            });
        }

    } catch (err) {
        console.log(err);
    }
}

export default function* allFolderWatcher() {
    yield all([
      takeEvery(GET_ALL_FOLDERS_DOCS, checkAllDocsSaga)
    ]);
  }
import { all, call, put, takeEvery } from 'redux-saga/effects';
import {
  CREATE_REPORT,
  CREATE_REPORT_FAILURE,
  CREATE_REPORT_SUCCESS,
  EDIT_REPORT,
  EDIT_REPORT_FAILURE,
  EDIT_REPORT_SUCCESS,
  GET_REPORTS_BY_TASK_ID,
  GET_REPORTS_BY_TASK_ID_SUCCESS,
} from '../redux/actions/reports';
import { createReport, editReport, getReports } from '../services/project';
import { notifyError, notifySuccess } from '../components/common/Alert';
import { emitter } from '../utils/emitter';
import { POPUP_EVENTS } from '../constants/events';
function *createReportSaga({ payload }) {
  try {
    const { msg } = yield call(createReport, payload);
    const { taskId, jobWorked: job_worked, nextTarget: next_target } = payload;
    const report = {
      job_worked,
      next_target,
      created_at: Date.now(),
    };
    yield put({
      type: CREATE_REPORT_SUCCESS,
      payload: { taskId, report },
    });
    emitter.emit(POPUP_EVENTS.HIDE_REPORT);
    notifySuccess(msg);
  } catch (err) {
    yield put({
      type: CREATE_REPORT_FAILURE,
    });
    notifyError(err.response?.data?.msg);
    console.log(err);
  }
}
function *editReportSaga({ payload }) {
  try {
    const { msg } = yield call(editReport, payload);
    const { taskId, jobWorked: job_worked, nextTarget: next_target } = payload;
    const report = {
      job_worked,
      next_target,
      created_at: Date.now(),
    };
    yield put({
      type: EDIT_REPORT_SUCCESS,
      payload: { taskId, report },
    });
    emitter.emit(POPUP_EVENTS.HIDE_REPORT);
    notifySuccess(msg);
  } catch (err) {
    yield put({
      type: EDIT_REPORT_FAILURE,
    });
    notifyError(err.response?.data?.msg);
    console.log(err);
  }
}
function *getReportsByTaskIdSaga({ payload }) {
  try {
    const { success, reports } = yield call(getReports, payload);
    if (success) {
      yield put({
        type: GET_REPORTS_BY_TASK_ID_SUCCESS,
        payload: {
          reports,
          taskId: payload.taskId,
        },
      });
    }
  } catch (err) {
    console.log(err);
  }
}

export default function *reportsWatcher() {
  yield all([
    takeEvery(CREATE_REPORT, createReportSaga),
    takeEvery(EDIT_REPORT, editReportSaga),
    takeEvery(GET_REPORTS_BY_TASK_ID, getReportsByTaskIdSaga),
  ]);
}

import React from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import Logo from '../../assets/images/Logo.svg'
import { MdMenu, MdMoreVert, MdClear, MdUploadFile, MdOutlineCreateNewFolder } from 'react-icons/md'
import { BsFiles } from 'react-icons/bs'
import { POPUP_EVENTS, HOME_EVENTS } from '../../constants/events';
import { emitter } from '../../utils/emitter';
import CONFIG from '../../constants/config';
import { mainNavToggle, activitiesBarToggle } from '../../redux/actions/layout';
import PlusIcon from '../../assets/images/plus-icon.svg';

function MobileHeader({ match }) {

    const dispatch = useDispatch();
    const layout = useSelector(s => s.layout);

    const folder_id = match.params.id ? match.params.id : null;
    const urlPath = match.path

    const acceptMimeTypes = Object.values(CONFIG.ACCEPT_MIME_TYPES)
        .map((arr) => arr.join(','))
        .join(',');

    function handleUploadFile(e) {
        emitter.emit(HOME_EVENTS.HANLDE_UPLOAD_FILE, e);
    }

    function handleUploadFiles(e) {
        emitter.emit(HOME_EVENTS.HANLDE_UPLOAD_FILES, e);
    }

    const propmtNewFolderName = () => {
        showCreateFolderPopup();
    };

    const showCreateFolderPopup = () => emitter.emit(POPUP_EVENTS.SHOW_CREATE_FOLDER, folder_id);

    function mainNavHandler() {
        dispatch(mainNavToggle())
    }
    function activitiesBarClick() {
        dispatch(activitiesBarToggle())
    }

    const quickCreateReminder = () => {
        emitter.emit(POPUP_EVENTS.CREATE_REMINDER);
    };

    return (
        <div className='mobile-header'>
            <div className="logo-wrap">
                <img src={Logo} alt="DocQuik" />
            </div>
            <div className='btn-mobile-view-group'>
                {urlPath === "/reminder" ?
                    <button type="button" className="reminder-btn ismobile-btn" onClick={quickCreateReminder}>
                        <img src={PlusIcon} className={layout.isMobileView ? 'me-0' : ''} alt="plusicon" />
                        {!layout.isMobileView ? 'Create Reminder' : null}
                    </button>
                    :
                    null
                }
                {urlPath === '/home' || urlPath === "/files" ? <Dropdown className="dropdown-wraps">
                    <Dropdown.Toggle id="dropdown-upload" className="dropdown-toggle btn-uploads">
                        {/* <button type="button"  className="dropdown-toggle btn-uploads"> */}
                        <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_118_1284)">
                                <path d="M11.4286 5.42857H7.78571C7.66738 5.42857 7.57143 5.33262 7.57143 5.21429V1.57143C7.57143 0.979736 7.09169 0.5 6.5 0.5C5.90831 0.5 5.42857 0.979736 5.42857 1.57143V5.21429C5.42857 5.33262 5.33262 5.42857 5.21429 5.42857H1.57143C0.979736 5.42857 0.5 5.90831 0.5 6.5C0.5 7.09169 0.979736 7.57143 1.57143 7.57143H5.21429C5.33262 7.57143 5.42857 7.66738 5.42857 7.78571V11.4286C5.42857 12.0203 5.90831 12.5 6.5 12.5C7.09169 12.5 7.57143 12.0203 7.57143 11.4286V7.78571C7.57143 7.66738 7.66738 7.57143 7.78571 7.57143H11.4286C12.0203 7.57143 12.5 7.09169 12.5 6.5C12.5 5.90831 12.0203 5.42857 11.4286 5.42857Z" fill="white" />
                            </g>
                            <defs>
                                <clipPath id="clip0_118_1284">
                                    <rect width="12" height="12" fill="white" transform="translate(0.5 0.5)" />
                                </clipPath>
                            </defs>
                        </svg>
                        {!layout.isMobileView ? 'Upload File' : null}
                        {/* </button> */}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className='uploadBtn-menu'>
                        <Dropdown.Item as="button" className="uploadBtn">
                            {/* <button type="button" className="uploadBtn" onClick={closeDropdownUpload}> */}
                            <i className='icon-upload'><MdUploadFile /></i>
                            Upload file
                            <input
                                type="file"
                                name="myfile"
                                id="uploadfile"
                                onChange={handleUploadFile}
                                accept={acceptMimeTypes}
                            />
                            {/* </button> */}
                        </Dropdown.Item>
                        <Dropdown.Item as="button" className="uploadBtn">
                            {/* <button type="button" className="" onClick={closeDropdownUpload}> */}
                            <i className='icon-upload'><BsFiles /></i>
                            Upload files
                            <input
                                type="file"
                                name="files[]"
                                id="uploadfile"
                                multiple="multiple"
                                onChange={handleUploadFiles}
                                accept={acceptMimeTypes}
                            />
                            {/* </button> */}
                        </Dropdown.Item>
                        <Dropdown.Item as="button" className="uploadBtn" onClick={propmtNewFolderName}>
                            {/* <button type="button" className='uploadBtn' onClick={propmtNewFolderName}> */}
                            <i className='icon-upload'><MdOutlineCreateNewFolder /></i>
                            Create folder
                            {/* </button> */}
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                    :
                    null
                }
                <button type="button" className='btns-mob' onClick={mainNavHandler}>{layout.isMainNavOpen ? <MdClear /> : <MdMenu />}</button>
                {urlPath === "/home" || urlPath === "/files" ? <button type="button" className='btns-mob' onClick={activitiesBarClick}><MdMoreVert /></button> : null}
            </div>
        </div>
    )
}

export default withRouter(MobileHeader)
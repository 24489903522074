import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
export default function CustomRedirect({ exclude, to }) {
  if (
    exclude.includes(window.location.pathname) ||
    window.location.pathname.search('/share-file') !== -1 ||
    window.location.pathname.search('/loginFromApp') !== -1
  ) {
    return <Redirect to={window.location.pathname}/>;
  }
  return <Redirect to={to}/>;
}
CustomRedirect.propTypes = {
  exclude: PropTypes.array,
  to: PropTypes.string.isRequired,
};
CustomRedirect.defaultProps = {
  exclude: [],
};

import axios from 'axios';
import CONFIG from '../constants/config';

export const getActivitiesForAdmin = async ({
  export_file,
  member_id,
  from_date,
  to_date,
  page,
  orderBy,
  sortBy,
}) => {
  const response = await axios({
    method: 'GET',
    url: `${CONFIG.BASE_URL}/reports/recent-activities`,
    withCredentials: true,
    params: {
      export_file,
      member_id,
      from_date: from_date !== 'yyyy-mm-dd' ? from_date : undefined,
      to_date: to_date !== 'yyyy-mm-dd' ? to_date : undefined,
      page,
      orderBy,
      sortBy,
    },
  });
  return response.data;
};

export const getStorageForAdmin = async () => {
  const response = await axios({
    method: 'GET',
    url: `${CONFIG.BASE_URL}/reports/storage-stats`,
    withCredentials: true,
  });
  return response.data;
};

export const getActivitiesForUser = async ({ date, from_date, to_date }) => {
  const response = await axios({
    method: 'GET',
    url: `${CONFIG.BASE_URL}/reports`,
    withCredentials: true,
    data: { date, from_date, to_date },
  });
  return response.data;
};

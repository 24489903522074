import { shallowMergeArrayByKey } from '../../utils/string';
import { PASTE_COPIED_FILE_SUCCESS } from '../actions/copiedFile';
import { CHECK_DOCS_SUCCESS } from '../actions/documents';
import {
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_FAILURE,
  RENAME_FILE_SUCCESS,
  RENAME_FILE_FAILURE,
  MOVE_FILE_SUCCESS,
  MOVE_FILE_FAILURE,
  REMOVE_FILE_SUCCESS,
  REMOVE_FILE_FAILURE,
  CHECK_FAVORITE_SUCCESS,
  CHECK_FILE_SUCCESS,
  MULTIPLE_FILE_SELECT,
  REMOVE_SELECTED_FILES
} from '../actions/files';
import { DELETE_FOLDER_SUCCESS } from '../actions/folders';
import { LINK_FILE_SUCCESS, DELETE_LINK_SUCCESS } from '../actions/linkFilePopup';

const initialState = [];

export default function files(state, action) {
  switch (action.type) {
    case CHECK_DOCS_SUCCESS:
      return [
        ...shallowMergeArrayByKey(state, action.payload.files, action.payload.docChecked, 'file_id'),
      ];
    case UPLOAD_FILE_SUCCESS:
    case UPLOAD_FILE_FAILURE:
    case RENAME_FILE_SUCCESS:
      state.find((f) => f.file_id === action.payload.fileId).new_name =
        action.payload.newName;
      return [...state];
    case RENAME_FILE_FAILURE:
    case MOVE_FILE_SUCCESS:
      state.find((f) => f.file_id === action.payload.fileId).parent_id =
        action.payload.newParentId || null;
      return [...state];
    case MOVE_FILE_FAILURE:
    case REMOVE_FILE_SUCCESS:
      return [...state.filter((f) => f.file_id !== action.payload)];
    case CHECK_FAVORITE_SUCCESS:
      if (state.find((f) => f.file_id === action.payload.fileId)) {
        state.find((f) => f.file_id === action.payload.fileId).favorite =
          action.payload.isFavorite;
      }
      return [...state];
    case CHECK_FILE_SUCCESS:
      if (state.find((f) => f.file_id === action.payload.fileId)) {
        state.find((f) => f.file_id === action.payload.fileId).checkout =
          action.payload.isCheck;
      }
      return [...state];
    case REMOVE_FILE_FAILURE:
    case PASTE_COPIED_FILE_SUCCESS:
      return [...state, action.payload];
    case DELETE_FOLDER_SUCCESS: {
      const fileIdsToDelete = [];
      (function getfileIdsToDelte(pid) {
        fileIdsToDelete.push(pid);
        const hasDescendants = state.filter((f) => f.parent_id === pid);
        if (hasDescendants.length) {
          hasDescendants.map((d) => getfileIdsToDelte(d.file_id));
        }
      }(action.payload));
      return [...state.filter((f) => !fileIdsToDelete.includes(f.file_id))];
    }
    case LINK_FILE_SUCCESS: {
      const stateChange1 = state.find(f => f.file_id === action.payload.file_id);
      const stateChange2 = state.find(f => f.file_id === action.payload.file_to_link.file_id);
      if (stateChange1) stateChange1.link += 1;
      if (stateChange2) stateChange2.link += 1;
      return [...state];
    }
    case DELETE_LINK_SUCCESS: {
      // console.log(action.payload)
      const stateChange1 = state.find(f => f.file_id === action.payload.file_id);
      const stateChange2 = state.find(f => f.file_id === action.payload.file_id_to_delete);
      if (stateChange1) stateChange1.link -= 1;
      if (stateChange2) stateChange2.link -= 1;
      return [...state];
    }
    
    default:
      return state || initialState;
  }
}

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFileAnalysisByDate } from '../../redux/actions/analysis';
import {
  Tooltip,
  LineChart,
  XAxis,
  YAxis,
  Legend,
  Line,
  ResponsiveContainer,
} from 'recharts';
import PropTypes from 'prop-types';

const CustomizedAxisTick = ({ x, y, payload }) => (
  <g transform={`translate(${x},${y})`}>
    <text
      x={0}
      y={0}
      dy={16}
      textAnchor="end"
      fill="#666"
      transform="rotate(-50)"
    >
      {payload.value}
    </text>
  </g>
);

export default function AnalysisByDate({ bydate, all, time, unit_of_time }) {
  const dispatch = useDispatch();
  const analysis = useSelector((s) => s.analysis[`by_${bydate}`]);
  useEffect(() => {
    dispatch(getFileAnalysisByDate({ bydate, all, time, unit_of_time }));

  }, [time, unit_of_time]);
  const colors = [
    '#393F93',
    '#95C675',
    '#FEE801',
    '#FFBD28',
    '#FF8A66',
    '#00A9FF',
    '#FF5A46',
  ];

  const chartSVG = document.getElementById('lineUpdateChart');
  if (chartSVG) {
    chartSVG.setAttribute('viewBox', '0 0 300 500');
    chartSVG.setAttribute('width', '300');
  }

  return (
    <div className="chart-line-wraps">
      {!analysis.isLoading && analysis.data.length ? (
        <ResponsiveContainer>
          <LineChart
            // width={600}
            // height={300}
            data={analysis.data}
            // margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
            // wrapperStyle={{ marginTop: '0rem', background: 'red' }}
            id="lineUpdateChart"
          >
            <XAxis
              dataKey="day"
              height={100}
              tick={<CustomizedAxisTick />}
              interval={0}
              className="date-chart-view"
            />
            <YAxis />
            <Tooltip />
            <Legend

              wrapperStyle={{
                paddingTop: '30px',
                display: 'none'
              }}
            />
            <Line type="monotone" dataKey="document" stroke={colors[0]} />
            <Line type="monotone" dataKey="txt" stroke={colors[1]} />
            <Line type="monotone" dataKey="image" stroke={colors[2]} />
            <Line type="monotone" dataKey="powerpoint" stroke={colors[3]} />
            <Line type="monotone" dataKey="excel" stroke={colors[4]} />
            <Line type="monotone" dataKey="pdf" stroke={colors[5]} />
          </LineChart>
        </ResponsiveContainer>
      ) :
        null
      }
    </div>
  );
}
AnalysisByDate.propTypes = {
  bydate: PropTypes.oneOf(['created_at', 'updated_at']).isRequired,
  all: PropTypes.bool,
  time: PropTypes.number,
  unit_of_time: PropTypes.string,
};
AnalysisByDate.defaultProps = {
  all: undefined,
  time: undefined,
  unit_of_time: undefined,
};

import React, { useRef } from 'react';
import Consent from 'react-cookie-consent';
import CONFIG from '../../constants/config';
import { getCookie } from '../../utils/cookie';
export function CookieConsent({ acceptHandler }) {
  const myRef = useRef(null);
  const containerStyle = {
    backgroundColor: 'rgba(49,49,49,.5)',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 9999,
    width: '100vw',
    height: '100vh',
  };
  const handleAccept = () => {
    document.cookie = 'acceptConsent=true';
    myRef.current.remove();
    acceptHandler();
  };
  const handleDecline = () => {
    window.open(`${CONFIG.HOME_PAGE_URL}/login`, '_self');
  };
  return (
    <div ref={myRef} style={!getCookie('acceptConsent') ? containerStyle : {}}>
      <Consent
        className="consent-cooke-wrap"
        location="bottom"
        expires={365}
        cookieName="acceptConsent"
        containerClasses="consent-container"
        contentClasses="consent-content"
        ButtonComponent={() => (
          <>
            <button type="button" className="btn-cookie" onClick={handleAccept}>
              I Accept
            </button>
            <button type="button" className="btn-outline-cookie" onClick={handleDecline}>
              I Decline
            </button>
          </>
        )}
        buttonWrapperClasses="consent-buttons"
        style={{
          background: '#303030',
        }}
      >
        <h2>We value your privacy</h2>
        <br />
        <p>We and our partners use technologies, such as cookies, and process
          personal data, such as IP addresses and cookie identifiers, to
          personalise ads and content based on your interests, measure the
          performance of ads and content, and derive insights about the audiences
          who saw ads and content. Click below to consent to the use of this
          technology and the processing of your personal data for these purposes.
          You can change your mind and change your consent choices at any time by
          returning to this site.
        </p>
      </Consent>
    </div>
  );
}
